import ZebraBrowserPrintWrapper from "zebra-browser-print-wrapper";
import React, { useState } from "react";

// const ProductionprintLabelMeat = ({ printdata, note, barcode, tara, grossWeight }) => {

export async function PrintLabelRicevimentoMeat(printdata, pieces) {
  let labelQty = parseInt(pieces);

  // let notes = note;
  // const [notes, setnotes] = useState({});
  let productNameRowOne;
  let productNameRowTwo;
  let productNameRowThree;
  // let productId;
  // let productTemperature = printdata?.productTemperature ?? "";
  // let productEarTag = "N/A";
  // let productSlaughteredDate = "";
  // let productBatchId = "";
  // let productNameEnglish;
  // let tara = "";
  // let grossWeight = "";
  let note = printdata?.productNotesRowOne;
  let notes = {};
  const noteSplit = (note) => {
    const arr = note.match(/.{1,35}/g);
    const [
      productNotesRowOne,
      productNotesRowTwo,
      productNotesRowThree,
      productNotesRowFour,
      productNotesRowFive,
      productNotesRowSix,
      productNotesRowSeven,
      productNotesRowEight,
      productNotesRowNine,
      productNotesRowTen,
      productNotesRowEleven,
      productNotesRowTwelve,
      productNotesRowThirteen,
      productNotesRowFourteen,
    ] = arr;

    notes = {
      productNotesRowOne,
      productNotesRowTwo,
      productNotesRowThree,
      productNotesRowFour,
      productNotesRowFive,
      productNotesRowSix,
      productNotesRowSeven,
      productNotesRowEight,
      productNotesRowNine,
      productNotesRowTen,
      productNotesRowEleven,
      productNotesRowTwelve,
      productNotesRowThirteen,
      productNotesRowFourteen,
    };
  };
  const nameSplit = (name) => {
    let arr = name.split("");
    let first = arr.slice(0, 56);
    let second = arr.slice(56, 112);
    let third = arr.slice(112);
    productNameRowOne = first.join("");
    productNameRowTwo = second.join("");
    productNameRowThree = third.join("");
  };
  if (printdata?.productNameRowOne) {
    nameSplit(printdata?.productNameRowOne);
  }
  if (note) {
    noteSplit(note);
  }
  // e.stopPropagation();
  while (labelQty > 0) {
    try {
      // Create a new instance of the object
      const browserPrint = new ZebraBrowserPrintWrapper();
      // Select default printer
      const defaultPrinter = await browserPrint.getDefaultPrinter();
      // Set the printer
      browserPrint.setPrinter(defaultPrinter);
      // Check if the printer is ready
      const printerStatus = await browserPrint.checkPrinterStatus();

      if (printerStatus.isReadyToPrint) {
        // ZPL script to print a qr code

        let grande_meat = `^XA
                          ~TA000
                          ~JSN
                          ^LT0
                          ^MNW
                          ^MTT
                          ^PON
                          ^PMN
                          ^LH0,0
                          ^JMA
                          ^PR6,6
                          ~SD15
                          ^JUS
                          ^LRN
                          ^CI27
                          ^PA0,1,1,0
                          ^XZ
                          ^XA
                          ^MMT
                          ^PW831
                          ^LL799
                          ^LS0
                          ^FT626,769^BQN,2,7
                          ^FH\^FDHA,${printdata?.productBarcodeId ? printdata?.productBarcodeId : ""}^FS
                          ^FT115,204^A0N,28,28^FH\^CI28^FD${
                            productNameRowOne ? productNameRowOne : ""
                          }^FS^CI27
                          ^FT115,239^A0N,28,28^FH\^CI28^FD${
                            productNameRowTwo ? productNameRowTwo :""
                          }^FS^CI27
                          ^FT115,281^A0N,23,23^FH\^CI28^FD${
                            printdata?.productNameEnglish ? printdata?.productNameEnglish : ""
                          }^FS^CI27
                          ^FT26,204^A0N,28,28^FH\^CI28^FD${
                            printdata?.productId ? printdata?.productId : ""
                          }^FS^CI27
                          ^FO24,304^GB424,264,1^FS
                          ^FT26,326^AAN,18,10^FH\^FD${
                            notes?.productNotesRowOne ? notes?.productNotesRowOne : ""
                          }^FS
                          ^FT26,344^AAN,18,10^FH\^FD${
                            notes?.productNotesRowTwo ? notes?.productNotesRowTwo : ""
                          }^FS
                          ^FT26,362^AAN,18,10^FH\^FD${
                            notes?.productNotesRowThree ? notes?.productNotesRowThree : ""
                          }^FS
                          ^FT26,380^AAN,18,10^FH\^FD${
                            notes?.productNotesRowFour ? notes?.productNotesRowFour : ""
                          }^FS
                          ^FT26,398^AAN,18,10^FH\^FD${
                            notes?.productNotesRowFive ? notes?.productNotesRowFive : ""
                          }^FS
                          ^FT26,416^AAN,18,10^FH\^FD${
                            notes?.productNotesRowSix ? notes?.productNotesRowSix : ""
                          }^FS
                          ^FT26,434^AAN,18,10^FH\^FD${
                            notes?.productNotesRowSeven ? notes?.productNotesRowSeven : ""
                          }^FS
                          ^FT26,452^AAN,18,10^FH\^FD${
                            notes?.productNotesRowEight ? notes?.productNotesRowEight : ""
                          }^FS
                          ^FT26,470^AAN,18,10^FH\^FD${
                            notes?.productNotesRowNine ? notes?.productNotesRowNine : ""
                          }^FS
                          ^FT26,488^AAN,18,10^FH\^FD${
                            notes?.productNotesRowTen ? notes?.productNotesRowTen : ""
                          }^FS
                          ^FT26,506^AAN,18,10^FH\^FD${
                            notes?.productNotesRowEleven ? notes?.productNotesRowEleven : ""
                          }^FS
                          ^FT26,524^AAN,18,10^FH\^FD${
                            notes?.productNotesRowTwelve ? notes?.productNotesRowTwelve : ""
                          }^FS
                          ^FT26,542^AAN,18,10^FH\^FD${
                            notes?.productNotesRowThirteen ? notes?.productNotesRowThirteen : ""
                          }^FS
                          ^FT26,560^AAN,18,10^FH\^FD${
                            notes?.productNotesRowFourteen ? notes?.productNotesRowFourteen : ""
                          }^FS
                          ^FT464,326^AAN,18,10^FH\^FDLotto/Batch^FS
                          ^FPH,1^FT627,332^A0N,28,28^FH\^CI28^FD${
                            printdata?.productBatchId ? printdata?.productBatchId : ""
                          }^FS^CI27
                          ^FT464,358^AAN,18,10^FH\^FDProd. il/Prod. on^FS
                          ^FPH,1^FT690,361^A0N,25,23^FH\^CI28^FD${
                            printdata?.productArrivalDate ? printdata?.productArrivalDate : ""
                          }^FS^CI27
                          ^FT464,390^AAN,18,10^FH\^FDScadenza/Exp. Date^FS
                          ^FPH,1^FT688,393^A0N,25,23^FH\^CI28^FD${
                            printdata?.productExpireDate ? printdata?.productExpireDate : ""
                          }^FS^CI27
                          ^FT464,430^AAN,18,10^FH\^FDPeso Lordo/Gross Wgt^FS
                          ^FPH,1^FT728,436^A0N,28,28^FH\^CI28^FD${
                            printdata?.productGrossWeight
                              ? (printdata?.productGrossWeight).toFixed(3)
                              : ""
                          } kg^FS^CI27
                          ^FT26,701^AAN,18,10^FH\^FDSezionato/Cut^FS
                          ^FPH,1^FT277,707^A0N,28,28^FH\^CI28^FD${
                            printdata?.productCutCountry ? printdata?.productCutCountry : ""
                          } - ${printdata?.productCeLabel2 ? printdata?.productCeLabel2 : ""}^FS^CI27
                          ^FT26,733^AAN,18,10^FH\^FDAuricolare/Ear Tag^FS
                          ^FPH,1^FT277,739^A0N,28,28^FH\^CI28^FD${
                            printdata?.productEarTag ? printdata?.productEarTag : ""
                          }^FS^CI27
                          ^FT26,605^AAN,18,10^FH\^FDNato/Born^FS
                          ^FPH,1^FT277,611^A0N,28,28^FH\^CI28^FD${
                            printdata?.productBornCountry ? printdata?.productBornCountry : ""
                          }^FS^CI27
                          ^FT26,637^AAN,18,10^FH\^FDAllevato/Raised^FS
                          ^FPH,1^FT277,643^A0N,28,28^FH\^CI28^FD${
                            printdata?.productRaisedCountry ? printdata?.productRaisedCountry : ""
                          }^FS^CI27
                          ^FT26,669^AAN,18,10^FH\^FDMacellato/Slaughtered^FS
                          ^FPH,1^FT277,675^A0N,28,28^FH\^CI28^FD${
                            printdata?.productSlaughteredCountry ? printdata?.productSlaughteredCountry : ""
                          } - ${printdata?.productCeLabel ? printdata?.productCeLabel : ""}^FS^CI27
                          ^FT464,462^AAN,18,10^FH\^FDTara/Tare^FS
                          ^FPH,1^FT728,468^A0N,28,28^FH\^CI28^FD
                          ${
                            printdata?.productTare
                              ? (printdata?.productTare).toFixed(3)
                              : ""
                          } 
                          kg^FS^CI27
                          ^FT464,494^AAN,18,10^FH\^FDPeso Netto/Net Wgt^FS
                          ^FPH,1^FT728,500^A0N,28,28^FH\^CI28^FD${
                            printdata?.productNetWeight
                              ? (printdata?.productNetWeight).toFixed(3)
                              : ""
                          } kg^FS^CI27
                    ^FT656,158^AAN,18,10^FH\^FDTemp^FS
                    ^FPH,1^FT709,161^A0N,25,25^FH\^CI28^FD${
                      printdata?.productTemperature ? printdata?.productTemperature : ""
                    } °C^FS^CI27
                          ^PQ1,0,1,Y
                          ^XZ`;
        browserPrint.print(grande_meat);
        console.log("Print success");
        labelQty--;
      } else {
        console.log("Error/s", printerStatus.errors);
        setTimeout(function (grande_meat) {
          browserPrint.print(grande_meat);
        }, 640);
      }
    } catch (error) {
      throw new Error(error);
    }
  }
}

// const productNameRowOne = "Nome prodotto lungo ma proprio lungo lungo"; // 56 characters max, then use next line
// const productNameRowTwo = " ma davvero molto lungo, ci vogliono ben"; // 56 characters max, then use next line
// const productNameRowThree = "tre righe per scriverlo"; // 56 characters max, then truncate

// const productNameEnglish =
//   "Very long product name that is written in English"; // 68 characters max, then truncate

// const productId = "946863";

// const productNotesRowOne = "These are the notes, row one"; // 35 characters max, then use next line
// const productNotesRowTwo = "These are the notes, row two"; // 35 characters max, then use next line
// const productNotesRowThree = "These are the notes, row three"; // 35 characters max, then use next line
// const productNotesRowFour = "These are the notes, row four"; // 35 characters max, then use next line
// const productNotesRowFive = "These are the notes, row five"; // 35 characters max, then use next line
// const productNotesRowSix = "These are the notes, row six"; // 35 characters max, then use next line
// const productNotesRowSeven = "These are the notes, row seven"; // 35 characters max, then use next line
// const productNotesRowEight = "These are the notes, row eight"; // 35 characters max, then use next line
// const productNotesRowNine = "These are the notes, row nine"; // 35 characters max, then use next line
// const productNotesRowTen = "These are the notes, row ten"; // 35 characters max, then use next line
// const productNotesRowEleven = "These are the notes, row eleven"; // 35 characters max, then use next line
// const productNotesRowTwelve = "These are the notes, row twelve"; // 35 characters max, then use next line
// const productNotesRowThirteen = "These are the notes, row thirteen"; // 35 characters max, then use next line
// const productNotesRowFourteen = "These are the notes, row fourteen"; // 35 characters max, then truncate

// const productArrivalDate = "17/06/2022";
// const productExpireDate = "30/07/2022";

// const productGrossWeight = "5.128";
// const productTare = "0.120";
// const productNetWeight = "5.120";
// const productTemperature = "12"; // if unavailable set N/A as values

// const productCutCountry = "Italia";
// const productCeLabel = "IT M8Y3T CE";
// const productEarTag = "N/A"; // if unavailable set N/A as value
// const productBornCountry = "Francia";
// const productRaisedCountry = "Germania";
// const productSlaughteredCountry = "Svizzera";
// const productSlaughteredDate = "02/06/2022";

// const productBatchId = "BD512XZ";
// const productBarcodeId = "Q1234567890123456789";

// const data = {};

//   React.useLayoutEffect(() => {
//     //printLabel();
//   }, []);

//   return (
//     <div className="App">
//       <button className="btn" onClick={(e) => printLabel(e, data)}>
//         <i className="far fa-print"></i>
//       </button>
//     </div>
//   );
// };

// export default ProductionprintLabelMeat;
