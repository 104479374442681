export const ADD = () => {
  return (
    <span className="me-1">
      <svg
        xmlns="http://www.w3.org/2000/svg"
        viewBox="0 0 24 24"
        width="20"
        height="20"
      >
        <path fill="none" d="M0 0h24v24H0z" />
        <path d="M11 11V7h2v4h4v2h-4v4h-2v-4H7v-2h4zm1 11C6.477 22 2 17.523 2 12S6.477 2 12 2s10 4.477 10 10-4.477 10-10 10zm0-2a8 8 0 1 0 0-16 8 8 0 0 0 0 16z" />
      </svg>
    </span>
  );
};

export const CANCEL = () => (
  <span className="me-1">
    <svg
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 24 24"
      width="20"
      height="20"
    >
      <path d="M12 10.586l4.95-4.95 1.414 1.414-4.95 4.95 4.95 4.95-1.414 1.414-4.95-4.95-4.95 4.95-1.414-1.414 4.95-4.95-4.95-4.95L7.05 5.636z"></path>
    </svg>
  </span>
);

export const UPDATE = () => (
  <span className="me-1">
    <svg
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 24 24"
      width="20"
      height="20"
    >
      <path fill="none" d="M0 0h24v24H0z" />
      <path d="M11 11V7h2v4h4v2h-4v4h-2v-4H7v-2h4zm1 11C6.477 22 2 17.523 2 12S6.477 2 12 2s10 4.477 10 10-4.477 10-10 10zm0-2a8 8 0 1 0 0-16 8 8 0 0 0 0 16z" />
    </svg>
  </span>
);

export const SEARCH = () => (
  <span className="me-1">
    <svg
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 24 24"
      width="20"
      height="20"
    >
      <path d="M18.031 16.617l4.283 4.282-1.415 1.415-4.282-4.283A8.96 8.96 0 0 1 11 20c-4.968 0-9-4.032-9-9s4.032-9 9-9 9 4.032 9 9a8.96 8.96 0 0 1-1.969 5.617zm-2.006-.742A6.977 6.977 0 0 0 18 11c0-3.868-3.133-7-7-7-3.868 0-7 3.132-7 7 0 3.867 3.132 7 7 7a6.977 6.977 0 0 0 4.875-1.975l.15-.15z" />
    </svg>
  </span>
);

export const VIEW = () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 24 24"
    width="24"
    height="24"
  >
    <path fill="none" d="M0 0h24v24H0z" />
    <path d="M12 3c5.392 0 9.878 3.88 10.819 9-.94 5.12-5.427 9-10.819 9-5.392 0-9.878-3.88-10.819-9C2.121 6.88 6.608 3 12 3zm0 16a9.005 9.005 0 0 0 8.777-7 9.005 9.005 0 0 0-17.554 0A9.005 9.005 0 0 0 12 19zm0-2.5a4.5 4.5 0 1 1 0-9 4.5 4.5 0 0 1 0 9zm0-2a2.5 2.5 0 1 0 0-5 2.5 2.5 0 0 0 0 5z" />
  </svg>
);

export const MODIFICATION = () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 24 24"
    width="24"
    height="24"
  >
    <path fill="none" d="M0 0h24v24H0z" />
    <path d="M5 19h1.414l9.314-9.314-1.414-1.414L5 17.586V19zm16 2H3v-4.243L16.435 3.322a1 1 0 0 1 1.414 0l2.829 2.829a1 1 0 0 1 0 1.414L9.243 19H21v2zM15.728 6.858l1.414 1.414 1.414-1.414-1.414-1.414-1.414 1.414z" />
  </svg>
);

export const DELETE = () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 24 24"
    width="24"
    height="24"
  >
    <path fill="none" d="M0 0h24v24H0z" />
    <path d="M17 6h5v2h-2v13a1 1 0 0 1-1 1H5a1 1 0 0 1-1-1V8H2V6h5V3a1 1 0 0 1 1-1h8a1 1 0 0 1 1 1v3zm1 2H6v12h12V8zm-9 3h2v6H9v-6zm4 0h2v6h-2v-6zM9 4v2h6V4H9z" />
  </svg>
);

export const RESET = () => (
  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 43.82 43.82">
    <defs></defs>
    <g id="Livello_2" data-name="Livello 2">
      <g id="Livello_1-2" data-name="Livello 1">
        <g id="Raggruppa_109" data-name="Raggruppa 109">
          <path
            id="Sottrazione_15"
            data-name="Sottrazione 15"
            className="cls-1"
            d="M21.91,43.82A21.91,21.91,0,0,1,6.42,6.42a21.91,21.91,0,1,1,31,31A21.8,21.8,0,0,1,21.91,43.82ZM11.2,23.39A10.27,10.27,0,1,0,21.47,13.12V8L15.05,14.4l6.42,6.42V15.68a7.71,7.71,0,1,1-7.7,7.7Z"
          />
        </g>
      </g>
    </g>
  </svg>
);

export const RETURN = () => (
  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 44 44">
    <defs></defs>
    <g id="Livello_2" data-name="Livello 2">
      <g id="Livello_1-2" data-name="Livello 1">
        <g id="Raggruppa_98" data-name="Raggruppa 98">
          <path
            id="ic_cancel_24px"
            data-name="ic cancel 24px"
            className="cls-1"
            d="M22,0A22,22,0,1,0,44,22,22,22,0,0,0,22,0ZM33,29.9,29.9,33,22,25.1,14.1,33,11,29.9,18.9,22,11,14.1,14.1,11,22,18.9,29.9,11,33,14.1,25.1,22Z"
          />
        </g>
      </g>
    </g>
  </svg>
);

export const SAVE = () => (
  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 43.82 43.81">
    <defs></defs>
    <g id="Livello_2" data-name="Livello 2">
      <g id="Livello_1-2" data-name="Livello 1">
        <g id="Raggruppa_98" data-name="Raggruppa 98">
          <path
            id="Sottrazione_13"
            data-name="Sottrazione 13"
            className="cls-1"
            d="M21.91,43.81A21.9,21.9,0,0,1,0,21.91,21.92,21.92,0,0,1,21.91,0,21.91,21.91,0,0,1,37.4,37.4,21.75,21.75,0,0,1,21.91,43.81ZM9.83,21.52,7.51,23.83,16.66,33,36.3,13.34,34,11,16.66,28.35,9.83,21.52Z"
          />
        </g>
      </g>
    </g>
  </svg>
);

export const MESSAGE = () => (
  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 44.23 44.21">
    <defs></defs>
    <g id="Livello_2" data-name="Livello 2">
      <g id="Livello_1-2" data-name="Livello 1">
        <path
          id="Icon_material-message"
          data-name="Icon material-message"
          className="cls-1"
          d="M4.42,0H39.79c2.43,0,4.78.48,4.4,4.42l0,39.79-8.84-8.84H4.42A4.43,4.43,0,0,1,0,31V4.42A4.43,4.43,0,0,1,4.42,0ZM8.84,26.53H35.37V22.11H8.84Zm0-6.63H35.37V15.48H8.84Zm0-6.64H35.37V8.84H8.84Z"
        />
      </g>
    </g>
  </svg>
);

export const SETTING = () => (
  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 44.81 46.05">
    <defs></defs>
    <g id="Livello_2" data-name="Livello 2">
      <g id="Livello_1-2" data-name="Livello 1">
        <path
          id="ic_settings_24px"
          data-name="ic settings 24px"
          className="cls-1"
          d="M39.14,25.23A18.1,18.1,0,0,0,39.3,23a17.94,17.94,0,0,0-.16-2.2l4.75-3.72a1.13,1.13,0,0,0,.27-1.44l-4.5-7.79a1.13,1.13,0,0,0-1.37-.5L32.68,9.62a16.4,16.4,0,0,0-3.8-2.21l-.86-6A1.09,1.09,0,0,0,26.91.5h-9a1.1,1.1,0,0,0-1.1,1l-.86,6a17.61,17.61,0,0,0-3.8,2.21L6.53,7.37a1.11,1.11,0,0,0-1.38.5L.65,15.66A1.12,1.12,0,0,0,.92,17.1l4.76,3.72a15.27,15.27,0,0,0,0,4.41L.92,29a1.14,1.14,0,0,0-.27,1.44l4.5,7.79a1.14,1.14,0,0,0,1.38.5l5.61-2.25a16.77,16.77,0,0,0,3.8,2.21l.86,6a1.1,1.1,0,0,0,1.1.95h9A1.1,1.1,0,0,0,28,44.6l.85-6a17.86,17.86,0,0,0,3.81-2.21l5.61,2.25a1.09,1.09,0,0,0,1.37-.5l4.5-7.79A1.14,1.14,0,0,0,43.88,29ZM22.41,30.91A7.89,7.89,0,1,1,30.29,23,7.89,7.89,0,0,1,22.41,30.91Z"
        />
      </g>
    </g>
  </svg>
);

export const USERPROFILEICON = () => (
  <i>
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="16"
      height="16"
      fill="currentColor"
      className="bi bi-person"
      viewBox="0 0 16 16"
    >
      <path d="M8 8a3 3 0 1 0 0-6 3 3 0 0 0 0 6zm2-3a2 2 0 1 1-4 0 2 2 0 0 1 4 0zm4 8c0 1-1 1-1 1H3s-1 0-1-1 1-4 6-4 6 3 6 4zm-1-.004c-.001-.246-.154-.986-.832-1.664C11.516 10.68 10.289 10 8 10c-2.29 0-3.516.68-4.168 1.332-.678.678-.83 1.418-.832 1.664h10z" />
    </svg>
  </i>
);

export const LOGOUTICON = () => (
  <svg version="1.1" viewBox="0 0 24 24">
    <g id="info" />
    <g id="icons">
      <g id="exit2">
        <path d="M12,10c1.1,0,2-0.9,2-2V4c0-1.1-0.9-2-2-2s-2,0.9-2,2v4C10,9.1,10.9,10,12,10z" />
        <path d="M19.1,4.9L19.1,4.9c-0.3-0.3-0.6-0.4-1.1-0.4c-0.8,0-1.5,0.7-1.5,1.5c0,0.4,0.2,0.8,0.4,1.1l0,0c0,0,0,0,0,0c0,0,0,0,0,0    c1.3,1.3,2,3,2,4.9c0,3.9-3.1,7-7,7s-7-3.1-7-7c0-1.9,0.8-3.7,2.1-4.9l0,0C7.3,6.8,7.5,6.4,7.5,6c0-0.8-0.7-1.5-1.5-1.5    c-0.4,0-0.8,0.2-1.1,0.4l0,0C3.1,6.7,2,9.2,2,12c0,5.5,4.5,10,10,10s10-4.5,10-10C22,9.2,20.9,6.7,19.1,4.9z" />
      </g>
    </g>
  </svg>
);

export const NAVTOGGLE = () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="16"
    height="16"
    fill="currentColor"
    className="bi bi-list"
    viewBox="0 0 16 16"
  >
    <path d="M2.5 12a.5.5 0 0 1 .5-.5h10a.5.5 0 0 1 0 1H3a.5.5 0 0 1-.5-.5zm0-4a.5.5 0 0 1 .5-.5h10a.5.5 0 0 1 0 1H3a.5.5 0 0 1-.5-.5zm0-4a.5.5 0 0 1 .5-.5h10a.5.5 0 0 1 0 1H3a.5.5 0 0 1-.5-.5z" />
  </svg>
);

export const WEIGHTICON = () => (
  <i className="weightIcon">
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 63 63.13">
      <defs></defs>
      <g id="Livello_2" data-name="Livello 2">
        <g id="Livello_1-2" data-name="Livello 1">
          <path
            id="Icon_awesome-weight-hanging"
            data-name="Icon awesome-weight-hanging"
            className="cls-1"
            d="M61.3,53.88,52.74,19.65a3.83,3.83,0,0,0-3.62-3H42.06a11.25,11.25,0,1,0-21.12,0H13.88a3.84,3.84,0,0,0-3.62,3L1.7,53.88c-1,3.91,1.72,7.75,5.43,7.75H55.87C59.58,61.63,62.28,57.79,61.3,53.88ZM31.5,16.63a3.75,3.75,0,1,1,3.75-3.75A3.75,3.75,0,0,1,31.5,16.63Z"
          />
        </g>
      </g>
    </svg>
  </i>
);

export const PLUSICON = () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="none"
    stroke="currentColor"
    strokeWidth="2"
    strokeLinecap="round"
    strokeLinejoin="round"
    className="feather feather-plus-circle"
  >
    <circle cx="12" cy="12" r="10"></circle>
    <line x1="12" y1="8" x2="12" y2="16"></line>
    <line x1="8" y1="12" x2="16" y2="12"></line>
  </svg>
);

export const CALCULATEICON = () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="30"
    height="30"
    viewBox="0 0 24 24"
    fill="none"
    stroke="currentColor"
    strokeWidth="2"
    strokeLinecap="round"
    strokeLinejoin="round"
    className="feather feather-download-cloud"
  >
    <polyline points="8 17 12 21 16 17"></polyline>
    <line x1="12" y1="12" x2="12" y2="21"></line>
    <path d="M20.88 18.09A5 5 0 0 0 18 9h-1.26A8 8 0 1 0 3 16.29"></path>
  </svg>
);

export const NavArrow = () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="none"
    stroke="currentColor"
    strokeWidth="2"
    strokeLinecap="round"
    strokeLinejoin="round"
    className="feather feather-navigation-2"
  >
    <polygon points="12 2 19 21 12 17 5 21 12 2"></polygon>
  </svg>
);
