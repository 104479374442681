import ZebraBrowserPrintWrapper from "zebra-browser-print-wrapper";

export async function ProductionIsRanNonMeatLabel(printdata) {
    let note = printdata?.productNotesRowOne;
    let productName = printdata?.productNameRowOne;
    let notes = {};
    let productNameRowOne;
    let productNameRowTwo;
    let productNameRowThree;

    const noteSplit = (note) => {
        const arr = note.match(/.{1,35}/g);
        const [
            productNotesRowOne,
            productNotesRowTwo,
            productNotesRowThree,
            productNotesRowFour,
            productNotesRowFive,
            productNotesRowSix,
            productNotesRowSeven,
            productNotesRowEight,
            productNotesRowNine,
            productNotesRowTen,
            productNotesRowEleven,
            productNotesRowTwelve,
            productNotesRowThirteen,
            productNotesRowFourteen,
            productNotesRowFifteen,
            productNotesRowSixteen,
            productNotesRowSeventeen,
            productNotesRowEighteen,
            productNotesRowNineteen,
            productNotesRowTwenty,
            productNotesRowTwentyone,
            productNotesRowTwentytwo,
            productNotesRowTwentythree,
            productNotesRowTwentyfour,
        ] = arr;
        notes = {
            productNotesRowOne,
            productNotesRowTwo,
            productNotesRowThree,
            productNotesRowFour,
            productNotesRowFive,
            productNotesRowSix,
            productNotesRowSeven,
            productNotesRowEight,
            productNotesRowNine,
            productNotesRowTen,
            productNotesRowEleven,
            productNotesRowTwelve,
            productNotesRowThirteen,
            productNotesRowFourteen,
            productNotesRowFifteen,
            productNotesRowSixteen,
            productNotesRowSeventeen,
            productNotesRowEighteen,
            productNotesRowNineteen,
            productNotesRowTwenty,
            productNotesRowTwentyone,
            productNotesRowTwentytwo,
            productNotesRowTwentythree,
            productNotesRowTwentyfour,
        };
    };

    const nameSplit = (name) => {
        let arr = name.split("");
        let first = arr.slice(0, 56);
        let second = arr.slice(56, 112);
        let third = arr.slice(112);
        productNameRowOne = first.join("");
        productNameRowTwo = second.join("");
        productNameRowThree = third.join("");
    };

    if (productName) {
        nameSplit(productName);
    }

    if (note && note.match(/.{1,35}/g)) {
        noteSplit(note);
    }

    try {
        // Create a new instance of the object
        const browserPrint = new ZebraBrowserPrintWrapper();
        // Select default printer
        const defaultPrinter = await (browserPrint.getDefaultPrinter());
        // Set the printer
        browserPrint.setPrinter(defaultPrinter);
        // Check if the printer is ready
        const printerStatus = await browserPrint.checkPrinterStatus();

        if (printerStatus.isReadyToPrint) {
            // ZPL script to print a qr code

            let grande_nonmeat = `^XA
            ~TA000
            ~JSN
            ^LT0
            ^MNW
            ^MTT
            ^PON
            ^PMN
            ^LH0,0
            ^JMA
            ^PR6,6
            ~SD15
            ^JUS
            ^LRN
            ^CI27
            ^PA0,1,1,0
            ^XZ
            ^XA
            ^MMT
            ^PW831
            ^LL799
            ^LS0
            ^FT544,722^BQN,2,7
            ^FH\^FDHA,${printdata?.productBarcodeId ? printdata?.productBarcodeId : ""}^FS
            ^FT115,193^A0N,28,28^FH\^CI28^FD${productNameRowOne ? productNameRowOne : ""}^FS^CI27
            ^FT115,228^A0N,28,28^FH\^CI28^FD${productNameRowTwo ? productNameRowTwo : ""}^FS^CI27
            ^FT115,260^A0N,23,23^FH\^CI28^FD${printdata?.productNameEnglish ? printdata?.productNameEnglish : ""}^FS^CI27
            ^FT24,193^A0N,28,28^FH\^CI28^FD${printdata?.productId ? printdata?.productId : ""}^FS^CI27
            ^FO12,277^GB428,436,1^FS
            ^FT16,291^AAN,18,10^FH\^FD${notes?.productNotesRowOne ? notes?.productNotesRowOne : ""}^FS
            ^FT16,309^AAN,18,10^FH\^FD${notes?.productNotesRowTwo ? notes?.productNotesRowTwo : ""}^FS
            ^FT16,327^AAN,18,10^FH\^FD${notes?.productNotesRowThree ? notes?.productNotesRowThree : ""}^FS
            ^FT16,345^AAN,18,10^FH\^FD${notes?.productNotesRowFour ? notes?.productNotesRowFour : ""}^FS
            ^FT16,363^AAN,18,10^FH\^FD${notes?.productNotesRowFive ? notes?.productNotesRowFive : ""}^FS
            ^FT16,381^AAN,18,10^FH\^FD${notes?.productNotesRowSix ? notes?.productNotesRowSix : ""}^FS
            ^FT16,399^AAN,18,10^FH\^FD${notes?.productNotesRowSeven ? notes?.productNotesRowSeven : ""}^FS
            ^FT16,417^AAN,18,10^FH\^FD${notes?.productNotesRowEight ? notes?.productNotesRowEight : ""}^FS
            ^FT16,435^AAN,18,10^FH\^FD${notes?.productNotesRowNine ? notes?.productNotesRowNine : ""}^FS
            ^FT16,453^AAN,18,10^FH\^FD${notes?.productNotesRowTen ? notes?.productNotesRowTen : ""}^FS
            ^FT16,471^AAN,18,10^FH\^FD${notes?.productNotesRowEleven ? notes?.productNotesRowEleven : ""}^FS
            ^FT16,489^AAN,18,10^FH\^FD${notes?.productNotesRowTwelve ? notes?.productNotesRowTwelve : ""}^FS
            ^FT16,507^AAN,18,10^FH\^FD${notes?.productNotesRowThirteen ? notes?.productNotesRowThirteen : ""}^FS
            ^FT16,525^AAN,18,10^FH\^FD${notes?.productNotesRowFourteen ? notes?.productNotesRowFourteen : ""}^FS
            ^FT16,543^AAN,18,10^FH\^FD${notes?.productNotesRowFifteen ? notes?.productNotesRowFifteen : ""}^FS
            ^FT16,561^AAN,18,10^FH\^FD${notes?.productNotesRowSixteen ? notes?.productNotesRowSixteen : ""}^FS
            ^FT16,579^AAN,18,10^FH\^FD${notes?.productNotesRowSeventeen ? notes?.productNotesRowSeventeen : ""}^FS
            ^FT16,597^AAN,18,10^FH\^FD${notes?.productNotesRowEighteen ? notes?.productNotesRowEighteen : ""}^FS
            ^FT16,615^AAN,18,10^FH\^FD${notes?.productNotesRowNineteen ? notes?.productNotesRowNineteen : ""}^FS
            ^FT16,633^AAN,18,10^FH\^FD${notes?.productNotesRowTwenty ? notes?.productNotesRowTwenty : ""}^FS
            ^FT16,651^AAN,18,10^FH\^FD${notes?.productNotesRowTwentyone ? notes?.productNotesRowTwentyone : ""}^FS
            ^FT16,669^AAN,18,10^FH\^FD${notes?.productNotesRowTwentytwo ? notes?.productNotesRowTwentytwo : ""}^FS
            ^FT16,687^AAN,18,10^FH\^FD${notes?.productNotesRowTwentythree ? notes?.productNotesRowTwentythree : ""}^FS
            ^FT16,705^AAN,18,10^FH\^FD${notes?.productNotesRowTwentyfour ? notes?.productNotesRowTwentyfour : ""}^FS
            ^FT464,315^AAN,18,10^FH\^FDLotto/Batch^FS
            ^FPH,1^FT622,321^A0N,28,28^FH\^CI28^FD${printdata?.productBatchId ? printdata?.productBatchId : ""}^FS^CI27
            ^FT464,347^AAN,18,10^FH\^FDProd. il/Prod. on^FS
            ^FPH,1^FT690,350^A0N,25,23^FH\^CI28^FD${printdata?.productArrivalDate ? printdata?.productArrivalDate : ""
                }^FS^CI27
            ^FT464,379^AAN,18,10^FH\^FDScadenza/Exp. Date^FS
            ^FPH,1^FT688,382^A0N,25,23^FH\^CI28^FD${printdata?.productExpireDate ? printdata?.productExpireDate : ""
                }^FS^CI27
            ^FT464,419^AAN,18,10^FH\^FDPeso Lordo/Gross Wgt^FS
            ^FPH,1^FT728,425^A0N,28,28^FH\^CI28^FD${printdata?.productGrossWeight ? printdata?.productGrossWeight : ""} kg^FS^CI27
            ^FT464,451^AAN,18,10^FH\^FDTara/Tare^FS
            ^FPH,1^FT728,457^A0N,28,28^FH\^CI28^FD${printdata?.productTare ? printdata?.productTare : ""} kg^FS^CI27
            ^FT464,483^AAN,18,10^FH\^FDPeso Netto/Net Wgt^FS
            ^FPH,1^FT728,489^A0N,28,28^FH\^CI28^FD${printdata?.productNetWeight ? printdata?.productNetWeight : ""} kg^FS^CI27
            ^FT689,147^AAN,18,10^FH\^FDTemp^FS
            ^FPH,1^FT742,150^A0N,25,25^FH\^CI28^FD${printdata?.productTemperature ? printdata?.productTemperature : ""} °C^FS^CI27
            ^FT181,787^A0N,17,23^FH\^CI28^FD(01)${printdata?.productEanGtin ? printdata.productEanGtin : ""}(15)${printdata?.productExpireDate ? printdata.productExpireDate : ""}(3103)${printdata?.productNetWeight ? printdata.productNetWeight : ""}(10)${printdata?.productBatchId ? printdata.productBatchId : ""}^FS^CI27
            ^BY2,3,45^FT163,764^BCN,,N,N
            ^FH\^FD>;>801${printdata?.productEanGtin ? printdata.productEanGtin : ""}15${printdata?.productExpireDate ? printdata.productExpireDate : ""}3103${printdata?.productNetWeight ? printdata.productNetWeight : ""}10${printdata?.productBatchId ? printdata.productBatchId : ""}^FS
            ^PQ1,0,1,Y
            ^XZ`
                ;

            browserPrint.print(grande_nonmeat);
            console.log("Print success");
            // labelsQty--;
        }
        else {
            console.log("Error/s", printerStatus.errors);
            setTimeout(function (grande_nonmeat) {
                browserPrint.print(grande_nonmeat);
            }, 640);
        }

    } catch (error) {
        throw new Error(error);
    }
}