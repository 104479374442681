import React, { useState, useEffect } from "react";
import SideBar from "../../components/Sidebar/sideBar";
import Header from "../../components/header/header";
import useRequest from "../../hooks/useRequest";
import { useHistory, useLocation } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import Scanner from "../../components/scanner/scanner";
import { Html5Qrcode } from "html5-qrcode";
import { toast } from "react-toastify";
import { useTranslation } from "react-i18next";
import Print from "../../components/printlabel/printLabel";
import Swal from "sweetalert2";
import withReactContent from "sweetalert2-react-content";
import { generateBarCode } from "../../components/Utils/generateBarCode";
import Autosuggest from "react-autosuggest";
import { getFilteredProducts } from "../../components/Utils/Utils";

const qrConfig = { fps: 10, qrbox: { width: 300, height: 300 } };
// const brConfig = { fps: 10, qrbox: { width: 300, height: 150 } };
let html5QrCode;

const ListaOdv = () => {
  const history = useHistory();
  const { userRoleId } = useSelector((state) => state.auth);
  const location = useLocation();
  const MySwal = withReactContent(Swal);
  const [t, i18n] = useTranslation("translation");
  const orderData = location.state ? location.state.data : "";
  const { request, response } = useRequest();
  const { request: pickingOrderRequest, response: pickingOrderResponse } =
    useRequest();
  const { request: pickingProcessRequest, response: pickingProcessResponse } =
    useRequest();
  const {
    request: pickedProdDeleteRequest,
    response: pickedProdDeleteResponse,
  } = useRequest();
  const { request: pickingProductSearchRequest, response: pickingProductSearchResponse } =
    useRequest();

  const [pickingOrder, setPickingOrder] = useState([]);
  const [pickedOrder, setPickedOrder] = useState([]);
  const [pickingIds, setPickingIds] = useState([]);
  const [isPlay, setIsPlay] = useState(true);
  const [barCode, setBarCode] = useState("");
  const [idPickingOrder, setIdPickingOrder] = useState("");
  const [scanBarcode, setScanBarcode] = useState(null);
  const [procDataPer, setProcDataPer] = useState(
    orderData?.picked_products_percent
  );
  const [products, setProducts] = useState([]);
  const [productSuggestion, setProductSuggestion] = useState([]);
  const [selectedProductName, setSelectedProductName] = useState("");
  const [productSearchQuery, setProductSearchQuery] = useState("");


  const handleClick = (myLink, product) => {
    var barCodeX = generateBarCode("A")
    if(product?.unit_measure === "PZ"){
      if(pickedOrder.length > 0){
        pickedOrder.forEach((item)=>{
          if(item?.id_article === product?.id_article && item?.picking_order?.id_selling_point === product?.id_selling_point){
            if(item?.barcode){
              barCodeX = item?.barcode
            }
          }
        })
      }
    }
    history.push({
      pathname : myLink,
      state: {orderData,product,barCodeX,procDataPer}
    })
  };

  useEffect(() => {
    // document.body.classList.remove("shipmentbody");
    document.body.className = "";
  }, []);

  useEffect(() => {
    if(scanBarcode && scanBarcode.length === 20){
      pickingProcessRequest("POST", "scan-product-process", {
        barcode: scanBarcode,
        id_selling_point: orderData?.id_selling_point,
        delivery_date: orderData?.delivery_date,
      });
    }
  }, [scanBarcode]);

  useEffect(() => {
    if (orderData) {
      request("POST", "picking-orders", {
        id_selling_point: orderData?.id_selling_point,
        date: orderData?.delivery_date,
      });
    }

    return () => html5QrCode.clear();
  }, [pickedProdDeleteResponse]);

  useEffect(() => {
    if (response) {
      if (response.status === "SUCCESS") {
        setPickingOrder(response?.data?.picking_orders);
        setPickedOrder(response?.data?.picked_orders);
        setIdPickingOrder(response?.data?.id_picking_order);
        setProcDataPer(response?.data?.percentage);
        setProducts(response?.data?.products);
      }
    }
  }, [response]);

  useEffect(() => {
    if (pickedOrder.length > 0) {
      setIsPlay(false);
      pickedOrder.map((prod) => {
        let ids = pickingIds;
        ids.push(prod.id_article);
        setPickingIds(ids);
      });
    }
  }, [pickedOrder]);

  const sendResult = () => {
    setIsPlay(true);
    pickingOrderRequest("POST", "picking-close", {
      id_picking_order: idPickingOrder,
    });
  };
  useEffect(() => {
    if (pickingOrderResponse && pickingOrderResponse?.status === "SUCCESS") {
      setIsPlay(false);
      history.push("/raccolta");
      toast.success(pickingOrderResponse?.message);
    } else {
      toast.error(pickingOrderResponse?.message);
    }
  }, [pickingOrderResponse]);

  useEffect(() => {
    if (barCode) {
      pickingProcessRequest("POST", "scan-product-process", {
        barcode: barCode,
        id_selling_point: orderData?.id_selling_point,
        delivery_date: orderData?.delivery_date,
      });
      setBarCode('')
    }
  }, [barCode]);

  useEffect(() => {
    if (pickedProdDeleteResponse?.status === "SUCCESS") {
      toast.success(pickedProdDeleteResponse?.message);
    } else {
      toast.error(pickedProdDeleteResponse?.message);
    }
  }, [pickedProdDeleteResponse]);

  useEffect(() => {
    if (
      pickingProcessResponse &&
      pickingProcessResponse?.status === "SUCCESS"
    ) {
      request("POST", "picking-orders", {
        id_selling_point: orderData?.id_selling_point,
        date: orderData?.delivery_date,
      });
      toast.success(pickingProcessResponse?.message);
      setScanBarcode('')
    } else {
      toast.error(pickingProcessResponse?.message);
      setScanBarcode('')
    }
  }, [pickingProcessResponse]);

  useEffect(() => {
    html5QrCode = new Html5Qrcode("reader");
  }, []);

  const handleClickAdvanced = () => {
    const qrCodeSuccessCallback = (decodedText, decodedResult) => {
      setBarCode(decodedText);

      if (decodedText) {
        handleStop();
      }
    };
    html5QrCode.start(
      { facingMode: "environment" },
      qrConfig,
      qrCodeSuccessCallback
    );
  };

  const handleStop = () => {
    try {
      html5QrCode
        .stop()
        .then((res) => {
          setBarCode("");
          html5QrCode.clear();
        })
        .catch((err) => {
          setBarCode("");
          console.info(err, " < error scan html5QrCode");
        });
    } catch (err) {
      setBarCode("");
      console.info(err, " < error scan");
    }
  };

  const circleClass = (status) => {
    if (status === "R") {
      return "statusCircle bg-danger";
    } else if (status === "Y") {
      return "statusCircle bg-warning";
    } else if (status === "G") {
      return "statusCircle bg-success";
    }
  };

  const deletePikedProdHandler = (id) => {
    MySwal.fire({
      title: t("Are you sure"),
      text: t("Delete Product"),
      icon: "warning",
      showCancelButton: true,
      confirmButtonText: t("Yup"),
      cancelButtonText: t("Cancel"),
      reverseButtons: true,
    }).then((result) => {
      if (result.isConfirmed) {
        pickedProdDeleteRequest("GET", `delete-picking-product/${id}`);
      } else if (result.isDismissed) {
      }
    });
  };

  const renderSuggestion = (suggestion) => suggestion;

  const searchProduct = ({value, reason}) => {
    const filteredProducts = getFilteredProducts(value, products);
    setProductSuggestion(filteredProducts);
  }

  const afterSearchClear = () => {
    setProductSuggestion(products);
  };

  const getSelectedValue = (suggestion) => {
    setProductSuggestion(products);
    setSelectedProductName(suggestion);
    pickingProductSearchRequest(
      "POST",
      "picking-product-search",
      {
        product_name: suggestion,
        id_selling_point: orderData?.id_selling_point,
        date: orderData?.delivery_date
      }
    )
    return suggestion;
  };

  useEffect(() => {
    if(pickingProductSearchResponse && pickingProductSearchResponse?.status === "SUCCESS"){
      setPickingOrder(pickingProductSearchResponse?.data?.picking_orders);
      setProductSearchQuery(selectedProductName)
    }
  }, [pickingProductSearchResponse])

  const onChange = (e) => {
    e.preventDefault();
    setProductSearchQuery(e.target.value)
  };

  const inputProps = {
    placeholder: t("product name"),
    value: productSearchQuery ? productSearchQuery : "",
    onChange,
  };

  const handleSearchReset = () => {
    setProductSearchQuery('')
    setSelectedProductName('');
    pickingProductSearchRequest(
      "POST",
      "picking-product-search",
      {
        product_name: '',
        id_selling_point: orderData?.id_selling_point,
        date: orderData?.delivery_date
      }
    )
  }

  return (
    <>
      {userRoleId === 5 || userRoleId === 4 ? (
        <div>
          <div className="dashboard_parent">
            {/* <!-- Sidebar --> */}

            <SideBar />
            <div className="dashboard_right">
              {/* <!-- dashboard header --> */}
              <Header
                title={t("Picking")}
                links={[{ to: "/raccolta", name: "ODV" }]}
              />

              {/* <!-- dashboard Content --> */}

              <div className="dashboard_content">
                <div className="dashboard-container">
                  <div className="p-3">
                    <div className="orderList-data">
                      {/* Order List Item */}
                      <div className="orderList-row">
                        {procDataPer <= 100 ? (
                          <div className="sm-progress">
                            <div
                              className="sm-progressInner"
                              style={{ height: `${procDataPer}%` }}
                            ></div>
                            <span>
                              {procDataPer !== 0 ? `${procDataPer} %` : ""}
                            </span>
                          </div>
                        ) : (
                          <div className="sm-progress">
                            <div
                              className="sm-progressInner"
                              style={{ height: `${procDataPer}%`, backgroundColor: '#f9ab00' }}
                            ></div>
                            <span>
                              {procDataPer !== 0 ? `${procDataPer} %` : ""}
                            </span>
                          </div>
                        )}
                        
                        <div className="suppliername">
                          <label>{t("Customer")}</label>
                          <h6>{orderData?.customer_description}</h6>
                        </div>

                        <div className="orderno">
                          <label>{t("Delivery Date")}</label>
                          <h6>{orderData?.delivery_date}</h6>
                        </div>
                        <div className="orderqty">
                          <label>{t("Qty ordered")}</label>
                          <h6>{orderData?.total_pieces}</h6>
                        </div>
                        <div className="orderqty">
                          <label> {t("Qty processed")}</label>
                          <h6> {orderData?.total_picked_pieces}</h6>
                        </div>
                      </div>

                      {/* Table */}
                      <div className="orderList-tabledata">
                        <div className="row">
                          <div className="col-md-6">
                            <div className="headerInput themeInput">
                              <label for="qrcode-picking">{t("Qr Code")}</label>
                              <input type="text" value={scanBarcode} onChange={(e) => {setScanBarcode(e.target.value)}} id="qrcode-picking" className="form-control w-25" placeholder={t("Qr Code")} autoFocus/>
                            </div>
                          </div>
                          <div className="col-md-4 offset-md-2">
                            <div className="supplierSearch themeInput themeIS">
                              <label>{t("Search for products")}</label>
                              <form
                                onSubmit={(e)=>{e.preventDefault()}}
                                className="position-relative"
                              >
                                <Autosuggest
                                  suggestions={productSuggestion}
                                  onSuggestionsFetchRequested={
                                    searchProduct
                                  }
                                  onSuggestionsClearRequested={
                                    afterSearchClear
                                  }
                                  // alwaysRenderSuggestions={true} 
                                  getSuggestionValue={getSelectedValue}
                                  renderSuggestion={renderSuggestion}
                                  inputProps={inputProps}
                                />
                                <button className="themeIS-btn" type="submit" onClick={handleSearchReset}>
                                  <i className="fas fa-search"></i>
                                </button>
                              </form>
                            </div>
                          </div> 
                        </div>
                        <div className="row mt-5">
                          <div className="col-md-2 offset-md-10">
                            <Scanner func={handleClickAdvanced} />
                          </div>
                        </div>
                        

                        <div className="table-responsive">
                          <table className="table themeTable table-bordered table-striped">
                            <thead>
                              <tr className="">
                                <th></th>
                                {/* <th>{t("Article Id")}</th> */}
                                <th>{t("Description")}</th>
                                <th>{t("Pieces")}</th>
                                <th>{t("Weight")}</th>
                                {/* <th>Note</th> */}
                                <th>{t("Batch Code")}</th>
                                <th>{t("Expiring Date")}</th>
                              </tr>
                            </thead>
                            <tbody>
                              {/* {pickingOrder &&
                                pickingOrder.map((order, index) =>
                                  console.log(order)
                                )} */}
                              {pickingOrder &&
                                pickingOrder.map((order, index) => (
                                  <tr
                                    onClick={() =>
                                      handleClick("/dettagli-elenco-odv", order)
                                    }
                                    className="cursor-pointer"
                                  >
                                    <td>
                                      <div className="sm-statusbox">
                                        <div
                                          className={circleClass(order?.status)}
                                        ></div>
                                      </div>
                                    </td>
                                    {/* <td data-th={t("Article Id")}>
                                      {order?.id_article}
                                    </td> */}
                                    <td data-th={t("Description")}>
                                      {order?.product_description}
                                    </td>
                                    <td data-th={t("Pieces")}>{order?.pieces}</td>
                                    <td data-th={t("Weight")}>{order?.weight}</td>
                                    {/* <td>{order?.delivery_note}</td> */}
                                    <td data-th={t("Batch Code")}>
                                      {order?.batch_code}
                                    </td>
                                    <td data-th={t("Expiring Date")}>
                                      {order?.expiring_date}
                                    </td>
                                    {/* <td> */}
                                    {/* <Print
                                        productId={order?.id_article}
                                        name={order?.product_description}
                                        weight={order?.weight}
                                        productBatchId={order?.batch_code}
                                        productExpireDate={order?.expiring_date}
                                        productArrivalDate={order?.delivery_date}
                                      /> */}
                                    {/* </td> */}
                                  </tr>
                                ))}
                            </tbody>
                          </table>
                        </div>

                        <div className="productaddedit">
                          <span className="productaddedit-head">
                            {t("PRODUCTS")}
                          </span>
                          {/* <div className="productaddedit-btns">
                            <button className="btn">
                              <i className="far fa-plus"></i>
                            </button>
                            <button className="btn">
                              <i className="far fa-minus"></i>
                            </button>
                          </div> */}
                        </div>
                      </div>

                      {/* Table */}
                      <div className="orderList-esc">
                        <h4>{t("PACKAGE")}</h4>
                        <div className="table-responsive">
                          <table className="table themeTable table-bordered table-striped">
                            <thead>
                              <tr>
                                <th></th>
                                {/* <th>{t("Article Id")}</th> */}
                                <th>{t("Description")}</th>
                                <th>{t("Pieces")}</th>
                                <th>{t("Weight")}</th>
                                {/* <th>u.m</th> */}
                                {/* <th>Note</th> */}
                                <th>{t("Batch Code")}</th>
                                <th>{t("Expiring Date")}</th>
                                {/* <th></th> */}
                              </tr>
                            </thead>
                            <tbody>
                              {pickedOrder &&
                                pickedOrder.map((order, index) => (
                                  <tr>
                                    <td>
                                      <div className="sm-statusbox">
                                        <div className="boxicon">
                                          <svg
                                            xmlns="http://www.w3.org/2000/svg"
                                            viewBox="0 0 24 24"
                                            width="26"
                                            height="26"
                                          >
                                            <path fill="none" d="M0 0h24v24H0z" />
                                            <path d="M3 10H2V4.003C2 3.449 2.455 3 2.992 3h18.016A.99.99 0 0 1 22 4.003V10h-1v10.001a.996.996 0 0 1-.993.999H3.993A.996.996 0 0 1 3 20.001V10zm16 0H5v9h14v-9zM4 5v3h16V5H4zm5 7h6v2H9v-2z" />
                                          </svg>
                                        </div>
                                        <div
                                          className={circleClass(order?.status)}
                                        ></div>
                                      </div>
                                    </td>
                                    {/* <td data-th={t("Article Id")}>
                                      {order?.id_article}
                                    </td> */}
                                    <td data-th={t("Description")}>
                                      {order?.product_description}
                                    </td>
                                    <td data-th={t("Pieces")}>{order?.pieces}</td>
                                    <td data-th={t("Weight")}>
                                      {(order?.weight).toFixed(3)}
                                    </td>
                                    {/* <td>{order?.um}</td> */}
                                    {/* <td>{order?.picking_order?.delivery_note}</td> */}
                                    <td data-th={t("Batch Code")}>
                                      {order?.batch?.batch_code}
                                    </td>
                                    <td data-th={t("Expiring Date")}>
                                      {order?.expiring_date}
                                    </td>
                                    <td>
                                      <button
                                        onClick={() =>
                                          deletePikedProdHandler(
                                            order?.id_picking
                                          )
                                        }
                                        className="printbtn"
                                      >
                                        <i className="far fa-times"></i>
                                      </button>
                                    </td>
                                  </tr>
                                ))}
                            </tbody>
                          </table>
                        </div>
                      </div>

                      <div className="orderList-dataction">
                        <button
                          className="btn"
                          onClick={() => history.push("/raccolta")}
                        >
                          <i className="far fa-times"></i>
                        </button>
                        {/* <button className="btn">
                          <i className="far fa-check"></i>
                        </button> */}
                        <button
                          className="btn disable"
                          onClick={() => sendResult()}
                          disabled={isPlay}
                        >
                          <i className="fas fa-play"></i>
                        </button>
                      </div>
                    </div>
                  </div>
                </div>
                {/* <!-- dashboard-container  END --> */}
              </div>
              {/* <!-- dashboard_content END --> */}
            </div>
          </div>

          {/* <!-- Modal -->   */}
          <div
            className="modal fade"
            id="editodernum"
            tabIndex="-1"
            aria-labelledby="editodernumLabel"
            aria-hidden="true"
          >
            <div className="modal-dialog modal-dialog-centered">
              <div className="modal-content">
                <div className="text-end pt-3 pe-3 mb-n3">
                  <button
                    type="button"
                    className="btn-close"
                    data-bs-dismiss="modal"
                    aria-label="Close"
                  ></button>
                </div>

                <div className="modal-body p-3 text-center">
                  <div className="form-group">
                    <label className="mb-2">
                      inserisci numero documento di trasporto
                    </label>
                    <h4>_rt256342F</h4>
                    {/* <input className="form-control" value="_rt256342F" type="text" /> */}
                  </div>
                </div>
                {/* <div className="modal-footer">
                      <button type="button" className="btn btn-secondary" data-bs-dismiss="modal">Close</button>
                      <button type="button" className="btn btn-primary">Save changes</button>
                  </div> */}
              </div>
            </div>
          </div>
        </div>
      ):(
        history.push("/")
      )}
    </>
  );
};

export default ListaOdv;
