import React, { useEffect, useState, useRef } from "react";
import { useLocation, useHistory } from "react-router-dom";
import DatePicker from "react-multi-date-picker";
import { toast } from "react-toastify";
import { useForm, Controller } from "react-hook-form";
import { useSelector, useDispatch } from "react-redux";
import axios from "axios";
import { useTranslation } from "react-i18next";
import Header from "../../components/header/header";
import SideBar from "../../components/Sidebar/sideBar";
import useRequest from "../../hooks/useRequest";
import { updateOrderRef } from "../../store/product/action";
import { generateBarCode } from "../../components/Utils/generateBarCode";
import { PrintLabelRicevimentoMeat } from "../../components/printlabel/receiving/ricevimentoMeatPrintLabel";
import { PrintLabelRicevimentoDry } from "../../components/printlabel/receiving/ricevimentoDryPrint";
import {
  RESET,
  RETURN,
  SAVE,
  MESSAGE,
} from "../../components/Utils/SVG";
import {
  addModelClass,
  removeModelClass,
  Genders,
} from "../../components/Utils/Utils";
import ToggleSwitch from "../../components/toggleButton/toggle";

const DettagliOrdine = (props) => {
  const { userRoleId } = useSelector((state) => state.auth);
  let weightTimer = useRef(null);
  const location = useLocation();
  const history = useHistory();
  const [t, i18n] = useTranslation("translation");
  const orderStatus = location.state ? location.state.orderStatus : "";
  const [count, setCount] = useState(0);
  const { request: requestSaveProduct, response: responseSaveProduct } =
    useRequest();
  const { request: printDataRequest, response: printDataResponse } =
    useRequest();
  const item = location?.state ? location.state.item : "";
  const order = location.state ? location.state.order : "";
  console.log(order);
  const stateFlag = location.state ? location.state.stateFlag : "";
  const processProductFlag = location.state
    ? location.state.processProductFlag
    : "";
  const [isOpenDrop, setIsOpenDrop] = useState(false);
  const [isOpenChat, setIsOpenChat] = useState(false);
  const [activeProductType, setactiveProductType] = useState(
    item?.product_type
  );
  const [orderRef, setOrderRef] = useState("");
  const [totalPieces, setTotalPieces] = useState(item?.pieces);
  const [totalWieght, setTotalWieght] = useState(item?.weight);
  const [barcode, setBarcode] = useState("");
  const [description, setDescription] = useState("");
  const [danneggiato, setDanneggiato] = useState("");
  const [chatMissingQuantity, setChatMissingQuantity] = useState("");
  const [grossWeight, setGrossWeight] = useState(0);
  const [netGrossAmount, setNetGrossAmount] = useState(0);
  const [grossDisabled, setGrossDisabled] = useState(true);
  const [netGrossDisabled, setNetGrossDisabled] = useState(true);
  const [netDisabled, setNetDisabled] = useState(true);
  const [piecesDisabled, setPiecesDisabled] = useState(true);
  const [isPiecesFieldDisabled, setIsPiecesFieldDisabled] = useState(true)
  const [itemData, setItemData] = useState({});
  const [previousPageData, setPreviousPageData] = useState({});
  const [balanceWeight, setBalanceWeight] = useState(0);
  const [ICountries, setICountries] = useState([]);
  const [isBalanceStart, setIsBalanceStart] = useState(true);
  const [printLabelType, setPrintLabelType] = useState(false); // false for single true for multiple
  const { request: requestCountry, response: responseCountry } = useRequest();
  const { orderRef: reduxOrderRef } = useSelector((state) => state.product);
  const {
    register,
    handleSubmit,
    formState: { errors },
    getValues,
    setValue,
    clearErrors,
    control,
    trigger,
    watch,
  } = useForm();
  const dispatch = useDispatch();
  const { weight } = watch();

 

  useEffect(() => {
    let newGrossWeight = Number(weight) + Number(netGrossAmount)
    setGrossWeight(newGrossWeight.toFixed(3));
  }, [weight, netGrossAmount]);
  
  // useEffect(() => {
  //   debugger
  //   if (!order || !item || orderStatus === "" || !reduxOrderRef) {
  //     handleBack();
  //   }
  // }, [item, order, reduxOrderRef, orderStatus]);

  useEffect(() => {
    if (item?.comment !== {}) {
      setDescription(item?.comment?.description);
      setDanneggiato(item?.comment?.damaged);
      setChatMissingQuantity(item?.comment?.missing_quantity);
    }
    if (location?.state?.item) {
      setPreviousPageData(location?.state?.item);
    }
  }, []);

  useEffect(() => {
    requestCountry("GET", "countries");
    setBarcode(generateBarCode("R"));
  }, []);

  useEffect(() => {
    if (responseCountry && responseCountry.data.country.length > 0) {
      setICountries(responseCountry.data.country);
    }
  }, [responseCountry]);

  useEffect(() => {
    getAll();
    return () => {
      clearInterval(weightTimer.current);
      weightTimer.current = null;
    };
  }, []);

  const remainingQty = (item) => {
    if (item?.unit_mesure === "KG") {
      return 1;
    } else {
      return item?.pieces - item?.received_pieces;
    }
  };

  const calculateWeight = (item) => {
    if (item?.unit_mesure === "KG") {
      if(balanceWeight !== 0){
        return item?.kg_per_pieces;
      }else{
        return ''
      }
    } else {
      return (+item?.weight - +item?.received_weight).toFixed(3);
    }
  };

  const checkState = (pieces) => {
    if (stateFlag === "last") {
      if (!processProductFlag && pieces < item?.pieces) {
        return "first";
      } else if (
        processProductFlag &&
        pieces < item?.missing_quantity_process
      ) {
        return "";
      } else if (
        processProductFlag &&
        pieces < item?.pieces &&
        item?.received_pieces === null
      ) {
        return "";
      } else {
        return stateFlag;
      }
    } else {
      return stateFlag;
    }
  };

  const getAll = () => {
    setItemData({
      idAreamag: item.id_areamag,
      providerName: item.provider_name,

      idodaline: item.idodaline,

      orderId: item.id_oda,

      purchasePrice: item.purchased_price,

      idWarehouse: item.id_warehouse,

      idArtical: item.id_article,

      idProvider: item.id_provider,

      unitMeasure: item.unit_mesure,

      orderType: item.order_type,

      kgPerPieces: item.kg_per_pieces,

      deliveryDate: item.delivery_date,

      missingQuantity: item.missing_quantity_process,
      nArticlePackage: item.number_artin_package,
      setQuantity: item?.set_quantity,

      state: item?.state,
    });

    setValue("missing_quantity_process", item.missing_quantity_process);
    setValue("product_name", item.product_name);
    setValue("batch_code", "");
    setValue("expiring_date", "");
    setValue("auricolare_code", "");
    setValue("born", item.born ? item.born.toLowerCase() : '');
    setValue("gender", "");
    setValue("birth_date", "");
    setValue("bred", item.bred ? item.bred.toLowerCase() : '');
    setValue("butchered", item.butchered ? item.butchered.toLowerCase() : '');
    setValue("ce_stamp", "");
    setValue("ce_stamp_2", "IT M8Y3T CE");
    setValue("butchered_date", "");
    setValue("dissected", item.dissected ? item.dissected.toLowerCase() : '');
    setValue("weight", calculateWeight(item));
    setValue("pieces", remainingQty(item));
    setValue("quantity", item.quantity);
    clearErrors();
  };

  const onSubmitHandler = (data) => {
    clearInterval(weightTimer.current);
    weightTimer.current = null;
    if (itemData?.unitMeasure === "KG") {
      setCount(count + 1);
    } else {
      setCount(0);
    }

    const {
      auricolare_code,
      batch_code,
      birth_date,
      butchered_date,
      ce_stamp,
      ce_stamp_2,
      expiring_date,
      pieces,
      product_name,
      butchered,
      quantity,
      gender,
      bred,
      born,
      dissected,
      missing_quantity_process,
      weight,
    } = data;

    requestSaveProduct("POST", "save-products", {
      state: checkState(pieces),
      order_id: itemData?.orderId,
      doc_ref_num: reduxOrderRef,
      product: {
        id_areamag: itemData?.idAreamag,
        id_warehouse: itemData?.idWarehouse,
        id_article: itemData?.idArtical,
        id_provider: itemData?.idProvider,
        idodaline: itemData?.idodaline,
        product_name: product_name,
        unit_meassure: itemData?.unitMeasure,
        auricolare_code: auricolare_code,
        batch_code: batch_code,
        birth_date: birth_date,
        butchered_date: butchered_date,
        ce_stamp: ce_stamp,
        bred_country: bred,
        ce_stamp_2: ce_stamp_2,
        expiring_date: expiring_date,
        provider_name: itemData?.providerName,
        quantity: quantity,
        gender: gender,
        pieces: pieces,
        butchered_country: butchered,
        dissected_country: dissected,
        birth_country: born,
        barcode: barcode,
        weight: grossWeight,
        purchased_price: itemData?.purchasePrice,
        order_type: itemData?.orderType,
        delivery_date: itemData?.deliveryDate,
        kg_per_piece: itemData?.kgPerPieces,
        n_article_in_package: itemData?.nArticlePackage,
        missing_quantity_process: missing_quantity_process,
        product_type: activeProductType,
        temperature: item?.temperature,
        total_pieces_ct:
          itemData?.unitMeasure === "KG" ? item?.pieces - count : item?.pieces,
        total_pieces: totalPieces,
        total_weight: totalWieght,
        net_weight: weight,
        note_lab: item?.note_lab,
        alt: item?.alt,
      },
      comment: {
        description: description,
        damaged: danneggiato,
        missing_quantity: chatMissingQuantity,
      },
    });
  };

  const handleBack = () => {
    history.push({
      pathname: "/ricezione",
      state: {
        order: order || null,
      },
    });
  };

  const handleReset = () => {
    getAll();
  };

  useEffect(() => {
    if (printDataResponse) {
      if (printDataResponse?.status === "SUCCESS") {
        if(printDataResponse?.data?.is_multiple){
          if (printDataResponse?.data?.product_type === "Dry") {
            PrintLabelRicevimentoDry(
              printDataResponse?.data?.products[0],
              printDataResponse?.data?.pieces
            );
          }else{
            PrintLabelRicevimentoMeat(
              printDataResponse?.data?.products[0],
              printDataResponse?.data?.pieces
            );
          }
        }else{
          if (printDataResponse?.data?.product_type === "Dry") {
            PrintLabelRicevimentoDry(
              printDataResponse?.data?.product,
              1
            );
          } else {
            PrintLabelRicevimentoMeat(
              printDataResponse?.data?.product,
              1
            );
          }
        }

        if (item?.unit_mesure !== "KG") {
          history.push({
            pathname: "/ricevimento-lista-ordini",
            state: {
              order: order || null,
              showDeleteButton: false,
            },
          });
        } else {
          if (item?.pieces - item?.received_pieces - count === 0) {
            history.push({
              pathname: "/ricevimento-lista-ordini",
              state: {
                order: order || null,
                showDeleteButton: false,
              },
            });
            setCount(0);
          } else {
            setBarcode(generateBarCode("R"));
          }
        }
      }
    }
  }, [printDataResponse]);

  const printLabelHandler = (id) => {
    if(printLabelType){
      printDataRequest("GET", `get-rece-multi-product/${id}`);
    }else{
      printDataRequest("GET", `get-rece-product/${id}`);
    }
  }

  useEffect(() => {
    if (responseSaveProduct) {
      if (responseSaveProduct.status === "SUCCESS") {
        if (responseSaveProduct?.data?.id_receiving) {
          printLabelHandler(responseSaveProduct?.data?.id_receiving);
        } else {
          if (item?.unit_mesure !== "KG") {
            history.push({
              pathname: "/ricevimento-lista-ordini",
              state: {
                order: order || null,
                showDeleteButton: false,
              },
            });
          } else {
            if (item?.pieces - item?.received_pieces - count === 0) {
              history.push({
                pathname: "/ricevimento-lista-ordini",
                state: {
                  order: order || null,
                  showDeleteButton: false,
                },
              });
              setCount(0);
            } else {
              setBarcode(generateBarCode("R"));
            }
          }
        }
      } else if (responseSaveProduct.status === "ERROR") {
        if(isBalanceStart){
          weightApiHandler();
        }
        toast.error("Manca qualche campo.");
      } else if (responseSaveProduct.status === "FAIL") {
        if(isBalanceStart){
          weightApiHandler();
        }
        toast.error(responseSaveProduct?.message);
      }
    }
  }, [responseSaveProduct]);

  const editHandler = () => {
    addModelClass("editodernum");
    setOrderRef(reduxOrderRef);
  };

  const closeHandler = () => {
    if (orderRef.trim().length === 0) {
      setOrderRef("");
      return;
    }

    removeModelClass("editodernum");
    dispatch(updateOrderRef({ orderRef }));
  };

  const weightApiHandler = () => {
    weightTimer.current = null
    weightTimer.current = setInterval(() => {
      // let url = "http://192.168.235.200:12345/bilancia_read_fake"
      let url = "http://localhost:12345/bilancia_read_parsed"
      axios.get(url).then((res) => {
        if (res) {
          if (res?.status === 200) {
            let weight = res?.data?.weight;
            setBalanceWeight(weight)
            if (weight > 0) {
              weight = +weight
              weight = weight.toFixed(3)
              setGrossWeight(weight);
              setValue('weight', weight)
            }
          }
        }
      });
    }, 1000);
  };

  // useEffect(() => {
  //   weightApiHandler();
  // }, []);

  const netWeightHandler = (value) => {
    if(balanceWeight === 0 && itemData?.unitMeasure === "KG"){
      value = +value
      setGrossWeight(value)
    }
  }

  const togglePrintLabelTypeHandler = () => {
    if (printLabelType) {
      setPrintLabelType(false);
    } else {
      setPrintLabelType(true);  
    }
  };

  const toggleBalanceHandler = () => {
    if (isBalanceStart) {
      setIsBalanceStart(false);
    } else {
      setIsBalanceStart(true);
    }
  }

  useEffect(() => {
    if (isBalanceStart) {
      weightApiHandler()
    } else {
      clearInterval(weightTimer.current);
      weightTimer.current = null;
    }
  }, [isBalanceStart]);

  return (
    <>
      {userRoleId === 5 || userRoleId === 1 ? (
        <div>
          <div className="dashboard_parent">
            {/* <!-- Sidebar --> */}

            <SideBar />
            <div className="dashboard_right">
              {/* <!-- dashboard header --> */}
              <Header
                title={t("Receipt")}
                links={[{ to: "/ricezione", name: t("order list") }]}
              />
              {/* <!-- dashboard Content --> */}

              <div className="dashboard_content">
                <div className="dashboard-container">
                  <div className="p-3">
                    <div className="orderList-data border-0">
                      {/* Order List Item */}
                      <div className="orderList-row">
                        {orderStatus !== "text-success" ? (
                          <div className="orderStatus">
                            <i className={`fa fa-caret-right ${orderStatus}`}></i>
                          </div>
                        ) : (
                          <div className="orderStatus orderStatusLast">
                            <i className="fa fa-caret-right text-secondary"></i>
                          </div>
                        )}

                        <div className="orderno">
                          <label>{t("Order no")}</label>
                          <h6>{item?.id_oda}</h6>
                        </div>
                        <div className="suppliername">
                          <label>{t("supplier")}</label>
                          <h6>{item?.provider_name}</h6>
                        </div>

                        <div className="orderdate">
                          <label>{t("owner")}</label>
                          <h6>{item?.delivery_date}</h6>
                        </div>
                        <div className="orderdate">
                          <label>{t("Qty ordered")}</label>
                          <h6> {item?.quantity}</h6>
                        </div>

                        <div className="docorder">
                          <label>{t("Order document no")}</label>
                          <h6>
                            {reduxOrderRef}
                            <button
                              className="btn btn-primary square-sm edit_btn"
                              data-bs-toggle="modal"
                              data-bs-target="#editodernum"
                              onClick={editHandler}
                              title={t("Document reference number")}
                            >
                              <i className="fas fa-pencil"></i>
                            </button>
                          </h6>
                        </div>
                        {orderStatus === "text-success" ? (
                          <div className="orderStatus orderStatusLast">
                            <i
                              className={`fa fa-caret-right ${orderStatus} `}
                            ></i>
                          </div>
                        ) : (
                          <div className="orderStatus orderStatusLast">
                            <i className="fa fa-caret-right text-secondary"></i>
                          </div>
                        )}
                      </div>

                      <div className="ptb-wrap">
                        <div className="products-typebox">
                          <div className="verticalBtns">
                            <div className="settingfl_btn">
                              <button
                                type="button"
                                title="Collocamento"
                                className={`btn customToggle ${
                                  isOpenDrop ? "show" : ""
                                }`}
                                onClick={() => setIsOpenDrop((prev) => !prev)}
                              >
                                {/* <SETTING/> */}
                              </button>
                            </div>

                            <div className="verticalBtns-action">
                              <div className="row">
                                S &nbsp;
                                <ToggleSwitch
                                  idToggle="print-toggle"
                                  isOn={printLabelType}
                                  handleToggle={togglePrintLabelTypeHandler}
                                />
                                &nbsp; M
                              </div>
                                
                              <button
                                // className="btn"
                                title={t("Comment")}
                                className={`btn customToggle ${
                                  isOpenChat ? "show" : ""
                                }`}
                                onClick={() => setIsOpenChat((prev) => !prev)}
                              >
                                <MESSAGE />
                              </button>
                              <a
                                href="javascript:;"
                                className="btn"
                                onClick={handleReset}
                                title={t("Reset")}
                              >
                                <RESET />
                              </a>
                              <a
                                href="javascript:;"
                                className="btn"
                                onClick={() => {
                                  history.push({
                                    pathname: "/ricevimento-lista-ordini",
                                    state: {
                                      order: order || null,
                                      showDeleteButton: false,
                                    },
                                  });
                                }}
                                title={t("Back")}
                              >
                                <RETURN />
                              </a>
                              <button
                                // href="#"
                                className="btn"
                                id="rightBtn"
                                onClick={handleSubmit(onSubmitHandler)}
                                title={t("Save")}
                              >
                                <SAVE />
                              </button>
                            </div>
                          </div>

                          {/* Setting Data */}
                          <div
                            className={`customSetting-menu ${
                              isOpenDrop ? "show" : ""
                            }`}
                          >
                            <button
                              className="closesetting"
                              onClick={() => setIsOpenDrop(false)}
                            >
                              <i className="far fa-times"></i>
                            </button>

                            <div className="sf-block">
                              <div className="sf-box">
                                <div className="sf-rotate">generale</div>
                                <div className="sf-boxinner">
                                  <div className="row g-2 mb-1 align-items-center">
                                    <div className="col">
                                      <div className="label">cella attiva</div>
                                    </div>
                                    <div className="col">
                                      <div className="form-check mb-0">
                                        <input
                                          className="form-check-input"
                                          type="checkbox"
                                          id="cellactive1"
                                        />
                                        <label
                                          className="form-check-label"
                                          htmlFor="cellactive1"
                                        ></label>
                                      </div>
                                    </div>
                                  </div>
                                  <div className="row g-2 mb-1 align-items-center">
                                    <div className="col">
                                      <div className="label">cella di carico</div>
                                    </div>
                                    <div className="col">
                                      <div className="themeInput">
                                        <select className="form-select">
                                          <option>scala1</option>
                                          <option>scala1</option>
                                          <option>scala1</option>
                                          <option>scala1</option>
                                        </select>
                                      </div>
                                    </div>
                                  </div>
                                  <div className="row g-2 mb-1 align-items-center">
                                    <div className="col">
                                      <div className="label">visualizz.ne</div>
                                    </div>
                                    <div className="col">
                                      <div className="themeInput">
                                        <select className="form-select">
                                          <option>dispositivo</option>
                                          <option>dispositivo</option>
                                          <option>dispositivo</option>
                                          <option>dispositivo</option>
                                        </select>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </div>

                              <div className="sf-box">
                                <div className="sf-rotate">lordo</div>
                                <div className="sf-boxinner">
                                  <div className="row g-2 mb-1 align-items-center">
                                    <div className="col">
                                      <div className="label">sincronizza</div>
                                    </div>
                                    <div className="col">
                                      <div className="form-check mb-0">
                                        <input
                                          className="form-check-input"
                                          type="checkbox"
                                          id="cellactive2"
                                        />
                                        <label
                                          className="form-check-label"
                                          htmlFor="cellactive2"
                                        ></label>
                                      </div>
                                    </div>
                                  </div>
                                  <div className="row g-2 mb-1 align-items-center">
                                    <div className="col">
                                      <div className="label">tipo operazione</div>
                                    </div>
                                    <div className="col">
                                      <div className="themeInput">
                                        <select className="form-select">
                                          <option>uguaglianza</option>
                                          <option>uguaglianza</option>
                                          <option>uguaglianza</option>
                                          <option>uguaglianza</option>
                                        </select>
                                      </div>
                                    </div>
                                  </div>
                                  <div className="row g-2 mb-1 align-items-center">
                                    <div className="col">
                                      <div className="label">valore fisso</div>
                                    </div>
                                    <div className="col">
                                      <div className="themeInput">
                                        <select className="form-select">
                                          <option>0,000</option>
                                          <option>0,000</option>
                                          <option>0,000</option>
                                          <option>0,000</option>
                                        </select>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </div>

                              <div className="sf-box">
                                <div className="sf-rotate">tara</div>
                                <div className="sf-boxinner">
                                  <div className="row g-2 mb-1 align-items-center">
                                    <div className="col">
                                      <div className="label">sincronizza</div>
                                    </div>
                                    <div className="col">
                                      <div className="form-check mb-0">
                                        <input
                                          className="form-check-input"
                                          type="checkbox"
                                          id="cellactive3"
                                        />
                                        <label
                                          className="form-check-label"
                                          htmlFor="cellactive3"
                                        ></label>
                                      </div>
                                    </div>
                                  </div>
                                  <div className="row g-2 mb-1 align-items-center">
                                    <div className="col">
                                      <div className="label">tipo operazione</div>
                                    </div>
                                    <div className="col">
                                      <div className="themeInput">
                                        <select className="form-select">
                                          <option>uguaglianza</option>
                                          <option>uguaglianza</option>
                                          <option>uguaglianza</option>
                                          <option>uguaglianza</option>
                                        </select>
                                      </div>
                                    </div>
                                  </div>
                                  <div className="row g-2 mb-1 align-items-center">
                                    <div className="col">
                                      <div className="label">valore fisso</div>
                                    </div>
                                    <div className="col">
                                      <div className="themeInput">
                                        <select className="form-select">
                                          <option>0,000</option>
                                          <option>0,000</option>
                                          <option>0,000</option>
                                          <option>0,000</option>
                                        </select>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </div>

                              <div className="sf-box">
                                <div className="sf-rotate">netto</div>
                                <div className="sf-boxinner">
                                  <div className="row g-2 mb-1 align-items-center">
                                    <div className="col">
                                      <div className="label">sincronizza</div>
                                    </div>
                                    <div className="col">
                                      <div className="form-check mb-0">
                                        <input
                                          className="form-check-input"
                                          type="checkbox"
                                          id="cellactive3"
                                        />
                                        <label
                                          className="form-check-label"
                                          htmlFor="cellactive3"
                                        ></label>
                                      </div>
                                    </div>
                                  </div>
                                  <div className="row g-2 mb-1 align-items-center">
                                    <div className="col">
                                      <div className="label">tipo operazione</div>
                                    </div>
                                    <div className="col">
                                      <div className="themeInput">
                                        <select className="form-select">
                                          <option>uguaglianza</option>
                                          <option>uguaglianza</option>
                                          <option>uguaglianza</option>
                                          <option>uguaglianza</option>
                                        </select>
                                      </div>
                                    </div>
                                  </div>
                                  <div className="row g-2 mb-1 align-items-center">
                                    <div className="col">
                                      <div className="label">valore fisso</div>
                                    </div>
                                    <div className="col">
                                      <div className="themeInput">
                                        <select className="form-select">
                                          <option>0,000</option>
                                          <option>0,000</option>
                                          <option>0,000</option>
                                          <option>0,000</option>
                                        </select>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>

                            <button
                              className="sf-savebtn"
                              onClick={() => setIsOpenDrop(false)}
                            >
                              salva modifiche
                            </button>
                          </div>

                          {/* Chat Data */}
                          <div
                            className={`customChat-menu ${
                              isOpenChat ? "show" : ""
                            }`}
                          >
                            <button
                              className="closesetting"
                              onClick={() => setIsOpenChat(false)}
                            >
                              <i className="far fa-times"></i>
                            </button>
                            <div className="chatBoxWarp">
                              <div className="chatBox">
                                <div className="chatBoxInner">
                                  <label>inserisci nota</label>
                                  <textarea
                                    value={description}
                                    name="description"
                                    onChange={(e) => {
                                      setDescription(e.target.value);
                                    }}
                                  />
                                </div>

                                <div className="damagedBox">
                                  <div className="form-check">
                                    <label
                                      className="form-check-label"
                                      htmlFor="danneggiato"
                                    >
                                      prodotto danneggiato
                                    </label>
                                    <input
                                      className="form-check-input"
                                      type="checkbox"
                                      id="danneggiato"
                                      name="danneggiato"
                                      checked={danneggiato}
                                      onChange={(e) =>
                                        setDanneggiato(e.target.checked)
                                      }
                                    />
                                  </div>

                                  <div className="">
                                    <div className="row align-items-center">
                                      <div className="col-auto">
                                        <label>inserisci quantità mancante</label>
                                      </div>
                                      <div className="col-5">
                                        <div className="themeInput themeIS">
                                          <input
                                            type="number"
                                            className="form-control"
                                            name="chatMissingQuantity"
                                            id="chatMissingQuantity"
                                            value={chatMissingQuantity}
                                            onChange={(e) => {
                                              setChatMissingQuantity(
                                                e.target.value
                                              );
                                            }}
                                          />
                                          <button
                                            type="button"
                                            className="themeIS-btn"
                                          >
                                            <i className="fas fa-pencil-alt"></i>
                                          </button>
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </div>

                              <div className="chatBoxSaveBtn">
                                <button onClick={() => setIsOpenChat(false)}>
                                  salva modifiche
                                </button>
                              </div>
                            </div>
                          </div>

                          <ul
                            className="nav nav-pills"
                            id="pills-tab"
                            role="tablist"
                          >
                            <li className="nav-item" role="presentation">
                              <button
                                className={`nav-link ${
                                  item?.product_type === "Dry" ? "active" : ""
                                } `}
                                id="pills-dry-tab"
                                data-bs-toggle="pill"
                                data-bs-target="#pills-dry"
                                type="button"
                                onClick={() => {
                                  setactiveProductType("Dry");
                                  clearErrors();
                                }}
                              >
                                <img src="img/ICONA-SECCO.svg" alt="secco" />
                                <span>{t("dry")}</span>
                              </button>
                            </li>
                            <li className="nav-item" role="presentation">
                              <button
                                className={`nav-link ${
                                  item?.product_type === "Meat" ? "active" : ""
                                } `}
                                id="pills-fresh-tab"
                                data-bs-toggle="pill"
                                data-bs-target="#pills-dry"
                                type="button"
                                role="tab"
                                aria-controls="pills-dry"
                                aria-selected="false"
                                onClick={() => {
                                  setactiveProductType("Meat");
                                  clearErrors();
                                }}
                              >
                                <img src="img/ICONA-FRESCO.svg" alt="fresco" />
                                <span>{t("fresh")}</span>
                              </button>
                            </li>
                          </ul>
                          <div className="tab-content" id="pills-tabContent">
                            <form>
                              <div
                                className="tab-pane fade show active"
                                id="pills-dry"
                                role="tabpanel"
                                aria-labelledby="pills-dry-tab"
                              >
                                <div className="itemsearch-wrap">
                                  <div className="single_item">
                                    <div className="row align-items-center">
                                      <div className="col-md-4 text-end mb-1 mb-md-0">
                                        <div className="label-row">
                                          <span className="label">
                                            {t("item")}
                                          </span>
                                        </div>
                                      </div>
                                      <div className="col-md-6">
                                        <div className="themeInput themeIS">
                                          <input
                                            type=""
                                            className={`form-control ${
                                              errors["product_name"]
                                                ? "is-invalid"
                                                : ""
                                            }`}
                                            {...register("product_name", {
                                              required: true,
                                            })}
                                            disabled
                                          />
                                        </div>
                                      </div>
                                    </div>
                                  </div>

                                  <div className="multi_item">
                                    <div className="row align-items-center">
                                      <div className="col-md-4 text-end mb-1 mb-md-0">
                                        <div className="label-row">
                                          <span className="label">
                                            {t("lot")}
                                          </span>
                                          <span className="label">
                                            {t("deadline")}
                                          </span>
                                        </div>
                                      </div>
                                      <div className="col-md-3 mb-1 mb-md-0">
                                        <div className="themeInput">
                                          <input
                                            type=""
                                            className="form-control"
                                            {...register("batch_code", {
                                              required: true,
                                            })}
                                          />
                                          {errors.batch_code?.type ===
                                            "required" && (
                                            <div className="custom-invalid-feedback-date">
                                              {t("This field is mandatory.")}
                                            </div>
                                          )}
                                        </div>
                                      </div>
                                      <div className="col-md-3 mb-1 mb-md-0">
                                        <div className="themeInput themeIS date_box">
                                          <Controller
                                            control={control}
                                            name="expiring_date"
                                            rules={{ required: true }}
                                            render={({
                                              field: {
                                                onChange,
                                                onBlur,
                                                value,
                                                ref,
                                              },
                                            }) => (
                                              <DatePicker
                                                placeholder={t("Select the date")}
                                                value={value}
                                                onChange={(date) => {
                                                  onChange(date.format());
                                                }}
                                                minDate={new Date()}
                                                format="YYYY-MM-DD"
                                              />
                                            )}
                                          />

                                          {errors.expiring_date?.type ===
                                            "required" && (
                                            <div className="custom-invalid-feedback-date">
                                              {t("This field is mandatory.")}
                                            </div>
                                          )}
                                          <button
                                            type="button"
                                            className="themeIS-btn"
                                          >
                                            <i className="far fa-calendar-alt"></i>
                                          </button>
                                        </div>
                                      </div>
                                    </div>
                                  </div>

                                  <div
                                    className={`multi_item ${
                                      activeProductType === "Dry"
                                        ? "boxDisabled"
                                        : ""
                                    }`}
                                  >
                                    <div className="row align-items-center">
                                      <div className="col-md-4 text-end mb-1 mb-md-0">
                                        <div className="label-row">
                                          <span className="label">
                                            {t("headset")}
                                          </span>
                                        </div>
                                      </div>
                                      <div className="col-md-3 mb-1 mb-md-0">
                                        <div className="themeInput">
                                          <input
                                            type="text"
                                            className={`form-control`}
                                            {...register("auricolare_code")}
                                            disabled={
                                              activeProductType === "Dry"
                                                ? true
                                                : false
                                            }
                                          />
                                        </div>
                                      </div>
                                    </div>
                                  </div>

                                  <div
                                    className={`my-4 ${
                                      activeProductType === "Dry"
                                        ? "boxDisabled"
                                        : ""
                                    }`}
                                  >
                                    <div className="multi_item">
                                      <div className="row align-items-center">
                                        <div className="col-md-4 text-end mb-1 mb-md-0">
                                          <div className="label-row">
                                            <span className="label">
                                              {t("born Italy")}
                                            </span>
                                            <span className="label">
                                              {t("sex")}
                                            </span>
                                            <span className="label">
                                              {t("date")}
                                            </span>
                                          </div>
                                        </div>
                                        <div className="col-md-4 mb-1 mb-md-0">
                                          <div className="themeInput">
                                            <select
                                              className={`form-control form-select ${
                                                errors["born"] ? "is-invalid" : ""
                                              }`}
                                              {...register("born", {
                                                required:
                                                  activeProductType === "Meat",
                                              })}
                                              placeholder={t("Select born nat")}
                                              disabled={
                                                activeProductType === "Dry"
                                                  ? true
                                                  : false
                                              }
                                            >
                                              <option value="">
                                                {t("Select born nat")}
                                              </option>
                                              {ICountries &&
                                                ICountries.map(
                                                  (data, index) => (
                                                    <option
                                                      value={data?.nome ? data?.nome.toLowerCase() : ''}
                                                      key={index}
                                                      selected={(data?.nome ? data?.nome.toLowerCase() : '') == (getValues('born')) ? true : false}
                                                    >
                                                      {data?.nome}
                                                    </option>
                                                  )
                                                )}
                                            </select>
                                            {errors.born?.type === "required" && (
                                              <div className="invalid-feedback">
                                                {t("This field is mandatory.")}
                                              </div>
                                            )}
                                          </div>
                                        </div>
                                        <div className="col-md-2 mb-1 mb-md-0">
                                          <div className="themeInput themeIS">
                                            <select
                                              className="form-control form-select"
                                              {...register("gender", {
                                                required:
                                                  activeProductType === "Meat",
                                              })}
                                              placeholder={t("Select born nat")}
                                              disabled={
                                                activeProductType === "Dry"
                                                  ? true
                                                  : false
                                              }
                                            >
                                              <option value="">
                                                {t("Select gender")}
                                              </option>
                                              {Genders &&
                                                Genders.map((gender, index) => (
                                                  <option
                                                    value={gender}
                                                    key={index}
                                                  >
                                                    {gender}
                                                  </option>
                                                ))}
                                            </select>
                                            {errors.gender?.type ===
                                              "required" && (
                                              <div className="custom-invalid-feedback-date">
                                                {t("This field is mandatory.")}
                                              </div>
                                            )}
                                          </div>
                                        </div>
                                        <div className="col-md-2 mb-1 mb-md-0">
                                          <div className="themeInput themeIS date_box">
                                            <Controller
                                              control={control}
                                              name="birth_date"
                                              rules={{
                                                required: false,
                                              }}
                                              render={({
                                                field: {
                                                  onChange,
                                                  onBlur,
                                                  value,
                                                  ref,
                                                },
                                              }) => (
                                                <DatePicker
                                                  placeholder={t(
                                                    "Select the date"
                                                  )}
                                                  value={value}
                                                  onChange={(date) => {
                                                    onChange(date.format());
                                                  }}
                                                  selected={value}
                                                  maxDate={new Date()}
                                                  format="YYYY-MM-DD"
                                                  disabled={
                                                    activeProductType === "Dry"
                                                      ? true
                                                      : false
                                                  }
                                                />
                                              )}
                                            />

                                            <button
                                              type="button"
                                              className="themeIS-btn"
                                              disabled={
                                                activeProductType === "Dry"
                                                  ? true
                                                  : false
                                              }
                                            >
                                              <i className="far fa-calendar-alt"></i>
                                            </button>
                                          </div>
                                          {errors.birth_date?.type ===
                                            "required" && (
                                            <div className="invalid-feedback">
                                              {t("This field is mandatory.")}
                                            </div>
                                          )}
                                        </div>
                                      </div>
                                    </div>

                                    <div className="multi_item">
                                      <div className="row align-items-center">
                                        <div className="col-md-4 text-end mb-1 mb-md-0">
                                          <div className="label-row">
                                            <span className="label">
                                              {t("allev Nationality")}
                                            </span>
                                          </div>
                                        </div>
                                        <div className="col-md-4 mb-1 mb-md-0">
                                          <div className="themeInput">
                                            <select
                                              type="text"
                                              className={`form-control form-select ${
                                                errors["bred"] ? "is-invalid" : ""
                                              }`}
                                              {...register("bred", {
                                                required:
                                                  activeProductType === "Meat",
                                              })}
                                              disabled={
                                                activeProductType === "Dry"
                                                  ? true
                                                  : false
                                              }
                                            >
                                              <option value="">
                                                {t("Select born nat")}
                                              </option>
                                              {ICountries &&
                                                ICountries.map(
                                                  (data, index) => (
                                                    <option
                                                      value={data?.nome ? data?.nome.toLowerCase() : ''}
                                                      key={index}
                                                      selected={(data?.nome ? data?.nome.toLowerCase() : '') == (getValues('bred')) ? true : false}
                                                    >
                                                      {data?.nome}
                                                    </option>
                                                  )
                                                )}
                                            </select>
                                            {errors.bred?.type === "required" && (
                                              <div className="invalid-feedback">
                                                {t("This field is mandatory.")}
                                              </div>
                                            )}
                                          </div>
                                        </div>
                                      </div>
                                    </div>

                                    <div className="multi_item">
                                      <div className="row align-items-center">
                                        <div className="col-md-4 text-end mb-1 mb-md-0">
                                          <div className="label-row">
                                            <span className="label">
                                              {t("slaughtered nationality")}
                                            </span>
                                            <span className="label">
                                              {t("stamp")}
                                            </span>
                                            <span className="label">
                                              {t("date")}
                                            </span>
                                          </div>
                                        </div>
                                        <div className="col-md-4 mb-1 mb-md-0">
                                          <div className="themeInput">
                                            <select
                                              type="text"
                                              className={`form-control form-select ${
                                                errors["butchered"]
                                                  ? "is-invalid"
                                                  : ""
                                              }`}
                                              {...register("butchered", {
                                                required:
                                                  activeProductType === "Meat",
                                              })}
                                              disabled={
                                                activeProductType === "Dry"
                                                  ? true
                                                  : false
                                              }
                                            >
                                              <option value="">
                                                {t("Select born nat")}
                                              </option>
                                              {ICountries &&
                                                ICountries.map(
                                                  (data, index) => (
                                                    <option
                                                      value={data?.nome ? data?.nome.toLowerCase() : ''}
                                                      key={index}
                                                      selected={(data?.nome ? data?.nome.toLowerCase() : '') == (getValues('butchered')) ? true : false}
                                                    >
                                                      {data?.nome}
                                                    </option>
                                                  )
                                                )}
                                            </select>
                                            {errors.butchered?.type ===
                                              "required" && (
                                              <div className="invalid-feedback">
                                                {t("This field is mandatory.")}
                                              </div>
                                            )}
                                          </div>
                                        </div>
                                        <div className="col-md-2 mb-1 mb-md-0">
                                          <div className="themeInput themeIS">
                                            <input
                                              type="text"
                                              className={`form-control ${
                                                errors["ce_stamp"]
                                                  ? "is-invalid"
                                                  : ""
                                              }`}
                                              {...register("ce_stamp", {
                                                required:
                                                  activeProductType === "Meat",
                                              })}
                                            />
                                            {errors.ce_stamp?.type ===
                                              "required" && (
                                              <div className="custom-invalid-feedback-date">
                                                {t("This field is mandatory.")}
                                              </div>
                                            )}
                                          </div>
                                        </div>
                                        <div className="col-md-2 mb-1 mb-md-0">
                                          <div className="themeInput themeIS date_box">
                                            <Controller
                                              control={control}
                                              name="butchered_date"
                                              rules={{
                                                required: false,
                                              }}
                                              render={({
                                                field: {
                                                  onChange,
                                                  onBlur,
                                                  value,
                                                  ref,
                                                },
                                              }) => (
                                                <DatePicker
                                                  placeholder={t(
                                                    "Select the date butchered_date"
                                                  )}
                                                  value={value}
                                                  onChange={(date) => {
                                                    onChange(date.format());
                                                  }}
                                                  selected={value}
                                                  format="YYYY-MM-DD"
                                                  disabled={
                                                    activeProductType === "Dry"
                                                      ? true
                                                      : false
                                                  }
                                                />
                                              )}
                                            />
                                            <button
                                              type="button"
                                              className="themeIS-btn"
                                              disabled={
                                                activeProductType === "Dry"
                                                  ? true
                                                  : false
                                              }
                                            >
                                              <i className="far fa-calendar-alt"></i>
                                            </button>
                                          </div>
                                          {errors.butchered_date?.type ===
                                            "required" && (
                                            <div className="invalid-feedback">
                                              {t("This field is mandatory.")}
                                            </div>
                                          )}
                                        </div>
                                      </div>
                                    </div>

                                    <div className="multi_item">
                                      <div className="row align-items-center">
                                        <div className="col-md-4 text-end mb-1 mb-md-0">
                                          <div className="label-row">
                                            <span className="label">
                                              {t("sectioned 1 naz")}
                                            </span>
                                            <span className="label">
                                              {t("stamp")}
                                            </span>
                                          </div>
                                        </div>
                                        <div className="col-md-4 mb-1 mb-md-0">
                                          <div className="themeInput">
                                            <select
                                              type="text"
                                              className={`form-control form-select ${
                                                errors["dissected"]
                                                  ? "is-invalid"
                                                  : ""
                                              }`}
                                              {...register("dissected", {
                                                required:
                                                  activeProductType === "Meat",
                                              })}
                                              disabled={
                                                activeProductType === "Dry"
                                                  ? true
                                                  : false
                                              }
                                            >
                                              <option value="">
                                                {t("Select born nat")}
                                              </option>
                                              {ICountries &&
                                                ICountries.map(
                                                  (data, index) => (
                                                    <option
                                                      value={data?.nome ? data?.nome.toLowerCase() : ''}
                                                      key={index}
                                                      selected={(data?.nome ? data?.nome.toLowerCase() : '') == (getValues('dissected')) ? true : false}
                                                    >
                                                      {data?.nome}
                                                    </option>
                                                  )
                                                )}
                                            </select>
                                            {errors.dissected?.type ===
                                              "required" && (
                                              <div className="invalid-feedback">
                                                {t("This field is mandatory.")}
                                              </div>
                                            )}
                                          </div>
                                        </div>
                                        <div className="col-md-2 mb-1 mb-md-0">
                                          <div className="themeInput themeIS">
                                            <input
                                              type="text"
                                              className={`form-control ${
                                                errors["ce_stamp_2"]
                                                  ? "is-invalid"
                                                  : ""
                                              }`}
                                              {...register("ce_stamp_2", {
                                                required:
                                                  activeProductType === "Meat",
                                              })}
                                            />
                                            {errors.ce_stamp_2?.type ===
                                              "required" && (
                                              <div className="custom-invalid-feedback-date">
                                                {t("This field is mandatory.")}
                                              </div>
                                            )}
                                          </div>
                                        </div>
                                      </div>
                                    </div>
                                  </div>

                                  <div className="row align-items-end">
                                    <div className="col-md-8">
                                      <div className="multi_item">
                                        <div className="row align-items-center">
                                          <div className="col-md-6 text-end">
                                            <div className="label-row">
                                              <span className="label">
                                                {t("gross")}
                                              </span>
                                            </div>
                                          </div>
                                          <div className="col-md-6">
                                            <div className="themeInput themeIS upd">
                                              <input
                                                type="number"
                                                value={grossWeight}
                                                disabled={grossDisabled}
                                                className="form-control text-end"
                                              />
                                              <button
                                                type="button"
                                                className="themeIS-btn"
                                                onClick={() =>{
                                                  setGrossDisabled(
                                                    (prev) => !prev
                                                  )
                                                }
                                                }
                                              >
                                                <i className="fas fa-pencil-alt"></i>
                                              </button>
                                            </div>
                                          </div>
                                        </div>
                                      </div>

                                      <div className="multi_item">
                                        <div className="row align-items-center">
                                          <div className="col-md-6 text-end">
                                            <div className="label-row">
                                              <span className="label">
                                                {t("Tare")}
                                              </span>
                                            </div>
                                          </div>
                                          <div className="col-md-6">
                                            <div className="themeInput themeIS">
                                              <input
                                                type="number"
                                                min="0"
                                                disabled={netGrossDisabled}
                                                className="form-control text-end"
                                                onChange={(e) => {
                                                  setNetGrossAmount(
                                                    e.target.value
                                                  );
                                                }}
                                              />
                                              <button
                                                type="button"
                                                className="themeIS-btn"
                                                onClick={() =>
                                                  setNetGrossDisabled(
                                                    (prev) => !prev
                                                  )
                                                }
                                              >
                                                <i className="fas fa-pencil-alt"></i>
                                              </button>
                                            </div>
                                          </div>
                                        </div>
                                      </div>

                                      <div className="multi_item">
                                        <div className="row align-items-center">
                                          <div className="col-md-6 text-end">
                                            <div className="label-row">
                                              <span className="label">
                                                {t("net")}
                                              </span>
                                            </div>
                                          </div>
                                          <div className="col-md-6">
                                            <div className="themeInput themeIS">
                                              <input
                                                type="text"
                                                disabled={netDisabled}
                                                className={`form-control text-end ${
                                                  errors["weight"]
                                                    ? "is-invalid"
                                                    : ""
                                                }`}
                                                {...register("weight", {
                                                  required: true,
                                                  min: 0,
                                                  pattern:
                                                    /^[0-9]+([,\.][0-9]+)?$/,
                                                })}
                                              />
                                              <button
                                                type="button"
                                                className="themeIS-btn"
                                                onClick={(e) => {
                                                    toggleBalanceHandler()
                                                    setNetDisabled((prev) => !prev)
                                                  }
                                                }
                                              >
                                                <i className="fas fa-pencil-alt"></i>
                                              </button>
                                              {errors.weight?.type ===
                                                "required" && (
                                                <div className="invalid-feedback">
                                                  {t("This field is mandatory.")}
                                                </div>
                                              )}
                                              {errors.weight?.type === "min" && (
                                                <div className="invalid-feedback">
                                                  {t(
                                                    "The weight field is invalid it must be a positive value"
                                                  )}
                                                </div>
                                              )}
                                              {errors.weight?.type ===
                                                "pattern" && (
                                                <div className="invalid-feedback">
                                                  {t(
                                                    "This field must be a number"
                                                  )}
                                                </div>
                                              )}
                                            </div>
                                          </div>
                                        </div>
                                      </div>

                                      <div className="multi_item">
                                        <div className="row align-items-center">
                                          <div className="col-md-6 text-end">
                                            <div className="label-row">
                                              <span className="label">
                                                {t("pieces")}
                                              </span>
                                            </div>
                                          </div>
                                          <div className="col-md-6">
                                            <div className="themeInput themeIS">
                                              <input
                                                disabled={isPiecesFieldDisabled}
                                                // disabled={
                                                //   item?.unit_mesure === "KG"
                                                //     ? true
                                                //     : piecesDisabled
                                                // }
                                                className={`form-control text-end${
                                                  errors["pieces"]
                                                    ? "is-invalid"
                                                    : ""
                                                }`}
                                                {...register("pieces", {
                                                  required: true,
                                                  min: 0,
                                                  pattern: /^[0-9]*$/,
                                                })}
                                                onChange={(e) => {
                                                  setValue(
                                                    "weight",
                                                    +(
                                                      e.target.value *
                                                      itemData?.kgPerPieces
                                                    ).toFixed(3)
                                                  );
                                                  setGrossWeight(
                                                    +(
                                                      e.target.value *
                                                      itemData?.kgPerPieces
                                                    ).toFixed(3)
                                                  );
                                                }}
                                              />
                                              <button
                                                type="button"
                                                className="themeIS-btn"
                                                onClick={(e) => {
                                                  if(isPiecesFieldDisabled){
                                                    setIsPiecesFieldDisabled(false)
                                                  }else{
                                                    setIsPiecesFieldDisabled(true)
                                                  }
                                                  setPiecesDisabled(
                                                    (prev) => !prev
                                                  )
                                                }}
                                              >
                                                <i className="fas fa-pencil-alt"></i>
                                              </button>
                                            </div>
                                            {errors.pieces?.type ===
                                              "required" && (
                                              <div className="invalid-feedback">
                                                {t(
                                                  "The Warehouse field is required"
                                                )}
                                              </div>
                                            )}
                                            {errors.pieces?.type === "min" && (
                                              <div className="invalid-feedback">
                                                Il campo del peso non è valido,
                                                deve essere un valore positivo.
                                              </div>
                                            )}
                                            {errors.pieces?.type ===
                                              "pattern" && (
                                              <div className="invalid-feedback">
                                                {t("This field must be a number")}
                                              </div>
                                            )}
                                          </div>
                                        </div>
                                      </div>
                                    </div>

                                    <div className="col-md-2">
                                      <div className="tt-kg">
                                        <img src="img/WEIGHT.svg" alt="weight" />
                                        {grossWeight}
                                        <small>Kg</small>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </form>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                {/* <!-- dashboard-container  END --> */}
              </div>
              {/* <!-- dashboard_content END --> */}
            </div>
          </div>
          {/* <!-- Modal -->   */}
          <div
            className="modal fade "
            id="editodernum"
            tabIndex="-1"
            aria-labelledby="editodernumLabel"
            aria-hidden="true"
          >
            <div className="modal-dialog modal-dialog-centered">
              <div className="modal-content">
                <div className="text-end pt-3 pe-3 mb-n3">
                  <button
                    type="button"
                    className="btn-close"
                    data-bs-dismiss="modal"
                    aria-label="Close"
                    onClick={closeHandler}
                  ></button>
                </div>

                <div className="modal-body p-5 mb-3 text-center">
                  <div className="form-group">
                    <label className="mb-2">
                      {t("enter transport document number")}
                    </label>
                    <h4>{orderRef}</h4>
                    <input
                      className="form-control"
                      value={orderRef}
                      type="text"
                      onChange={(e) => setOrderRef(e.target.value)}
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      ):(
        history.push("/")
      )}
    </>
  );
};

export default DettagliOrdine;
