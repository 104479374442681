import { Link } from "react-router-dom";
import { Swiper, SwiperSlide } from "swiper/react";
import React, { useState, useEffect } from "react";
import { useTranslation } from "react-i18next";
import "swiper/css";
import "swiper/css/navigation";
import { Navigation } from "swiper";
import "swiper/css";
import { useLocation, useHistory } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import SideBar from "../../components/Sidebar/sideBar";
import Header from "../../components/header/header";
import useRequest from "../../hooks/useRequest";
import DatePicker from "react-multi-date-picker";
import Autosuggest from "react-autosuggest";
import { getFilteredProducts } from "../../components/Utils/Utils";

const Spedizione = () => {
  const history = useHistory();
  const { userRoleId } = useSelector((state) => state.auth);
  const [t, i18n] = useTranslation("translation");
  const { request, response } = useRequest();
  const { request: pastProductRequest, response: pastProductResponse } = useRequest();
  const { request: sellingPointSearchRequest, response: sellingPointSearchResponse } = useRequest();
  const [selectIndex, setSelectIndex] = useState(0);
  const [sendingData, setSendingData] = useState([]);
  const [sliderData, setSliderData] = useState([]);
  const [pastProducts, setPastProducts] = useState([]);
  const [sellingPoint, setSellingPoint] = useState('');
  const [deliveryDate, setDeliveryDate] = useState('');
  const [sellingPointSuggestionList, setSellingPointSuggestionList] = useState([]);
  const [sellingPointSearchQuery, setSellingPointSearchQuery] = useState("");



  useEffect(() => {
    request("GET", "sending-view");
    document.body.className = "";
    // document.body.classList.remove("shipmentbody");
    // document.body.classList.remove("production");
  }, []);

  useEffect(() => {
    if (response) {
      if (response.status === "SUCCESS") {
        // setSelectIndex(0);
        // setSliderData(response?.data?.delivery_dates);
        setSendingData(response?.data?.orders);
      }
    }
  }, [response]);

  const onSlideClick = (i) => {
    setSendingData(sliderData[i]?.sendings);
    setSelectIndex(i);
  };

  const searchPastProducts = () => {
    pastProductRequest('POST', 'past-products', {
      selling_point: sellingPoint,
      delivery_date: deliveryDate
    })
  }

  useEffect(() => {
    if (pastProductResponse) {
      if (pastProductResponse.status === "SUCCESS") {
        setPastProducts(pastProductResponse?.data?.products);
      }
    }

  }, [pastProductResponse]);

  useEffect(() => {
    if (sellingPointSearchResponse) {
      if (sellingPointSearchResponse.status === "SUCCESS") {
        setSellingPointSuggestionList(sellingPointSearchResponse?.data?.names.map((d) => ({ key: d.customer_description, value: d.id_selling_point })))
      }
    }
  }, [sellingPointSearchResponse])

  const renderSuggestion = (suggestion) => suggestion.key;

  const onSuggestionsClearRequested = () => {
    setSellingPointSuggestionList(sellingPointSuggestionList);
  };

  const getSuggestionValue = suggestion => {
    console.log("getSuggestionValue", suggestion)
    // setSellingPointSearchQuery(suggestion.key);
    setSellingPoint(suggestion.value);
    return suggestion.key;
  };


  const onSuggestionsFetchRequested = ({ value, reason }) => {
    sellingPointSearchRequest("GET", `selling-points?name=${value}`)
  };

  const onChange = (e, {newValue}) => {
    // e.preventDefault();
    setSellingPointSearchQuery(newValue)
    // setSearchQuery(e.target.value);
  };

  const inputProps = {
    placeholder: t("selling point"),
    value: sellingPointSearchQuery,
    onChange,
  };

  return (
    <>
      {userRoleId === 5 || userRoleId === 3 ? (
        <div className="dashboard_parent">
          {/* <!-- Sidebar --> */}
          <SideBar />

          <div className="dashboard_right">
            {/* <!-- dashboard header --> */}

            <Header
              title="Spedizione"
              links={[{ to: "/spedizione", name: "spedizione lista odv" }]}
            />

            {/* <!-- dashboard Content --> */}

            <div className="dashboard_content">
              <div className="dashboard-container">
                <div className="topfix">
                  <div className="supplierFilter">
                    <div className="supplierSearch themeInput themeIS">
                      <div className="w-100 w-md-auto d-md-flex align-items-end ms-auto">
                        <div className="supplierSearch themeInput themeIS mb-2 mb-md-0">
                          <form className="position-relative">
                            <label>{t("selling point")}</label>
                            <Autosuggest
                              suggestions={sellingPointSuggestionList}
                              onSuggestionsFetchRequested={
                                onSuggestionsFetchRequested
                              }
                              onSuggestionsClearRequested={
                                onSuggestionsClearRequested
                              }
                              // alwaysRenderSuggestions={true}
                              getSuggestionValue={getSuggestionValue}
                              renderSuggestion={renderSuggestion}
                              inputProps={inputProps}
                            />
                            {/* <input type="text" value={sellingPoint} onChange={(e) => {setSellingPoint(e.target.value)}} className="form-control" /> */}
                            {/* <button className="themeIS-btn" type="button">
                              <i className="fas fa-search"></i>
                            </button> */}
                          </form>
                        </div>
                        <div className="mb-2 mb-md-0">
                          <div className="themeInput themeIS date_box mx-md-4">
                            <label>{t('Delivery Date')}</label>
                            <DatePicker
                              placeholder={t('Delivery Date')}
                              value={deliveryDate}
                              onChange={(date) => {
                                date && setDeliveryDate(date.format());
                              }}
                              format="YYYY-MM-DD"
                            />
                            <div className="theme-btn-group">
                              <button type="button" className="themeIS-btn">
                                <i className="far fa-calendar-alt"></i>
                              </button>
                              <button className="themeIS-btn theme-search-btn" type="button" onClick={searchPastProducts}
                                data-bs-toggle="modal"
                                data-bs-target="#pastProductId"
                              >
                                <i className="fas fa-search"></i>
                              </button>
                            </div>
                          </div>
                        </div>
                      </div>
                      
                    </div>
                  </div>

                </div>
                <div className="p-3">
                  <div>
                    {/* Order List Item */}
                    {sendingData &&
                      sendingData.map((data, index) => (
                        <Link
                          to={{
                            pathname: "/spedizione-lista-odv",
                            state: {
                              data: data || null,
                            },
                          }}
                          className="orderList-row newOrder_list_row"
                        >
                          <div className="sm-progress">
                            {/* <div
                            className="sm-progressInner"
                            style={{
                              height: `${data?.sent_percentage}%`,
                            }}
                          ></div> */}

                            {data?.sent_percentage <= 100 ? (
                              <div
                                className="sm-progressInner"
                                style={{
                                  height: `${data?.sent_percentage}%`,
                                }}
                              ></div>
                            ) : (
                              <div
                                className="sm-progressInner"
                                style={{
                                  height: `${data?.sent_percentage}%`,
                                  backgroundColor: '#f9ab00'
                                }}
                              ></div>
                            )}

                            <span>
                              {data?.sent_percentage !== 0
                                ? `${data?.sent_percentage} %`
                                : ""}
                            </span>
                          </div>
                          <div className="suppliername">
                            <label>Punto Vendita</label>
                            <h6>{data?.customer}</h6>
                          </div>
                          <div className="suppliername">
                            <label>Destinazione</label>
                            <h6>
                              {" "}
                              {data?.selling_point?.substr(0, 25)}{" "}
                              {data?.selling_point?.length > 25 ? "..." : ""}
                            </h6>
                          </div>
                          <div className="suppliername">
                            <label>Agente</label>
                            <h6>{data?.agent}</h6>
                          </div>
                          <div className="orderdate">
                            <label>Data richiesta</label>
                            <h6>{data?.delivery_date}</h6>
                          </div>
                          <div className="suppliername">
                            <label>Zone</label>
                            <h6>{data?.zone_c}</h6>
                          </div>
                        </Link>
                      ))}
                  </div>
                </div>
              </div>
              {/* <!-- dashboard-container  END --> */}
            </div>
            {/* <!-- dashboard_content END --> */}
          </div>
          <div
            className="modal fade"
            id="pastProductId"
            tabIndex={-1}
            aria-labelledby="pastProductModal"
            aria-hidden="true"
          >
            <div className="modal-dialog modal-dialog-centered">
              <div className="modal-content">
                <div className="modal-body">
                  <div className="table-responsive simpTable">
                    <table className="table table-striped">
                      <thead className="text-uppercase">
                        <th>{t('id catalog')}</th>
                        <th>{t('Product description')}</th>
                        <th>{t('um')}</th>
                        <th>{t('weight')}</th>
                        <th>{t('pieces')}</th>
                        <th>{t('ID_ARTICLE')}</th>
                        <th>{t('Expiring Date')}</th>
                        <th>{t('Batch Code')}</th>
                        <th>{t('box')}</th>
                      </thead>
                      <tbody>
                        {pastProducts && pastProducts.map((data, index) => (
                          <>
                            <tr>
                              {t('Pickup order')}: {data?.id_picking_order}
                            </tr>
                            {data?.products && data?.products.map((d, i) => (
                              <tr>
                                <td>{d?.id_catalog}</td>
                                <td>{d?.product_description}</td>
                                <td>{d?.um}</td>
                                <td>{d?.weight}</td>
                                <td>{d?.pieces}</td>
                                <td>{d?.id_article}</td>
                                <td>{d?.expiring_date}</td>
                                <td>{d?.batch_code}</td>
                                <td>{d?.box}</td>
                              </tr>
                            ))}
                          </>
                        ))}
                      </tbody>
                    </table>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      ) : (
        history.push("/")
      )}
    </>
  );
};

export default Spedizione;
