import React, { useEffect, useState, useRef } from "react";
import { WEIGHTICON } from "../Utils/SVG";
import useRequest from "../../hooks/useRequest";
import { updateLoading } from "../../store/auth/action";
import { useSelector, useDispatch } from "react-redux";
import { useTranslation } from "react-i18next";

const SonProductList = (props) => {
  const {
    stock,
    product,
    isEdit,
    index,
    addMoreSonProduct,
    setAddMoreSonProduct,
    setIdArticle,
  } = props;

  const relationship = product.relationship;
  const id = product.id;
  const sonId = product.sonId;
  const name = product.name;
  const weight = product.weight;
  const quantity = product.quantity;
  const required = product.required;
  const uniqueId = product.unique_id;
  console.info("stock S : - ", stock)

  const [t, i18n] = useTranslation("translation");
  const [searchQuery, setSearchQuery] = useState("");
  const [templateProductData, setTemplateProductData] = useState([]);
  const { request: productSearchRequest, response: productSearchResponse } =
    useRequest();
  const [toSearch, setToSearch] = useState(isEdit !== true);
  const [isFirstSearch, setIsFirstSearch] = useState(true);
  const searchQueryRef = useRef();
  const dispatch = useDispatch();

  useEffect(() => {
    if (productSearchResponse && productSearchResponse.status === "SUCCESS") {
      /*
        description: "Agnello da latte porzionato (coscio, spalla, carrè) da 1 kg. ca."
        id_article: 7485
        kg_per_pieces: 1
        um: "KG"
        */
      setTemplateProductData(productSearchResponse?.data?.products);
    }
  }, [productSearchResponse]);
  
  useEffect(() => {
    // if (description) {
    setSearchQuery(name);
    // }
  }, [name]);

  useEffect(() => {
    if (searchQuery.trim().length > 0 && toSearch && !isFirstSearch) {
      searchQueryRef.current = setTimeout(() => {
        if(stock){
          productSearchRequest("GET", `search-product?name=${searchQuery}`);
          dispatch(updateLoading({ loading: false }));
        }else{
          productSearchRequest("GET", `products-data?name=${searchQuery}`);
          dispatch(updateLoading({ loading: false }));
        }
      }, 1000);
    }

    return () => {
      clearTimeout(searchQueryRef.current);
      setIsFirstSearch(false);
    };
  }, [searchQuery]);

  const removeAddSonProduct = () => {
    const rows = [...addMoreSonProduct];
    const index = rows.findIndex((row) => row.unique_id === uniqueId);
    rows.splice(index, 1);
    setAddMoreSonProduct(rows);
  };

  const sonProductFilterHandler = (data) => {
    const values = [...addMoreSonProduct];

    const index = values.findIndex((prod) => prod.unique_id === uniqueId);

    values[index] = { ...values[index], ...data };
    setIdArticle(values[index].id);
    setAddMoreSonProduct(values);
  };

  const updateSonQtyHandler = (id, quantity) => {
    const oldSonProduct = [...addMoreSonProduct];

    const index = oldSonProduct.findIndex((prod) => prod.id === id);

    oldSonProduct[index] = { ...oldSonProduct[index], quantity };

    setAddMoreSonProduct(oldSonProduct);
  };

  const updateRequiredHandler = (required) => {
    const oldSonProduct = [...addMoreSonProduct];

    const index = oldSonProduct.findIndex(
      (prod) => prod.unique_id === uniqueId
    );

    oldSonProduct[index] = { ...oldSonProduct[index], required };

    setAddMoreSonProduct(oldSonProduct);
  };

  return (
    <ul
      className={
        relationship === "main_son"
          ? "list-unstyled batchyellowColor"
          : "list-unstyled batchlightColor"
      }
    >
      <>
        <li>
          <label>
            {t("SON")} {index + 1}
          </label>
        </li>

        <li className="lotW">
          <div className="batchWorkList-ml">
            <label>{t("ID_ARTICLE")}</label>

            <span style={{ color: "#182749" }}>{id}</span>
          </div>
        </li>
        <li>
          <div className="batchWorkList-ml">
            <label>{t("ARTICLE")}</label>

            <div className="position-relative w-100">
              <input
                className="form-control"
                type="text"
                value={searchQuery}
                onChange={(e) => {
                  setSearchQuery(e.target.value);
                  setToSearch(true);
                }}
              />

              <ul className="list-unstyled mb-0 filterCustom-drop">
                {templateProductData &&
                  templateProductData.map((product, index) => (
                    <li
                      key={index}
                      onClick={() => {
                        sonProductFilterHandler({
                          shelflife: product.shelflife,
                          name: product.description,
                          weight: product.kg_per_pieces,
                          macrocat: product.macrocat,
                          id: product.id_article,
                        });
                        setTemplateProductData([]);
                        setToSearch(false);
                      }}
                    >
                     {product.id_article} - {product.description}
                    </li>
                  ))}
              </ul>
            </div>
          </div>
        </li>

        <li className="theoreticalWCheck">
          <div className="batchWorkList-ml">
            <div className="d-flex align-items-center w-100">
              <div className="form-check mb-0 mt-1">
                <input
                  className="form-check-input"
                  title="Obbligatorio"
                  type="checkbox"
                  checked={required}
                  onChange={(e) => updateRequiredHandler(e.target.checked)}
                />
              </div>
              <WEIGHTICON />
              <label>{t("theoretical weight (KG)")}</label>
            </div>
            <input className="form-control" type="text" defaultValue={weight} />
          </div>
        </li>
        <li className="actionW ms-auto">
          <div className="batchWorkList-ml">
            <button onClick={removeAddSonProduct}>
              <i className="far fa-minus-circle"></i>
            </button>
          </div>
        </li>
      </>
    </ul>
  );
};

export default SonProductList;
