import {
  SET_ORDER_REF,
  SET_PROVIDERS,
  GET_TOP_PROVIDERS,
  CLOSED_PROVIDERS,
} from "./actionTypes";

export const updateOrderRef = (payload) => {
  return {
    type: SET_ORDER_REF,
    payload,
  };
};

export const updateProviders = (payload) => {
  return {
    type: SET_PROVIDERS,
    payload,
  };
};

export const getTopProviders = (payload) => {
  return {
    type: GET_TOP_PROVIDERS,
    payload,
  };
};

export const closedProviders = (payload) => {
  return {
    type: CLOSED_PROVIDERS,
    payload,
  };
};
