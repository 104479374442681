import React, { useEffect, useState } from "react";
import { Link, NavLink } from "react-router-dom";
import Header from "../../components/header/header";
import SideBar from "../../components/Sidebar/sideBar";
import { useSelector, useDispatch } from "react-redux";
import { useHistory, useLocation } from "react-router-dom";
import useRequest from "../../hooks/useRequest";
import { toast } from "react-toastify";
import { useTranslation } from "react-i18next";
import DatePicker from "react-multi-date-picker";
import Scanner from "../../components/scanner/scanner";
import { Html5Qrcode } from "html5-qrcode";
import { checkDateIsExpired } from "../../components/Utils/Utils";


const qrConfig = { fps: 10, qrbox: { width: 300, height: 300 } };
// const brConfig = { fps: 10, qrbox: { width: 300, height: 150 } };
let html5QrCode;

const DettagliElencoOdv = () => {
  const location = useLocation();
  const [t, i18n] = useTranslation("translation");
  const history = useHistory();
  const { userRoleId } = useSelector((state) => state.auth);
  const orderData = location.state ? location.state.orderData : null;
  const product = location.state ? location.state.product : null;
  const procDataPer = location.state ? location.state.procDataPer : null;
  const barCodeX = location.state ? location.state.barCodeX : null;

  const { request, response } = useRequest();
  const [batchs, setBatchs] = useState([]);

  const [barCode, setBarCode] = useState("");
  const [batchId, setBatchId] = useState(product ? product?.id_batch : "");

  const [articleId, setArticleId] = useState(
    product ? product?.id_article : ""
  );
  const [batchCode, setBatchCode] = useState(
    product ? product?.batch_code : ""
  );
  const [expiringDate, setExpiringDate] = useState(
    product ? product?.expiring_date : ""
  );
  const [isProductExpired, setIsProductExpired] = useState(false)

  const [batchCodeData, setBatchCodeData] = useState([]);
  const [barCodeData, setBarCodeData] = useState([]);

  const [weight, setWeight] = useState(
    product ? (product?.weight / product?.pieces).toFixed(3) : 0
  );
  const [barcodeWeight, setBarcodeWeight] = useState(product ? (product?.weight / product?.pieces).toFixed(3) : 0);
  const [actualWeight, setActualWeight] = useState(
    product ? product?.weight / product?.pieces : 0
  );

  const [pieces, setPieces] = useState(1);
  const { request: batchCodeRequest, response: batchCodeResponse } =
    useRequest();
  const { request: barCodeRequest, response: barCodeResponse } = useRequest();

  useEffect(() => {
    html5QrCode = new Html5Qrcode("reader");
    let isExpired  = checkDateIsExpired(product ? product?.expiring_date : "")
    setIsProductExpired(isExpired)
  }, []);

  useEffect(() => {
    if (response && response.status === "SUCCESS") {
      toast.success(response?.message);
      history.goBack();
    } else {
      toast.error(response?.message);
    }
  }, [response]);

  const handleBack = () => {
    history.push({
      pathname: "/raccolta",
      state: {
        data: orderData || null,
      },
    });
  };

  useEffect(() => {
    document.body.className = "";
    batchCodeRequest("GET", `batch-codes/${articleId}`);

    barCodeRequest("POST", "product-barcodes", {
      batch_code: batchCode,
      id_article: articleId,
      expiring_date: expiringDate
    });
  }, []);

  useEffect(() => {
    if (barCodeResponse && barCodeResponse.status === "SUCCESS") {
      setBarCodeData(barCodeResponse?.data?.barcodes);
    }
  }, [barCodeResponse]);

  useEffect(() => {
    if (batchCodeResponse && batchCodeResponse.status === "SUCCESS") {
      let batches = batchCodeResponse?.data?.batches;
      setBatchCodeData(batches);
      // if (product?.id_batch && product?.batch_code) {
      //   batches.push({
      //     id_batch: product?.id_batch,
      //     batch_code: product?.batch_code,
      //     weight: product?.weight,
      //     pieces: product?.pieces,
      //     expiring_date: product?.expiring_date,
      //   });
      //   setBatchCodeData(batches);
      // } else {
      //   setBatchCodeData(batches);
      // }
    }
  }, [batchCodeResponse]);

  const setBatchCodeHandler = (id) => {
    const rows = [...batchCodeData];
    const index = rows.findIndex((data) => data.id_batch === +id);
    const batch = rows[index];
    if (batch) {
      setBatchCode(batch?.batch_code);
      setBatchId(batch?.id_batch);
      setExpiringDate(batch?.expiring_date);
      barCodeRequest("POST", "product-barcodes", {
        batch_code: batch?.batch_code,
        id_article: articleId,
        expiring_date: batch?.expiring_date
      });
    }
  };

  const hanlePickingProccess = () => {
    request("POST", "picking-process", {
      barcode: barCode,
      barcode_x: barCodeX,
      batch_code: batchCode,
      id_article: articleId,
      customer_description: orderData?.customer_description,
      expiring_date: expiringDate,
      id_selling_point: orderData?.id_selling_point,
      delivery_date: orderData?.delivery_date,
      product: {
        ...product,
        weight: +weight,
        pieces: +pieces,
        total_pieces: product?.pieces,
        total_weight: product?.weight,
      },
    });
  };

  const barcodeHanlder = (e) => {
    let barCode = e.target.value;
    setBarCode(barCode);
    barCodeData.map((data, index) => {
      if (data.barcode === barCode) {
        if(barCode.includes('R')){
          let totalWeight = +data.weight
          let dataPieces = parseInt(data.pieces)
          let singleWeight = totalWeight / dataPieces
          setBarcodeWeight(singleWeight)
          let dataWeight = singleWeight * pieces
          dataWeight = dataWeight.toFixed(3);
          setWeight(dataWeight);
        }else{
          let dataWeight = +data.weight
          setBarcodeWeight(dataWeight)
          dataWeight = dataWeight * pieces
          dataWeight = dataWeight.toFixed(3);
          setWeight(dataWeight);
        }
      }
    });
  };

  const numInputHandler = (e, input) => {
    let value = e.target.value
    console.info(value)
    if(value && !isNaN(+value)){
      console.info('Is a number')
      if(input === "weight"){
        setWeight(value);
      }else if(input === "pieces"){
        setPieces(parseInt(value))
        let dataWeight = 0
        if(barcodeWeight && value){
          dataWeight = +barcodeWeight * parseInt(value)
          dataWeight = dataWeight.toFixed(3);
        }
        setWeight(dataWeight);
      }
    }else{
      console.info('Is not a number')
      if(value){
        toast.error(t('not_number'));
      }else{
        if(input === "weight"){
          setWeight('');
        }else if(input === "pieces"){
          setPieces('');
          setWeight('');
        }
      }
    }
  }
  
  const handleClickAdvanced = () => {
    const qrCodeSuccessCallback = (decodedText, decodedResult) => {
      setBarCode(decodedText);

      if (decodedText) {
        handleStop();
      }
    };
    html5QrCode.start(
      { facingMode: "environment" },
      qrConfig,
      qrCodeSuccessCallback
    );
  };

  const handleStop = () => {
    try {
      html5QrCode
        .stop()
        .then((res) => {
          html5QrCode.clear();
        })
        .catch((err) => {
          console.info(err, " < error scan html5QrCode");
        });
    } catch (err) {
      console.info(err, " < error scan");
    }
  };

  return (
    <>
      {userRoleId === 5 || userRoleId === 4 ? (
        <div>
          <div className="dashboard_parent">
            <SideBar />

            <div className="dashboard_right">
              {/* <!-- dashboard header --> */}

              <Header
                title={"Warehouse"}
                links={[{ to: "/raccolta", name: "ODV" }]}
              />
              {/* <!-- dashboard Content --> */}

              <div className="dashboard_content">
                <div className="dashboard-container">
                  <div className="p-3">
                    <div className="orderList-data">
                      {/* Order List Item */}
                      <div className="orderList-row">
                        <div className="sm-progress">
                          <div
                            className="sm-progressInner"
                            style={{ height: `${procDataPer}%` }}
                          ></div>
                          <span>
                            {procDataPer !== 0 ? `${procDataPer} %` : ""}
                          </span>
                        </div>
                        <div className="suppliername">
                          <label>{t("Customer")} </label>
                          <h6>{orderData?.customer_description}</h6>
                        </div>
                        <div className="orderno">
                          <label>{t("Delivery Date")}</label>
                          <h6>{orderData?.delivery_date}</h6>
                        </div>
                        <div className="orderdate">
                          <label>{t("Qty ordered")}</label>
                          <h6> {orderData?.total_pieces}</h6>
                        </div>
                        <div className="orderqty">
                          <label>{t("Qty processed")}</label>
                          <h6>{orderData?.total_picked_pieces}</h6>
                        </div>
                      </div>

                      <div className="ptb-wrap">
                        <div className="products-typebox new_products-typebox py-3 py-md-5">
                          <div className="d-block newPrdTypeBox_700">
                            <div className="itemsearch-wrap">
                              <div className="single_item">
                                <div className="row align-items-center">
                                  <div className="col-md-4 text-end mb-1 mb-md-0">
                                    <div className="label-row">
                                      <span className="label">{t("item")}</span>
                                    </div>
                                  </div>
                                  <div className="col-md-6">
                                    <div className="themeInput themeIS">
                                      <input
                                        type="text"
                                        value={
                                          product
                                            ? product?.product_description
                                            : ""
                                        }
                                        className="form-control"
                                      />
                                      <button className="themeIS-btn">
                                        <i className="fas fa-search"></i>
                                      </button>
                                    </div>
                                  </div>
                                </div>
                              </div>

                              <div className="multi_item">
                                <div className="row align-items-center">
                                  <div className="col-md-4 text-end mb-1 mb-md-0">
                                    <div className="label-row">
                                      <span className="label">{t("lot")}</span>
                                      <span className="label">
                                        {t("production date")}
                                      </span>
                                    </div>
                                  </div>
                                  <div className="col-md-3 mb-1 mb-md-0">
                                    <div className="themeInput">
                                      <select
                                        onChange={(e) => {
                                          setBatchCodeHandler(e.target.value);
                                        }}
                                        className="form-select"
                                      >
                                        <option value="" hidden>
                                          {t("select the lot")}
                                        </option>

                                        {batchCodeData.map((data, index) => (
                                          <option
                                            key={index}
                                            value={data.id_batch}
                                            selected={
                                              data.id_batch === batchId
                                                ? true
                                                : false
                                            }
                                          >
                                            {data.batch_code}
                                          </option>
                                        ))}
                                      </select>
                                    </div>
                                  </div>
                                  <div className="col-md-3 mb-1 mb-md-0">
                                    <div className="themeInput themeIS date_box mx-md-10">
                                      <DatePicker
                                        placeholder={t("Select the date")}
                                        value={expiringDate}
                                        onChange={(date) => {
                                          setExpiringDate(date.format());
                                        }}
                                        format="DD/MM/YYYY"
                                      />
                                      <button className="themeIS-btn">
                                        <i className="far fa-calendar-alt"></i>
                                      </button>
                                    </div>
                                  </div>
                                </div>
                              </div>
                              <div className="multi_item">
                                <div className="row align-items-center">
                                  <div className="col-md-4 text-end mb-1 mb-md-0">
                                    <div className="label-row">
                                      <span className="label">
                                        {t("Barcode")}
                                      </span>
                                    </div>
                                  </div>
                                  <div className="col-md-3 mb-1 mb-md-0">
                                    <div className="row">
                                      <div className="themeInput col-md-6">
                                        <select
                                          onChange={(e) => {
                                            barcodeHanlder(e);
                                          }}
                                          className="form-select"
                                          value={barCode}
                                          disabled={isProductExpired}
                                        >
                                          <option value="" hidden>
                                            {t("select the barcode")}
                                          </option>

                                          {barCodeData.map((data, index) => (
                                            <option
                                              key={index}
                                              value={data.barcode}
                                            >
                                              Weight: {data.weight} &nbsp; Barcode:{" "}
                                              {data.barcode} &nbsp;
                                            </option>
                                          ))}
                                        </select>
                                      </div>
                                      <div className="col-md-6">
                                        <Scanner func={!isProductExpired ? handleClickAdvanced : ''} />
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </div>

                              <div className="row">
                                <div className="col-md-8">
                                  <div>
                                    <div className="multi_item">
                                      <div className="row align-items-center">
                                        <div className="col-md-6 text-end">
                                          <div className="label-row">
                                            <span className="label">
                                              {t("gross weight")}
                                            </span>
                                          </div>
                                        </div>
                                        <div className="col-md-5">
                                          <div className="themeInput">
                                            <input
                                              type="text"
                                              onChange={(e) => {
                                                numInputHandler(e, 'weight');
                                              }}
                                              value={weight}
                                              className="form-control text-end" 
                                            />
                                          </div>
                                        </div>
                                      </div>
                                    </div>
                                  </div>

                                  <div className="multi_item">
                                    <div className="row align-items-center">
                                      <div className="col-md-6 text-end">
                                        <div className="label-row">
                                          <span className="label">
                                            {t("pieces")}
                                          </span>
                                        </div>
                                      </div>
                                      <div className="col-md-5">
                                        <div className="themeInput">
                                          <input
                                            type="text"
                                            onChange={(e) => {
                                              numInputHandler(e, 'pieces')
                                            }}
                                            value={pieces}
                                            className="form-control text-end"
                                          />
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>

                      <div className="orderList-dataction">
                        {/* <button className="btn">
                          <i className="far fa-print"></i>
                        </button>
                        <button className="btn">
                          <i className="far fa-undo"></i>
                        </button> */}
                        <button onClick={() => history.goBack()} className="btn">
                          <i className="far fa-times"></i>
                        </button>
                        <button onClick={hanlePickingProccess} className="btn">
                          <i className="far fa-check"></i>
                        </button>
                        {/* <button className="btn disabled"><i className="fas fa-play"></i></button> */}
                      </div>
                    </div>
                  </div>
                </div>
                {/* <!-- dashboard-container  END --> */}
              </div>
              {/* <!-- dashboard_content END --> */}
            </div>
          </div>
        </div>
      ):(
        history.push("/")
      )}
    </>
  );
};

export default DettagliElencoOdv;
