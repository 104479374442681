import React, { useState, useEffect } from "react";
import { toast } from "react-toastify";
import { useLocation, useHistory } from "react-router-dom";
import { useSelector } from "react-redux";
import DatePicker from "react-multi-date-picker";
import { useTranslation } from "react-i18next";

import SideBar from "../../components/Sidebar/sideBar";
import Header from "../../components/header/header";
import useRequest from "../../hooks/useRequest";
import { convertDate } from "../../components/Utils/Utils";
import { CALCULATEICON } from "../../components/Utils/SVG";
import SonProductModificationList from "../../components/layout/mofication/sonProductBoxModification";
import FatherProductModificationList from "../../components/layout/mofication/fatherProductBoxModification";

const ProduzioneModificare = () => {
  const location = useLocation();
  const history = useHistory();
  const [t, i18n] = useTranslation("translation");
  const jobData = location.state ? location.state.job : "";
  const [jobQuantityValidation, setJobQuantityValidation] = useState(false);
  const [jobDateValidation, setJobDateValidation] = useState(false);
  const [toSearch, setToSearch] = useState(true);
  const [date, setDate] = useState("");
  const [idJob, setJobId] = useState("");
  const [jobQuantity, setJobQuantity] = useState("");
  const { request: jobExcutionDataRequest, response: jobExcutionDataResponse } =
    useRequest();

  const { request: createJobRequest, response: createJobResponse } =
    useRequest();

  const { request: departmentRequest, response: departmentResponse } =
    useRequest();
  const { request: sonProdRequest, response: sonProdResponse } =
    useRequest();

  const {
    request: fatherSonCalculationRequest,
    response: fatherSonCalculationResponse,
  } = useRequest();

  const { userRoleId, idWarehouse } = useSelector((state) => state.auth);
  const [fatherCurrentId, setFatherCurrentId] = useState(0);
  const [sonProductCurrentId, setSonProductCurrentId] = useState(0);
  const [idArticle, setIdArticle] = useState(null);
  const batchSon = location.state ? location.state.batchSon : "";
  const [barcode, setBarcode] = useState(null);
  const [fatherProducts, setFatherProducts] = useState([]);
  const [sonProducts, setSonProducts] = useState([]);

  const [isFirstEdit, setIsFirstEdit] = useState(false);
  const [departments, setDepartments] = useState([]);
  const [department, setDepartment] = useState(jobData?.id_department ? jobData?.id_department : null);
  const { request: scanBarcodeRequest, response: scanBarcodeResponse } = useRequest();
  const [scanBarcode, setScanBarcode] = useState(null);

  useEffect(() => {
    if (!jobData || jobData == null) {
      handleBack();
    }
  }, [jobData]);

  const handleBack = () => {
    history.push({
      pathname: "/produzione",

      state: {
        jobData: jobData || null,
      },
    });
  };

  useEffect(() => {
    departmentRequest("GET", `departments?id_warehouse=${idWarehouse}`);
    document.title = "HQF - Produzione";
    document.body.classList.add("production");
  }, []);

  useEffect(() => {
    if (departmentResponse) {
      if (departmentResponse.status === "SUCCESS") {
        setDepartments(departmentResponse?.data);
      }
    }
  }, [departmentResponse]);

  useEffect(() => {
    setIsFirstEdit(true);
    setJobId(jobData?.id_job);
    setJobQuantity(jobData ? jobData?.pieces_main_son : "");
    setDate(jobData ? convertDate(jobData?.execution_date) : "");
    setToSearch(false);
    if (jobData?.id_job_set) {
      jobExcutionDataRequest("GET", `get-job-execution/${jobData?.id_job_set}`);
    }
  }, []);

  useEffect(() => {
    if (idArticle && sonProducts.length == 1) {
      jobExcutionDataRequest("GET", `get-father-son-products/${idArticle}`);
    }
  }, [idArticle]);

  useEffect(() => {
    if (jobExcutionDataResponse) {
      if (jobExcutionDataResponse.status === "SUCCESS") {
        setFatherProducts(
          jobExcutionDataResponse?.data?.father_products.map((a, idx) => ({
            ...a,
            lotto: "",
          }))
        );
        setSonProducts(
          jobExcutionDataResponse?.data?.son_products.map((product, idx) => ({
            ...product,
            unique_id: product?.id
          }))
        );
      } else {
        toast.error(jobExcutionDataResponse?.message);
      }
    }
  }, [jobExcutionDataResponse]);

  useEffect(()=>{
    if(scanBarcode && scanBarcode.length === 20){
      scanBarcodeRequest('POST', `scan-barcode-product`, {barcode: scanBarcode})
    }
  }, [scanBarcode])
  
  useEffect(()=>{
    if(scanBarcodeResponse?.status === "SUCCESS"){
      setScanBarcode('')
      // Set father product data in father list
      let product = scanBarcodeResponse?.data?.product
      let barcode = scanBarcodeResponse?.data?.barcode
      let fatherProduct = {
        id: fatherProducts.length > 0 ? fatherProducts.length + 1 : 0,
        id_father: product?.id_article,
        description: product?.product_name,
        weight_father: product?.weight,
        macrocategory: product?.macrocat,
        shelflife: product?.shelflife,
        batch_father: product?.batch_code,
        lottos: [],
        barcode: barcode,
        type: "main"
      }
      const newFatherProducts = [...fatherProducts, fatherProduct]
      setFatherProducts(newFatherProducts);
    }else if(scanBarcodeResponse?.status === "ERROR"){
      toast.error(scanBarcodeResponse?.message);
    }
  }, [scanBarcodeResponse])

  const checkDateObj = (date) => {
    if (date) {
      if (typeof date === "object") {
        let d = date.toLocaleDateString();
        let DateList = d.split("/");

        let newDate = DateList[2] + "-" + DateList[1] + "-" + DateList[0];
        return newDate;
      } else {
        return date;
      }
    }
  };

  const jobSaveHandler = () => {
    if (jobQuantity === "") {
      setJobQuantityValidation(true);
      return;
    } else {
      setJobQuantityValidation(false);
    }

    if (date === "") {
      setJobDateValidation(true);
    } else {
      setJobDateValidation(false);
    }
    if(fatherProducts.length > 0 && sonProducts.length > 0){
      createJobRequest("POST", `job-update/${jobData?.id_job_set}`, {
        id_department: department,
        quantity: +jobQuantity,
        creation_date: checkDateObj(date),
        father_products: fatherProducts,
        son_products: sonProducts,
        is_stock: jobData?.is_stock === true ? true : false,
        weight_son:
          sonProducts.length > 0 && sonProducts[0].type === "main"
            ? sonProducts[0].weight_son
            : null,
        id_warehouse:
          jobData && jobData.id_warehouse ? jobData.id_warehouse : null,
        order_id: jobData && jobData.id_odv ? jobData.id_odv : null,
        state: jobData && jobData.state ? jobData.state : null,
        batch_son: batchSon,
      });
    }else{
      if(fatherProducts.length === 0){
        toast.error(t('Please select atleast one father product'));
      }else if(sonProducts.length === 0){
        toast.error(t('Please select atleast one son product'));
      }
    }
  };

  useEffect(() => {
    if (createJobResponse) {
      if (createJobResponse?.status === "SUCCESS") {
        history.push("/produzione");
        toast.success(createJobResponse.message);
      } else {
        toast.error(createJobResponse.message);
      }
    }
  }, [createJobResponse]);

  const getSonProducts = () => {
    let main_father_articleId = null;
    let sec_father_ids = [];
    let main_son_id = null
    fatherProducts.map((data) => {
      if (data?.type === "main") {
        if (data?.id_father !== "") {
          main_father_articleId = data?.id_father;
        }
      }
      if (data?.type !== "main") {
        if (data?.id_father !== "") {
          sec_father_ids.push(data?.id_father);
        }
      }
    });
    sonProducts.map((data) => {
      if (data?.type === "main") {
        if (data?.id_son !== "") {
          main_son_id = data?.id_son;
        }
      }
    });
    sonProdRequest('POST', 'get-son-products', {
      id_article: main_son_id,
      main_father_id_article: main_father_articleId,
      sec_father_id_articles: sec_father_ids,
    })
  }

  useEffect(() => {
    if (sonProdResponse) {
      if (sonProdResponse?.status === "SUCCESS") {
        let sonArray = sonProdResponse?.data?.son_products.map(
          (data, index) => {
            data.required = false;
            data.sonId = index;
            data.description = data.name;
            delete data.name;
            data.id_son = data.id;
            data.weight_son = data.weight;
            delete data.weight;
            if(data.relationship === "main_son"){
              data.required = true
              data.type = "main"
              delete data.relationship;
            }else{
              data.type = "secondary"
              delete data.relationship;
            }
            return data;
          }
        );
        setSonProductCurrentId(sonArray.length);
        setSonProducts(sonArray);
      }
    }
  }, [sonProdResponse]);

  // Deprecated 
  const calculateFatherSonProduct = () => {
    let main_father_articleId;
    let sec_father_ids = [];
    fatherProducts.map((data) => {
      if (data?.type === "main") {
        if (data?.id !== "") {
          main_father_articleId = data?.id_father;
        }
      }
      if (data?.type !== "main") {
        if (data?.id !== "") {
          sec_father_ids.push(data?.id_father);
        }
      }
    });
    fatherSonCalculationRequest("POST", "father-son-products", {
      main_father_id_article: main_father_articleId,
      sec_father_id_articles: sec_father_ids,
    });
  };

  const setLottoHandler = (lotto, id) => {
    if (lotto == "return") {
      return;
    }
    const rows = [...fatherProducts];
    const index = rows.findIndex((prod) => prod.id === id);
    rows[index] = { ...rows[index], lotto };
    setFatherProducts(rows);
  };

  const resetHandler = () => {
    setDate("");
    setJobQuantity("");
    setFatherProducts([]);
    setSonProducts([]);
    setJobDateValidation(false);
    setJobQuantityValidation(false);
  };

  const removeFatherDataHandler = (id) => {
    let newFatherProducts = fatherProducts.filter((item) => item.id !== id);
    setFatherProducts(newFatherProducts);
  };

  const productFilterHandler = (data, id) => {
    const values = [...fatherProducts];

    const index = values.findIndex((prod) => prod.id === id);

    values[index] = { ...values[index], ...data };

    setFatherProducts(values);
  };

  const removeSonDataHandler = (id) => {
    let newSonProducts = sonProducts.filter((item) => {
      if (item.id_son) {
        return item.id_son !== id;
      } else {
        return item.id !== id;
      }
    });

    setSonProducts(newSonProducts);
  };

  const sonProductFilterHandler = (data, id) => {
    const values = [...sonProducts];
    const index = values.findIndex((prod) => prod.sonId === id);
    values[index] = { ...values[index], ...data };
    setIdArticle(values[index].id);
    console.log(values);
    setSonProducts(values);
  };

  const departmentObj = {
    packages: "Confezionamento",
    warehouse: "Lab. Magazzino",
    receiving: "Lab. Ricezino",
    lab_boning: "Lab. Disosso",
    lab_fish: "Lab. Pesce",
    lab_packaging: "Lab. Confezionamento",
    lab_minced_meat: "Lab. Macinati",
    lab_cheese: "Lab. Formaggi",
    lab_portioned: "Lab. Porzionati",
    lab_dry: "Lab. Secco",
    lab_tartare: "Lab. Tartara",
    stock_sendings: "Stock e Preparazione",
  };

  return (
    <>
      {userRoleId === 5 || userRoleId === 2 ? (
        <div className="container">
          <div className="dashboard_parent">
            {/* <!-- Sidebar --> */}
            <SideBar />
            <div className="dashboard_right bg-white">
              {/* <!-- dashboard header --> */}

              <Header
                title={t("Production")}
                links={[{ to: "/produzione", name: t("production list") }]}
              />

              {/* <!-- dashboard Content --> */}

              <div className="dashboard_content">
                <div className="dashboard-container bg-white">
                  {/* New Processing */}
                  <div className="NewProcess">
                    <div className="NewProcessHead">
                      <div className="d-flex align-items-center">
                        <h3 className="mb-0 me-3">{t("PROCESSING")}</h3>
                        <h4 className="mb-0">{jobData?.id_job_set}</h4>
                      </div>
                    </div>

                    <div className="NewProcessInner">
                      <div className="row">
                        <div className="col-md-8">
                          <div className="row align-items-center mb-3">
                            <div className="col-md-7">
                              <div className="row align-items-center">
                                <div className="col-md-5 mb-1 mb-md-0">
                                  <label>{t("EXECUTION DATE")}</label>
                                </div>
                                <div className="col-md-7">
                                  <div className="themeInput themeIS date_box">
                                    <DatePicker
                                      placeholder="Seleziona la data"
                                      // value={date}
                                      value={date}
                                      onChange={(date) => {
                                        setDate(date.format());
                                      }}
                                      // selected={value}
                                      minDate={new Date()}
                                      format="YYYY-MM-DD"
                                    />
                                    <button
                                      type="button"
                                      className="themeIS-btn"
                                    >
                                      <i className="far fa-calendar-alt"></i>
                                    </button>
                                    {jobDateValidation && (
                                      <div className="custom-invalid-feedback-date">
                                        {t("This field is mandatory.")}
                                      </div>
                                    )}
                                  </div>
                                </div>
                              </div>
                            </div>
                            <div className="col-md-5">
                              <div className="row align-items-center">
                                <div className="col-auto mb-1 mb-md-0">
                                  <label>{t("QUANTITY")}</label>
                                </div>
                                <div className="col">
                                  <div className="themeInput date_box">
                                    <input
                                      type="number"
                                      min="0"
                                      placeholder="INS. NUM. PZ."
                                      className="form-control"
                                      name=""
                                      value={jobQuantity}
                                      onChange={(e) => {
                                        setJobQuantity(e.target.value);
                                      }}
                                    />
                                  </div>
                                  {jobQuantityValidation && (
                                    <div className="custom-invalid-feedback-date">
                                      {t("This field is mandatory.")}
                                    </div>
                                  )}
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                        <div className="col-md-4">
                          <div className="row align-items-center mb-3">
                            <div className="col-12">
                              <select
                                onChange={(e) => setDepartment(e.target.value)}
                                className="form-select theme-select"
                              >
                                <option value="" hidden>
                                  Select Department
                                </option>
                                {departments &&
                                  departments.map((item, index) => (
                                    <option
                                      key={index}
                                      value={item.id_department}
                                      selected={
                                        item.id_department ===
                                        jobData.id_department
                                          ? true
                                          : false
                                      }
                                    >
                                      {departmentObj[item.department]}
                                    </option>
                                  ))}
                              </select>
                            </div>
                          </div>

                          <div className="row mt-4">
                            <div className="col-md-4">
                              <div className="headerInput themeInput">
                                <label for="qrcode">{t("Qr Code")}</label>
                                <input type="text" value={scanBarcode} onChange={(e) => {setScanBarcode(e.target.value)}} id="qrcode" className="form-control" placeholder={t("Qr Code")} autoFocus/>
                              </div>
                            </div>
                            <div className="col-md-6">
                              <div className="orderList-dataction">
                                <button
                                  className="btn bgBtnTheme "
                                  title="calculate product"
                                  onClick={getSonProducts}
                                >
                                  <CALCULATEICON />
                                </button>

                                {/* <button
                                  className="btn bgBtnTheme "
                                  title={t("Reset")}
                                  onClick={resetHandler}
                                >
                                  <i className="far fa-undo"></i>
                                </button> */}
                                <button
                                  className="btn bgBtnTheme"
                                  title={t("To close")}
                                  onClick={() => history.push("/produzione")}
                                >
                                  <i className="far fa-times"></i>
                                </button>
                                <button
                                  className="btn bgBtnTheme"
                                  title={t("Save")}
                                  onClick={jobSaveHandler}
                                >
                                  <i className="far fa-check"></i>
                                </button>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>

                    <div className="container mt-md-4 mt-2">
                      <div className="batchWorkListBlock">
                        <div className="batchWorkList">
                          {fatherProducts &&
                            fatherProducts.map((product, index) => {
                              return (
                                <FatherProductModificationList
                                  product={product}
                                  key={product.id}
                                  index={index}
                                  fatherProducts={fatherProducts}
                                  setFatherProducts={setFatherProducts}
                                  setBarcode={setBarcode}
                                />
                              );
                            })}
                          <button
                            className="batchWorkListAdd"
                            onClick={() => {
                              setFatherProducts((prev) => [
                                ...prev,
                                {
                                  id: fatherProducts.length > 0 ? fatherProducts.length + 1 : 0,
                                  description: "",
                                  price_coeff: "",
                                  weight_father: "",
                                  type: "",
                                  lottos: [],
                                  fatherId: fatherCurrentId + 1,
                                },
                              ]);

                              setFatherCurrentId((prev) => prev + 1);
                            }}
                          >
                            AGGIUNGI PADRE
                            <i className="far fa-plus-circle"></i>
                          </button>
                          <hr className="customHr" />
                          <div className="my-4"></div>
                          {sonProducts &&
                            sonProducts.map((product, index) => {
                              return (
                                <SonProductModificationList
                                  key={product.id}
                                  index={index}
                                  product={product}
                                  setSonProducts={setSonProducts}
                                  sonProducts={sonProducts}
                                  setIdArticle={setIdArticle}
                                />
                              );
                            })}
                          <button
                            className="batchWorkListAdd"
                            onClick={() => {
                              setSonProducts((prev) => [
                                ...prev,
                                {
                                  id_son: "",
                                  id: sonProducts.length > 0 ? sonProducts.length + 1 : 0,
                                  description: "",
                                  price_coeff: "",
                                  weight_son: "",
                                  type: "",
                                  lottos: [],
                                  sonId: sonProductCurrentId + 1,
                                  shelflife: null,
                                },
                              ]);

                              setSonProductCurrentId((prev) => prev + 1);
                            }}
                          >
                            {t("ADD SON")}
                            <i className="far fa-plus-circle"></i>
                          </button>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      ) : (
        history.push("/produzione")
      )}
    </>
  );
};

export default ProduzioneModificare;
