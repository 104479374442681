import React from "react";
import { Link } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { authSuccess, logout } from "../../store/auth/action";
import NavLink from "../navlink/NavLink";
import { LOGOUTICON } from "../Utils/SVG";
import { useTranslation } from "react-i18next";

const DashSideBar = () => {
  const [t, i18n] = useTranslation("translation");
  const dispatch = useDispatch();

  return (
    <div
      className="dashboard_left"
      data-bs-scroll="true"
      tabIndex="-1"
      id="offcanvasExample"
      aria-labelledby="offcanvasExampleLabel"
    >
      <div className="dashmenu">
        <Link to="/" className="navbar-brand">
          <img src="/img/logo.png" alt="" />
        </Link>
        <ul className="list-unstyled dashmenu-list mb-0">
          <NavLink href="/dashboard1">
            <li>
              <Link to="/dashboard1">
                <i className="menu-icon">
                  <img src="img/DASHBOARD.svg" alt="DASHBOARD" />
                </i>
                Dashboard 1
              </Link>
            </li>
          </NavLink>
          <NavLink href="/dashboard2">
            <li>
              <Link to="/dashboard2">
                <i className="menu-icon">
                  <img src="img/DASHBOARD.svg" alt="DASHBOARD" />
                </i>
                Dashboard 2
              </Link>
            </li>
          </NavLink>
          <NavLink href="/dashboard3">
            <li className="">
              <Link to="/dashboard3">
                <i className="menu-icon">
                  <img src="img/DASHBOARD.svg" alt="DASHBOARD" />
                </i>
                Dashboard 3
              </Link>
            </li>
          </NavLink>
          <NavLink href="/dashboard4">
            <li className="">
              <Link to="/dashboard4">
                <i className="menu-icon">
                  <img src="img/DASHBOARD.svg" alt="DASHBOARD" />
                </i>
                Dashboard 4
              </Link>
            </li>
          </NavLink>
          <NavLink href="/report">
            <li className="">
              <Link to="/report">
                <i className="menu-icon">
                  <img src="img/DASHBOARD.svg" alt="REPORT" />
                </i>
                Report
              </Link>
            </li>
          </NavLink>
        </ul>
      </div>
      <div className="list-unstyled dashmenu-list mb-0">
        <li className="my-0">
          <Link to="" onClick={() => dispatch(logout())}>
            <i className="menu-icon">
              <LOGOUTICON />
            </i>
            {t("Log out")}
          </Link>
        </li>
      </div>
    </div>
  );
};

export default DashSideBar;