import ZebraBrowserPrintWrapper from "zebra-browser-print-wrapper";
import React, { useState, useEffect } from "react";

// const ProductionprintLabelNonMeat = ({
//   printdata,
//   note,
//   barcode,
//   tara,
//   grossWeight,
// }) => {
// const [notes, setNotes] = useState({});

export async function printLabelNonMeatProduction(printdata) {
  let note = printdata?.productNotesRowOne;
  let productName = printdata?.productNameRowOne;
  let notes = {};
  let productNameRowOne;
  let productNameRowTwo;
  let productNameRowThree;

  const noteSplit = (note) => {
    const arr = note.match(/.{1,35}/g);
    const [
      productNotesRowOne,
      productNotesRowTwo,
      productNotesRowThree,
      productNotesRowFour,
      productNotesRowFive,
      productNotesRowSix,
      productNotesRowSeven,
      productNotesRowEight,
      productNotesRowNine,
      productNotesRowTen,
      productNotesRowEleven,
      productNotesRowTwelve,
      productNotesRowThirteen,
      productNotesRowFourteen,
      productNotesRowFifteen,
      productNotesRowSixteen,
      productNotesRowSeventeen,
      productNotesRowEighteen,
      productNotesRowNineteen,
      productNotesRowTwenty,
      productNotesRowTwentyone,
      productNotesRowTwentytwo,
      productNotesRowTwentythree,
      productNotesRowTwentyfour,
      productNotesRowTwentyfive,
    ] = arr;
    notes = {
      productNotesRowOne,
      productNotesRowTwo,
      productNotesRowThree,
      productNotesRowFour,
      productNotesRowFive,
      productNotesRowSix,
      productNotesRowSeven,
      productNotesRowEight,
      productNotesRowNine,
      productNotesRowTen,
      productNotesRowEleven,
      productNotesRowTwelve,
      productNotesRowThirteen,
      productNotesRowFourteen,
      productNotesRowFifteen,
      productNotesRowSixteen,
      productNotesRowSeventeen,
      productNotesRowEighteen,
      productNotesRowNineteen,
      productNotesRowTwenty,
      productNotesRowTwentyone,
      productNotesRowTwentytwo,
      productNotesRowTwentythree,
      productNotesRowTwentyfour,
      productNotesRowTwentyfive,
    };
  };
  const nameSplit = (name) => {
    let arr = name.split("");
    let first = arr.slice(0, 56);
    let second = arr.slice(56, 112);
    let third = arr.slice(112);
    productNameRowOne = first.join("");
    productNameRowTwo = second.join("");
    productNameRowThree = third.join("");
  };

  if (productName) {
    nameSplit(productName);
  }

  if (note && note.match(/.{1,35}/g)) {
    noteSplit(note);
  }

  try {
    // Create a new instance of the object
    const browserPrint = new ZebraBrowserPrintWrapper();
    // Select default printer
    const defaultPrinter = await browserPrint.getDefaultPrinter();
    // Set the printer
    browserPrint.setPrinter(defaultPrinter);
    // Check if the printer is ready
    const printerStatus = await browserPrint.checkPrinterStatus();

    if (printerStatus.isReadyToPrint) {
      // ZPL script to print a qr code

      let grande_nonmeat = `^XA
                        ~TA000
                        ~JSN
                        ^LT0
                        ^MNW
                        ^MTT
                        ^PON
                        ^PMN
                        ^LH0,0
                        ^JMA
                        ^PR6,6
                        ~SD15
                        ^JUS
                        ^LRN
                        ^CI27
                        ^PA0,1,1,0
                        ^XZ
                        ^XA
                        ^MMT
                        ^PW831
                        ^LL799
                        ^LS0
                        ^FT623,799^BQN,2,7
                        ^FH\^FDHA,${printdata?.productBarcodeId ? printdata?.productBarcodeId : ""}^FS
                        ^FT115,204^A0N,28,28^FH\^CI28^FD${
                          productNameRowOne ? productNameRowOne : ""
                        }^FS^CI27
                        ^FT115,239^A0N,28,28^FH\^CI28^FD${
                          productNameRowTwo ? productNameRowTwo : ""
                        }^FS^CI27
                        ^FT115,281^A0N,23,23^FH\^CI28^FD${
                          printdata?.productNameEnglish ? printdata?.productNameEnglish : ""
                        }^FS^CI27
                        ^FT26,204^A0N,28,28^FH\^CI28^FD${
                          printdata?.productId ? printdata?.productId : ""
                        }^FS^CI27
                        ^FO24,304^GB424,472,1^FS
                        ^FT26,326^AAN,18,10^FH\^FD${
                          notes?.productNotesRowOne ? notes?.productNotesRowOne : ""
                        }^FS
                        ^FT26,344^AAN,18,10^FH\^FD${
                          notes?.productNotesRowTwo ? notes?.productNotesRowTwo : ""
                        }^FS
                        ^FT26,362^AAN,18,10^FH\^FD${
                          notes?.productNotesRowThree ? notes?.productNotesRowThree : ""
                        }^FS
                        ^FT26,380^AAN,18,10^FH\^FD${
                          notes?.productNotesRowFour ? notes?.productNotesRowFour : ""
                        }^FS
                        ^FT26,398^AAN,18,10^FH\^FD${
                          notes?.productNotesRowFive ? notes?.productNotesRowFive : ""
                        }^FS
                        ^FT26,416^AAN,18,10^FH\^FD${
                          notes?.productNotesRowSix ? notes?.productNotesRowSix : ""
                        }^FS
                        ^FT26,434^AAN,18,10^FH\^FD${
                          notes?.productNotesRowSeven ? notes?.productNotesRowSeven : ""
                        }^FS
                        ^FT26,452^AAN,18,10^FH\^FD${
                          notes?.productNotesRowEight ? notes?.productNotesRowEight : ""
                        }^FS
                        ^FT26,470^AAN,18,10^FH\^FD${
                          notes?.productNotesRowNine ? notes?.productNotesRowNine : ""
                        }^FS
                        ^FT26,488^AAN,18,10^FH\^FD${
                          notes?.productNotesRowTen ? notes?.productNotesRowTen : ""
                        }^FS
                        ^FT26,506^AAN,18,10^FH\^FD${
                          notes?.productNotesRowEleven ? notes?.productNotesRowEleven : ""
                        }^FS
                        ^FT26,524^AAN,18,10^FH\^FD${
                          notes?.productNotesRowTwelve ? notes?.productNotesRowTwelve : ""
                        }^FS
                        ^FT26,542^AAN,18,10^FH\^FD${
                          notes?.productNotesRowThirteen ? notes?.productNotesRowThirteen : ""
                        }^FS
                        ^FT26,560^AAN,18,10^FH\^FD${
                          notes?.productNotesRowFourteen ? notes?.productNotesRowFourteen : ""
                        }^FS
                        ^FT26,578^AAN,18,10^FH\^FD${
                          notes?.productNotesRowFifteen ? notes?.productNotesRowFifteen : ""
                        }^FS
                        ^FT26,596^AAN,18,10^FH\^FD${
                          notes?.productNotesRowSixteen ? notes?.productNotesRowSixteen : ""
                        }^FS
                        ^FT26,614^AAN,18,10^FH\^FD${
                          notes?.productNotesRowSeventeen ? notes?.productNotesRowSeventeen : ""
                        }^FS
                        ^FT26,632^AAN,18,10^FH\^FD${
                          notes?.productNotesRowEighteen ? notes?.productNotesRowEighteen : ""
                        }^FS
                        ^FT26,650^AAN,18,10^FH\^FD${
                          notes?.productNotesRowNineteen ? notes?.productNotesRowNineteen : ""
                        }^FS
                        ^FT26,668^AAN,18,10^FH\^FD${
                          notes?.productNotesRowTwenty ? notes?.productNotesRowTwenty : ""
                        }^FS
                        ^FT26,686^AAN,18,10^FH\^FD${
                          notes?.productNotesRowTwentyone ? notes?.productNotesRowTwentyone : ""
                        }^FS
                        ^FT26,704^AAN,18,10^FH\^FD${
                          notes?.productNotesRowTwentytwo ? notes?.productNotesRowTwentytwo : ""
                        }^FS
                        ^FT26,722^AAN,18,10^FH\^FD${
                          notes?.productNotesRowTwentythree ? notes?.productNotesRowTwentythree : ""
                        }^FS
                        ^FT26,740^AAN,18,10^FH\^FD${
                          notes?.productNotesRowTwentyfour ? notes?.productNotesRowTwentyfour : ""
                        }^FS
                        ^FT26,758^AAN,18,10^FH\^FD${
                          notes?.productNotesRowTwentyfive ? notes?.productNotesRowTwentyfive : ""
                        }^FS
                        ^FT464,326^AAN,18,10^FH\^FDLotto/Batch^FS
                        ^FPH,1^FT622,332^A0N,28,28^FH\^CI28^FD${
                          printdata?.productBatchId ? printdata?.productBatchId : ""
                        }^FS^CI27
                        ^FT464,358^AAN,18,10^FH\^FDProd. il/Prod. on^FS
                        ^FPH,1^FT690,361^A0N,25,23^FH\^CI28^FD${
                          printdata?.productArrivalDate ? printdata?.productArrivalDate : ""
                        }^FS^CI27
                        ^FT464,390^AAN,18,10^FH\^FDScadenza/Exp. Date^FS
                        ^FPH,1^FT688,393^A0N,25,23^FH\^CI28^FD${
                          printdata?.productExpireDate ? printdata?.productExpireDate : ""
                        }^FS^CI27
                        ^FT464,430^AAN,18,10^FH\^FDPeso Lordo/Gross Wgt^FS
                        ^FPH,1^FT728,436^A0N,28,28^FH\^CI28^FD${
                          printdata?.productGrossWeight ? printdata?.productGrossWeight : ""
                        } kg^FS^CI27
                        ^FT464,462^AAN,18,10^FH\^FDTara/Tare^FS
                        ^FPH,1^FT728,468^A0N,28,28^FH\^CI28^FD${
                          printdata?.productTare ? printdata?.productTare : ""
                        } kg^FS^CI27
                        ^FT464,494^AAN,18,10^FH\^FDPeso Netto/Net Wgt^FS
                        ^FPH,1^FT728,500^A0N,28,28^FH\^CI28^FD${
                          printdata?.productNetWeight ? printdata?.productNetWeight : ""
                        } kg^FS^CI27
                        ^FT689,158^AAN,18,10^FH\^FDTemp^FS
                        ^FPH,1^FT742,161^A0N,25,25^FH\^CI28^FD${
                          printdata?.productTemperature ? printdata?.productTemperature : ""
                        } °C^FS^CI27
                        ^PQ1,0,1,Y
                        ^XZ`;
      browserPrint.print(grande_nonmeat);
      console.log("Print success");
    } else {
      console.log("Error/s", printerStatus.errors);
    }
  } catch (error) {
    throw new Error(error);
  }
}

// const productNameRowOne = "Nome prodotto lungo ma proprio lungo lungo"; // 56 characters max, then use next line
// const productNameRowTwo = " ma davvero molto lungo, ci vogliono ben"; // 56 characters max, then use next line
// const productNameRowThree = "tre righe per scriverlo"; // 56 characters max, then truncate

// const productNameEnglish =
//   "Very long product name that is written in English"; // 68 characters max, then truncate

// const productId = "946863";

// const productNotesRowOne = "These are the notes, row one"; // 35 characters max, then use next line
// const productNotesRowTwo = "These are the notes, row two"; // 35 characters max, then use next line
// const productNotesRowThree = "These are the notes, row three"; // 35 characters max, then use next line
// const productNotesRowFour = "These are the notes, row four"; // 35 characters max, then use next line
// const productNotesRowFive = "These are the notes, row five"; // 35 characters max, then use next line
// const productNotesRowSix = "These are the notes, row six"; // 35 characters max, then use next line
// const productNotesRowSeven = "These are the notes, row seven"; // 35 characters max, then use next line
// const productNotesRowEight = "These are the notes, row eight"; // 35 characters max, then use next line
// const productNotesRowNine = "These are the notes, row nine"; // 35 characters max, then use next line
// const productNotesRowTen = "These are the notes, row ten"; // 35 characters max, then use next line
// const productNotesRowEleven = "These are the notes, row eleven"; // 35 characters max, then use next line
// const productNotesRowTwelve = "These are the notes, row twelve"; // 35 characters max, then use next line
// const productNotesRowThirteen = "These are the notes, row thirteen"; // 35 characters max, then use next line
// const productNotesRowFourteen = "These are the notes, row fourteen"; // 35 characters max, then use next line
// const productNotesRowFifteen = "These are the notes, row fifteen"; // 35 characters max, then use next line
// const productNotesRowSixteen = "These are the notes, row sixteen"; // 35 characters max, then use next line
// const productNotesRowSeventeen = "These are the notes, row seventeen"; // 35 characters max, then use next line
// const productNotesRowEighteen = "These are the notes, row eighteen"; // 35 characters max, then use next line
// const productNotesRowNineteen = "These are the notes, row nineteen"; // 35 characters max, then use next line
// const productNotesRowTwenty = "These are the notes, row twenty"; // 35 characters max, then use next line
// const productNotesRowTwentyone = "These are the notes, row twentyone"; // 35 characters max, then use next line
// const productNotesRowTwentytwo = "These are the notes, row twentytwo"; // 35 characters max, then use next line
// const productNotesRowTwentythree = "These are the notes, row twentythree"; // 35 characters max, then use next line
// const productNotesRowTwentyfour = "These are the notes, row twentyfour"; // 35 characters max, then use next line
// const productNotesRowTwentyfive = "These are the notes, row twentyfive"; // 35 characters max, then truncate

// const productArrivalDate = "17/06/2022";
// const productExpireDate = "30/07/2022";

// const productGrossWeight = "5.128";
// const productTare = "0.120";
// const productNetWeight = "5.120";
// const productTemperature = "12"; // if unavailable set N/A as values

// const productBatchId = "BD512XZ";
// const productBarcodeId = "Q1234567890123456789";

//   const data = {};

//   React.useLayoutEffect(() => {
//     //printLabel();
//   }, []);

//   return (
//     <div className="App">
//       <button className="btn" onClick={printLabel}>
//         <i className="far fa-print"></i>
//       </button>
//     </div>
//   );
// };

// export default ProductionprintLabelNonMeat;
