import React from "react";
import "./toggleButton.css";

const ToggleSwitch = ({ idToggle, isOn, handleToggle }) => {
  return (
    <>
      <input
        className="react-switch-checkbox"
        id={`react-switch-new-${idToggle}`}
        type="checkbox"
        checked={isOn}
        onChange={handleToggle}
      />
      <label
        className="react-switch-label"
        style={{ background: isOn && "#182749" }}
        htmlFor={`react-switch-new-${idToggle}`}
      >
        <span className={`react-switch-button`} />
      </label>
    </>
  );
};

export default ToggleSwitch;
