import { Link, NavLink } from "react-router-dom";
import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/css";
import "swiper/css/navigation";
import { Navigation } from "swiper";
import "swiper/css";

import SideBar from "../../components/Sidebar/sideBar";
import Header from "../../components/header/header";
import useRequest from "../../hooks/useRequest";

const Dashboard = () => {
  return (
    <div className="dashboard_parent">
      {/* <!-- Sidebar --> */}
      <SideBar />

      <div className="dashboard_right">
        {/* <!-- dashboard header --> */}

        <Header
          title="Ricevimento"
          links={[{ to: "/Ricevimento", name: "lista ODA" }]}
        />

        {/* <!-- dashboard Content --> */}

        <div className="dashboard_content">
          <div className="dashboard-container">
            <div className="supplierFilter">
              <div className="supplierType">
                <span>fornitore:</span>
                <div className="dropdown">
                  <button
                    className="btn dropdown-toggle"
                    id="dropdownMenuLink"
                    data-bs-toggle="dropdown"
                    aria-expanded="false"
                  >
                    TUTTI
                  </button>

                  <ul className="dropdown-menu" aria-labelledby="dropdownMenuLink">
                    <li>
                      <a className="dropdown-item" href="#!">
                        Option 1
                      </a>
                    </li>
                    <li>
                      <a className="dropdown-item" href="#!">
                        Option 2
                      </a>
                    </li>
                    <li>
                      <a className="dropdown-item" href="#!">
                        Option 3
                      </a>
                    </li>
                  </ul>
                </div>
              </div>

              <ul className="list-unstyled mb-0 supplierFilter-Link">
                <li>
                  <Link to="">ROVAGNATI</Link>
                </li>
                <li>
                  <Link to="">PARMACOTTO</Link>
                </li>
                <li>
                  <Link to="">LINDT</Link>
                </li>
                <li>
                  <Link to="">ROCHER</Link>
                </li>
              </ul>
            </div>

            <div>
              <Swiper
                spaceBetween={15}
                slidesPerView={"auto"}
                navigation={true}
                modules={[Navigation]}
                className="odalist"
            
              >
                <SwiperSlide>
                  <div className="slidecard active">
                    <button className="list-card">
                      <div className="list-card-head">
                        <span>Lun</span>
                        <span>12/01/2021</span>
                      </div>

                      <div className="lc-data-row">
                        <span>Ordini</span>
                        <strong>150</strong>
                      </div>
                      <div className="lc-data-row">
                        <span>ordinato</span>
                        <strong>8.420,500</strong>
                      </div>
                      <div className="lc-data-row">
                        <span>evaso</span>
                        <strong>200</strong>
                      </div>
                    </button>
                  </div>
                </SwiperSlide>
                <SwiperSlide>
                  <div className="slidecard">
                    <button className="list-card">
                      <div className="list-card-head">
                        <span>Lun</span>
                        <span>12/01/2021</span>
                      </div>

                      <div className="lc-data-row">
                        <span>Ordini</span>
                        <strong>150</strong>
                      </div>
                      <div className="lc-data-row">
                        <span>ordinato</span>
                        <strong>8.420,500</strong>
                      </div>
                      <div className="lc-data-row">
                        <span>evaso</span>
                        <strong>200</strong>
                      </div>
                    </button>
                  </div>
                </SwiperSlide>
                <SwiperSlide>
                  <div className="slidecard">
                    <button className="list-card">
                      <div className="list-card-head">
                        <span>Lun</span>
                        <span>12/01/2021</span>
                      </div>

                      <div className="lc-data-row">
                        <span>Ordini</span>
                        <strong>150</strong>
                      </div>
                      <div className="lc-data-row">
                        <span>ordinato</span>
                        <strong>8.420,500</strong>
                      </div>
                      <div className="lc-data-row">
                        <span>evaso</span>
                        <strong>200</strong>
                      </div>
                    </button>
                  </div>
                </SwiperSlide>
                <SwiperSlide>
                  <div className="slidecard">
                    <button className="list-card">
                      <div className="list-card-head">
                        <span>Lun</span>
                        <span>12/01/2021</span>
                      </div>

                      <div className="lc-data-row">
                        <span>Ordini</span>
                        <strong>150</strong>
                      </div>
                      <div className="lc-data-row">
                        <span>ordinato</span>
                        <strong>8.420,500</strong>
                      </div>
                      <div className="lc-data-row">
                        <span>evaso</span>
                        <strong>200</strong>
                      </div>
                    </button>
                  </div>
                </SwiperSlide>
                <SwiperSlide>
                  <div className="slidecard">
                    <button className="list-card">
                      <div className="list-card-head">
                        <span>Lun</span>
                        <span>12/01/2021</span>
                      </div>

                      <div className="lc-data-row">
                        <span>Ordini</span>
                        <strong>150</strong>
                      </div>
                      <div className="lc-data-row">
                        <span>ordinato</span>
                        <strong>8.420,500</strong>
                      </div>
                      <div className="lc-data-row">
                        <span>evaso</span>
                        <strong>200</strong>
                      </div>
                    </button>
                  </div>
                </SwiperSlide>
                <SwiperSlide>
                  <div className="slidecard">
                    <button className="list-card">
                      <div className="list-card-head">
                        <span>Lun</span>
                        <span>12/01/2021</span>
                      </div>

                      <div className="lc-data-row">
                        <span>Ordini</span>
                        <strong>150</strong>
                      </div>
                      <div className="lc-data-row">
                        <span>ordinato</span>
                        <strong>8.420,500</strong>
                      </div>
                      <div className="lc-data-row">
                        <span>evaso</span>
                        <strong>200</strong>
                      </div>
                    </button>
                  </div>
                </SwiperSlide>
                <SwiperSlide>
                  <div className="slidecard">
                    <button className="list-card">
                      <div className="list-card-head">
                        <span>Lun</span>
                        <span>12/01/2021</span>
                      </div>

                      <div className="lc-data-row">
                        <span>Ordini</span>
                        <strong>150</strong>
                      </div>
                      <div className="lc-data-row">
                        <span>ordinato</span>
                        <strong>8.420,500</strong>
                      </div>
                      <div className="lc-data-row">
                        <span>evaso</span>
                        <strong>200</strong>
                      </div>
                    </button>
                  </div>
                </SwiperSlide>
                <SwiperSlide>
                  <div className="slidecard">
                    <button className="list-card">
                      <div className="list-card-head">
                        <span>Lun</span>
                        <span>12/01/2021</span>
                      </div>

                      <div className="lc-data-row">
                        <span>Ordini</span>
                        <strong>150</strong>
                      </div>
                      <div className="lc-data-row">
                        <span>ordinato</span>
                        <strong>8.420,500</strong>
                      </div>
                      <div className="lc-data-row">
                        <span>evaso</span>
                        <strong>200</strong>
                      </div>
                    </button>
                  </div>
                </SwiperSlide>
                <SwiperSlide>
                  <div className="slidecard">
                    <button className="list-card">
                      <div className="list-card-head">
                        <span>Lun</span>
                        <span>12/01/2021</span>
                      </div>

                      <div className="lc-data-row">
                        <span>Ordini</span>
                        <strong>150</strong>
                      </div>
                      <div className="lc-data-row">
                        <span>ordinato</span>
                        <strong>8.420,500</strong>
                      </div>
                      <div className="lc-data-row">
                        <span>evaso</span>
                        <strong>200</strong>
                      </div>
                    </button>
                  </div>
                </SwiperSlide>
                <SwiperSlide>
                  <div className="slidecard">
                    <button className="list-card">
                      <div className="list-card-head">
                        <span>Lun</span>
                        <span>12/01/2021</span>
                      </div>

                      <div className="lc-data-row">
                        <span>Ordini</span>
                        <strong>150</strong>
                      </div>
                      <div className="lc-data-row">
                        <span>ordinato</span>
                        <strong>8.420,500</strong>
                      </div>
                      <div className="lc-data-row">
                        <span>evaso</span>
                        <strong>200</strong>
                      </div>
                    </button>
                  </div>
                </SwiperSlide>
              </Swiper>
            </div>

            <div className="p-3">
              <div className="orderList-wrap">
                {/* Order List Item */}
                <div className="orderList-row">
                  <div className="orderStatus">
                    <i className="fa fa-caret-right text-danger"></i>
                  </div>
                  <div className="orderno">
                    <label>Ordine no.</label>
                    <h6>152654</h6>
                  </div>
                  <div className="suppliername">
                    <label>fornitore</label>
                    <h6>AGROITTICA S</h6>
                  </div>
                  <div className="ownername">
                    <label>proprietario</label>
                    <h6>vettore</h6>
                  </div>
                  <div className="orderdate">
                    <label>proprietario</label>
                    <h6>02/02/2022</h6>
                  </div>
                  <div className="orderdate">
                    <label> ora evasione</label>
                    <h6> 00.00</h6>
                  </div>
                  <div className="orderqty">
                    <label>Q.tà ordinata</label>
                    <h6>3,000</h6>
                  </div>
                  <div className="orderprocess">
                    <label> Q.tà evasa</label>
                    <h6> 2,000</h6>
                  </div>
                </div>

                {/* Order List Item */}
                <div className="orderList-row">
                  <div className="orderStatus">
                    <i className="fa fa-caret-right text-warning"></i>
                  </div>
                  <div className="orderno">
                    <label>Ordine no.</label>
                    <h6>152654</h6>
                  </div>
                  <div className="suppliername">
                    <label>fornitore</label>
                    <h6>AGROITTICA S</h6>
                  </div>
                  <div className="ownername">
                    <label>proprietario</label>
                    <h6>vettore</h6>
                  </div>
                  <div className="orderdate">
                    <label>proprietario</label>
                    <h6>02/02/2022</h6>
                  </div>
                  <div className="orderdate">
                    <label> ora evasione</label>
                    <h6> 00.00</h6>
                  </div>
                  <div className="orderqty">
                    <label>Q.tà ordinata</label>
                    <h6>3,000</h6>
                  </div>
                  <div className="orderprocess">
                    <label> Q.tà evasa</label>
                    <h6> 2,000</h6>
                  </div>
                </div>

                {/* Order List Item */}
                <div className="orderList-row">
                  <div className="orderStatus">
                    <i className="fa fa-caret-right text-warning"></i>
                  </div>
                  <div className="orderno">
                    <label>Ordine no.</label>
                    <h6>152654</h6>
                  </div>
                  <div className="suppliername">
                    <label>fornitore</label>
                    <h6>AGROITTICA S</h6>
                  </div>
                  <div className="ownername">
                    <label>proprietario</label>
                    <h6>vettore</h6>
                  </div>
                  <div className="orderdate">
                    <label>proprietario</label>
                    <h6>02/02/2022</h6>
                  </div>
                  <div className="orderdate">
                    <label> ora evasione</label>
                    <h6> 00.00</h6>
                  </div>
                  <div className="orderqty">
                    <label>Q.tà ordinata</label>
                    <h6>3,000</h6>
                  </div>
                  <div className="orderprocess">
                    <label> Q.tà evasa</label>
                    <h6> 2,000</h6>
                  </div>
                </div>

                {/* Order List Item */}
                <div className="orderList-row">
                  <div className="orderStatus">
                    <i className="fa fa-caret-right text-warning"></i>
                  </div>
                  <div className="orderno">
                    <label>Ordine no.</label>
                    <h6>152654</h6>
                  </div>
                  <div className="suppliername">
                    <label>fornitore</label>
                    <h6>AGROITTICA S</h6>
                  </div>
                  <div className="ownername">
                    <label>proprietario</label>
                    <h6>vettore</h6>
                  </div>
                  <div className="orderdate">
                    <label>proprietario</label>
                    <h6>02/02/2022</h6>
                  </div>
                  <div className="orderdate">
                    <label> ora evasione</label>
                    <h6> 00.00</h6>
                  </div>
                  <div className="orderqty">
                    <label>Q.tà ordinata</label>
                    <h6>3,000</h6>
                  </div>
                  <div className="orderprocess">
                    <label> Q.tà evasa</label>
                    <h6> 2,000</h6>
                  </div>
                </div>

                {/* Order List Item */}
                <div className="orderList-row">
                  <div className="orderStatus">
                    <i className="fa fa-caret-right text-warning"></i>
                  </div>
                  <div className="orderno">
                    <label>Ordine no.</label>
                    <h6>152654</h6>
                  </div>
                  <div className="suppliername">
                    <label>fornitore</label>
                    <h6>AGROITTICA S</h6>
                  </div>
                  <div className="ownername">
                    <label>proprietario</label>
                    <h6>vettore</h6>
                  </div>
                  <div className="orderdate">
                    <label>proprietario</label>
                    <h6>02/02/2022</h6>
                  </div>
                  <div className="orderdate">
                    <label> ora evasione</label>
                    <h6> 00.00</h6>
                  </div>
                  <div className="orderqty">
                    <label>Q.tà ordinata</label>
                    <h6>3,000</h6>
                  </div>
                  <div className="orderprocess">
                    <label> Q.tà evasa</label>
                    <h6> 2,000</h6>
                  </div>
                </div>

                {/* Order List Item */}
                <div className="orderList-row orderStatus-success">
                  <div className="orderStatus">
                    <i className="fa fa-caret-right text-success"></i>
                  </div>
                  <div className="orderno">
                    <label>Ordine no.</label>
                    <h6>152654</h6>
                  </div>
                  <div className="suppliername">
                    <label>fornitore</label>
                    <h6>AGROITTICA S</h6>
                  </div>
                  <div className="ownername">
                    <label>proprietario</label>
                    <h6>vettore</h6>
                  </div>
                  <div className="orderdate">
                    <label>proprietario</label>
                    <h6>02/02/2022</h6>
                  </div>
                  <div className="orderdate">
                    <label> ora evasione</label>
                    <h6> 00.00</h6>
                  </div>
                  <div className="orderqty">
                    <label>Q.tà ordinata</label>
                    <h6>3,000</h6>
                  </div>
                  <div className="orderprocess">
                    <label> Q.tà evasa</label>
                    <h6> 2,000</h6>
                  </div>
                </div>

                {/* Order List Item */}
                <div className="orderList-row orderStatus-success">
                  <div className="orderStatus">
                    <i className="fa fa-caret-right text-success"></i>
                  </div>
                  <div className="orderno">
                    <label>Ordine no.</label>
                    <h6>152654</h6>
                  </div>
                  <div className="suppliername">
                    <label>fornitore</label>
                    <h6>AGROITTICA S</h6>
                  </div>
                  <div className="ownername">
                    <label>proprietario</label>
                    <h6>vettore</h6>
                  </div>
                  <div className="orderdate">
                    <label>proprietario</label>
                    <h6>02/02/2022</h6>
                  </div>
                  <div className="orderdate">
                    <label> ora evasione</label>
                    <h6> 00.00</h6>
                  </div>
                  <div className="orderqty">
                    <label>Q.tà ordinata</label>
                    <h6>3,000</h6>
                  </div>
                  <div className="orderprocess">
                    <label> Q.tà evasa</label>
                    <h6> 2,000</h6>
                  </div>
                </div>

                {/* Order List Item */}
                <div className="orderList-row orderStatus-success">
                  <div className="orderStatus">
                    <i className="fa fa-caret-right text-success"></i>
                  </div>
                  <div className="orderno">
                    <label>Ordine no.</label>
                    <h6>152654</h6>
                  </div>
                  <div className="suppliername">
                    <label>fornitore</label>
                    <h6>AGROITTICA S</h6>
                  </div>
                  <div className="ownername">
                    <label>proprietario</label>
                    <h6>vettore</h6>
                  </div>
                  <div className="orderdate">
                    <label>proprietario</label>
                    <h6>02/02/2022</h6>
                  </div>
                  <div className="orderdate">
                    <label> ora evasione</label>
                    <h6> 00.00</h6>
                  </div>
                  <div className="orderqty">
                    <label>Q.tà ordinata</label>
                    <h6>3,000</h6>
                  </div>
                  <div className="orderprocess">
                    <label> Q.tà evasa</label>
                    <h6> 2,000</h6>
                  </div>
                </div>
              </div>
            </div>
          </div>
          {/* <!-- dashboard-container  END --> */}
        </div>
        {/* <!-- dashboard_content END --> */}
      </div>
    </div>
  );
};

export default Dashboard;
