import { Link } from "react-router-dom";
import { Swiper, SwiperSlide } from "swiper/react";
import React, { useState, useEffect } from "react";
import { useLocation, useHistory } from "react-router-dom";
import "swiper/css";
import "swiper/css/navigation";
import { Navigation } from "swiper";
import "swiper/css";
import { useDispatch, useSelector } from "react-redux";
import SideBar from "../../components/Sidebar/sideBar";
import Header from "../../components/header/header";
import useRequest from "../../hooks/useRequest";
import useMultiRequestLoader from "../../hooks/useMultiRequestLoader";
import { useTranslation } from "react-i18next";
import { getFilteredProducts } from "../../components/Utils/Utils";
import Autosuggest from "react-autosuggest";
import LoadingSpinner from "../../components/loader/LoadingSpinner";
import moment from "moment";

const Magazzino = () => {
  const [t, i18n] = useTranslation("translation");

  const [selectIndex, setSelectIndex] = useState(0);
  const [picklingData, setPicklingData] = useState([]);
  const [isNoDates, setIsNoDates] = useState(true);
  const [sliderData, setSliderData] = useState([]);
  const [suggestionList, setSuggestionList] = useState([]);
  const [globalSuggestionList, setGlobalSuggestionList] = useState([]);
  const [searchQuery, setSearchQuery] = useState("");
  const [selectedProviderName, setSelectedProviderName] = useState("");

  const { loading, updateResponse } = useMultiRequestLoader(3);
  const history = useHistory();
  const { userRoleId } = useSelector((state) => state.auth);
  const { request, response } = useRequest(true, updateResponse);
  const { request: suppliersRequest, response: suppliersResponse } = useRequest(
    true,
    updateResponse
  );

  const {
    request: pinkingDateOrderRequest,
    response: pikingDateOrderResponse,
  } = useRequest(true, updateResponse);

  const {
    request: onPagePinkingDateOrderRequest,
    response: onPagePikingDateOrderResponse,
  } = useRequest();
  const {
    request: pinkingSearchOrderRequest,
    response: pikingSearchOrderResponse,
  } = useRequest();

  useEffect(() => {
    request("GET", "picking-dates");
    suppliersRequest("GET", "suppliers");
    document.body.className = "";
  }, []);

  useEffect(() => {
    if (response) {
      if (response.status === "SUCCESS") {
        if (response?.data?.dates.length > 0) {
          setSelectIndex(0);
          setSliderData(response?.data?.dates);
          setIsNoDates(false);
          pinkingDateOrderRequest("POST", "picking-date-orders", {
            delivery_date: response?.data?.dates[0]?.delivery_date,
          });
        } else {
          pinkingDateOrderRequest("POST", "picking-date-orders", {
            delivery_date: moment().format("DD/MM/YYYY"),
          });
        }
      }
    }
  }, [response]);

  useEffect(() => {
    if (!isNoDates) {
      if (
        pikingDateOrderResponse &&
        pikingDateOrderResponse.status === "SUCCESS"
      ) {
        setPicklingData(pikingDateOrderResponse?.data?.orders);
      }
    }
  }, [pikingDateOrderResponse]);

  useEffect(() => {
    if (
      onPagePikingDateOrderResponse &&
      onPagePikingDateOrderResponse.status === "SUCCESS"
    ) {
      setPicklingData(onPagePikingDateOrderResponse?.data?.orders);
    }
  }, [onPagePikingDateOrderResponse]);

  useEffect(() => {
    if (
      pikingSearchOrderResponse &&
      pikingSearchOrderResponse.status === "SUCCESS"
    ) {
      setSelectIndex();
      setPicklingData(pikingSearchOrderResponse?.data?.orders);
      if (selectedProviderName?.length > 0) {
        setSearchQuery(selectedProviderName);
      }
    }
  }, [pikingSearchOrderResponse]);

  useEffect(() => {
    if (suppliersResponse) {
      // updateResponse();
      if (suppliersResponse.status === "SUCCESS") {
        const supplierName = suppliersResponse?.data?.supplier_name.map(
          (name) => name.customer_description
        );
        setGlobalSuggestionList(supplierName);
        setSuggestionList(supplierName);
      }
    }
  }, [suppliersResponse]);

  const onSlideClick = (i) => {
    onPagePinkingDateOrderRequest("POST", "picking-date-orders", {
      delivery_date: response?.data?.dates[i]?.delivery_date,
    });
    setSearchQuery("");
    setSelectIndex(i);
  };

  const searchProvider = (e) => {
    e.preventDefault();
  };

  const onSuggestionsFetchRequested = ({ value, reason }) => {
    const filteredProducts = getFilteredProducts(value, globalSuggestionList);
    setSuggestionList(filteredProducts);
  };

  const onSuggestionsClearRequested = () => {
    setSuggestionList(globalSuggestionList);
  };

  const renderSuggestion = (suggestion) => suggestion;

  const getSuggestionValue = (suggestion) => {
    setSuggestionList(suggestionList);
    setSelectedProviderName(suggestion);
    pinkingSearchOrderRequest("GET", `picking-order-search?name=${suggestion}`);
    return suggestion;
  };

  const onChange = (e) => {
    e.preventDefault();
    setSearchQuery(e.target.value);
  };

  const inputProps = {
    placeholder: t("Point of sale"),
    value: searchQuery ? searchQuery : "",
    onChange,
  };

  return (
    <>
      {userRoleId === 5 || userRoleId === 4 ? (
        <div className="dashboard_parent">
          {/* <!-- Sidebar --> */}
          <SideBar />
          {loading && <LoadingSpinner />}

          <div className="dashboard_right">
            {/* <!-- dashboard header --> */}

            <Header
              title={t("Picking")}
              links={[{ to: "/raccolta", name: "ODV" }]}
            />

            {/* <!-- dashboard Content --> */}

            <div className="dashboard_content">
              <div className="dashboard-container">
                <div className="topfix">
                  <div className="supplierFilter">
                    <div className="supplierSearch themeInput themeIS">
                      {/* <label>{t("")}</label> */}
                      <form
                        onSubmit={searchProvider}
                        className="themeInput position-relative"
                      >
                        {/* <input type="text" placeholder="search here" /> */}
                        <Autosuggest
                          suggestions={suggestionList}
                          onSuggestionsFetchRequested={
                            onSuggestionsFetchRequested
                          }
                          onSuggestionsClearRequested={
                            onSuggestionsClearRequested
                          }
                          // alwaysRenderSuggestions={true}
                          getSuggestionValue={getSuggestionValue}
                          renderSuggestion={renderSuggestion}
                          inputProps={inputProps}
                        />
                        <button className="themeIS-btn" type="submit">
                          <i className="fas fa-search"></i>
                        </button>
                      </form>
                    </div>
                  </div>
                  <div>
                    <Swiper
                      spaceBetween={15}
                      slidesPerView={"auto"}
                      navigation={true}
                      modules={[Navigation]}
                      className="odalist"
                    >
                      {sliderData &&
                        sliderData.map((data, index) => (
                          <SwiperSlide key={index}>
                            <div
                              className="slidecard"
                              onClick={() => onSlideClick(index)}
                            >
                              <button
                                className={`list-card ${
                                  index === selectIndex ? "bg-white" : ""
                                } `}
                              >
                                <div className="list-card-head">
                                  <span>
                                    {!data?.day_name ? " LUN " : data?.day_name}
                                  </span>
                                  <span>{data?.delivery_date}</span>
                                </div>

                                <div className="lc-data-row">
                                  <span>Ordini</span>
                                  <strong>{data?.total_pickings}</strong>
                                </div>
                              </button>
                            </div>
                          </SwiperSlide>
                        ))}
                    </Swiper>
                  </div>
                </div>

                <div className="p-3">
                  <div className="orderList-wrap">
                    {/* Order List Item */}
                    {picklingData &&
                      picklingData.map((data, index) => (
                        <Link
                          to={{
                            pathname: "/lista-odv",
                            state: {
                              data: data || null,
                            },
                          }}
                          className="orderList-row"
                        >
                          <div className="sm-progress">
                            {data?.picked_products_percent <= 100 ? (
                              <div
                                className="sm-progressInner"
                                style={{
                                  height: `${data?.picked_products_percent}%`,
                                }}
                              ></div>
                            ) : (
                              <div
                                className="sm-progressInner"
                                style={{
                                  height: `${data?.picked_products_percent}%`,
                                  backgroundColor: '#f9ab00'
                                }}
                              ></div>
                            )}
                            
                            <span>
                              {data?.picked_products_percent !== 0
                                ? `${data?.picked_products_percent} %`
                                : ""}
                            </span>
                          </div>
                          <div className="orderdate">
                            <label>{t("PickingOrder")}</label>
                            <h6>{data?.id_picking_order ? data?.id_picking_order : ''}</h6>
                          </div>
                          <div className="suppliername">
                            <label>{t("Point of sale")}</label>
                            <h6>{data?.customer_description}</h6>
                          </div>
                          <div className="orderdate">
                            <label>{t("Delivery Date")}</label>
                            <h6>{data?.delivery_date}</h6>
                          </div>
                          <div className="orderqty">
                            <label>{t("Zone")}</label>
                            <h6>{data?.zona}</h6>
                          </div>
                          <div className="orderprocess">
                            <label> {t("Selling Point Note")}</label>
                            <h6>
                              {data?.selling_point_note?.substr(0, 30)}{" "}
                              {data?.selling_point_note?.length > 50
                                ? "..."
                                : ""}
                            </h6>
                          </div>
                        </Link>
                      ))}
                  </div>
                </div>
              </div>
              {/* <!-- dashboard-container  END --> */}
            </div>
            {/* <!-- dashboard_content END --> */}
          </div>
        </div>
      ) : (
        history.push("/")
      )}
    </>
  );
};

export default Magazzino;
