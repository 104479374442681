import DashSideBar from "../../components/Sidebar/DashSideBar";
import Header from "../../components/header/header";

const DashboardLayout = ({ children, title }) => {
  return (
    <div className="dashboard_parent">
      <DashSideBar />
      <div className="dashboard_right">
        <Header title={title} links={[{ to: "/", name: "Dashboard" }]} />
        {children}
      </div>
    </div>
  );
};

export default DashboardLayout;
