import React, { useState, useEffect } from "react";
import { Route, Switch, Redirect } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { ToastContainer } from "react-toastify";
import axios from "axios";

import "./App.css";
import { API } from "./constant/api";
import useRequest from "./hooks/useRequest";
import LoadingSpinner from "./components/loader/LoadingSpinner";
import { authSuccess, logout } from "./store/auth/action";
// import {
//   updateProviders,
//   getTopProviders,
//   closedProviders,
// } from "./store/product/action";

import { setLanguage } from "./store/setting/action";
import Login from "./pages/user/Login";
import RicevimentoLista from "./pages/ricevimento/ricevimento-lista-ordini";
import StaffIndex from "./pages/staff/index";
import AddStaff from "./pages/staff/add";
import EditStaff from "./pages/staff/edit";
import ViewStaff from "./pages/staff/view";
import i18n from "./i18n";
import { privateRoutes } from "./routes/routes";

const BACKEND_URL = API.PORT;
const SUPPORTED_LANGUAGES = ["en", "it"];

function App() {
  const [token, setToken] = useState(null);
  const dispatch = useDispatch();

  const {
    loggedIn,
    loading,
    token: reduxToken,
    userRoleId,
  } = useSelector((state) => state.auth);

  // const { request: requestProviders, response: responseProviders } =
  //   useRequest();

  useEffect(() => {
    const storedLanguage = localStorage.getItem("i18nextLng");
    if (!storedLanguage) {
      return;
    }
    if (!SUPPORTED_LANGUAGES.includes(storedLanguage)) {
      localStorage.setItem("i18nextLng", "it");
      i18n.changeLanguage("it");
    } else {
      dispatch(setLanguage({ language: storedLanguage }));
    }
  }, []);

  useEffect(() => {
    const token = localStorage.getItem("token");
    if (!token) {
      dispatch(authSuccess({ loggedIn: false }));
    } else {
      setToken(token);
    }
  }, []);

  // useEffect(() => {
  //   if (reduxToken) {
  //     requestProviders("GET", "providers");
  //   }
  // }, [reduxToken]);

  // useEffect(() => {
  //   if (responseProviders) {
  //     if (responseProviders.status === "SUCCESS") {
  //       dispatch(
  //         getTopProviders({
  //           topProviders: responseProviders.data.top_providers,
  //         })
  //       );

  //       dispatch(
  //         closedProviders({
  //           closedProviders: responseProviders.data.closed_providers,
  //         })
  //       );

  //       const providers = responseProviders.data.all_providers.map(
  //         (provider) => provider.provider_name
  //       );
  //       dispatch(updateProviders({ providers }));
  //     }
  //   }
  // }, [responseProviders]);

  useEffect(() => {
    const url = `${BACKEND_URL}/user-data`;
    if (token) {
      const config = {
        headers: {
          Authorization: `Bearer ${token}`,
          "Accept-Language": "it",
        },
      };
      axios
        .get(url, config)
        .then((res) => {
          let userResponse = res.data;
          if (userResponse.status === "FAIL") {
            dispatch(logout());
          } else {
            dispatch(
              authSuccess({
                loggedIn: true,
                token,
                userId: userResponse.data.id_user,
                name: userResponse.data.name,
                email: userResponse.data.email,
                userRoleId: userResponse.data.id_role,
                idDepartment: userResponse.data.id_department,
                idWarehouse: userResponse.data.department.warehouse.id_warehouse,
                role: userResponse.data.role,
              })
            );
          }
        })
        .catch((err) => {
          dispatch(logout());
        });
    }
  }, [token]);

  return (
    <>
      {loading && <LoadingSpinner />}
      {loggedIn !== null && (
        <>
          {loggedIn ? (
            <Switch>
              <Route
                path="/ricevimento-lista-ordini"
                render={(props) => <RicevimentoLista {...props} exact />}
              />
              <Route path="/utente">
                {userRoleId !== 5 ? <Redirect to="/" /> : <StaffIndex />}
              </Route>
              <Route path="/aggiungi-utente">
                {userRoleId !== 5 ? <Redirect to="/" /> : <AddStaff />}
              </Route>
              <Route path="/modificare/:id">
                {userRoleId !== 5 ? <Redirect to="/" /> : <EditStaff />}
              </Route>
              <Route path="/view/:id">
                {userRoleId !== 5 ? <Redirect to="/" /> : <ViewStaff />}
              </Route>
              {privateRoutes.map((route, index) => (
                <Route key={index} exact {...route} />
              ))}
              <Redirect to="/" />
            </Switch>
          ) : (
            <Switch>
              <Route path="/login" component={Login} exact />
              <Redirect to="/login" />
            </Switch>
          )}

          <ToastContainer />
        </>
      )}
    </>
  );
}

export default App;
