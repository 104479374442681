import { useHistory } from "react-router-dom";
import React, { useEffect } from "react";
import { toast } from "react-toastify";
import { useForm } from "react-hook-form";
import { useDispatch } from "react-redux";

import useRequest from "../../hooks/useRequest";
import { authSuccess } from "../../store/auth/action";

const Login = () => {
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm();

  const dispatch = useDispatch();
  const { response, request } = useRequest();
  const history = useHistory();

  useEffect(() => {
    if (response) {
      if (response.status === "SUCCESS") {
        toast.success("You are now logged in!");

        history.push("/");

        return () =>
          dispatch(
            authSuccess({
              token: response.data.token,
              userId: response.data.user.id_user,
              name: response.data.user.name,
              email: response.data.user.email,
              userRoleId: response.data.user.id_role,
              role: response.data.user.role,
            })
          );
      } else {
        toast.error("invalid credentials");
      }
    }
  }, [response]);

  const onSubmit = (data) => {
    const { email, password } = data;
    request("POST", "login", { email, password });
  };

  return (
    <div className="loginsign-wrap bg-with-h100">
      <div className="loginform-wrap">
        <div className="loginlogo">
          <img src="img/logo.png" alt="" />
        </div>
        <div className="login-form default-form">
          <div className="form-inner">
            {/* <h3>Login to HQF</h3> */}
            <div className="loginCircle">
              <img src="img/loginIcon.svg" alt="" />
            </div>
            {/* <!--Login Form--> */}
            <form method="post" onSubmit={handleSubmit(onSubmit)}>
              <div className="px-4 py-3">
                <div className="form-group">
                  {/* <label>Email</label> */}
                  <input
                    className={`form-control ${errors.email && "is-invalid"}`}
                    type="text"
                    name="email"
                    placeholder="Email"
                    {...register("email", {
                      required: true,
                      pattern:
                        /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/,
                    })}
                  />
                  {errors.email?.type === "required" && (
                    <div className="invalid-feedback">
                      The email field is required.
                    </div>
                  )}
                  {errors.email?.type === "pattern" && (
                    <div className="invalid-feedback">
                      The email must be a valid email address.
                    </div>
                  )}
                </div>

                <div className="form-group">
                  {/* <label>Password</label> */}
                  <input
                    className={`form-control ${
                      errors.password && "is-invalid"
                    }`}
                    id="password-field"
                    type="password"
                    name="password"
                    placeholder="Password"
                    {...register("password", {
                      required: true,
                      // pattern: /(?=.*\d)(?=.*[a-z])(?=.*[A-Z]).{8,}/,
                    })}
                  />
                  {errors.password?.type === "required" && (
                    <div className="invalid-feedback">
                      The password field is required.
                    </div>
                  )}
                  {/* {errors.password?.type === "pattern" && (
                    <div className="invalid-feedback">
                      Password must be of 8 characters long with atleast one
                      uppercase, one lowercase and one number.
                    </div>
                  )} */}
                </div>
              </div>
              {/* <div className="form-group">
                  <div className="field-outer">
                    <div className="form-check">
                      <input className="form-check-input" type="checkbox" value="" id="rememberme"/>
                      <label className="form-check-label" for="rememberme">
                        Remember me
                      </label>
                    </div>
                    <Link to="/" className="pwd">Forgot password?</Link>
                  </div>
                </div> */}

              <button
                className="loginbtn"
                type="submit"
                onClick={handleSubmit(onSubmit)}
              >
                Log In
              </button>
            </form>

            {/* <div className="bottom-box">
                <div className="text">Don't have an account? <Link to="/" className="call-modal signup">Signup</Link>
                </div>
                <div className="divider"><span>or</span></div>
                <div className="btn-box row">
                  <div className="col-lg-6 col-md-12">
                    <a href="javscript:void(0);" className="theme-btn social-btn-two facebook-btn"><i className="fab fa-facebook-f"></i> Log In via
                      Facebook</a>
                  </div>
                  <div className="col-lg-6 col-md-12">
                    <a href="javscript:void(0);" className="theme-btn social-btn-two google-btn"><i className="fab fa-google"></i> Log In via Gmail</a>
                  </div>
                </div>
              </div> */}
          </div>
        </div>
      </div>
    </div>
  );
};

export default Login;
