import React, { useState, useEffect } from "react";
import { Link, NavLink } from "react-router-dom";
import SideBar from "../../components/Sidebar/sideBar";
import Header from "../../components/header/header";
import useRequest from "../../hooks/useRequest";
import { useHistory, useLocation } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import Scanner from "../../components/scanner/scanner";
import { Html5Qrcode } from "html5-qrcode";
import { toast } from "react-toastify";
import Translator from "../../components/translation/translate";
import { useTranslation } from "react-i18next";
import Swal from "sweetalert2";
import withReactContent from "sweetalert2-react-content";
import { SendingPrintLabel } from "../../components/printlabel/sendingPrintLabel/sendingPrintLabel";

const qrConfig = { fps: 10, qrbox: { width: 300, height: 300 } };
let html5QrCode;

const SpedizioneListaOdv = () => {
  const history = useHistory();
  const { userRoleId } = useSelector((state) => state.auth);
  const MySwal = withReactContent(Swal);
  const location = useLocation();
  const [t, i18n] = useTranslation("translation");
  const orderData = location.state ? location.state.data : "";
  const idPickingOrder = orderData?.id_picking_order;
  const [procDataPer, setProcDataPer] = useState(orderData?.sent_percentage);
  const [idSellingPoint, setIdSellingPoint] = useState("");

  const { request, response } = useRequest();
  const [pickingProducts, setPickingProducts] = useState([]);

  const { request: cartoonRequest, response: cartoonResponse } = useRequest();
  const [cartoons, setCartoons] = useState([]);
  const [printBoxNumber, setPrintBoxNumber] = useState();

  const { request: sendingProcessRequest, response: sendingProcessResponse } =
    useRequest();

  const { request: closeOrderRequest, response: closeOrderResponse } =
    useRequest();
  const { request: printLabelDataRequest, response: printLabelDataResponse } =
    useRequest();
  const {
    request: deleteCaroonProductRequest,
    response: deleteCaroonProductResponse,
  } = useRequest();

  const { request: cartoonDeleteRequest, response: cartoonDeleteResponse } =
    useRequest();

  const [boxNum, setBoxNum] = useState(1);
  const [totalCartoon, setTotalCartoon] = useState(0);
  const [isOnLoad, setIsOnLoad] = useState(true);
  const [isCloseDisable, setIsCloseDisable] = useState(cartoons?.length > 0 ? false : true);

  const [tabs, setTabs] = useState([
    {
      name: "cartone1",
      isActive: true,
    },
  ]);

  const handleClick = (myLink, product) => {
    history.push({
      pathname: myLink,
      state: { orderData, product },
    });
  };
  useEffect(() => {
    document.body.className = "";
    // document.body.classList.remove("shipmentbody");
  }, []);

  useEffect(() => {
    if (idPickingOrder) {
      request("GET", `sending-products/${idPickingOrder}`);
    }
  }, [deleteCaroonProductResponse, cartoonDeleteResponse]);

  useEffect(() => {
    if (response) {
      if (response.status === "SUCCESS") {
        setPickingProducts(response?.data?.products);
        setProcDataPer(response.data.percentage);
      }
    }
  }, [response]);

  useEffect(() => {
    if (printLabelDataResponse) {
      if (printLabelDataResponse.status === "SUCCESS") {
        if (printLabelDataResponse?.data?.printData.length > 0) {
          SendingPrintLabel(printLabelDataResponse?.data?.printData);
        }
      }
    }
  }, [printLabelDataResponse]);

  useEffect(() => {
    if (closeOrderResponse) {
      setIsCloseDisable(false)
      if (closeOrderResponse.status === "SUCCESS") {
        toast.success(closeOrderResponse?.message);
      } else {
        toast.error(closeOrderResponse?.message);
      }
      // request("GET", `sending-products/${idPickingOrder}`);
      // cartoonRequest("POST", "cartoons", {
      //   id_picking_order: idPickingOrder,
      //   box_num: boxNum,
      //   state: isOnLoad,
      // });
      history.push("/spedizione");
    }
  }, [closeOrderResponse]);

  useEffect(() => {
    if (cartoonDeleteResponse) {
      if (cartoonDeleteResponse.status === "SUCCESS") {
        toast.success(cartoonDeleteResponse?.message);
      } else {
        toast.error(cartoonDeleteResponse?.message);
      }
    }
  }, [cartoonDeleteResponse]);

  useEffect(() => {
    if (deleteCaroonProductResponse) {
      if (deleteCaroonProductResponse.status === "SUCCESS") {
        toast.success(deleteCaroonProductResponse?.message);
      } else {
        toast.error(deleteCaroonProductResponse?.message);
      }
    }
  }, [deleteCaroonProductResponse]);

  useEffect(() => {
    if (orderData) {
      cartoonRequest("POST", "cartoons", {
        id_picking_order: idPickingOrder,
        box_num: boxNum,
        state: isOnLoad,
      });
    }
  }, []);

  useEffect(() => {
    if (cartoonResponse) {
      if (cartoonResponse.status === "SUCCESS") {
        setIsCloseDisable(cartoonResponse?.data?.cartoons.length > 0 ? false : true)
        setCartoons(cartoonResponse?.data?.cartoons);
        setPrintBoxNumber(cartoonResponse?.data?.box_num);
        setIdSellingPoint(
          cartoonResponse?.data?.cartoons[0]?.picking_product?.picking_order
            ?.id_selling_point
        );
        let totalBox = cartoonResponse?.data?.total_box;

        if (
          cartoonResponse?.data.box_num === 1 &&
          cartoonResponse?.data.state
        ) {
          let totalBox = cartoonResponse?.data?.total_box;
          if(totalBox){
            setTotalCartoon(totalBox);
            console.log(totalBox);
            let boxes = Array.from(Array(totalBox), (_, i) => ({
              name: `cartoon-${i + 1}`,
              isActive: i === 0 ? true : false,
              isDelete: false,
            }));
            setTabs(boxes);
          }
        }
      }
    }
  }, [cartoonResponse]);

  useEffect(() => {
    if (
      sendingProcessResponse &&
      sendingProcessResponse?.status === "SUCCESS"
    ) {
      request("GET", `sending-products/${idPickingOrder}`);
      cartoonRequest("POST", "cartoons", {
        id_picking_order: idPickingOrder,
        box_num: boxNum,
        state: false,
      });
      toast.success(response?.message);
    } else {
      // alert(response?.message);
      // toast.error(response?.message);
    }
  }, [sendingProcessResponse, cartoonDeleteResponse]);

  // useEffect(() => {
  //   html5QrCode = new Html5Qrcode("reader");
  // }, []);

  useEffect(() => {
    if (boxNum) {
      cartoonRequest("POST", "cartoons", {
        id_picking_order: idPickingOrder,
        box_num: boxNum,
        state: false,
      });
    }
  }, [boxNum, deleteCaroonProductResponse, cartoonDeleteResponse]);

  // const handleClickAdvanced = () => {
  //   const qrCodeSuccessCallback = (decodedText, decodedResult) => {
  //     setBarCode(decodedText);
  //     if (decodedText) {
  //       handleStop();
  //     }
  //   };
  //   html5QrCode.start(
  //     { facingMode: "environment" },
  //     qrConfig,
  //     qrCodeSuccessCallback
  //   );
  // };

  // const handleStop = () => {
  //   try {
  //     html5QrCode
  //       .stop()
  //       .then((res) => {
  //         html5QrCode.clear();
  //       })
  //       .catch((err) => {});
  //   } catch (err) {}
  // };

  const cartoonDelete = (id) => {
    let newTabs = tabs
      .filter((data, index) => id !== index)
      .map((data, index) => {
        data.isActive = false;
        if (index >= id) {
          data.name = `cartoon-${index + 1}`;
          data.isActive = false;
        }
        if (index === 0) {
          data.isActive = true;
          setBoxNum(1);
        }
        return data;
      });
    setTabs(newTabs);

    cartoonDeleteRequest("POST", "sending-box-delete", {
      id_picking_order: idPickingOrder,
      box_num: id + 1,
    });
  };

  const packedProdHandler = (product) => {
    sendingProcessRequest("POST", "scan-sending-product-process", {
      id_picking_order: product?.id_picking_order,
      id_picking: product?.id_picking,
      id_batch: product?.id_batch,
      box_num: boxNum,
    });
  };

  const closeHandler = () => {
    setIsCloseDisable(true)
    closeOrderRequest("GET", `sending-close-order/${idPickingOrder}`);
  };

  const deleteHandler = (id) => {
    MySwal.fire({
      title: t("Are you sure"),
      text: t("Delete Product"),
      icon: "warning",
      showCancelButton: true,
      confirmButtonText: t("Yup"),
      cancelButtonText: t("Cancel"),
      reverseButtons: true,
    }).then((result) => {
      if (result.isConfirmed) {
        deleteCaroonProductRequest("GET", `sending-delete-cartoon/${id}`);
      } else if (result.isDismissed) {
      }
    });
  };

  const printLabelHandler = (id) => {
    printLabelDataRequest("POST", "sending-print-data", {
      id_selling_point: id,
      box_num: boxNum,
      delivery_date: orderData?.delivery_date
    });
  };

  return (
    <>
      {userRoleId === 5 || userRoleId === 3 ? (
        <div>
          <div className="dashboard_parent">
            {/* <!-- Sidebar --> */}

            <SideBar />
            <div className="dashboard_right">
              {/* <!-- dashboard header --> */}
              <Header
                title="Spedizione"
                links={[{ to: "/Spedizione", name: "spedizione lista odv" }]}
              />

              {/* <!-- dashboard Content --> */}

              <div className="dashboard_content">
                <div className="dashboard-container">
                  <div className="p-3">
                    <div className="orderList-data">
                      {/* Order List Item */}
                      <div className="orderList-row">
                        <div className="sm-progress">
                          <div
                            className="sm-progressInner"
                            style={{ height: `${procDataPer}%` }}
                          ></div>
                          <span>
                            {procDataPer !== 0 ? `${procDataPer} %` : ""}
                          </span>
                        </div>
                        <div className="suppliername">
                          <label>{t("Customer")}</label>
                          <h6>{orderData?.customer}</h6>
                        </div>

                        <div className="orderno">
                          <label>{t("Delivery Date")}</label>
                          <h6>{orderData?.delivery_date}</h6>
                        </div>
                      </div>

                      {/* Table */}
                      <div className="orderList-tabledata">
                        {/* qr code */}

                        {/* <Scanner func={handleClickAdvanced} /> */}

                        <div className="table-responsive">
                          <table className="table themeTable table-bordered table-striped">
                            <thead>
                              <tr className="">
                                <th>{t("Article Id")}</th>
                                <th>{t("Description")}</th>
                                <th>{t("Pieces")}</th>
                                <th>{t("Weight")}</th>
                                <th>u.m</th>
                                <th>{t("Batch Code")}</th>
                                <th>{t("Expiring Date")}</th>
                              </tr>
                            </thead>
                            <tbody>
                              {pickingProducts &&
                                pickingProducts.map((product, index) => (
                                  <tr
                                    className="cursor-pointer"
                                    onClick={() => {
                                      packedProdHandler(product);
                                    }}
                                  >
                                    <td>{product?.id_article}</td>
                                    <td>{product?.product_description}</td>
                                    <td>{product?.pieces}</td>
                                    <td>{product?.weight}</td>
                                    <td>{product?.um}</td>
                                    <td>{product?.batch?.batch_code}</td>
                                    <td>{product?.batch?.expiring_date}</td>
                                  </tr>
                                ))}
                            </tbody>
                          </table>
                        </div>
                      </div>

                      {/* Table */}
                      {/* <div className="orderList-esc">
                        <h4 className="text-white">{t("PACKAGE")}</h4>
                        <div className="table-responsive">
                          <table className="table themeTable table-bordered table-striped">
                            <thead>
                              <tr>
                                <th></th>
                                <th>{t("Article Id")}</th>
                                <th>{t("Description")}</th>
                                <th>{t("Pieces")}</th>
                                <th>{t("Weight")}</th>
                                <th>u.m</th>
                                <th>{t("Batch Code")}</th>
                                <th>{t("Expiring Date")}</th>
                                <th></th>
                              </tr>
                            </thead>
                            <tbody>
                              {pickedOrder &&
                                pickedOrder.map((order, index) => (
                                  <tr>
                                    <td>
                                      <div className="sm-statusbox">
                                        <div className="boxicon">
                                          <svg
                                            xmlns="http://www.w3.org/2000/svg"
                                            viewBox="0 0 24 24"
                                            width="26"
                                            height="26"
                                          >
                                            <path fill="none" d="M0 0h24v24H0z" />
                                            <path d="M3 10H2V4.003C2 3.449 2.455 3 2.992 3h18.016A.99.99 0 0 1 22 4.003V10h-1v10.001a.996.996 0 0 1-.993.999H3.993A.996.996 0 0 1 3 20.001V10zm16 0H5v9h14v-9zM4 5v3h16V5H4zm5 7h6v2H9v-2z" />
                                          </svg>
                                        </div>
                                        <div className="statusCircle bg-success"></div>
                                      </div>
                                    </td>
                                    <td>{order?.id_article}</td>
                                    <td>{order?.product_description}</td>
                                    <td>{order?.pieces}</td>
                                    <td>{order?.weight}</td>
                                    <td>{order?.um}</td>
                                    <td>{order?.batch_code}</td>
                                    <td>{order?.expiring_date}</td>
                                    <td>
                                      <button className="printbtn">
                                        <i className="far fa-print"></i>
                                      </button>
                                    </td>
                                  </tr>
                                ))}
                            </tbody>
                          </table>
                        </div>
                      </div> */}

                      <div className="orderList-esc">
                        <h4 className="text-white text-uppercase d-flex align-items-end mb-4">
                          <img
                            src="/img/SPEDIZIONE.svg"
                            width="40"
                            alt="SPEDIZIONE"
                            className="me-2"
                          />
                          SPEDIZIONE
                        </h4>

                        {/* <div className="row justify-content-center">
                          <div className="col-lg-4 mb-2 mb-md-4">
                            <label className="text-white text-uppercase mb-1">
                              Cliente
                            </label>
                            <div className="themeInput themeIS">
                              <input
                                type="text"
                                className="form-control"
                                placeholder="caviale siberiano secco"
                              />
                              <button className="themeIS-btn">
                                <i className="fas fa-search"></i>
                              </button>
                            </div>
                          </div>

                          <div className="col-lg-4 mb-2 mb-md-4">
                            <label className="text-white text-uppercase mb-1">
                              Corriere
                            </label>
                            <div className="themeInput themeIS">
                              <input
                                type="text"
                                className="form-control"
                                placeholder="caviale siberiano secco"
                              />
                              <button className="themeIS-btn">
                                <i className="fas fa-search"></i>
                              </button>
                            </div>
                          </div>
                        </div> */}

                        <div className="themeTabs mt-4 mt-md-5">
                          <ul className="nav nav-tabs" id="myTab" role="tablist">
                            {tabs.map((t, idx) => (
                              <li className="nav-item" role="presentation">
                                <button
                                  className={`nav-link ${
                                    tabs[idx].isActive ? "active" : ""
                                  }`}
                                  id={`${t.name}-tab`}
                                  data-bs-toggle="tab"
                                  data-bs-target={`#${t.name}-tab-pane`}
                                  type="button"
                                  role="tab"
                                  aria-controls={`${t.name}-tab-pane`}
                                  aria-selected="true"
                                  onClick={() => {
                                    const oldTabs = [...tabs].map(
                                      (tab, index) => {
                                        if (tab.name === t.name) {
                                          tab.isActive = true;
                                          setBoxNum(index + 1);
                                        } else {
                                          tab.isActive = false;
                                        }
                                        return tab;
                                      }
                                    );
                                    setTabs(oldTabs);
                                  }}
                                >
                                  {t.name}
                                </button>

                                <button
                                  onClick={() => cartoonDelete(idx)}
                                  className="btn btn-danger processedDelete"
                                  title="Delete processed product"
                                >
                                  <i className="far fa-times"></i>
                                </button>
                              </li>
                            ))}
                            <li className="nav-item" role="presentation">
                              <button
                                className="nav-link"
                                onClick={() => {
                                  const oldTabs = [...tabs].map((tab) => {
                                    tab.isActive = false;
                                    return tab;
                                  });

                                  setTabs([
                                    ...oldTabs,
                                    {
                                      name: `cartoon-${tabs.length + 1}`,
                                      isActive: true,
                                      isDelete: true,
                                    },
                                  ]);
                                  setBoxNum(tabs.length + 1);
                                  setIsOnLoad(false);
                                }}
                              >
                                <i className="far fa-plus"></i>
                              </button>
                            </li>
                          </ul>
                          <div className="tab-content" id="myTabContent">
                            {tabs.map((tab, idx) => (
                              <div
                                className={`tab-pane fade ${
                                  tabs[idx].isActive ? "show active" : ""
                                }`}
                                id={`${tab.name}-tab-pane`}
                                role="tabpanel"
                                aria-labelledby={`${tab.name}-tab`}
                                tabIndex={0}
                              >
                                <div className="table-responsive">
                                  <table className="table themeTable table-bordered table-striped">
                                    {cartoons.length > 0 ? (
                                      <>
                                        <thead>
                                          <tr className="">
                                            <th>{t("Article Id")}</th>
                                            <th>{t("Description")}</th>
                                            <th>{t("Pieces")}</th>
                                            <th>{t("Weight")}</th>
                                            <th>u.m</th>
                                            <th>{t("Batch Code")}</th>
                                            <th>{t("Expiring Date")}</th>
                                            <th></th>
                                          </tr>
                                        </thead>
                                        <tbody>
                                          {cartoons.map((cartoon, index) => (
                                            <tr>
                                              <td className="text-uppercase">
                                                {
                                                  cartoon?.picking_product
                                                    ?.id_article
                                                }
                                              </td>
                                              <td>
                                                {
                                                  cartoon?.picking_product
                                                    ?.product_description
                                                }
                                              </td>
                                              <td>{cartoon?.pieces}</td>
                                              <td>
                                                {cartoon?.picking_product?.weight}
                                              </td>
                                              <td>
                                                {cartoon?.picking_product?.um}
                                              </td>
                                              <td>
                                                {
                                                  cartoon?.picking_product?.batch
                                                    ?.batch_code
                                                }
                                              </td>
                                              <td>
                                                {
                                                  cartoon?.picking_product?.batch
                                                    ?.expiring_date
                                                }
                                              </td>
                                              <td>
                                                <button
                                                  className="printbtn "
                                                  onClick={() =>
                                                    deleteHandler(
                                                      cartoon?.id_sending_box
                                                    )
                                                  }
                                                >
                                                  <i className="far fa-times"></i>
                                                </button>
                                              </td>
                                            </tr>
                                          ))}
                                        </tbody>
                                      </>
                                    ) : (
                                      <>
                                        <thead>
                                          <tr className="">
                                            <th>{t("Article Id")}</th>
                                            <th>{t("Description")}</th>
                                            <th>{t("Pieces")}</th>
                                            <th>{t("Weight")}</th>
                                            <th>u.m</th>
                                            <th>{t("Batch Code")}</th>
                                            <th>{t("Expiring Date")}</th>
                                          </tr>
                                        </thead>
                                        <tbody>
                                          <tr>
                                            <td> </td>
                                            <td> </td>
                                            <td> </td>
                                            <td> </td>
                                            <td> </td>
                                            <td> </td>
                                            <td> </td>
                                          </tr>
                                        </tbody>
                                      </>
                                    )}
                                  </table>
                                </div>
                              </div>
                            ))}
                          </div>
                        </div>
                      </div>

                      <div className="orderList-dataction">
                        <button
                          className="btn"
                          onClick={() => history.push("/spedizione")}
                        >
                          <i className="far fa-times"></i>
                        </button>

                        <button
                          className="btn"
                          disabled={cartoons?.length > 0 ? false : true}
                          onClick={() => printLabelHandler(idSellingPoint)}
                        >
                          <i className="far fa-print"></i>
                        </button>

                        <button
                          className="btn disable"
                          // onClick={() => sendResult()}
                          disabled={isCloseDisable}
                          onClick={() => closeHandler()}
                        >
                          <i className="fas fa-play"></i>
                        </button>
                      </div>
                    </div>
                  </div>
                </div>
                {/* <!-- dashboard-container  END --> */}
              </div>
              {/* <!-- dashboard_content END --> */}
            </div>
          </div>

          {/* <!-- Modal -->   */}
          <div
            className="modal fade"
            id="editodernum"
            tabIndex="-1"
            aria-labelledby="editodernumLabel"
            aria-hidden="true"
          >
            <div className="modal-dialog modal-dialog-centered">
              <div className="modal-content">
                <div className="text-end pt-3 pe-3 mb-n3">
                  <button
                    type="button"
                    className="btn-close"
                    data-bs-dismiss="modal"
                    aria-label="Close"
                  ></button>
                </div>

                <div className="modal-body p-3 text-center">
                  <div className="form-group">
                    <label className="mb-2">
                      inserisci numero documento di trasporto
                    </label>
                    <h4>_rt256342F</h4>
                    {/* <input className="form-control" value="_rt256342F" type="text" /> */}
                  </div>
                </div>
                {/* <div className="modal-footer">
                      <button type="button" className="btn btn-secondary" data-bs-dismiss="modal">Close</button>
                      <button type="button" className="btn btn-primary">Save changes</button>
                  </div> */}
              </div>
            </div>
          </div>
        </div>
      ):(
        history.push("/")
      )}
    </>
  );
};

export default SpedizioneListaOdv;
