import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { setLanguage } from "../../store/setting/action";

const Translator = () => {
  const [t, i18n] = useTranslation("translation");
  const dispatch = useDispatch();
  const storedLanguage = localStorage.getItem("i18nextLng");
  const languageOnchange = (e) => {
    if (e.target.value === "it") {
      dispatch(setLanguage({ language: "it" }));
      localStorage.setItem("i18nextLng", "it");
      return i18n.changeLanguage("it");
    } else {
      localStorage.setItem("i18nextLng", "en");
      dispatch(setLanguage({ language: "en" }));

      return i18n.changeLanguage("en");
    }
  };

  return (
    <>
      <div className="ms-auto">
        <select 
          className="form-select form-control pe-4 pe-md-5 me-1 me-md-0"
          value={storedLanguage}
          onChange={languageOnchange}
        >
          <option value={"it"} selected={storedLanguage === "it" ? "it" : ""}>
            Italian
          </option>
          <option value={"en"} selected={storedLanguage === "en" ? "en" : ""}>
            English
          </option>
        </select>
      </div>
    </>
  );
};

export default Translator;
