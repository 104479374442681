import React, { useState, useEffect, useRef } from "react";
import { useDispatch, useSelector } from "react-redux";
import SideBar from "../../components/Sidebar/sideBar";
import Header from "../../components/header/header";
import { useTranslation } from "react-i18next";
import useRequest from "../../hooks/useRequest";
import useMultiRequestLoader from "../../hooks/useMultiRequestLoader";
import LoadingSpinner from "../../components/loader/LoadingSpinner";
import { removeModelClass } from "../../components/Utils/Utils";
import { toast } from "react-toastify";
import { PrintLabelMeatProduction } from "../../components/printlabel/production/productionmeatlabel";
import { printLabelNonMeatProduction } from "../../components/printlabel/production/productionNonmeatLabel";
import { ProductionIsRanNonMeatLabel } from "../../components/printlabel/production/productionIsRanNonMeatLabel";
import { ProductionIsEanMeatLabel } from "../../components/printlabel/production/productionIsEanMeatLabel";
import Autosuggest from "react-autosuggest";
import DatePicker from "react-multi-date-picker";
import Pagination from "../../components/Pagination/Pagination";
import { API } from "../../constant/api";




const BACKEND_URL = API.Base_Url;



const searchQueryHandlerTable = (page, per_page, sortBy, order) => {
  if (sortBy.length > 0) {
    if (sortBy == "CODICE" || sortBy == "CODE") {
      sortBy = "a.article_code";
    } else if (sortBy === "ITEM" || sortBy === "ARTICOLO") {
      sortBy = "a.description"; 
    } else if (sortBy === "DEADLINE" || sortBy === "SCADENZA") {
      sortBy = "b.expiring_date";
    } else if (sortBy === "BATCH" || sortBy === "LOTTO") {
      sortBy = "b.batch_code";
    } else if (sortBy === "peso" || sortBy === "weight") {
      sortBy = "b.weight";
    } else if (sortBy === "PCS" || sortBy === "PZ") {
      sortBy = "b.pieces";
    } else if (sortBy === "PURCHASE PRICE" || sortBy === "PREZZO D'ACQUISTO") {
      sortBy = "b.purchased_price";
    } else if (sortBy === "AREA" || sortBy === "AREA") {
      sortBy = "ab.area";
    }
  } else {
    sortBy = "createdAt";
  }
  order = order.length > 0 ? order : "desc";
  return `movements?page=${page}&per_page=${per_page}&sortBy=${sortBy}&order=${order}`;
};


const Movimentazioni = () => {
  // Default set variables
  document.body.classList.remove("shipmentbody");
  const [t, i18n] = useTranslation("translation");
  const { userRoleId } = useSelector((state) => state.auth);
  const inputRefBarcode = useRef(null);
  // Requests
  const { loading, updateResponse } = useMultiRequestLoader(1);
  const { request, response } = useRequest(true, updateResponse);
  const { request: getCausalMovementRequest, response: causalMovementResponse } = useRequest();
  const { request: updateGenericMovementRequest, response: updateGenericMovementResponse } = useRequest();
  const { request: downloadMovementRequest, response: downloadMovementResponse } = useRequest();
  const { request: printProductRequest, response: printProductResponse } = useRequest();
  const { request: customerProductRequest, response: customerProductResponse } = useRequest();
  const { request: productRequest, response: productResponse } = useRequest();
  const { request: articleRequest, response: articleResponse } = useRequest();
  const { request: areaBatchesRequest, response: areaBatchesResponse } = useRequest();
  const { request: productScannerRequest, response: productScannerResponse } = useRequest();
  const { request: updateScannedProductRequest, response: updateScannedProductResponse } = useRequest();



  // 
  // Movements data list state
  const [movements, setMovements] = useState([])
  const [movement, setMovement] = useState({
    batch_code: "",
    description: "",
    weight: "",
    pieces: "",
    article_code: "",
    area: "",
    expiring_date: "",
    purchased_price: ""
  })
  const [causalMovements, setCausalMovements] = useState([])
  const [areaBatches, setAreaBatches] = useState([])
  const [idAreaBatch, setIdAreaBatch] = useState(null)

  const [idCausalMovement, setIdCausalMovement] = useState(null)
  const [printProducts, setPrintProducts] = useState([])
  const [customerProducts, setCustomerProducts] = useState([])
  const [expiringDate, setExpiringDate] = useState('')
  const [description, setDescription] = useState('')
  const [batchCode, setBatchCode] = useState('')
  const [quantity, setQuantity] = useState(false)
  const [articles, setArticles] = useState([])
  const [articlesSuggestionList, setArticlesSuggestionList] = useState([]);
  const [selectedArticleName, setSelectedArticleName] = useState("");
  const [showDeleteButton, setShowDeleteButton] = useState(false);
  const [articleSearchQuery, setArticleSearchQuery] = useState("");
  const [page, setPage] = useState(1);
  const [totalDocuments, setTotalDocuments] = useState(10);
  const [perPage, setPerPage] = useState(10);
  const [currentSort, setCurrentSort] = useState({
    sortBy: "CODE",
    order: "desc",
  });
  const [productBarCode, setProductBarCode] = useState('')
  const [product, setProduct] = useState('')
  const [scanedProducts, setScanedProducts] = useState(0)
  const [scanedWeight, setScanedWeight] = useState(0)
  const [productWeight, setProductWeight] = useState('')
  const [productPieces, setProductPieces] = useState('')

  const OBJ_TABLE = [
    t("CODE"),
    t("ITEM"),
    t("DEADLINE"),
    t("BATCH"),
    t("weight"),
    t("PCS"),
    t("PURCHASE PRICE"),
    t("AREA"),
  ];

  useEffect(() => {
    initialLoadHandler()
  }, []);

  // Get movements data
  useEffect(() => {
    if (productBarCode.length >= 14) {
      productScannerRequest("POST", "product-scanner", { barcode: productBarCode })
    }
  }, [productBarCode]);

  useEffect(() => {
    if (productScannerResponse) {
      if (productScannerResponse.status === "SUCCESS") {
        setProduct(productScannerResponse?.data?.product)
        setScanedProducts(scanedProducts + 1)
        setScanedWeight(scanedWeight + +(productScannerResponse?.data?.product?.weight))
        setProductPieces(productScannerResponse?.data?.product?.pieces)
        setProductWeight(productScannerResponse?.data?.product?.weight)
      }
    }
  }, [productScannerResponse])

  const initialLoadHandler = () => {

    request(
      "GET",
      searchQueryHandlerTable(
        page,
        perPage,
        currentSort.sortBy,
        currentSort.order
      )
    );
    // searchQueryHandlerTable(page, perPage, sortBy, newOrder, question)

    // request("GET", `movements?page=${page}&per_page=${perPage}`);

    getCausalMovementRequest("GET", "causal-movements")
    articleRequest('GET', 'article-search')
    areaBatchesRequest('GET', "area-batches")


  }

  useEffect(() => {
    if (response) {
      if (response?.status === "SUCCESS") {
        setMovements(response?.data?.movements)
        setTotalDocuments(response?.data?.total_records)
      }
    }
    if (causalMovementResponse) {
      if (causalMovementResponse?.status === "SUCCESS") {
        setCausalMovements(causalMovementResponse?.data?.causal_movements)
      }
    }
    if (articleResponse) {
      if (articleResponse?.status === "SUCCESS") {
        setArticlesSuggestionList(articleResponse?.data?.articles)
      }
    }
    if (areaBatchesResponse) {
      if (areaBatchesResponse?.status === "SUCCESS") {
        setAreaBatches(areaBatchesResponse?.data?.area_batch)
      }
    }
  }, [response, causalMovementResponse, articleResponse, areaBatchesResponse]);

  const setMovementHandler = (data) => {
    setMovement(data)
  }

  const setIdCausalMovementHandler = (idCausalMovement) => {
    setIdCausalMovement(idCausalMovement)
  }

  const closeModelHandler = () => {
    setIdCausalMovement(null)
    removeModelClass('dataEditModal')
  }

  const closeProductModelHandler = () => {
    removeModelClass('productModal')
  }

  const movementChangeHandler = (event) => {
    const { name, value } = event.target;
    setMovement((prevProps) => ({
      ...prevProps,
      [name]: value
    }));
  };

  const updateGenericMovementHandler = () => {
    if (idCausalMovement) {
      closeModelHandler()
      updateGenericMovementRequest('POST', 'update-movement', {
        batch_code: movement?.batch_code,
        description: movement?.description,
        weight: movement?.weight,
        pieces: movement?.pieces,
        expiring_date: movement?.expiring_date,
        id_cause: idCausalMovement,
        id_area: idAreaBatch
      })
    } else {
      toast.error(t('select cause'));
    }
  }

  useEffect(() => {
    if (updateGenericMovementResponse) {
      if (updateGenericMovementResponse?.status === "SUCCESS") {
        request("GET", "movements");
        toast.success(updateGenericMovementResponse?.message);
      } else {
        toast.error(updateGenericMovementResponse?.message);
      }
    }
  }, [updateGenericMovementResponse]);

  const downloadMovementsHandler = () => {
    let query = `?expiring_date=${expiringDate}&description=${description}&batch_code=${batchCode}`
    let url = `${BACKEND_URL}api/v1/download-movements${query}`
    window.open(url, '_blank', 'noreferrer');
  }

  const printProductHandler = (batchCode, articleCode) => {
    printProductRequest('POST', 'print-products', {
      batch_code: batchCode,
      article_code: articleCode
    })
  }

  const customerListHandler = (batchCode, articleCode) => {
    customerProductRequest('POST', 'customer-products', {
      batch_code: batchCode,
      article_code: articleCode
    })
  }

  useEffect(() => {
    if (customerProductResponse) {
      if (customerProductResponse?.status === 'SUCCESS') {
        setCustomerProducts(customerProductResponse?.data?.customers)
      }
    }
  }, [customerProductResponse]);

  useEffect(() => {
    if (printProductResponse) {
      if (printProductResponse?.status === 'SUCCESS') {
        setPrintProducts(printProductResponse?.data?.receiving_products)
      }
    }
  }, [printProductResponse]);

  useEffect(() => {
    if (expiringDate) {
      searchHandler('')
    }
    if (quantity) {
      searchHandler('')
    }
  }, [expiringDate, quantity]);

  const searchHandler = (article) => {
    let qty = ''
    if (quantity) {
      qty = 0
    }
    let query = ''
    if (article) {
      query = `?page=${page}&per_page=${perPage}&expiring_date=${expiringDate}&description=${article}&batch_code=${batchCode}&quantity=${qty}`
    } else {
      query = `?expiring_date=${expiringDate}&description=${description}&batch_code=${batchCode}&quantity=${qty}`
    }
    request("GET", `movements${query}`);
  }

  const searchQueryHandler = (page, per_page) => {
    return `movements?page=${page}&per_page=${per_page}&expiring_date=${expiringDate}&description=${description}&batch_code=${batchCode}`;
  };

  const searchResetHandler = () => {
    setQuantity(false)
    setExpiringDate('')
    setDescription('')
    setBatchCode('')
    initialLoadHandler()
  }

  const getProductDataHandler = (data) => {
    productRequest('POST', `get-prod-product`, {
      id: data?.id_weight,
      id_article: data?.id_article,
      is_father: false
    })
  }

  useEffect(() => {
    if (productResponse) {
      if (productResponse?.status === "SUCCESS") {
        if (productResponse?.data?.product_type === "Dry" && !productResponse?.data?.is_ean) {
          printLabelNonMeatProduction(productResponse?.data?.product);
        } else if (productResponse?.data?.product_type === "Dry" && productResponse?.data?.is_ean) {
          ProductionIsRanNonMeatLabel(productResponse?.data?.product)
        }
        else if (productResponse?.data?.product_type === "Meat" && !productResponse?.data?.is_ean) {
          PrintLabelMeatProduction(productResponse?.data?.product);
        }
        else if (productResponse?.data?.product_type === "Meat" && productResponse?.data?.is_ean) {
          ProductionIsEanMeatLabel(productResponse?.data?.product)
        }
      }
    }
  }, [productResponse]);

  // Search article
  const onChange = (e) => {
    e.preventDefault();
    setArticleSearchQuery(e.target.value)
  };

  const inputProps = {
    placeholder: t("articolo"),
    value: articleSearchQuery ? articleSearchQuery : "",
    onChange,
  };

  const searchProvider = (e) => {
    e.preventDefault();
  };

  const onSuggestionsFetchRequested = ({ value, reason }) => {
    articleRequest('GET', `article-search?name=${value}`)
  };

  const onSuggestionsClearRequested = () => {
    setArticlesSuggestionList(articlesSuggestionList);
  };

  const renderSuggestion = (suggestion) => suggestion;

  const getSuggestionValue = (suggestion) => {
    setDescription(suggestion)
    setArticlesSuggestionList(articlesSuggestionList);
    setSelectedArticleName(suggestion);
    searchHandler(suggestion)
    setShowDeleteButton(false);
    return suggestion;
  };

  const getBackDate = () => {
    let date = new Date();
    return date;
  };

  const fetchMoreData = ({ selected }) => {
    setMovements([]);
    setPage(selected + 1);
    request("GET", searchQueryHandler(selected + 1, perPage));
  };

  const perPageChangeHandler = (event) => {
    request("GET", searchQueryHandler(1, event.target.value));
    setPage(1);
    setPerPage(event.target.value);
  };


  const sortingHandler = (sortBy) => {
    if (currentSort.sortBy == sortBy) {
      const newOrder = currentSort.order === "asc" ? "desc" : "asc";
      request(
        "GET",
        searchQueryHandlerTable(page, perPage, sortBy, newOrder)
      );
      setCurrentSort({ sortBy, order: newOrder });
    } else {
      request(
        "GET",
        searchQueryHandlerTable(page, perPage, sortBy, "desc")
      );
      setCurrentSort({ sortBy, order: "desc" });
    }
  };

  const updateScannedProductHandler = () => {
    closeProductModelHandler()
    updateScannedProductRequest('POST', 'update-scanned-product', {
      id_batch: product?.id_batch,
      batch_code: product?.batch_code,
      id_article: product?.id_article,
      pieces: parseInt(productPieces),
      weight: +productWeight
    })
  }

  useEffect(() => {
    if (updateScannedProductResponse) {
      if (updateScannedProductResponse?.status === 'SUCCESS') {
        setProduct(null)
        setProductBarCode('')
        setProductPieces('')
        setProductWeight('')
        toast.success(updateScannedProductResponse?.message);
      } else {
        toast.error(updateScannedProductResponse?.message);
      }
    }
  }, [updateScannedProductResponse]);

  return (
    <>
      <div className="dashboard_parent">
        {/* <!-- Sidebar --> */}
        <SideBar />
        {loading && <LoadingSpinner />}
        <div className="dashboard_right bg-white">
          {/* <!-- dashboard header --> */}

          <Header
            title={t('Movimentazioni')}
            links={[{ to: "/movimentazioni", name: 'Movimentazioni' }]}
          />

          {/* <!-- dashboard Content --> */}

          <div className="dashboard_content">
            <div className="dashboard-container">
              <div className="px-md-5 px-3 pt-3 pt-md-5 pb-3 pb-md-4">
                <div className="magazzinobtn_header">
                  <div className="row">
                    <div className="col-md-4 mb-3">
                      <button className="btn btn-primary" onClick={initialLoadHandler}>
                        <i className="far fa-undo"></i>
                      </button>
                      {/* <button className="btn btn-primary">
                        <i className="far fa-plus"></i>
                      </button> */}
                    </div>
                    <div className="col-md-4 text-md-center mb-3">
                      {/* <button className="btn btn-primary">
                        <i className="far fa-plus"></i>
                      </button> */}
                      <button className="btn btn-primary" onClick={downloadMovementsHandler}>esporta</button>
                      <button className="btn btn-primary" onClick={(e) => { setQuantity(true) }}>
                        Dispo No Stock
                      </button>
                      <button
                        className="btn btn-primary"
                        data-bs-toggle="modal"
                        data-bs-target="#productModal"
                        onClick={(e) => {setTimeout(()=>{
                          inputRefBarcode.current.focus()
                        }, 500)}} 
                      >
                        {t('Scan Product')}
                      </button>
                    </div>
                    <div className="col-md-4 text-md-end mb-3">
                      <button className="btn btn-primary" onClick={searchResetHandler}>Reset</button>
                    </div>
                  </div>

                  <div className="row">
                    <div className="col-md-3 mb-2">
                      <div className="themeInput themeIS date_box mx-md-4">
                        <DatePicker
                          placeholder={t('Expiring Date')}
                          value={expiringDate ? expiringDate : getBackDate()}
                          onChange={(date) => {
                            date && setExpiringDate(date.format());
                          }}
                          format="YYYY-MM-DD"
                        />
                        <button type="button" className="themeIS-btn">
                          <i className="far fa-calendar-alt"></i>
                        </button>

                      </div>
                    </div>
                    <div className="col-md-4 col-xl-3">
                      <div className="themeInput themeIS mb-2 mb-md-0">
                        <form
                          onSubmit={searchProvider}
                          className="themeInput position-relative"
                        >
                          <Autosuggest
                            suggestions={articlesSuggestionList}
                            onSuggestionsFetchRequested={
                              onSuggestionsFetchRequested
                            }
                            onSuggestionsClearRequested={
                              onSuggestionsClearRequested
                            }
                            getSuggestionValue={getSuggestionValue}
                            renderSuggestion={renderSuggestion}
                            inputProps={inputProps}
                          />
                          <button className="themeIS-btn" type="submit">
                            <i className="fas fa-search"></i>
                          </button>
                        </form>
                      </div>
                    </div>
                    <div className="col-md-4 col-xl-3">
                      <div className="themeInput themeIS mb-2 mb-md-0">
                        <input
                          type="text"
                          className="form-control"
                          placeholder="lotto"
                          value={batchCode}
                          onChange={(e) => { setBatchCode(e.target.value) }}
                        />
                        <button type="button" className="themeIS-btn" onClick={(e) => { searchHandler('') }}>
                          <i className="fas fa-search"></i>
                        </button>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="table-responsive simpTable">
                <table className="table table-striped">
                  <thead>
                    <tr>
                      <th></th>
                      {OBJ_TABLE.map((thData) => {
                        return (
                          <>
                            {currentSort.order == "asc" && currentSort.sortBy === thData ? <th onClick={() => sortingHandler(thData)}>{thData} <i className="fas fa-sort-up"></i> </th> : <th onClick={() => sortingHandler(thData)}>{thData}  <i className="fas fa-sort-down"></i> </th>}
                          </>
                        )
                      })}
                      <th></th>
                    </tr>
                  </thead>
                  <tbody>
                    {movements && movements.map((data, index) => (
                      <tr>
                        <td>
                          <div className="d-flex align-items-center">
                            <div className="form-check mb-0">
                              <input
                                className="form-check-input"
                                type="checkbox"
                                id={`generic-mov-${index}`}
                              />
                            </div>
                            <button
                              className="btn ms-1 btn-sm"
                              data-bs-toggle="modal"
                              data-bs-target="#dataEditModal"
                              onClick={(e) => { setMovementHandler(data) }}
                            >
                              <i className="fas fa-plus"></i>
                            </button>
                            <button className="btn ms-1 btn-sm">
                              <i className="fas fa-history"></i>
                            </button>
                          </div>
                        </td>

                        <td>{data?.article_code}</td>

                        <td>
                          <div className="max-w-desc">
                            {data?.description}
                          </div>
                        </td>

                        <td>{data?.expiring_date}</td>

                        <td>
                          {data?.batch_code}
                        </td>

                        <td>{data?.weight}</td>

                        <td>{data?.pieces}</td>

                        <td>{data?.purchased_price}</td>

                        <td>{data?.area}</td>

                        <td>
                          <button
                            className="btn btn-primary square-xs ms-1"
                            data-bs-toggle="modal"
                            data-bs-target="#TableDataModalCustomerList"
                            onClick={(e) => { customerListHandler(data?.batch_code, data?.article_code) }}
                          >
                            <i className="fa fa-arrow-up"></i>
                          </button>

                          <button
                            className="btn btn-primary square-xs ms-1"
                            data-bs-toggle="modal"
                            data-bs-target="#TableDataModal"
                            onClick={(e) => { printProductHandler(data?.batch_code, data?.article_code) }}
                          >
                            <i className="far fa-print"></i>
                          </button>
                        </td>
                      </tr>
                    ))}
                  </tbody>
                </table>
              </div>
              {perPage !== 0 && (
                <Pagination
                  page={page}
                  totalRecords={totalDocuments}
                  getNewData={fetchMoreData}
                  perPage={perPage}
                  defaultPerPage={10}
                  perPageChangeHandler={perPageChangeHandler}
                  currentDocLength={movements.length}
                />
              )}
            </div>
          </div>
        </div>

        {/* Data Edit Modal */}
        <div
          className="modal fade"
          id="TableDataModal"
          tabIndex={-1}
          aria-labelledby="TableDataModalLabel"
          aria-hidden="true"
        >
          <div className="modal-dialog modal-dialog-centered">
            <div className="modal-content">
              <div className="modal-body">
                <div className="table-responsive simpTable">
                  <table className="table table-striped">
                    <thead className="text-uppercase">
                      <th>{t('ARTICLE')}</th>
                      <th>{t('VAR. WEIGHT')}</th>
                      <th>{t('VAR. PIECES')}</th>
                      <th>{t('PRINT')}</th>
                    </thead>
                    <tbody>
                      {printProducts && printProducts.map((data, index) => (
                        <tr>
                          <td>{data?.description}</td>
                          <td>{data?.weight}</td>
                          <td>{data?.pieces}</td>
                          <td>
                            <button
                              className="btn btn-primary square-xs ms-1"
                              onClick={(e) => { getProductDataHandler(data) }}
                            >
                              <i className="far fa-print"></i>
                            </button>
                          </td>
                        </tr>
                      ))}
                    </tbody>
                  </table>
                </div>
              </div>
            </div>
          </div>
        </div>


        {/* Data Edit Modal */}
        <div
          className="modal fade"
          id="TableDataModalCustomerList"
          tabIndex={-1}
          aria-labelledby="TableDataModalCustomerListLabel"
          aria-hidden="true"
        >
          <div className="modal-dialog modal-dialog-centered">
            <div className="modal-content">
              <div className="modal-body">
                <div className="table-responsive simpTable">
                  <table className="table table-striped">
                    <thead className="text-uppercase">
                      <th>punto_vendita</th>
                      <th>quantita</th>
                    </thead>
                    <tbody>
                      {customerProducts && customerProducts.map((data, index) => (
                        <tr>
                          <td>{data?.customer_name}</td>
                          <td>{data?.quantity}</td>
                        </tr>
                      ))}
                    </tbody>
                  </table>
                </div>
              </div>
            </div>
          </div>
        </div>

        {/* Data Edit Modal */}
        <div
          className="modal fade"
          id="dataEditModal"
          tabIndex={-1}
          aria-labelledby="dataEditModalLabel"
          aria-hidden="true"
        >
          <div className="modal-dialog modal-dialog-centered">
            <div className="modal-content">
              <div className="modal-body">
                <div className="mb-3">
                  <label>{t('COD. ART.')}</label>
                  <input className="form-control h-40px" name="article_code" value={movement?.article_code} onChange={movementChangeHandler} />
                </div>
                <div className="col-md-12">
                  <div className="mb-3">
                    <label>{t('Area-Batches')}</label>
                    <select className="form-select from-control d-40px" name="id_area" onChange={(e) => { setIdAreaBatch(parseInt(e.target.value)) }}>
                      {areaBatches && areaBatches.map((data, index) => (
                        <option key={index} value={data?.id_area}>{data?.area}</option>
                      ))}
                    </select>
                  </div>
                </div>
                <div className="mb-3">
                  <label>{t('ITEM')}</label>
                  <input className="form-control h-40px" name="description" value={movement?.description} onChange={movementChangeHandler} />
                </div>

                <div className="row">
                  <div className="col-md-6">
                    <div className="mb-3">
                      <label>{t('Weight (kg)')}</label>
                      <input className="form-control h-40px" name="weight" value={movement?.weight} onChange={movementChangeHandler} />
                    </div>
                  </div>
                  <div className="col-md-6">
                    <div className="mb-3">
                      <label>{t('PCS')}</label>
                      <input className="form-control h-40px" name="pieces" value={movement?.pieces} onChange={movementChangeHandler} />
                    </div>
                  </div>
                  <div className="col-md-12">
                    <div className="mb-3">
                      <label>{t('CAUSALE')}</label>
                      <select className="form-select from-control d-40px" name="id_cause" onChange={(e) => { setIdCausalMovementHandler(e.target.value) }}>
                        {causalMovements && causalMovements.map((data, index) => (
                          <option key={index} value={data?.id_cause}>{data?.description}</option>
                        ))}
                      </select>
                    </div>
                  </div>
                </div>
              </div>
              <div className="modal-footer">
                <button className="btn p-0 mx-2" onClick={closeModelHandler}>
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="30"
                    height="30"
                    viewBox="0 0 44 44"
                  >
                    <defs></defs>
                    <g id="Livello_2" data-name="Livello 2">
                      <g id="Livello_1-2" data-name="Livello 1">
                        <g id="Raggruppa_98" data-name="Raggruppa 98">
                          <path
                            fill="#132243"
                            id="ic_cancel_24px"
                            data-name="ic cancel 24px"
                            className="cls-1"
                            d="M22,0A22,22,0,1,0,44,22,22,22,0,0,0,22,0ZM33,29.9,29.9,33,22,25.1,14.1,33,11,29.9,18.9,22,11,14.1,14.1,11,22,18.9,29.9,11,33,14.1,25.1,22Z"
                          ></path>
                        </g>
                      </g>
                    </g>
                  </svg>
                </button>

                <button className="btn p-0 mx-2" onClick={updateGenericMovementHandler}>
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="30"
                    height="30"
                    viewBox="0 0 43.82 43.81"
                  >
                    <defs></defs>
                    <g id="Livello_2" data-name="Livello 2">
                      <g id="Livello_1-2" data-name="Livello 1">
                        <g id="Raggruppa_98" data-name="Raggruppa 98">
                          <path
                            fill="#132243"
                            id="Sottrazione_13"
                            data-name="Sottrazione 13"
                            className="cls-1"
                            d="M21.91,43.81A21.9,21.9,0,0,1,0,21.91,21.92,21.92,0,0,1,21.91,0,21.91,21.91,0,0,1,37.4,37.4,21.75,21.75,0,0,1,21.91,43.81ZM9.83,21.52,7.51,23.83,16.66,33,36.3,13.34,34,11,16.66,28.35,9.83,21.52Z"
                          ></path>
                        </g>
                      </g>
                    </g>
                  </svg>
                </button>
              </div>
            </div>
          </div>
        </div>
        <div
          className="modal fade"
          id="productModal"
          tabIndex={-1}
          aria-labelledby="productModalLabel"
          aria-hidden="true"
          show="true"
        >
          <div className="modal-dialog modal-dialog-centered">
            <div className="modal-content">
              <div className="modal-body">
                <div className="col-md-6">
                  <div className="mb-3">
                    <label>{t('Barcode')}</label>
                    <input type="text" id="product_barcode_id" className="form-control h-40px" name="product_barcode" value={productBarCode} onChange={(e) => setProductBarCode(e.target.value)} ref={inputRefBarcode} />
                  </div>
                </div>
                <div className="row">
                  <div className="col-md-6">
                    <div className="mb-3">
                      <label>{t('Scanned weight')}</label>
                      <input type="text" className="form-control h-40px" name="product_scanned_weight" value={scanedWeight == 0 ? '' : scanedWeight} />
                    </div>
                  </div>
                  <div className="col-md-6">
                    <div className="mb-3">
                      <label>{t('Scanned Pieces')}</label>
                      <input type="text" className="form-control h-40px" name="product_scanned_pieces" value={scanedProducts == 0 ? '' : scanedProducts} />
                    </div>
                  </div>
                </div>
                <div className="mb-3">
                  <label>{t('Batchcode')}</label>
                  <input type="text" className="form-control h-40px" name="product_batch_code" value={product?.batch_code} />
                </div>
                <div className="col-md-12">
                  <div className="mb-3">
                    <label>{t('Pieces in batch table')}</label>
                    <input type="text" className="form-control h-40px" name="product_pieces" value={productPieces} onChange={(e) => {setProductPieces(e.target.value)}} />
                  </div>
                </div>
                <div className="mb-3">
                  <label>{t('Weight')}</label>
                  <input type="text" className="form-control h-40px" name="product_weight" value={productWeight} onChange={(e) => {setProductWeight(e.target.value)}} />
                </div>
                <div className="col-md-12">
                  <div className="mb-3">
                    <label>{t('Article ID')}</label>
                    <input type="text" className="form-control h-40px" name="product_article_id" value={product?.id_article} />
                  </div>
                </div>
                <div className="col-md-12">
                  <div className="mb-3">
                    <label>{t('Description')}</label>
                    <input type="text" className="form-control h-40px" name="product_description" value={product?.product_name} />
                  </div>
                </div>
              </div>
              <div className="modal-footer">
                <button className="btn p-0 mx-2" onClick={closeProductModelHandler}>
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="30"
                    height="30"
                    viewBox="0 0 44 44"
                  >
                    <defs></defs>
                    <g id="Livello_2" data-name="Livello 2">
                      <g id="Livello_1-2" data-name="Livello 1">
                        <g id="Raggruppa_98" data-name="Raggruppa 98">
                          <path
                            fill="#132243"
                            id="ic_cancel_24px"
                            data-name="ic cancel 24px"
                            className="cls-1"
                            d="M22,0A22,22,0,1,0,44,22,22,22,0,0,0,22,0ZM33,29.9,29.9,33,22,25.1,14.1,33,11,29.9,18.9,22,11,14.1,14.1,11,22,18.9,29.9,11,33,14.1,25.1,22Z"
                          ></path>
                        </g>
                      </g>
                    </g>
                  </svg>
                </button>

                <button className="btn p-0 mx-2" onClick={updateScannedProductHandler}>
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="30"
                    height="30"
                    viewBox="0 0 43.82 43.81"
                  >
                    <defs></defs>
                    <g id="Livello_2" data-name="Livello 2">
                      <g id="Livello_1-2" data-name="Livello 1">
                        <g id="Raggruppa_98" data-name="Raggruppa 98">
                          <path
                            fill="#132243"
                            id="Sottrazione_13"
                            data-name="Sottrazione 13"
                            className="cls-1"
                            d="M21.91,43.81A21.9,21.9,0,0,1,0,21.91,21.92,21.92,0,0,1,21.91,0,21.91,21.91,0,0,1,37.4,37.4,21.75,21.75,0,0,1,21.91,43.81ZM9.83,21.52,7.51,23.83,16.66,33,36.3,13.34,34,11,16.66,28.35,9.83,21.52Z"
                          ></path>
                        </g>
                      </g>
                    </g>
                  </svg>
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>

    </>
  );
};

export default Movimentazioni;
