import ZebraBrowserPrintWrapper from "zebra-browser-print-wrapper";
import React from "react";
import { SAVE } from "../Utils/SVG";

function PrintDryProduct(props) {
  const {
    productId,
    productBatchId,
    name,
    weight,
    pieces,
    productExpireDate,
    productArrivalDate,
    isSaveBtn = false,
    onSubmitHandler,
    barcode,
  } = props;

  let labelQty = parseInt(pieces);

  async function printLabel(e, data) {
    e.stopPropagation();
    while(labelQty > 0){
      try {
        // Create a new instance of the object
        const browserPrint = new ZebraBrowserPrintWrapper();
        // Select default printer
        const defaultPrinter = await browserPrint.getDefaultPrinter();
        // Set the printer
        browserPrint.setPrinter(defaultPrinter);
        // Check if the printer is ready
        const printerStatus = await browserPrint.checkPrinterStatus();
  
        if (printerStatus.isReadyToPrint) {
          // ZPL script to print a qr code
  
          let piccola_alt = `^XA
                    ~TA000
                    ~JSN
                    ^LT0
                    ^MNW
                    ^MTT
                    ^PON
                    ^PMN
                    ^LH0,0
                    ^JMA
                    ^PR6,6
                    ~SD15
                    ^JUS
                    ^LRN
                    ^CI27
                    ^PA0,1,1,0
                    ^XZ
                    ^XA
                    ^MMT
                    ^PW400
                    ^LL200
                    ^LS0
                    ^FT275,203^BQN,2,5
                    ^FH\^FDMA,${data?.productBarcodeId ?? ""}^FS
                    ^FT15,35^A0N,17,18^FH\^CI28^FD${data?.productNameRowOne ?? ""}^FS^CI27
                    ^FT15,56^A0N,17,18^FH\^CI28^FD${ data?.productNameRowTwo ?? ""}^FS^CI27
                    ^FT15,77^A0N,17,18^FH\^CI28^FD${productNameRowThree ?? ""}^FS^CI27
                    ^FT340,75^A0N,17,18^FH\^CI28^FD${ data?.productId ?? ""}^FS^CI27
                    ^FT16,102^A0N,14,15^FH\^CI28^FDIngresso/Arriv.^FS^CI27
                    ^FT0,105^A0N,17,18^FB250,1,4,R^FH\^CI28^FD${ data?.productArrivalDate ?? ""}^FS^CI27
                    ^FT16,126^A0N,14,15^FH\^CI28^FDScad./Exp. Date^FS^CI27
                    ^FT0,129^A0N,17,18^FB250,1,4,R^FH\^CI28^FD${data?.productExpireDate ?? ""}^FS^CI27
                    ^FT16,150^A0N,14,15^FH\^CI28^FDLotto/Batch^FS^CI27
                    ^FT0,153^A0N,17,18^FB251,1,4,R^FH\^CI28^FD${data?.productBatchId ?? ""}^FS^CI27
                    ^FT16,174^A0N,14,15^FH\^CI28^FDPeso netto/Net weight^FS^CI27
                    ^FT0,177^A0N,17,18^FB252,1,4,R^FH\^CI28^FD${data?.productNetWeight ?? ""} kg^FS^CI27
                    ^PQ1,0,1,Y
                    ^XZ`;
          browserPrint.print(piccola_alt);
          console.log("Print succes");
          labelQty--;
        } else {
          console.log("Error/s", printerStatus.errors);
          setTimeout(function(piccola_alt) {
              browserPrint.print(piccola_alt);
          }, 640);
        }
      } catch (error) {
        throw new Error(error);
      }
    }
    
  }

  let productNameRowOne;
  let productNameRowTwo;
  let productNameRowThree;

  const nameSplit = (name) => {
    let arr = name.split("");
    let first = arr.slice(0, 42);
    let second = arr.slice(42, 84);
    let third = arr.slice(84);
    productNameRowOne = first.join("");
    productNameRowTwo = second.join("");
    productNameRowThree = third.join("");
  };
  if (name !== undefined) {
    nameSplit(name);
  }

  const data = {
    productNameRowOne: productNameRowOne,
    productNameRowTwo: productNameRowTwo,
    productNameRowThree: productNameRowThree,
    productId: productId,
    productArrivalDate: productArrivalDate,
    productExpireDate: productExpireDate,
    productBatchId: productBatchId,
    productNetWeight: weight,
    productBarcodeId: barcode,
  };

  React.useLayoutEffect(() => {
    // printLabel();
  }, []);

  return (
    <>
      {isSaveBtn ? (
        <button
          // href="#"
          className="btn"
          id="rightBtn"
          onClick={(e) => {
            onSubmitHandler();
            printLabel(e, data);
          }}
          // title={t("Save")}
        >
          <SAVE />
        </button>
      ) : (
        <button className="printbtn" onClick={(e) => {
          printLabel(e, data);
        }}>
          <i className="far fa-print"></i>
        </button>
      )}
    </>
  );
}

export default PrintDryProduct;
