import React, { useState } from "react";
import { Link, NavLink } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";

import { USERPROFILEICON, NAVTOGGLE } from "../Utils/SVG";
import Translator from "../../components/translation/translate";

const Header = ({ title, links }) => {
  const { userId } = useSelector((state) => state.auth);
  const loginTime = new Date().toLocaleString();

  return (
    <>
      <div className="headerdash">
        <nav className="navbar navbar-expand-lg p-0 w-100">
          <button
            className="navtoggle"
            data-bs-toggle="offcanvas"
            data-bs-target="#offcanvasExample"
            aria-controls="offcanvasExample"
          >
            <NAVTOGGLE />
          </button>

          <nav
            aria-label="breadcrumb"
            className="d-none d-md-flex ms-2 ms-md-3"
          >
            <ol className="breadcrumb mb-0">
              {links.length > 0 &&
                links.map((link, index) => (
                  <li key={index} className="breadcrumb-item">
                    <Link to={link.to}>{link.name} </Link>
                  </li>
                ))}
              <li className="breadcrumb-item active" aria-current="page">
                {title}
              </li>
            </ol>
          </nav>

          <Translator />

          <div className="headerDashboard-right ms-2 ms-md-3">
            <div className="headerDashboard-user">
              <USERPROFILEICON />
              <div className="headerDashboard-text">
                <span>ID : {userId}</span>
                <small>{loginTime}</small>
              </div>
            </div>
          </div>
        </nav>
      </div>
    </>
  );
};

export default Header;
