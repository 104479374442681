export const Genders = ["Maschio", "Femmina"];

export const addModelClass = (idName) => {
  document.body.classList.add("modal-open");
  document.getElementById(idName).classList.add("show");
  document.getElementById(idName).style.display = "block";
  document.getElementById(idName).style.backgroundColor =
    " rgb(63 61 61 / 70%)";
};

export const removeModelClass = (idName) => {
  document.body.classList.remove("modal-open");
  document.getElementById(idName).classList.remove("show");
  document.getElementById(idName).style.display = "none";
  document.getElementById(idName).style.backgroundColor = "";
  document.querySelectorAll(".modal-backdrop").forEach(el => el.remove());
};

export const openProductionModel = (editdata) => {
  document.body.classList.add("modal-open");
  document.body.style.overflow = "hidden";
  document.body.style.paddingRight = "15px";
  document.getElementById(editdata).style.display = "block";
  document.getElementById(editdata).style.paddingRight = "1px";
  // document.getElementById(editdata).classList.remove("fade");
  document.getElementById(editdata).classList.add("show");
  // document.getElementById(editdata).role.add("dialog");
};

export const closeProductionModel = (editdata) => {
  document.body.classList.remove("modal-open");
  document.body.style.overflow = "";
  document.body.style.paddingRight = "";
  document.getElementById(editdata).style.display = "";
  document.getElementById(editdata).style.paddingRight = "";
  // document.getElementById(editdata).classList.add("fade");
  document.getElementById(editdata).classList.remove("show");
};

export const capitalizeFirstLetter = (string) => {
  if (!string || string?.length === 0) {
    return;
  }
  string = string.toLowerCase();

  return string.charAt(0).toUpperCase() + string.slice(1);
};

export const escapeRegexCharacters = (str) => {
  return str.replace(/[.*+?^${}()|[\]\\]/g, "\\$&");
};

export const getFilteredProducts = (value, products) => {
  // console.log(value, products);
  const escapedValue = escapeRegexCharacters(value.trim());
  console.log(escapedValue);
  const regex = new RegExp(escapedValue, "i");
  // console.log(escapedValue.search(products[0]));
  return products.filter((product) => regex.test(product));
};

export const convertDate = (date) => {
  date = date.split("/");
  let day = date[0];
  let month = date[1];
  let year = date[2];
  let mydate = new Date(month + "-" + day + "-" + year);
  return mydate;
};

export const convertExpiryDate = (date) => {
  if (date) {
    let newDate = date.split("/");
    let day = newDate[0];
    let month = newDate[1];
    let year = newDate[2];
    let mydate = new Date(year + "-" + month + "-" + day);
    return mydate;
  } else {
    return "";
  }
};

export const formatDate = (date) => {
  if (date) {
    try {
      let dateList = date.split("-");
      let newDate = dateList[2] + "/" + dateList[1] + "/" + dateList[0];
      return newDate;
    } catch (e) {
      let newDate = date.toLocaleDateString();
      return newDate;
    }
  }
  return "";
};

export const changeDateFormat = (date, valueType) => {
  if (date) {
    if (valueType === "object") {
      date = date.toLocaleDateString();
      let newDate = date.replaceAll("/", "-");
      return newDate;
    } else if (valueType === "str") {
      let dateList = date.split("-");
      let day = dateList[2];
      let month = dateList[1];
      let year = dateList[0];
      return day + "-" + month + "-" + year;
    } else {
      return "";
    }
  } else {
    return "";
  }
};

export const checkDateIsExpired = (dateStr) => {
  if(dateStr){
    let currentDate = new Date()
    currentDate.setHours(0,0,0,0);
    let dateObj = new Date(dateStr.split('/')[2], dateStr.split('/')[1], dateStr.split('/')[0])
    dateObj.setHours(0,0,0,0);
    let isExpired = currentDate <= dateObj ? false : true
    return isExpired
  }else{
    return false
  }
}