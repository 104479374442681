import {
  SET_ORDER_REF,
  SET_PROVIDERS,
  GET_TOP_PROVIDERS,
  CLOSED_PROVIDERS,
} from "./actionTypes";

const initialState = {
  orderRef: "",
  providers: [],
  topProviders: [],
  closedProviders: [],
};

const setOrderRefReducer = (state = initialState, { type, payload }) => {
  switch (type) {
    case SET_ORDER_REF:
      return {
        ...state,
        ...payload,
      };
    case SET_PROVIDERS:
      return {
        ...state,
        ...payload,
      };
    case GET_TOP_PROVIDERS:
      return {
        ...state,
        ...payload,
      };
    case CLOSED_PROVIDERS:
      return {
        ...state,
        ...payload,
      };
    default:
      return state;
  }
};

export default setOrderRefReducer;
