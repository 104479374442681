import Dashboard from "../pages/dashboard/dashboard";
import Dashboard1 from "../pages/dashboard/Dashboard1";
import Dashboard2 from "../pages/dashboard/Dashboard2";
import Dashboard3 from "../pages/dashboard/Dashboard3";
import Dashboard4 from "../pages/dashboard/Dashboard4";

import Report from "../pages/dashboard/report";
import Ricevimento from "../pages/ricevimento/ricevimento";
import DettagliOrdine from "../pages/ricevimento/dettagli-ordine";

import Magazzino from "../pages/magazzino/Magazzino";
import ListaOdv from "../pages/magazzino/lista-odv";
import DettagliElencoOdv from "../pages/magazzino/dettagli-elenco-odv";
import Receipt from "../pages/magazzino/receipt";

// import Produzione from "../pages/produzione/produzione";
import Produzione from "../pages/produzione/produzione";

import ProduzioneAggiunta from "../pages/produzione/produzione-aggiunta";
import ProduzioneReimballare from "../pages/produzione/produzione-reimballare";
import ProduzioneModificare from "../pages/produzione/produzione-modificare";
import ProduzioneLista from "../pages/produzione/produzione-lista";

import Spedizione from "../pages/spedizione/spedizione";
import SpedizioneListaOdv from "../pages/spedizione/spedizione-lista-odv";
import Movimentazioni from "../pages/genric-movement/movimentazioni";

export const privateRoutes = [
  { path: "/", component: Dashboard },
  { path: "/dashboard1", component: Dashboard1 },
  { path: "/dashboard2", component: Dashboard2 },
  { path: "/dashboard3", component: Dashboard3 },
  { path: "/dashboard4", component: Dashboard4 },
  { path: "/report", component: Report },
  { path: "/ricezione", component: Ricevimento },
  { path: "/dettagli-ordine", component: DettagliOrdine },
  { path: "/produzione", component: Produzione },
  { path: "/produzione-aggiunta", component: ProduzioneAggiunta },
  { path: "/produzione-reimballare", component: ProduzioneReimballare },
  { path: "/produzione-modificare", component: ProduzioneModificare },
  { path: "/produzione-lista", component: ProduzioneLista },
  { path: "/raccolta", component: Magazzino },
  { path: "/lista-odv", component: ListaOdv },
  { path: "/dettagli-elenco-odv", component: DettagliElencoOdv },
  { path: "/receipt", component: Receipt },
  { path: "/spedizione", component: Spedizione },
  { path: "/spedizione-lista-odv", component: SpedizioneListaOdv },
  { path: "/movimentazioni", component: Movimentazioni },
  { path: "/report", component: Report },

];
