import { useState, useEffect } from "react";

const useMultiRequestLoader = (count) => {
  const [loading, setLoading] = useState(true);
  const [resCount, setResCount] = useState(0);

  useEffect(() => {
    if (resCount == count) {
      setLoading(false);
    }
  }, [resCount]);

  const updateResponse = () => {
    console.log(resCount);
    setResCount((prev) => prev + 1);
  };

  return {
    loading,
    updateResponse,
  };
};

export default useMultiRequestLoader;
