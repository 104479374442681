import ZebraBrowserPrintWrapper from "zebra-browser-print-wrapper-https";


export async function ProductionIsEanMeatLabel(printdata) {
  let productNameRowOne;
  let productNameRowTwo;
  let productNameRowThree;

  let note = printdata?.productNotesRowOne;

  if (note === " ") {
    note = note.trim();
  }

  let notes = {
    productNotesRowOne: null,
    productNotesRowTwo: null,
    productNotesRowThree: null,
    productNotesRowFour: null,
    productNotesRowFive: null,
    productNotesRowSix: null,
    productNotesRowSeven: null,
    productNotesRowEight: null,
    productNotesRowNine: null,
    productNotesRowTen: null,
    productNotesRowEleven: null,
    productNotesRowTwelve: null,
    productNotesRowThirteen: null,
  };

  const noteSplit = (note) => {
    let arr = null;
    try {
      arr = note.match(/.{1,35}/g);
    } catch (e) {
      arr = null;
    }

    if (arr) {
      const [
        productNotesRowOne,
        productNotesRowTwo,
        productNotesRowThree,
        productNotesRowFour,
        productNotesRowFive,
        productNotesRowSix,
        productNotesRowSeven,
        productNotesRowEight,
        productNotesRowNine,
        productNotesRowTen,
        productNotesRowEleven,
        productNotesRowTwelve,
        productNotesRowThirteen,
      ] = arr;

      notes = {
        productNotesRowOne: productNotesRowOne ? productNotesRowOne : null,
        productNotesRowTwo: productNotesRowTwo ? productNotesRowTwo : null,
        productNotesRowThree: productNotesRowThree ? productNotesRowThree : null,
        productNotesRowFour: productNotesRowFour ? productNotesRowFour : null,
        productNotesRowFive: productNotesRowFive ? productNotesRowFive : null,
        productNotesRowSix: productNotesRowSix ? productNotesRowSix : null,
        productNotesRowSeven: productNotesRowSeven ? productNotesRowSeven : null,
        productNotesRowEight: productNotesRowEight ? productNotesRowEight : null,
        productNotesRowNine: productNotesRowNine ? productNotesRowNine : null,
        productNotesRowTen: productNotesRowTen ? productNotesRowTen : null,
        productNotesRowEleven: productNotesRowEleven ? productNotesRowEleven : null,
        productNotesRowTwelve: productNotesRowTwelve ? productNotesRowTwelve : null,
        productNotesRowThirteen: productNotesRowThirteen ? productNotesRowThirteen : null
      };
    }
  };

  const nameSplit = (name) => {
    let arr = name.split("");
    let first = arr.slice(0, 56);
    let second = arr.slice(56, 112);
    let third = arr.slice(112);
    productNameRowOne = first.join("");
    productNameRowTwo = second.join("");
    productNameRowThree = third.join("");
  };

  if (printdata?.productNameRowOne) {
    nameSplit(printdata?.productNameRowOne);
  }

  if (note && note.match(/.{1,35}/g)) {
    noteSplit(note);
  }
  try {
    // Create a new instance of the object
    const browserPrint = new ZebraBrowserPrintWrapper();
    // Select default printer
    const defaultPrinter = await (browserPrint.getDefaultPrinter());
    // Set the printer
    browserPrint.setPrinter(defaultPrinter);
    // Check if the printer is ready
    const printerStatus = await browserPrint.checkPrinterStatus();

    if (printerStatus.isReadyToPrint) {
      // ZPL script to print a qr code

      let grande_meat = `^XA
                      ~TA000
                    ~JSN
                    ^LT0
                    ^MNW
                    ^MTT
                    ^PON
                    ^PMN
                    ^LH0,0
                    ^JMA
                    ^PR6,6
                    ~SD15
                    ^JUS
                    ^LRN
                    ^CI27
                    ^PA0,1,1,0
                    ^XZ
                    ^XA
                    ^MMT
                    ^PW831
                    ^LL799
                    ^LS0
                    ^FT626,769^BQN,2,7
                    ^FH\^FDHA,${printdata?.productBarcodeId ? printdata?.productBarcodeId : ""}^FS
                    ^FT115,193^A0N,28,28^FH\^CI28^FD${productNameRowOne ? productNameRowOne : ""}^FS^CI27
                    ^FT115,228^A0N,28,28^FH\^CI28^FD${productNameRowTwo ? productNameRowTwo : ""}^FS^CI27
                    ^FT115,270^A0N,23,23^FH\^CI28^FD${printdata?.productNameEnglish ? printdata?.productNameEnglish : ""}^FS^CI27
                    ^FT16,193^A0N,28,28^FH\^CI28^FD${printdata?.productId ? printdata?.productId : ""}^FS^CI27
                    ^FO16,293^GB424,264,1^FS
                    ^FT16,315^AAN,18,10^FH\^FD${notes?.productNotesRowOne ? notes?.productNotesRowOne : ""}^FS
                    ^FT16,333^AAN,18,10^FH\^FD${notes?.productNotesRowTwo ? notes?.productNotesRowTwo : ""}^FS
                    ^FT16,351^AAN,18,10^FH\^FD${notes?.productNotesRowThree ? notes?.productNotesRowThree : ""}^FS
                    ^FT16,369^AAN,18,10^FH\^FD${notes?.productNotesRowFour ? notes?.productNotesRowFour : ""}^FS
                    ^FT16,387^AAN,18,10^FH\^FD${notes?.productNotesRowFive ? notes?.productNotesRowFive : ""}^FS
                    ^FT16,405^AAN,18,10^FH\^FD${notes?.productNotesRowSix ? notes?.productNotesRowSix : ""}^FS
                    ^FT16,423^AAN,18,10^FH\^FD${notes?.productNotesRowSeven ? notes?.productNotesRowSeven : ""}^FS
                    ^FT16,441^AAN,18,10^FH\^FD${notes?.productNotesRowEight ? notes?.productNotesRowEight : ""}^FS
                    ^FT16,459^AAN,18,10^FH\^FD${notes?.productNotesRowNine ? notes?.productNotesRowNine : ""}^FS
                    ^FT16,477^AAN,18,10^FH\^FD${notes?.productNotesRowTen ? notes?.productNotesRowTen : ""}^FS
                    ^FT16,495^AAN,18,10^FH\^FD${notes?.productNotesRowEleven ? notes?.productNotesRowEleven : ""}^FS
                    ^FT16,513^AAN,18,10^FH\^FD${notes?.productNotesRowTwelve ? notes?.productNotesRowTwelve : ""}^FS
                    ^FT16,531^AAN,18,10^FH\^FD${notes?.productNotesRowThirteen ? notes?.productNotesRowThirteen : ""}^FS
                    ^FT472,315^AAN,18,10^FH\^FDLotto/Batch^FS
                    ^FPH,1^FT627,332^A0N,28,28^FH\^CI28^FD${printdata?.productBatchId ? printdata?.productBatchId : ""}^FS^CI27
                    ^FT464,347^AAN,18,10^FH\^FDProd. il/Prod. on^FS
                    ^FPH,1^FT699,353^A0N,25,23^FH\^CI28^FD${printdata?.productArrivalDate ? printdata?.productArrivalDate : ""}^FS^CI27
                    ^FT464,378^AAN,18,10^FH\^FDScadenza/Exp. Date^FS
                    ^FPH,1^FT699,384^A0N,25,23^FH\^CI28^FD${printdata?.productExpireDate ? printdata?.productExpireDate : ""}^FS^CI27
                    ^FT464,418^AAN,18,10^FH\^FDPeso Lordo/Gross Wgt^FS
                    ^FPH,1^FT728,424^A0N,28,28^FH\^CI28^FD${printdata?.productGrossWeight ? printdata?.productGrossWeight : ""} kg^FS^CI27
                    ^FT16,690^AAN,18,10^FH\^FDSezionato/Cut^FS
                    ^FPH,1^FT288,696^A0N,28,28^FH\^CI28^FD${printdata?.productCutCountry ? printdata?.productCutCountry : ""} - ${printdata?.productCeLabel2 ? printdata?.productCeLabel2 : ""}^FS^CI27
                    ^FT16,722^AAN,18,10^FH\^FDAuricolare/Ear Tag^FS
                    ^FPH,1^FT288,728^A0N,28,28^FH\^CI28^FD${printdata?.productEarTag ? printdata?.productEarTag : ""}^FS^CI27
                    ^FT16,594^AAN,18,10^FH\^FDNato/Born^FS
                    ^FPH,1^FT288,600^A0N,28,28^FH\^CI28^FD${printdata?.productBornCountry ? printdata?.productBornCountry : ""}^FS^CI27
                    ^FT16,626^AAN,18,10^FH\^FDAllevato/Raised^FS
                    ^FPH,1^FT288,632^A0N,28,28^FH\^CI28^FD${printdata?.productRaisedCountry ? printdata?.productRaisedCountry : ""}^FS^CI27
                    ^FT16,658^AAN,18,10^FH\^FDMacellato/Slaughtered^FS
                    ^FPH,1^FT288,664^A0N,28,28^FH\^CI28^FD${printdata?.productSlaughteredCountry ? printdata?.productSlaughteredCountry : ""} - ${printdata?.productCeLabel ? printdata?.productCeLabel : ""}^FS^CI27
                    ^FT464,450^AAN,18,10^FH\^FDTara/Tare^FS
                    ^FPH,1^FT728,456^A0N,28,28^FH\^CI28^FD${printdata?.productTare ? printdata?.productTare : ""} kg^FS^CI27
                    ^FT464,482^AAN,18,10^FH\^FDPeso Netto/Net Wgt^FS
                    ^FPH,1^FT728,488^A0N,28,28^FH\^CI28^FD${printdata?.productNetWeight ? printdata?.productNetWeight : ""} kg^FS^CI27
                    ^FT656,136^AAN,18,10^FH\^FDTemp^FS
                    ^FPH,1^FT709,139^A0N,25,25^FH\^CI28^FD${printdata?.productTemperature ? printdata?.productTemperature : ""}  °C^FS^CI27
                    ^FT312,780^A0N,14,20^FH\^CI28^FD(01)${printdata?.productEanGtin ? printdata?.productEanGtin : ""}(15)${printdata?.productExpireDate ? printdata.productExpireDate : ""}(3103)${printdata.productNetWeight ? printdata.productNetWeight : ""}(10)${printdata.productBatchId ? printdata.productBatchId : ""}^FS^CI27
                    ^BY1,3,43^FT18,796^BCN,,N,N
                    ^FH\^FD>;>801${printdata?.productEanGtin ? printdata?.productEanGtin : ""}15${printdata?.productExpireDate ? printdata.productExpireDate : ""}3103${printdata.productNetWeight ? printdata.productNetWeight : ""}10${printdata.productBatchId ? printdata.productBatchId : ""}^FS
                    ^PQ1,0,1,Y
                    ^XZ`;

      browserPrint.print(grande_meat);
      console.log("Print success");
      // labelsQty--;
    }
    else {
      console.log("Error/s", printerStatus.errors);
      setTimeout(function (grande_meat) {
        browserPrint.print(grande_meat);
      }, 640);
    }
  }
  catch (error) {
    throw new Error(error);
  }
}