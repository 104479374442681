import React, { useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { useLocation, useHistory } from "react-router-dom";
import { Link } from "react-router-dom";
import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/css";
import DatePicker from "react-multi-date-picker";
import "swiper/css/navigation";
import Autosuggest from "react-autosuggest";
import { Navigation } from "swiper";
import "swiper/css";
import moment from "moment";
import { useTranslation } from "react-i18next";
import SideBar from "../../components/Sidebar/sideBar";
import Header from "../../components/header/header";
import useRequest from "../../hooks/useRequest";
import useMultiRequestLoader from "../../hooks/useMultiRequestLoader";
import LoadingSpinner from "../../components/loader/LoadingSpinner";
import { getFilteredProducts } from "../../components/Utils/Utils";
import { updateOrderRef } from "../../store/product/action";

const Ricevimento = () => {
  const history = useHistory();
  const [t, i18n] = useTranslation("translation");
  const storedLanguage = localStorage.getItem("i18nextLng");
  const { loading, updateResponse } = useMultiRequestLoader(4);
  const { token } = useSelector((state) => state.auth);

  const { userRoleId } = useSelector((state) => state.auth);

  const [newOrders, setNewOrders] = useState([]);
  const [orders, setOrders] = useState([]);
  const [providers, setProviders] = useState([]);
  const [topProviders, setTopProviders] = useState([]);
  const [closedProviders, setClosedProviders] = useState([]);

  const [selectIndex, setSelectIndex] = useState(1);
  const [searchQuery, setSearchQuery] = useState("");
  const [providerSearchQuery, setProviderSearchQuery] = useState("");

  const [selectedProvider, setSelectedProvider] = useState("");
  const [orderRef, setOrderRef] = useState("");
  const [suggestionList, setSuggestionList] = useState([]);
  const [providerSuggestionList, setProviderSuggestionList] = useState([]);

  const [selectedProviderName, setSelectedProviderName] = useState("");
  const [toDate, setToDate] = useState("");
  const [fromDate, setFromDate] = useState("");
  const [providerName, setProviderName] = useState("");
  const [searchQueryFilter, setSearchQueryFilter] = useState("");
  const [successStatus, setSuccessStatus] = useState(false);
  const [showDeleteButton, setShowDeleteButton] = useState(false);

  const dispatch = useDispatch();

  const { request, response } = useRequest(true, updateResponse);
  const { request: requestProviders, response: responseProviders } = useRequest(
    true,
    updateResponse
  );  // deprecated

  const { request: orderRequest, response: orderResponse } = useRequest(
    true,
    updateResponse
  );
  const { request: requestTopProviders, response: responseTopProviders } = useRequest(
    true,
    updateResponse
  );
  const { request: requestCloseOrderProviders, response: responseCloseOrderProviders } = useRequest(
    true,
    updateResponse
  );
  const { request: onPageOrderRequest, response: onPageOrderResponse } = useRequest();
  const { request: closeOrderRequest, response: closeOrderResponse } = useRequest();
  const { request: orderSearchRequest, response: orderSearchResponse } = useRequest();
  const { request: providerSearchRequest, response: providerSearchResponse } = useRequest();

  useEffect(() => {
    request("GET", "order-dates");
    requestTopProviders('GET', 'top-providers')
    requestCloseOrderProviders('GET', 'close-order-providers')
    document.title = "HQF - Ricevimento";
    localStorage.setItem("orderRef", "");
    dispatch(updateOrderRef({ orderRef }));
    document.body.classList.remove("production");
    document.body.classList.remove("shipmentbody");
  }, [storedLanguage]);

  useEffect(() => {
    if (response) {
      if (response.status === "SUCCESS") {
        setNewOrders(response?.data?.dates);
        setSelectIndex(1);
        orderRequest("POST", "order-view", {
          is_previous: response?.data?.dates[1].is_previous,
          delivery_date: response?.data?.dates[1].delivery_date,
        });
        if (selectedProviderName?.length > 0) {
          setSearchQuery(selectedProviderName);
        }
      }
    }
  }, [response]);

  useEffect(() => {
    if (orderResponse) {
      if (orderResponse.status === "SUCCESS") {
        setOrders(orderResponse?.data?.orders);
        updateResponse();
      }
    }
  }, [orderResponse]);

  useEffect(() => {
    if (responseTopProviders) {
      if (responseTopProviders.status === "SUCCESS") {
        setTopProviders(responseTopProviders.data.top_providers);
      }
    }
  }, [responseTopProviders]);

  // deprected
  useEffect(() => {
    if (responseCloseOrderProviders) {
      if (responseCloseOrderProviders.status === "SUCCESS") {
        setClosedProviders(responseCloseOrderProviders.data.closed_providers);
      }
    }
  }, [responseCloseOrderProviders]);

  useEffect(() => {
    if (providers.length > 0) {
      setSuggestionList(providers);
    }
  }, [providers]);

  const updateCloseOrderStates = () => {
    // setSearchQueryFilter("");
    setSuccessStatus(false);
    setProviderName("");
    setToDate("");
    setFromDate("");
  };

  useEffect(() => {
    if (onPageOrderResponse) {
      if (onPageOrderResponse.status === "SUCCESS") {
        setOrders(onPageOrderResponse?.data?.orders);
      }
    }
  }, [onPageOrderResponse]);

  useEffect(() => {
    if (orderSearchResponse) {
      if (orderSearchResponse.status === "SUCCESS") {
        setOrders(orderSearchResponse?.data?.provider_orders);
      }
    }
  }, [orderSearchResponse]);

  useEffect(() => {
    if (providerName && !fromDate && !toDate) {
      setSearchQueryFilter(providerName);
      closeOrderRequest("POST", "close-orders", {
        provider_name: providerName,
        from_date: "",
        to_date: "",
      });
    } else if (providerName && fromDate && toDate) {
      if (moment(fromDate).isSameOrBefore(toDate)) {
        closeOrderRequest("POST", "close-orders", {
          provider_name: providerName,
          from_date: fromDate,
          to_date: toDate,
        });
      }
    } else if (!providerName && fromDate && toDate) {
      if (moment(fromDate).isSameOrBefore(toDate)) {
        closeOrderRequest("POST", "close-orders", {
          provider_name: "",
          from_date: fromDate,
          to_date: toDate,
        });
      }
    }
  }, [providerName, fromDate, toDate]);

  useEffect(() => {
    if (closeOrderResponse) {
      if (closeOrderResponse.status === "SUCCESS") {
        setOrders(closeOrderResponse.data.close_orders);
        setSuccessStatus(true);
      }
    }
  }, [closeOrderResponse]);

  useEffect(() => {
    if (providerSearchResponse && providerSearchResponse.status === "SUCCESS") {
      const newProviders = providerSearchResponse.data.providers.map(
        (provider) => provider.provider_name
      );
      setProviderSuggestionList(newProviders)
    }
  }, [providerSearchResponse])

  const onSlideClick = (i) => {
    onPageOrderRequest("POST", "order-view", {
      is_previous: newOrders[i].is_previous,
      delivery_date: newOrders[i].delivery_date,
    });
    setSelectIndex(i);
    updateCloseOrderStates();
    setSelectedProvider("");
    setSelectedProviderName("");
    setSearchQuery("");
  };

  const searchProvider = (e) => {
    e.preventDefault();
  };

  const providerFilterHandler = (name) => {
    setSelectedProvider((prev) => (prev === name ? "" : name));
    const provider_name = name === selectedProvider ? "" : name;
    orderSearchRequest("POST", "order-search", {
      provider_name,
    });

    updateCloseOrderStates();
    setSelectedProviderName("");
    setSearchQuery("");
  };

  const onChange = (e) => {
    e.preventDefault();
    setProviderSearchQuery(e.target.value)
    // setSearchQuery(e.target.value);
  };

  const onChangeSearchFilter = (e) => {
    e.preventDefault();
    setSearchQueryFilter(e.target.value);
  };

  const onSuggestionsFetchRequested = ({ value, reason }) => {
    providerSearchRequest("GET", `search-providers?name=${value}`)
  };

  const onSuggestionsFetchCloseOrderRequested = ({ value, reason }) => {
    const filteredProducts = getFilteredProducts(value, closedProviders);

    setSuggestionList(filteredProducts);
  };

  const onSuggestionsClearRequested = () => {
    setProviderSuggestionList(providerSuggestionList);
  };

  const onSuggestionsClearCloseOrderRequested = () => {
    setSuggestionList(closedProviders);
  };

  const renderSuggestion = (suggestion) => suggestion;

  const getSuggestionValue = (suggestion) => {
    setProviderSuggestionList(providerSuggestionList);
    setSelectedProviderName(suggestion);
    orderSearchRequest("POST", "order-search", {
      provider_name: suggestion,
    });
    setShowDeleteButton(false);
    updateCloseOrderStates();
    setSelectedProvider("");
    setSelectIndex("");
    return suggestion;
  };

  const getProviderName = (suggestion) => {
    setSuggestionList(closedProviders);
    setShowDeleteButton(true);
    setProviderName(suggestion);
    setSelectedProviderName("");
    setSearchQuery("");
    setSelectedProvider("");
    setSelectIndex("");
    return suggestion;
  };

  const inputProps = {
    placeholder: t("supplier name"),
    value: providerSearchQuery ? providerSearchQuery : "",
    onChange,
  };

  const inputPropsFilter = {
    placeholder: t("supplier name"),
    value: searchQueryFilter ? searchQueryFilter : "",
    onChange: onChangeSearchFilter,
  };

  const getBackDate = () => {
    let date = new Date();
    date.setFullYear(date.getFullYear() - 2);
    return date;
  };

  return (
    <>
      {userRoleId === 5 || userRoleId === 1 ? (
        <div className="dashboard_parent">
          {/* <!-- Sidebar --> */}
          <SideBar></SideBar>

          {loading && <LoadingSpinner />}

          <div className="dashboard_right">
            {/* <!-- dashboard header --> */}

            <Header
              title={t("receipt")}
              links={[{ to: "/ricezione", name: t("receipt") }]}
            />
            {/* <!-- dashboard Content --> */}

            <div className="dashboard_content">
              <div className="dashboard-container">
                <div className="topfix">
                  <div className="supplierFilter">
                    <div className="supplierSearch themeInput themeIS">
                      <label>{t("Search for open orders")}</label>
                      <form
                        onSubmit={searchProvider}
                        className="themeInput position-relative"
                      >
                        <Autosuggest
                          suggestions={providerSuggestionList}
                          onSuggestionsFetchRequested={
                            onSuggestionsFetchRequested
                          }
                          onSuggestionsClearRequested={
                            onSuggestionsClearRequested
                          }
                          // alwaysRenderSuggestions={true}
                          getSuggestionValue={getSuggestionValue}
                          renderSuggestion={renderSuggestion}
                          inputProps={inputProps}
                        />

                        {/* <div className="position-relative w-100">
                          <input
                            className="form-control"
                            type="text"
                            value={providerSearchQuery}
                            onChange={(e) => {
                              setProviderSearchQuery(e.target.value);
                              setIsProviderSearch(true)
                            }}
                          />

                          {isProviderSearch && <ul className="list-unstyled mb-0 filterCustom-drop">
                            {providers.length > 0 &&
                              providers.map((data, index) => (
                                <li
                                  key={index}
                                  onClick={() => searchProviderOrderHandler(data.provider_name)}
                                >
                                  {data.provider_name}
                                </li>
                              ))}
                          </ul>}
                        </div> */}
                        <button className="themeIS-btn" type="submit">
                          <i className="fas fa-search"></i>
                        </button>
                      </form>
                    </div>

                    <ul className="list-unstyled mb-0 supplierFilter-Link">
                      {topProviders &&
                        topProviders.map((provider, index) => (
                          <li
                            key={index}
                            style={{
                              cursor: "pointer",
                              color:
                                selectedProvider === provider.provider_name
                                  ? "#f9ab00"
                                  : "",
                            }}
                            onClick={() =>
                              providerFilterHandler(provider.provider_name)
                            }
                          >
                            {provider.provider_name}
                          </li>
                        ))}
                    </ul>

                    <div className="w-100 w-md-auto d-md-flex align-items-end ms-auto">
                      <div className="supplierSearch themeInput themeIS mb-2 mb-md-0">
                        <label>{t("Search for closed orders")}</label>
                        <form
                          onSubmit={searchProvider}
                          className="themeInput position-relative"
                        >
                          <Autosuggest
                            suggestions={suggestionList}
                            onSuggestionsFetchRequested={
                              onSuggestionsFetchCloseOrderRequested
                            }
                            onSuggestionsClearRequested={
                              onSuggestionsClearCloseOrderRequested
                            }
                            // alwaysRenderSuggestions={true}
                            getSuggestionValue={getProviderName}
                            renderSuggestion={renderSuggestion}
                            inputProps={inputPropsFilter}
                          />
                          <button className="themeIS-btn" type="submit">
                            <i className="fas fa-search"></i>
                          </button>
                        </form>
                      </div>

                      <div className="mb-2 mb-md-0">
                        <div className="themeInput themeIS date_box mx-md-4">
                          <DatePicker
                            placeholder="dalla data"
                            value={fromDate ? fromDate : getBackDate()}
                            onChange={(date) => {
                              date && setFromDate(date.format());
                              setSelectedProvider("");
                              setSearchQuery("");
                            }}
                            format="YYYY-MM-DD"
                          />
                          <button type="button" className="themeIS-btn">
                            <i className="far fa-calendar-alt"></i>
                          </button>
                        </div>
                      </div>

                      <div className="mb-2 mb-md-0">
                        <div className="themeInput themeIS date_box">
                          <DatePicker
                            placeholder="ad oggi"
                            value={toDate ? toDate : new Date()}
                            onChange={(date) => {
                              date && setToDate(date.format());
                              setSelectedProvider("");
                              setSearchQuery("");
                            }}
                            minDate={fromDate}
                            format="YYYY-MM-DD"
                          />
                          <button type="button" className="themeIS-btn">
                            <i className="far fa-calendar-alt"></i>
                          </button>
                        </div>
                      </div>
                    </div>
                  </div>

                  <div>
                    <Swiper
                      spaceBetween={15}
                      slidesPerView={"auto"}
                      navigation={true}
                      modules={[Navigation]}
                      className="odalist"
                    >
                      {newOrders &&
                        newOrders.map((newOrder, index) => (
                          <SwiperSlide key={index}>
                            {index === 0 ? (
                              <div
                                className="slidecard active"
                                onClick={() => onSlideClick(index)}
                              >
                                <button className="list-card">
                                  <div className="list-card-head">
                                    <span>{t("BACKWARDS")}</span>
                                    {/* <span>12/01/2021</span> */}
                                  </div>
                                  <div className="lc-newdata">
                                    <small>{t("Orders")}</small>
                                    <strong>{newOrder?.total_orders}</strong>
                                  </div>
                                </button>
                              </div>
                            ) : (
                              <div
                                className="slidecard"
                                onClick={() => onSlideClick(index)}
                              >
                                {/* "list-card bg-white" */}
                                <button
                                  className={`list-card ${index === selectIndex ? "bg-white" : ""
                                    } `}
                                >
                                  <div className="list-card-head">
                                    <span>{newOrder?.day_name}</span>
                                    <span>{newOrder?.delivery_date}</span>
                                  </div>

                                  <div className="lc-newdata">
                                    <small>{t("Orders")}</small>
                                    <strong>{newOrder?.total_orders}</strong>
                                  </div>
                                </button>
                              </div>
                            )}
                          </SwiperSlide>
                        ))}
                    </Swiper>
                  </div>
                </div>

                <div className="p-3">
                  <div className="orderList-wrap">
                    {orders &&
                      orders.map((order, index) => {
                        let cls;
                        if (!successStatus) {
                          cls =
                            order?.total_quantity ===
                              order?.total_missing_quantity_process
                              ? "text-danger"
                              : "text-warning";
                        } else {
                          cls = "text-success";
                        }

                        return (
                          <Link
                            to={{
                              pathname: "/ricevimento-lista-ordini",
                              state: {
                                order: order || null,
                                showDeleteButton: showDeleteButton,
                              },
                            }}
                            className="orderList-row"
                            key={index}
                          >
                            {cls !== "text-success" ? (
                              <div className="orderStatus">
                                <i className={`fa fa-caret-right ${cls} `}></i>
                              </div>
                            ) : (
                              ""
                            )}

                            <div className="orderno">
                              <label>{t("order n")}</label>
                              <h6>{order?.id_oda}</h6>
                            </div>
                            <div className="suppliername">
                              <label>{t("supplier")}</label>
                              {order?.provider_name ? (
                                <h6>{order?.provider_name}</h6>
                              ) : (
                                <h6>{order?.batch?.provider_name}</h6>
                              )}
                            </div>
                            <div className="ownername">
                              <label>{t("qty")}</label>
                              <h6>
                                {order?.total_pieces} pz / {order?.total_weight}
                                kg
                              </h6>
                            </div>
                            <div className="orderdate">
                              <label>{t("order date")}</label>
                              <h6>{order?.delivery_date}</h6>
                            </div>

                            {cls === "text-success" && (
                              <div className="orderStatus orderStatusLast">
                                <i className={`fa fa-caret-right ${cls} `}></i>
                              </div>
                            )}
                          </Link>
                        );
                      })}
                  </div>
                </div>
              </div>
              {/* <!-- dashboard-container  END --> */}
            </div>

            {/* <!-- dashboard_content END --> */}
          </div>
        </div>
      ) : (
        history.push("/")
      )}
    </>
  );
};

export default Ricevimento;
