import { Link } from "react-router-dom";
import React, { useState, useEffect } from "react";
import { Swiper, SwiperSlide } from "swiper/react";
import { useDispatch, useSelector } from "react-redux";
import { useTranslation } from "react-i18next";
import { useHistory } from "react-router-dom";
import { Navigation } from "swiper";
import Autosuggest from "react-autosuggest";
import "swiper/css";
import "swiper/css/navigation";
import "swiper/css";
import Swal from "sweetalert2";
import { toast } from "react-toastify";
import withReactContent from "sweetalert2-react-content";
import SideBar from "../../components/Sidebar/sideBar";
import Header from "../../components/header/header";
import useRequest from "../../hooks/useRequest";
import { getFilteredProducts } from "../../components/Utils/Utils";
import { generateBarCode } from "../../components/Utils/generateBarCode";
import LoadingSpinner from "../../components/loader/LoadingSpinner";

const MySwal = withReactContent(Swal);

const Produzione = () => {
  const history = useHistory();
  const [newJobs, setNewJobs] = useState([]);
  const [t] = useTranslation("translation");
  const [jobs, setJobs] = useState([]);
  const [names, setNames] = useState([]);

  const [searchQuery, setSearchQuery] = useState("");
  const [catalogSearchQuery, setCatalogSearchQuery] = useState("");
  const [selectIndex, setSelectIndex] = useState(1);
  const { userRoleId, role } = useSelector((state) => state.auth);
  const [selectedProviderName, setSelectedProviderName] = useState("");
  const [closedOrderKnow, setClosedOrderKnow] = useState(true);

  const [suggestionList, setSuggestionList] = useState([]);
  const [globalSuggestionList, setGlobalSuggestionList] = useState([]);
  const [closedSuggestionList, setClosedSuggestionList] = useState([]);
  const [catalogSuggestionList, setCatalogSuggestionList] = useState([]);
  const [categoryDescription, setCategoryDescription] = useState("");

  const [globalClosedSuggestionList, setGlobalClosedSuggestionList] = useState(
    []
  );
  const [selectedClosedOrder, setSelectedClosedOrder] = useState("");
  const [searchQueryFilter, setSearchQueryFilter] = useState("");
  const [idJob, setIdJob] = useState("");

  const { request, response, loading } = useRequest();
  const { request: jobDeleteRequest, response: jobDeleteResponse } =
    useRequest();
  const { request: nameRequest, response: nameResponse } = useRequest();
  const { request: closedOrderRequest, response: closedOrderResponse } =
    useRequest();
  const { request: closeJobRequest, response: closeJobResponse } = useRequest();
  const { request: catalogSearchRequest, response: catalogSearchResponse } = useRequest();

  useEffect(() => {
    request("GET", "jobs");
    nameRequest("GET", "names");
    document.title = "HQF - Produzione";
    document.body.classList.add("production");
  }, []);

  useEffect(() => {
    if (response) {
      if (response.status === "SUCCESS") {
        if(response?.data?.is_category){
          setSelectIndex();
          setJobs(response?.data?.jobs)
        }else{
          if (response?.data?.jobs?.execution_dates.length > 0) {
            setNewJobs(response?.data?.jobs?.execution_dates);
            if (response?.data?.jobs?.execution_dates.length == 1) {
              setSelectIndex(0);
              setJobs(response?.data?.jobs?.execution_dates[0]?.jobs);
            } else {
              let executionDate = response?.data?.jobs?.execution_dates[0];
              if (executionDate?.is_previous) {
                setSelectIndex(1);
                setJobs(response?.data?.jobs?.execution_dates[1]?.jobs);
              } else {
                setSelectIndex(0);
                setJobs(response?.data?.jobs?.execution_dates[0]?.jobs);
              }
            }
          } else {
            if (selectedProviderName?.length > 0) {
              setSearchQuery(selectedProviderName);
            }
            setSelectIndex();
            setJobs(response?.data?.jobs?.jobs);
          }
        }
      }else{
        setSelectIndex();
        setJobs([])
      }
    }
  }, [response]);

  useEffect(() => {
    if (closedOrderResponse) {
      if (closedOrderResponse.status === "SUCCESS") {
        if (selectedClosedOrder?.length > 0) {
          setSearchQueryFilter(selectedClosedOrder);
        }
        setSearchQuery("");
        setSelectIndex();
        // setSelectIndex(1);
        // setNewJobs(closedOrderResponse?.data?.execution_dates);
        setJobs(closedOrderResponse?.data?.closed_jobs);
        setClosedOrderKnow(false);
      }
    }
  }, [closedOrderResponse]);

  useEffect(() => {
    if (nameResponse) {
      if (nameResponse.status === "SUCCESS") {
        setNames(nameResponse?.data?.names);
        const names = nameResponse?.data?.names.map((name) => name.name);
        setGlobalSuggestionList(names);
        setSuggestionList(names);
        // const closedOrder = nameResponse?.data?.closed_jobs?.map(
        //   (closedOrder) => closedOrder.description
        // );
        // setClosedSuggestionList(closedOrder);
        // setGlobalClosedSuggestionList(closedOrder);
      }
    }
  }, [nameResponse]);

  useEffect(()=>{
    if(categoryDescription){
      
    }
  },[categoryDescription])

  const onSlideClick = (i) => {
    setJobs(newJobs[i].jobs);
    setSelectIndex(i);
    setSearchQuery("");
    setSearchQueryFilter("");
  };

  const jobClass = (job) => {
    if (job.is_stock === null) {
      return "orderList-rowNBlank orderList-haslocation";
    } else if (!job.is_stock) {
      return "orderList-haslocation";
    } else {
      return " ";
    }
  };

  const jobStatusClass = (job) => {
    if (job.is_stock === null) {
      return "fa fa-caret-right";
    } else if (job.status === "RED") {
      return "fa fa-caret-right text-danger ";
    } else if (job.status === "YELLOW") {
      return "fa fa-caret-right text-warning";
    } else if (job.status === "GREEN") {
      return "fa fa-caret-right text-success";
    }
  };

  const redirectPathUrl = (job) => {
    if (job.is_stock === null) {
      return "/produzione-aggiunta";
    } else {
      return "/produzione-lista";
    }
  };
  const searchProvider = (e) => {
    e.preventDefault();
  };

  const jobDeleteHandler = (id) => {
    MySwal.fire({
      title: "Sei sicuro?",
      text: "Vuoi eliminare?",
      icon: "warning",
      showCancelButton: true,
      confirmButtonText: "Sì",
      cancelButtonText: "No",
      confirmButtonColor: "#FF0000",
      reverseButtons: true,
    }).then((result) => {
      if (result.isConfirmed) {
        jobDeleteRequest("GET", `job-delete/${id}`);
      } else if (result.isDismissed) {
      }
    });
  };

  useEffect(() => {
    if (jobDeleteResponse) {
      if (jobDeleteResponse.status === "SUCCESS") {
        toast.success(jobDeleteResponse.message);
        request("GET", "jobs");
      }
    }
  }, [jobDeleteResponse]);

  useEffect(() => {
    if (closeJobResponse && closeJobResponse.status === "SUCCESS") {
      const closeJobs = closeJobResponse.data.closed_jobs.map(
        (product) => product.description
      );
      setClosedSuggestionList(closeJobs)
    }
  }, [closeJobResponse])

  useEffect(() => {
    if (catalogSearchResponse) {
      if (catalogSearchResponse.status === "SUCCESS") {
        setCatalogSuggestionList(catalogSearchResponse?.data?.category.map(d => d.description))
      }
    }
  }, [catalogSearchResponse])

  const onSuggestionsFetchRequested = ({ value, reason }) => {
    const filteredProducts = getFilteredProducts(value, globalSuggestionList);
    setSuggestionList(filteredProducts);
  };
  const onSuggestionsFetchRequestedCatalog = ({ value, reason }) => {
    catalogSearchRequest("GET", `catalog-category?name=${value}`)
  };

  const onSuggestionsClearRequested = () => {
    setSuggestionList(globalSuggestionList);
  };

  const onSuggestionsFetchCloseOrderRequested = ({ value, reason }) => {
    // const filteredProducts = getFilteredProducts(
    //   value,
    //   globalClosedSuggestionList
    // );
    // setClosedSuggestionList(filteredProducts);
    if (!isNaN(+value)) {
      setIdJob(value)
      closeJobRequest('GET', `close-job-search?id=${value}`)
    }
  };

  const onSuggestionsClearCloseOrderRequested = () => {
    setClosedSuggestionList(globalClosedSuggestionList);
  };

  const onSuggestionsClearRequestedCatalog = () => {
    setCatalogSuggestionList(catalogSuggestionList);
  };

  const renderSuggestion = (suggestion) => suggestion;

  const getSuggestionValue = (suggestion) => {
    setSuggestionList(suggestionList);
    setSelectedProviderName(suggestion);
    setSearchQueryFilter("");
    request("GET", `jobs?name=${suggestion}`);
    return suggestion;
  };
  const getSuggestionValueCatalog = (suggestion) => {
    setCatalogSearchQuery(suggestion)
    request("GET", `jobs?category=${suggestion}`);
    return suggestion
  };

  const getSuggestionValueClosedOrders = (suggestion) => {
    setClosedSuggestionList(closedSuggestionList);
    setSelectedClosedOrder(suggestion);
    setSelectedProviderName("");

    closedOrderRequest("POST", "closed-jobs", {
      name: suggestion,
      id_job: idJob
    });
    return suggestion;
  };

  const onChange = (e) => {
    e.preventDefault();
    setSearchQuery(e.target.value);
  };

  const inputProps = {
    placeholder: t("Point of sale"),
    value: searchQuery ? searchQuery : "",
    onChange,
  };

  const onChangeSearchFilter = (e) => {
    e.preventDefault();
    setSearchQueryFilter(e.target.value);
  };
  const onChangeCatalog = (e, { newValue }) => {
    // e.preventDefault();
    console.log(newValue)
    setCatalogSearchQuery(newValue);
  };

  const inputPropsFilter = {
    placeholder: t("job number"),
    value: searchQueryFilter ? searchQueryFilter : "",
    onChange: onChangeSearchFilter,
  };

  const inputPropsCatalog = {
    placeholder: t("Catalog Description"),
    value: catalogSearchQuery ? catalogSearchQuery : "",
    onChange: onChangeCatalog,
  };

  return (
    <>
      {userRoleId === 5 || userRoleId === 2 ? (
        <div className="dashboard_parent ">
          {/* <!-- Sidebar --> */}
          <SideBar />
          {loading && <LoadingSpinner />}
          <div className="dashboard_right">
            {/* <!-- dashboard header --> */}

            <Header
              title={t("Production")}
              links={[{ to: "/produzione", name: t("production list") }]}
            />
            {/* <!-- dashboard Content --> */}
            <div className="dashboard_content">
              <div className="dashboard-container">
                <div className="topfix">
                  <div className="supplierFilter d-flex justify-content-between">
                    <div className="supplierSearch themeInput themeIS">
                      {/* <label>{t("Name")}</label> */}
                      <form
                        onSubmit={searchProvider}
                        className="themeInput position-relative"
                      >
                        <Autosuggest
                          suggestions={suggestionList}
                          onSuggestionsFetchRequested={
                            onSuggestionsFetchRequested
                          }
                          onSuggestionsClearRequested={
                            onSuggestionsClearRequested
                          }
                          // alwaysRenderSuggestions={true}
                          getSuggestionValue={getSuggestionValue}
                          renderSuggestion={renderSuggestion}
                          inputProps={inputProps}
                        />

                        <button className="themeIS-btn" type="submit">
                          <i className="fas fa-search"></i>
                        </button>
                      </form>
                    </div>
                    <div className="supplierSearch themeInput themeIS">
                      {/* <label>{t("Name")}</label> */}
                      <form
                        onSubmit={searchProvider}
                        className="themeInput position-relative"
                      >
                        <Autosuggest
                          suggestions={catalogSuggestionList}
                          onSuggestionsFetchRequested={
                            onSuggestionsFetchRequestedCatalog
                          }
                          onSuggestionsClearRequested={
                            onSuggestionsClearRequestedCatalog
                          }
                          // alwaysRenderSuggestions={true}
                          getSuggestionValue={getSuggestionValueCatalog}
                          renderSuggestion={renderSuggestion}
                          inputProps={inputPropsCatalog}
                        />

                        <button className="themeIS-btn" type="submit">
                          <i className="fas fa-search"></i>
                        </button>
                      </form>
                    </div>
                    <div className="supplierSearch themeInput themeIS ">
                      {/* <label>{t("Search for closed orders")}</label> */}
                      <form
                        onSubmit={searchProvider}
                        className="themeInput position-relative"
                      >
                        <Autosuggest
                          suggestions={closedSuggestionList}
                          onSuggestionsFetchRequested={
                            onSuggestionsFetchCloseOrderRequested
                          }
                          onSuggestionsClearRequested={
                            onSuggestionsClearCloseOrderRequested
                          }
                          // // alwaysRenderSuggestions={true}
                          getSuggestionValue={getSuggestionValueClosedOrders}
                          renderSuggestion={renderSuggestion}
                          inputProps={inputPropsFilter}
                        />
                        <button className="themeIS-btn" type="submit">
                          <i className="fas fa-search"></i>
                        </button>
                      </form>
                    </div>
                  </div>
                  <div>
                    <Swiper
                      spaceBetween={15}
                      slidesPerView={"auto"}
                      navigation={true}
                      modules={[Navigation]}
                      className="odalist"
                    >
                      {newJobs &&
                        newJobs.map((newJob, index) => (
                          <SwiperSlide key={index}>
                            {newJob?.is_previous ? (
                              <div
                                className="slidecard active"
                                onClick={() => onSlideClick(index)}
                              >
                                <button className="list-card">
                                  <div className="list-card-head">
                                    <span>{t("BACKWARDS")}</span>
                                    <span>{newJob.execution_date}</span>
                                  </div>
                                  <div className="lc-newdata justify-content-between">
                                    <small>{t("processing")}</small>
                                    <strong>{newJob.total_jobs}</strong>
                                  </div>
                                </button>
                              </div>
                            ) : (
                              <div
                                className="slidecard"
                                onClick={() => onSlideClick(index)}
                              >
                                <button
                                  className={`list-card ${index === selectIndex ? "bg-white" : ""
                                    } `}
                                >
                                  <div className="list-card-head">
                                    <span>{newJob.day_name}</span>
                                    <span>{newJob.execution_date}</span>
                                  </div>
                                  <div className="lc-newdata justify-content-between">
                                    <small>{t("processing")}</small>
                                    <strong>{newJob.total_jobs}</strong>
                                  </div>
                                </button>
                              </div>
                            )}
                          </SwiperSlide>
                        ))}
                    </Swiper>
                  </div>
                </div>

                <div className="p-3">
                  {userRoleId === 5 && closedOrderKnow && (
                    <>
                      <button
                        className="requestWorkBtn"
                        onClick={() =>
                          history.push({
                            pathname: "/produzione-aggiunta",
                            state: { batchSon: generateBarCode("BS") },
                          })
                        }
                      >
                        <i className="fas fa-plus me-2"></i>{" "}
                        {t("REQUEST WORKING")}
                      </button>

                      <button
                        className="requestWorkBtn"
                        onClick={() =>
                          history.push({
                            pathname: "/produzione-reimballare",
                            state: { batchSon: generateBarCode("BS") },
                          })
                        }
                      >
                        <i className="fas fa-plus me-2"></i>{" "}
                        {t("Repackaging")}
                      </button>
                    </>
                  )}

                  {/* Production List */}
                  <div className="prodDashList">
                    {jobs &&
                      jobs.map((job, index) => (
                        <Link
                          className={`orderList-row orderList-row_scnd orderListproduzione ${jobClass(
                            job
                          )}`}
                          to={{
                            pathname: redirectPathUrl(job),
                            state: {
                              job: job || null,
                              batchSon: generateBarCode("BS"),
                              closedOrderKnow: closedOrderKnow,
                            },
                          }}
                          key={index}
                        >
                          {jobStatusClass(job) !==
                            "fa fa-caret-right text-success" ? (
                            <div className="orderStatus">
                              {/* <i className="fa fa-caret-right"></i> */}
                              <i className={` ${jobStatusClass(job)}`}></i>
                            </div>
                          ) : (
                            ""
                          )}

                          <div className="orderno">
                            <label>{t("processing number")}</label>

                            {job?.is_stock !== null ? (
                              <h6>{job?.id_job_set}</h6>
                            ) : (
                              ""
                            )}
                          </div>
                          {job?.is_stock == null ? (
                            <div className="suppliername">
                              <label>{t("name")}</label>
                              <h6>{job?.son_name} </h6>
                            </div>
                          ) : (
                            <div className="suppliername">
                              <label>{t("name")}</label>
                              <h6>{job?.product_name} </h6>
                            </div>
                          )}

                          <div className="orderdate">
                            <label>{t("date")}</label>
                            {!closedOrderKnow ? (
                              <h6>{job.data_closed} </h6>
                            ) : jobClass(job) !== " " ? (
                              job.execution_date ? (
                                <h6>{job.execution_date}</h6>
                              ) : (
                                <h6></h6>
                              )
                            ) : job.execution_date ? (
                              <h6>{job.execution_date}</h6>
                            ) : (
                              <h6></h6>
                            )}
                          </div>
                          <div className="ownername">
                            <label>{t("quantity")}</label>

                            {job.pieces_main_son ? (
                              <h6>{job.pieces_main_son}</h6>
                            ) : (
                              <h6>{job.quantity}</h6>
                            )}
                          </div>

                          {jobStatusClass(job) ===
                            "fa fa-caret-right text-success" ? (
                            <div className="orderStatus orderStatusLast orderStatusLast_scnd ">
                              {/* <i className="fa fa-caret-right"></i> */}
                              <i className={` ${jobStatusClass(job)}`}></i>
                            </div>
                          ) : (
                            ""
                          )}

                          {role &&
                            role.description === "manager" &&
                            job.state ? (
                            <div
                              className="orderStatus orderStatusLast"
                              onClick={(e) => e.preventDefault()}
                            >
                              <button
                                className="btn-square"
                                title={t("Change")}
                                onClick={() => {
                                  history.push({
                                    pathname: "/produzione-modificare",
                                    state: {
                                      job: job || null,
                                      batchSon: generateBarCode("BS"),
                                    },
                                  });
                                }}
                              >
                                {jobClass(job) !==
                                  "orderList-rowNBlank orderList-haslocation" ? (
                                  <i className="fas fa-pen"></i>
                                ) : (
                                  ""
                                )}
                              </button>
                              <button
                                className="btn-square"
                                title={t("Delete")}
                                onClick={() => {
                                  jobDeleteHandler(job.id_job_set);
                                }}
                              >
                                {jobClass(job) !==
                                  "orderList-rowNBlank orderList-haslocation" ? (
                                  <i className="fas fa-trash"></i>
                                ) : (
                                  ""
                                )}
                              </button>
                            </div>
                          ) : (
                            ""
                          )}

                          {!job.is_stock ? (
                            <div className="orderList-location">
                              <span>
                                <i className="far fa-map-marker-alt"></i>
                                {job.address} &nbsp;|&nbsp; Note lab: {job.note_lab}
                              </span>
                            </div>
                          ) : (
                            ""
                          )}
                        </Link>
                      ))}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      ) : (
        history.push("/")
      )}
    </>
  );
};

export default Produzione;
