import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useTranslation } from "react-i18next";
import { useHistory, useLocation } from "react-router-dom";
import useRequest from "../../hooks/useRequest";
import DashboardLayout from "../../components/layout/dashboard-layout";
import { API } from "../../constant/api";

const BACKEND_URL = API.Base_Url;

const Report = () => {
  const { userId, userRoleId } = useSelector((state) => state.auth);
  const [t, i18n] = useTranslation("translation");
  const history = useHistory();
  const [batchCode, setBatchCode] = useState('');
  const [idJobSetting, setIdJobSetting] = useState('');
  const [batch, setBatch] = useState('');
  const [idArticle, setIdArticle] = useState('');


  const fetchFileHandler = (inputType) => {
    if(inputType === "batch"){
      let url = `${BACKEND_URL}api/v1/report_tracciabilita?batch=${batchCode}`
      window.open(url, '_blank', 'noreferrer');
    }else if(inputType === "job_setting"){
      let url = `${BACKEND_URL}api/v1/scheda_lavorazione?id_job_set=${idJobSetting}`
      window.open(url, '_blank', 'noreferrer');
    }else if(inputType === "batch_article"){
      let url = `${BACKEND_URL}api/v1/report_rintracciabilita?batch=${batch}&id_article=${idArticle}`
      window.open(url, '_blank', 'noreferrer');

    }
  }

  return (
    <>
      {userRoleId && userRoleId === 5 ? (
        <>
          <DashboardLayout title="Dashboard 2">
            <div className="row" style={{margin: '100px'}}>
              <div className="col-md-4">
                <label className="text-white" for="batchId">{t('Traceability reports')}</label>
                <input
                  type="text"
                  id="batchId"
                  className="form-control"
                  placeholder={t('Batch Code')}
                  value={batchCode}
                  onChange={(e) => {setBatchCode(e.target.value)}}
                />
              </div>
              <div className="col-md-1" style={{marginTop: '20px'}}>
                <button type="button" className="btn btn-primary" onClick={(e) => {fetchFileHandler('batch')}} style={{width: '50px', height: '50px'}}>
                  <i className="fa fa-arrow-circle-down"></i>
                </button>
              </div>
            </div>
            <div className="row" style={{margin: '100px'}}>
              <div className="col-md-4">
                <label className="text-white" for="idJob">{t('Processing card')}</label>
                <input
                  type="text"
                  id="idJob"
                  className="form-control"
                  placeholder={t('Job_number')}
                  value={idJobSetting}
                  onChange={(e) => {setIdJobSetting(e.target.value)}}
                />
              </div>
              <div className="col-md-1" style={{marginTop: '20px'}}>
                <button type="button" className="btn btn-primary" onClick={(e) => {fetchFileHandler('job_setting')}} style={{width: '50px', height: '50px'}}>
                  <i className="fa fa-arrow-circle-down"></i>
                </button>
              </div>
            </div>

            <div className="row" style={{margin: '100px'}}>
              <div className="col-md-4">
                <label className="text-white" for="idJob">{t('batch')}</label>
                <input
                  type="text"
                  id="reportBatchId"
                  className="form-control"
                  placeholder={t('batch')}
                  value={batch}
                  onChange={(e) => {setBatch(e.target.value)}}
                />
              </div>
              <div className="col-md-4">
                <label className="text-white" for="idJob">{t('Article ID')}</label>
                <input
                  type="text"
                  id="articleId"
                  className="form-control"
                  placeholder={t('Article ID')}
                  value={idArticle}
                  onChange={(e) => {setIdArticle(e.target.value)}}
                />
              </div>
              <div className="col-md-1" style={{marginTop: '20px'}}>
                <button type="button" className="btn btn-primary" onClick={(e) => {fetchFileHandler('batch_article')}} style={{width: '50px', height: '50px'}}>
                  <i className="fa fa-arrow-circle-down"></i>
                </button>
              </div>
            </div>

          </DashboardLayout>
        </>
      ) : (
        history.push("/")
      )}
    </>
  );
};

export default Report;

