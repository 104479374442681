import React from "react";

const Scanner = ({ func, id }) => {
  return (
    <div className="position-relative text-end mb-2">
      <div id="reader" width="100%" />
      <button
        className="btn btn-primary text-white scanBtn d-flex align-items-center ms-auto"
        // onClick={() => func()}
        onClick={(e) => func(e, id)}
        title="Scan "
      >
        <svg
          // className="me-2"
          version="1.1"
          xmlns="http://www.w3.org/2000/svg"
          x="0px"
          y="0px"
          viewBox="0 0 1000 1000"
          enableBackground="new 0 0 1000 1000"
        >
          <g>
            <path d="M990,484.3" />
            <path
              fill="currentColor"
              d="M953.4,521.9H31.9c-12.1,0-21.9-9.8-21.9-21.9c0-12.1,9.8-21.9,21.9-21.9h921.5c12.1,0,21.9,9.8,21.9,21.9C975.4,512.1,965.6,521.9,953.4,521.9z M865.7,236.7c0-88.4-21.3-109.7-109.7-109.7h-87.8V83.1h131.6c60.6,0,109.7,49.1,109.7,109.7v131.6h-43.9V236.7L865.7,236.7z M119.7,236.7v87.8H75.8V192.8c0-60.6,49.1-109.7,109.7-109.7h131.6V127h-87.8C142.1,127,119.7,148.3,119.7,236.7z M119.7,763.3c0,87.1,22.6,109.7,109.7,109.7h87.8v43.9H185.5c-60.6,0-109.7-49.1-109.7-109.7V675.5h43.9L119.7,763.3L119.7,763.3z M865.7,763.3v-87.8h43.9v131.6c0,60.6-49.1,109.7-109.7,109.7H668.2V873H756C844.4,873,865.7,850.4,865.7,763.3z"
            />
          </g>
        </svg>
        {/* Scan */}
      </button>
      {/* <button onClick={() => handleStop()}>stop pro</button> */}
    </div>
  );
};
export default Scanner;
