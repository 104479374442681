import React from "react";

import "./Spinner.css";

const LoadingSpinner = () => {
  return (
    <div className="loader-wrapper" id="loader_img">
      <div className="loader">
        <img src="./img/logo.png" alt="" />
        <div className="material-spinner"></div>
      </div>
    </div>
  );
};

export default LoadingSpinner;

