import React, { useState, useEffect } from "react";
import { useHistory, useParams } from "react-router-dom";

import Header from "../../components/header/header";
import SideBar from "../../components/Sidebar/sideBar";
import useRequest from "../../hooks/useRequest";
import { CANCEL } from "../../components/Utils/SVG";
import { useTranslation } from "react-i18next";

const View = () => {
  const history = useHistory();
  const { id } = useParams();
  const [t, i18n] = useTranslation("translation");

  const [userData, setuserData] = useState({});

  const { request, response } = useRequest();

  useEffect(() => {
    request("GET", `user/${id}`);
  }, []);

  useEffect(() => {
    if (response) {
      if (response.status === "SUCCESS") {
        setuserData(response.data);
      }
    }
  }, [response]);

  return (
    <div className="dashboard_parent">
      <SideBar />
      <div className="dashboard_right">
        <Header
          title={t("Add user")}
          links={[{ to: "/utente", name: t("user") }]}
        />
        <div className="dashboard_content">
          <div className="dashboard-container">
            <div className="p-4">
              <div className="bg-white rounded-3 mw-800">
                <div className="p-3 border-bottom">
                  <h5 className="mb-0">{t("Visualization")}</h5>
                </div>
                <div className="p-3 border-bottom">
                  <div className="row">
                    <div className="col-6">
                      <label className="fw-bold">{t("First name")}</label>
                      <span className="form-control-plaintext pt-0 mb-3 pt-0">
                        {userData?.name}
                      </span>
                    </div>

                    <div className="col-6">
                      <label className="fw-bold">{t("Last name")}</label>
                      <span className="form-control-plaintext pt-0 mb-3 pt-0">
                        {userData?.surename}
                      </span>
                    </div>

                    <div className="col-6">
                      <label className="fw-bold">{t("Username")}</label>
                      <span className="form-control-plaintext pt-0 mb-3 pt-0">
                        {userData?.username}
                      </span>
                    </div>

                    <div className="col-6">
                      <label className="fw-bold">E-Mail</label>
                      <span className="form-control-plaintext pt-0 mb-3 pt-0">
                        {userData?.email}
                      </span>
                    </div>

                    <div className="col-6">
                      <label className="fw-bold">{t("Telephone Number")}</label>
                      <span className="form-control-plaintext pt-0 mb-3 pt-0">
                        {userData?.phone}
                      </span>
                    </div>

                    <div className="col-6">
                      <label className="fw-bold">{t("Village")}</label>
                      <span className="form-control-plaintext pt-0 mb-3 pt-0">
                        {userData?.country}
                      </span>
                    </div>

                    <div className="col-6">
                      <label className="fw-bold">{t("Role")}</label>
                      <span className="form-control-plaintext pt-0 mb-3 pt-0">
                        {userData?.role?.description}
                      </span>
                    </div>

                    <div className="col-6">
                      <label className="fw-bold">{t("Warehouse")}</label>
                      <span className="form-control-plaintext pt-0 mb-3 pt-0">
                        {userData?.department?.warehouse?.warehouse_name} ({userData?.department?.warehouse?.warehouse_code})
                      </span>
                    </div>

                    <div className="col-6">
                      <label className="fw-bold">{t("Department")}</label>
                      <span className="form-control-plaintext pt-0 mb-3 pt-0">
                        {userData?.department?.department}
                      </span>
                    </div>

                    <div className="col-6">
                      <label className="fw-bold">{t("Activation date")}</label>
                      <span className="form-control-plaintext pt-0 mb-3 pt-0">
                        {userData?.activation_date}
                      </span>
                    </div>
                  </div>
                </div>
                <div className="p-3">
                  <div className="d-flex justify-content-end">
                    <button
                      className="btn-pill btn-pill-secondary"
                      onClick={() => history.push("/utente")}
                    >
                      <CANCEL />
                      {t("Cancel")}
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default View;
