import React, { useEffect, useState } from "react";
import { toast } from "react-toastify";
import { useHistory } from "react-router-dom";
import { useForm } from "react-hook-form";
import useRequest from "../../hooks/useRequest";
import Header from "../../components/header/header";
import SideBar from "../../components/Sidebar/sideBar";
import { ADD, CANCEL } from "../../components/Utils/SVG";
import { useTranslation } from "react-i18next";

const AddStaff = () => {
  const history = useHistory();
  const [t, i18n] = useTranslation("translation");

  const [warehouses, setWarehouses] = useState([]);

  const [roles, setRoles] = useState([]);

  const [departments, setDepartments] = useState([]);

  const { request, response } = useRequest();

  const { request: warehouseRequest, response: warehouseResponse } = useRequest();

  const { request: roleRequest, response: roleResponse } = useRequest();

  const { request: departmentRequest, response: departmentResponse } =
    useRequest();

  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm();

  useEffect(() => {
    warehouseRequest("GET", "warehouses");
    roleRequest("GET", "roles");
  }, []);

  const warehouseHandler = (idWarehouse) => {
    departmentRequest("GET", `departments?id_warehouse=${idWarehouse}`);
  }

  useEffect(() => {
    if (warehouseResponse) {
      if (warehouseResponse.status === "SUCCESS") {
        setWarehouses(warehouseResponse.data);
      }
    }
  }, [warehouseResponse]);

  useEffect(() => {
    if (roleResponse) {
      if (roleResponse.status === "SUCCESS") {
        setRoles(roleResponse.data);
      }
    }
  }, [roleResponse]);

  useEffect(() => {
    if (departmentResponse) {
      if (departmentResponse.status === "SUCCESS") {
        setDepartments(departmentResponse.data);
      }
    }
  }, [departmentResponse]);

  useEffect(() => {
    if (response) {
      if (response.status === "SUCCESS") {
        toast.success(response?.message);
        history.push("/utente");
      }
    }
  }, [response]);

  const onSubmitHandler = (data) => {
    const {
      id_warehouse,
      name,
      surename,
      phone,
      country,
      username,
      email,
      password,
      confirm_password,
      id_role,
      id_department,
    } = data;

    request("POST", "add-user", {
      id_warehouse,
      name,
      surename,
      phone,
      country,
      username,
      email,
      password,
      confirm_password,
      id_role,
      id_department,
    });
  };

  return (
    <div className="dashboard_parent">
      <SideBar />
      <div className="dashboard_right">
        <Header
          title={t("Add user")}
          links={[{ to: "/utente", name: t("user") }]}
        />
        <div className="dashboard_content">
          <div className="dashboard-container">
            <div className="p-4">
              <form onSubmit={handleSubmit(onSubmitHandler)}>
                <div className="bg-white rounded-3 mw-800">
                  <div className="p-3 border-bottom">
                    <h5 className="mb-0">{t("Add user")}</h5>
                  </div>
                  <div className="p-3 border-bottom">
                    <div className="row">
                      <div className="col-md-6">
                        <div className="mb-3">
                          <label className="label">{t("First name")}</label>
                          <input
                            type="text"
                            className={`form-control ${
                              errors["name"] ? "is-invalid" : ""
                            }`}
                            {...register("name", {
                              required: true,
                            })}
                          />
                          {errors.name?.type === "required" && (
                            <div className="invalid-feedback">
                              {t("The name field is required")}
                            </div>
                          )}
                        </div>
                      </div>
                      <div className="col-md-6">
                        <div className="mb-3">
                          <label className="label">{t("Last name")}</label>
                          <input
                            type="text"
                            className={`form-control ${
                              errors["surename"] ? "is-invalid" : ""
                            }`}
                            {...register("surename", {
                              required: true,
                            })}
                          />
                          {errors.surename?.type === "required" && (
                            <div className="invalid-feedback">
                              {t("The surname field is required")}
                            </div>
                          )}
                        </div>
                      </div>
                      <div className="col-md-6">
                        <div className="mb-3">
                          <label className="label">
                            {t("Telephone Number")}
                          </label>
                          <input
                            type="text"
                            className={`form-control ${
                              errors["phone"] ? "is-invalid" : ""
                            }`}
                            {...register("phone", {
                              required: true,
                            })}
                          />
                          {errors.phone?.type === "required" && (
                            <div className="invalid-feedback">
                              {t("The telephone number field is required")}
                            </div>
                          )}
                        </div>
                      </div>
                      <div className="col-md-6">
                        <div className="mb-3">
                          <label className="label">{t("Village")}</label>
                          <input
                            type="text"
                            className={`form-control ${
                              errors["country"] ? "is-invalid" : ""
                            }`}
                            {...register("country", {
                              required: true,
                            })}
                          />
                          {errors.country?.type === "required" && (
                            <div className="invalid-feedback">
                              {t("Country field is required")}
                            </div>
                          )}
                        </div>
                      </div>
                      <div className="col-md-6">
                        <div className="mb-3">
                          <label className="label">{t("Username")}</label>
                          <input
                            type="text"
                            className={`form-control ${
                              errors["username"] ? "is-invalid" : ""
                            }`}
                            {...register("username", {
                              required: true,
                            })}
                          />
                          {errors.username?.type === "required" && (
                            <div className="invalid-feedback">
                              {t("The username field is required")}
                            </div>
                          )}
                        </div>
                      </div>
                      <div className="col-md-6">
                        <div className="mb-3">
                          <label className="label">E-mail</label>
                          <input
                            type="text"
                            className={`form-control ${
                              errors["email"] ? "is-invalid" : ""
                            }`}
                            {...register("email", {
                              required: true,
                              pattern:
                                /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/,
                            })}
                          />
                          {errors.email?.type === "required" && (
                            <div className="invalid-feedback">
                              {t("The e-mail field is required")}
                            </div>
                          )}
                          {errors.email?.type === "pattern" && (
                            <div className="invalid-feedback">
                              {t("The email must be a valid email address")}
                            </div>
                          )}
                        </div>
                      </div>
                      <div className="col-md-6">
                        <div className="mb-3">
                          <label className="label">Password</label>
                          <input
                            type="text"
                            className={`form-control ${
                              errors["password"] ? "is-invalid" : ""
                            }`}
                            {...register("password", {
                              required: true,
                              pattern: /(?=.*\d)(?=.*[a-z])(?=.*[A-Z]).{8,}/,
                            })}
                          />

                          {errors.password?.type === "required" && (
                            <div className="invalid-feedback">
                              {t("The password field is required")}
                            </div>
                          )}

                          {errors.password?.type === "pattern" && (
                            <div className="invalid-feedback">
                              {t(
                                "Confirm password must be 8 characters with at least one upper case one lower case and one number"
                              )}
                            </div>
                          )}
                        </div>
                      </div>
                      <div className="col-md-6">
                        <div className="mb-3">
                          <label className="label">
                            {t("Confirm password")}
                          </label>
                          <input
                            type="text"
                            className={`form-control ${
                              errors["confirm_password"] ? "is-invalid" : ""
                            }`}
                            {...register("confirm_password", {
                              required: true,
                              pattern: /(?=.*\d)(?=.*[a-z])(?=.*[A-Z]).{8,}/,
                            })}
                          />

                          {errors.confirm_password?.type === "required" && (
                            <div className="invalid-feedback">
                              {t("The Confirm password field is required")}
                            </div>
                          )}
                          {errors.confirm_password?.type === "pattern" && (
                            <div className="invalid-feedback">
                              {t(
                                "The confirmation password must be 8 characters long with at least one uppercase one lowercase and one number"
                              )}
                            </div>
                          )}
                        </div>
                      </div>
                      <div className="col-md-6">
                        <div className="mb-3">
                          <label className="label">{t("Warehouse")}</label>
                          <select
                            type="text"
                            className={`form-control form-select ${
                              errors["id_warehouse"] ? "is-invalid" : ""
                            }`}
                            {...register("id_warehouse", {
                              required: true,
                            })}
                            onChange={(e) => {warehouseHandler(e.target.value)}}
                          >
                            <option value="">{t("Select Warehouse")}</option>
                            {warehouses &&
                              warehouses.map((warehouse) => (
                                <option
                                  value={warehouse?.id_warehouse}
                                  key={warehouse?.id_warehouse}
                                >
                                  {warehouse?.warehouse_name}
                                </option>
                              ))}
                          </select>
                          {errors.id_warehouse?.type === "required" && (
                            <div className="invalid-feedback">
                              {t("The Warehouse field is required")}
                            </div>
                          )}
                        </div>
                      </div>
                      <div className="col-md-6">
                        <div className="mb-3">
                          <label className="label">{t("Role")}</label>
                          <select
                            type="text"
                            className={`form-control form-select ${
                              errors["id_role"] ? "is-invalid" : ""
                            }`}
                            {...register("id_role", {
                              required: true,
                            })}
                          >
                            <option value="">{t("Select Role")}</option>
                            {roles &&
                              roles.map((role) => (
                                <option
                                  value={role?.id_role}
                                  key={role?.id_role}
                                >
                                  {role?.description}
                                </option>
                              ))}
                          </select>
                          {errors.id_role?.type === "required" && (
                            <div className="invalid-feedback">
                              {t("The role field is required")}
                            </div>
                          )}
                        </div>
                      </div>
                      <div className="col-md-6">
                        <div className="mb-3">
                          <label className="label">{t("Department")}</label>
                          <select
                            type="text"
                            className={`form-control form-select ${
                              errors["id_department"] ? "is-invalid" : ""
                            }`}
                            {...register("id_department", {
                              required: true,
                            })}
                          >
                            <option value="">{t("Select Department")}</option>
                            {departments &&
                              departments.map((depart) => (
                                <option
                                  value={depart?.id_department}
                                  key={depart?.id_department}
                                >
                                  {depart?.department}
                                </option>
                              ))}
                          </select>
                          {errors.id_department?.type === "required" && (
                            <div className="invalid-feedback">
                              {t("The Department field is required")}
                            </div>
                          )}
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="p-3">
                    <div className="d-flex justify-content-end">
                      <button
                        className="btn-pill me-3"
                        // onClick={handleSubmit(onSubmitHandler)}
                      >
                        <ADD />
                        {t("Save")}
                      </button>
                      <button
                        className="btn-pill btn-pill-secondary"
                        onClick={() => history.push("/utente")}
                      >
                        <CANCEL />
                        {t("Cancel")}
                      </button>
                    </div>
                  </div>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default AddStaff;
