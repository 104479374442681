import React, { useState, useEffect, useRef, useCallback } from "react";
import { useLocation, useHistory } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import axios from "axios";
import { toast } from "react-toastify";
import Scanner from "../../components/scanner/scanner";
import { Html5Qrcode } from "html5-qrcode";
import SideBar from "../../components/Sidebar/sideBar";
import Header from "../../components/header/header";
import useRequest from "../../hooks/useRequest";
import { PLUSICON, NavArrow } from "../../components/Utils/SVG";
import { addModelClass, removeModelClass } from "../../components/Utils/Utils";
import { generateBarCode } from "../../components/Utils/generateBarCode";
import { PrintLabelMeatProduction } from "../../components/printlabel/production/productionmeatlabel";
import { printLabelNonMeatProduction } from "../../components/printlabel/production/productionNonmeatLabel";
import Swal from "sweetalert2";
import withReactContent from "sweetalert2-react-content";
import ToggleSwitch from "../../components/toggleButton/toggle";
import { ProductionIsRanNonMeatLabel } from "../../components/printlabel/production/productionIsRanNonMeatLabel";
import { ProductionIsEanMeatLabel } from "../../components/printlabel/production/productionIsEanMeatLabel";

const qrConfig = { fps: 10, qrbox: { width: 300, height: 300 } };
let html5QrCode;

const ProduzioneLista = () => {
  const { userRoleId } = useSelector((state) => state.auth);
  let weightTimer = useRef(null);
  let fatherExecutionRef = useRef([]);
  const history = useHistory();
  const location = useLocation();
  const [t, i18n] = useTranslation("translation");
  const job = location.state ? location.state.job : "";
  const batchSon = job?.id_job_set ? "JBS" + job?.id_job_set : "JBS";
  const completeButtonShow = location.state
    ? location.state.closedOrderKnow
    : "";
  const [hide, setHide] = useState(false);
  const [fatherExecutionData, setFatherExecutionData] = useState([]);
  const [sonExecutionData, setSonExecutionData] = useState([]);
  const [fatherExecutionModalData, setFatherExecutionModalData] = useState([]);
  const [sonExecutionModalData, setSonExecutionModalData] = useState([]);
  const [printLabelData, setPrintLabelData] = useState({});
  const [toggleValue, setToggleValue] = useState(false);
  const [fatherToggleValue, setFatherToggleValue] = useState(true);
  const [balanceWeight, setBalanceWeight] = useState(0);
  const [productPercentage, setProductPercentage] = useState('');
  const [productTotalWeight, setProductTotalWeight] = useState(0);

  // const [urlValue, setUrlValue] = useState("http://192.168.235.200:12345/bilancia_read_fake");
  const [urlValue, setUrlValue] = useState(
    "http://localhost:12345/bilancia_read_parsed"
  );

  const [table, setTable] = useState("both");
  const MySwal = withReactContent(Swal);

  const { request, response } = useRequest();
  const { request: executionUpdateRequest, response: executionUpdateResponse } =
    useRequest();
  const { request: jobProcessRequest, response: jobProcessResponse } =
    useRequest();
  const { request: deleteJobRequest, response: deleteJobResponse } =
    useRequest();
  const { request: modalItemsRequest, response: modalItemsResponse } =
    useRequest();
  const { request: batchCodeRequest, response: batchCodeResponse } =
    useRequest();
  const { request: printDataRequest, response: printDataResponse } =
    useRequest();
  const { request: jobStatusRequest, response: jobStatusResponse } =
    useRequest();

  useEffect(() => {
    if (!job || job == null) {
      handleBack();
    }
  }, [job]);

  const handleBack = () => {
    history.push({
      pathname: "/produzione",
      state: {
        job: job || null,
      },
    });
  };

  useEffect(() => {
    request("GET", `get-job-execution/${job?.id_job_set}`);
    document.title = "HQF - Produzione";
    document.body.classList.add("production");
    return () => {
      clearInterval(weightTimer.current);
      html5QrCode?.clear();
    };
  }, []);

  useEffect(() => {
    if (response) {
      if (response.status === "SUCCESS") {
        setProductPercentage(response?.data?.product_weight_per)
        setProductTotalWeight(response?.data?.product_total_weight)
        const fatherData = response?.data?.father_products.map(
          (data, index) => {
            data["id"] = index;
            data["lottoEdit"] = false;
            data["isActive"] = false;
            if (
              data?.actual_weight_father === 0 ||
              !data?.actual_weight_father
            ) {
              data["state"] = "first";
            } else {
              data["state"] = "second";
            }
            return data;
          }
        );

        setFatherExecutionData(fatherData);
        fatherExecutionRef.current = fatherData;

        setSonExecutionData(
          response?.data?.son_products.map((data, index) => {
            data["id"] = index;
            data["show"] = true;
            data["isActive"] = false;
            if (data?.actual_weight_son === 0 || !data?.actual_weight_son) {
              data["state"] = "first";
            } else {
              data["state"] = "second";
            }
            return data;
          })
        );
      }
      // To start balance machine to weight product
      balanceProductHandler(urlValue);
    }
  }, [response]);

  // Weight product put on balance machine start to fetch weight data
  const balanceProductHandler = (url) => {
    clearInterval(weightTimer.current);
    weightTimer.current = null;
    weightTimer.current = setInterval(() => {
      const balanceWeight = axios.get(url).then((res) => {
        if (res) {
          if (res?.status === 200) {
            let weight = res?.data?.weight;
            if (weight > 0) {
              setBalanceWeight(weight);
              return weight;
            } else {
              return null;
            }
          } else {
            return null;
          }
        } else {
          return null;
        }
      });
    }, 1000);
  };

  // To save product balance weight in weight table
  const saveProductBalanceWeight = (data, type) => {
    // Show active product
    // setActiveDeactiveProduct(type, data)
    // Set payload
    let payload = {
      state: data.state,
      relationship: type,
      description: data?.description,
      type: data?.type,
      id_job_set: data?.id_job_set,
    };
    // Add keys according to type
    if (type === "father") {
      let weight = balanceWeight === 0 ? data?.weight_father : balanceWeight
      payload.actual_weight_father = weight;
      payload.batch_father = data?.batch_father;
      payload.id_father = data?.id_father;
      payload.id_job_exec_father = data?.id_job_exec_father;
      payload.weight_father = data?.weight_father;
    } else {
      payload.actual_weight_son = balanceWeight;
      payload.batch_son = batchSon;
      payload.id_son = data?.id_son;
      payload.id_job_exec_son = data?.id_job_exec_son;
      payload.weight_son = data?.weight_son;
      payload.barcode = generateBarCode("P");
    }
    // Call api to save balance weight on product
    executionUpdateRequest("POST", "job-exec-update-data", payload);
  };

  const saveProcessHandler = () => {
    jobStatusRequest("GET", `job-status/${job?.id_job_set}`);
  };

  useEffect(() => {
    if (jobStatusResponse) {
      if (jobStatusResponse?.status === "SUCCESS") {
        if (jobStatusResponse?.data?.job_status === "GREEN" && !jobStatusResponse?.data?.is_closed) {
          MySwal.fire({
            title: t("Are you sure"),
            text: t("Completed processing"),
            icon: "warning",
            showCancelButton: true,
            confirmButtonText: t("Yup"),
            cancelButtonText: t("Cancel"),
            reverseButtons: true,
          }).then((result) => {
            if (result.isConfirmed) {
              jobProcessRequest("POST", "job-complete", {
                id_job_set: job?.id_job_set,
                job_execution_father: fatherExecutionData,
                job_execution_son: sonExecutionData,
              });
            } else if (result.isDismissed) {
            }
          });
        } else if (
          jobStatusResponse?.data?.job_status === "YELLOW" ||
          jobStatusResponse?.data?.job_status === "RED"
        ) {
          toast.error(jobStatusResponse?.message);
        } else if (jobStatusResponse?.data?.is_closed) {
          toast.error(jobStatusResponse?.message);
        }
      } else {
        toast.error(jobStatusResponse?.message);
      }
    }
  }, [jobStatusResponse]);

  useEffect(() => {
    if (jobProcessResponse) {
      if (jobProcessResponse?.status === "SUCCESS") {
        toast.success(jobProcessResponse?.message);
        history.push("/produzione");
      } else if (jobProcessResponse?.status === "ERROR") {
        toast.error(jobProcessResponse?.message);
      } else if (jobProcessResponse?.status === "FAIL") {
        toast.error(jobProcessResponse?.message);
      }
    }
  }, [jobProcessResponse]);

  useEffect(() => {
    if (executionUpdateResponse) {
      if (executionUpdateResponse.status === "SUCCESS") {
        if (executionUpdateResponse?.data?.relationship === "father") {
          const oldFatherArr = [...fatherExecutionData].map((father) => ({
            ...father,
            state: "second",
          }));
          setFatherExecutionData(oldFatherArr);
          fatherExecutionRef.current = oldFatherArr;
        } else {
          const oldSonArr = [...sonExecutionData].map((son) => ({
            ...son,
            state: "second",
            barcode: null,
          }));
          setSonExecutionData(oldSonArr);
          // Print label
          if (executionUpdateResponse?.data?.is_updated) {
            const fatherProducts = [...fatherExecutionData];
            let fatherProduct =
              fatherProducts.length > 0 ? fatherProducts[0] : null;
            printDataRequest("POST", "get-prod-product", {
              id: executionUpdateResponse?.data?.id_job_weight,
              id_article: executionUpdateResponse?.data?.id_article,
              is_father: fatherToggleValue,
              id_father: fatherProduct?.id_father,
              barcode: fatherProduct?.barcode,
            });
          }
        }
        request("GET", `get-job-execution/${job?.id_job_set}`);
        toast.success(executionUpdateResponse?.message);
      } else {
        toast.warn(executionUpdateResponse?.message);
      }
    }
  }, [executionUpdateResponse]);

  const batchEditHandler = (data) => {
    const rows = [...fatherExecutionData];
    const index = rows.findIndex((prod) => prod.id === data?.id);
    rows[index] = { ...rows[index], lottoEdit: true };
    setFatherExecutionData(rows);
    fatherExecutionRef.current = rows;
  };

  const setLottoHandler = (batch_father, id) => {
    const rows = [...fatherExecutionData];
    const index = rows.findIndex((prod) => prod.id === id);
    rows[index] = { ...rows[index], batch_father };
    setFatherExecutionData(rows);
    fatherExecutionRef.current = rows;
  };

  const exicutionDataModalHandler = () => {
    // clearInterval(weightTimer.current);
    // weightTimer.current = null;
    addModelClass("showExicutionSonModel");
    if (table === "both") {
      modalItemsRequest("POST", "get-job-exec-products", {
        id: job?.id_job_set,
        table: table,
      });
    } else if (table === "father") {
      fatherExecutionData.map((item) => {
        if (item.isActive) {
          modalItemsRequest("POST", "get-job-exec-products", {
            id: item.id_job_exec_father,
            table: table,
          });
        }
      });
    } else if (table === "son") {
      sonExecutionData.map((item) => {
        if (item.isActive) {
          modalItemsRequest("POST", "get-job-exec-products", {
            id: item.id_job_exec_son,
            table: table,
          });
        }
      });
    }
  };

  useEffect(() => {
    if (modalItemsResponse) {
      if (modalItemsResponse.status === "SUCCESS") {
        setFatherExecutionModalData(
          modalItemsResponse?.data?.job_execution_father
        );
        setSonExecutionModalData(modalItemsResponse?.data?.job_execution_son);
      }
    }
  }, [modalItemsResponse]);

  const closeExicutionDataModalHandler = () => {
    removeModelClass("showExicutionSonModel");
    request("GET", `get-job-execution/${job?.id_job_set}`);
  };

  const jobDeleteHandler = (e, data, listType) => {
    e.stopPropagation();
    if (listType === "father") {
      let newfatherArry = fatherExecutionModalData.filter(
        (item) => item.id_jwf !== data.id_jwf
      );
      setFatherExecutionModalData(newfatherArry);
      deleteJobRequest("POST", "delete-job-execution", {
        id: data.id_jwf,
        table: "father",
      });
    } else if (listType === "son") {
      let newsonArry = sonExecutionModalData.filter(
        (item) => item.id_jws !== data.id_jws
      );
      setSonExecutionModalData(newsonArry);

      deleteJobRequest("POST", "delete-job-execution", {
        id: data.id_jws,
        table: "son",
      });
    }
  };

  useEffect(() => {
    if (deleteJobResponse) {
      if (deleteJobResponse.status === "SUCCESS") {
        toast.success(deleteJobResponse.message);
      } else {
        toast.error(deleteJobResponse.message);
      }
    }
  }, [deleteJobResponse]);

  const departmentObj = {
    packages: "Confezionamento",
    warehouse: "Lab. Magazzino",
    receiving: "Lab. Ricezino",
    lab_boning: "Lab. Disosso",
    lab_fish: "Lab. Pesce",
    lab_packaging: "Lab. Confezionamento",
    lab_minced_meat: "Lab. Macinati",
    lab_cheese: "Lab. Formaggi",
    lab_portioned: "Lab. Porzionati",
    lab_dry: "Lab. Secco",
    lab_tartare: "Lab. Tartara",
    stock_sendings: "Stock e Preparazione",
  };

  useEffect(() => {
    if (fatherExecutionData.length > 0) {
      html5QrCode = new Html5Qrcode("reader");
    }
  }, [fatherExecutionData]);

  useEffect(() => {
    if (batchCodeResponse && batchCodeResponse.status === "SUCCESS") {
      setLottoHandler(
        batchCodeResponse?.data?.batch_code,
        batchCodeResponse?.data?.id
      );
    }
  }, [batchCodeResponse]);

  const handleClickAdvanced = (e, id) => {
    e.stopPropagation();
    const qrCodeSuccessCallback = (decodedText, decodedResult) => {
      if (decodedText) {
        batchCodeRequest("POST", "get-batch-code", {
          barcode: decodedText,
          id: id,
        });
        handleStop();
      }
    };
    html5QrCode.start(
      { facingMode: "environment" },
      qrConfig,
      qrCodeSuccessCallback
    );
  };

  const handleStop = () => {
    try {
      html5QrCode
        .stop()
        .then((res) => {
          html5QrCode.clear();
        })
        .catch((err) => { });
    } catch (err) { }
  };

  const labelToggleButtonHandler = () => {
    if (fatherToggleValue) {
      setFatherToggleValue(false);
    } else {
      setFatherToggleValue(true);
    }
  };

  const toggleButtonHandler = () => {
    clearInterval(weightTimer.current);
    weightTimer.current = null;
    if (urlValue === "http://localhost:12345/bilancia_read_parsed") {
      setUrlValue("http://localhost:23456/bilancia_read_parsed");
      setToggleValue(true);
      balanceProductHandler("http://localhost:23456/bilancia_read_parsed")
    } else {
      setUrlValue("http://localhost:12345/bilancia_read_parsed");
      setToggleValue(false);
      balanceProductHandler("http://localhost:12345/bilancia_read_parsed")
    }
  };

  useEffect(() => { 
    if (printDataResponse) {
      if (printDataResponse?.status === "SUCCESS") {
        setPrintLabelData(printDataResponse?.data?.product);
        if (printDataResponse?.data?.product_type === "Dry" && !printDataResponse?.data?.is_ean) {
          printLabelNonMeatProduction(printDataResponse?.data?.product);
        } else if (printDataResponse?.data?.product_type === "Dry" && printDataResponse?.data?.is_ean) {
          ProductionIsRanNonMeatLabel(printDataResponse?.data?.product)
        }
        else if (printDataResponse?.data?.product_type === "Meat" && !printDataResponse?.data?.is_ean) {
          PrintLabelMeatProduction(printDataResponse?.data?.product);
        }
        else if (printDataResponse?.data?.product_type === "Meat" && printDataResponse?.data?.is_ean) {
          ProductionIsEanMeatLabel(printDataResponse?.data?.product)
        }
      }
    }
  }, [printDataResponse]);

  const printLabelHandler = (data) => {
    if (data?.id_jwf) {
      printDataRequest("POST", "get-prod-product", {
        id: data?.id_jwf,
        id_article: data?.id_father,
        is_father: fatherToggleValue,
        id_father: data?.id_father,
        barcode: data?.barcode,
      });
    } else if (data?.id_jws) {
      const fatherProducts = [...fatherExecutionData];
      let fatherProduct = fatherProducts.length > 0 ? fatherProducts[0] : null;
      printDataRequest("POST", "get-prod-product", {
        id: data?.id_jws,
        id_article: data?.id_son,
        is_father: fatherToggleValue,
        id_father: fatherProduct?.id_father,
        barcode: fatherProduct?.barcode,
      });
    }
  };

  return (
    <>
      {userRoleId === 5 || userRoleId === 2 ? (
        <div className="dashboard_parent">
          {/* <!-- Sidebar --> */}
          <SideBar />
          <div className="dashboard_right">
            {/* <!-- dashboard header --> */}

            <Header
              title={t("Production")}
              links={[{ to: "/produzione", name: t("production list") }]}
            />

            {/* <!-- dashboard Content --> */}

            <div className="dashboard_content">
              <div className="dashboard-container bg-white">
                {/* Production Details */}
                <div className="prodDetailsWrap pt-0">
                  <div className="container">
                    <div className="fixedHead">
                      <ul className="prodDetails-row list-unstyled mb-5">
                        <li>
                          <label>{t("processing number")}</label>
                          <h5>{job.id_job_set}</h5>
                        </li>
                        <li>
                          <label>{t("job name")}</label>
                          <h5>{job?.product_name}</h5>
                        </li>

                        <li>
                          <label>{t("date")}</label>
                          <h5>{job.execution_date}</h5>
                        </li>
                        <li>
                          <label>{t("quantity")}</label>
                          {job.pieces_main_son ? (
                            <h5>{job.pieces_main_son}</h5>
                          ) : (
                            ""
                          )}
                        </li>
                        <li>
                          <label>{t("operator")}</label>

                          <div className="ownername">
                            {job?.department ? (
                              <h6>
                                {departmentObj[job?.department?.department]}
                              </h6>
                            ) : (
                              ""
                            )}
                          </div>
                        </li>
                      </ul>
                      <div
                        className="row"
                        style={{ marginLeft: "0px", fontSize: "25px" }}
                      >
                        <div className="col-md-1">
                          {t("Weight")}
                          <div className="row">
                            <div
                              className="col-md-6 ml-4"
                              style={{ fontSize: "50px", fontWeight: "bold" }}
                            >
                              {balanceWeight}
                            </div>
                          </div>
                        </div>

                        <div className="col-md-2">
                          % {t("processed_weight")}
                          <div className="row">
                            <div
                              className="col-md-6 ml-4"
                              style={{ fontSize: "30px", fontWeight: "bold" }}
                            >
                              {productPercentage}
                            </div>
                          </div>
                        </div>

                        <div className="col-md-2">
                          {t("Remaining weight kg")}
                          <div className="row">
                            <div
                              className="col-md-6 ml-4"
                              style={{ fontSize: "30px", fontWeight: "bold" }}
                            >
                              {productTotalWeight.toFixed(3)}
                            </div>
                          </div>
                        </div>

                        {!job.is_stock ? (
                          <div
                            className="col-md-6"
                            style={{ marginLeft: "30px" }}
                          >
                            <div>{t("address_exec")}</div>
                            <div style={{ fontSize: "15px" }}>{job?.address}</div>
                          </div>
                        ) : ('')}
                      </div>

                      <div className="row mb-4">
                        <div className="col-md-11">
                          <div className="orderList-dataction p-0 justify-content-center justify-content-md-end">
                            F &nbsp;
                            <ToggleSwitch
                              idToggle="father-toggle"
                              isOn={fatherToggleValue}
                              handleToggle={labelToggleButtonHandler}
                            />
                            &nbsp; P &nbsp; &nbsp; BP &nbsp;
                            <ToggleSwitch
                              idToggle="query-toggle"
                              isOn={toggleValue}
                              handleToggle={toggleButtonHandler}
                            />
                            &nbsp; BG
                            <button
                              className="BtnWithBg btn"
                              onClick={exicutionDataModalHandler}
                            >
                              <NavArrow />
                            </button>
                            <button
                              className="BtnWithBg btn"
                              onClick={() => history.push("/produzione")}
                              title={t("To close")}
                            >
                              <i className="far fa-times"></i>
                            </button>
                            {completeButtonShow && (
                              <button
                                className="btn-pill btn-primary rouded"
                                onClick={saveProcessHandler}
                              >
                                {t("COMPLETE")}{" "}
                                <i className="fa fa-caret-right ms-2"></i>
                              </button>
                            )}
                          </div>
                        </div>
                      </div>
                    </div>

                    <div className="batchWorkListBlock produzione-lista-block scrolledSec mt-4">

                      <div className="batchWorkList">
                        {fatherExecutionData &&
                          fatherExecutionData.map((data, index) => (
                            <>
                              <div
                                className={`prodDescList ${data?.isActive ? "ischecked" : ""
                                  }`}
                                key={data.id}

                                onClick={() =>
                                  !data?.is_weighted ? (saveProductBalanceWeight(data, "father")) : ('')
                                }
                              >

                                <ul className="list-unstyled">
                                  <li>
                                    <label>
                                      {t("FATHER")} {index + 1}
                                    </label>
                                  </li>
                                  <li className="lotW">
                                    <div
                                      className="batchWorkList-ml pr-2"
                                      onClick={(e) => {
                                        e.stopPropagation();
                                      }}
                                    >
                                      <label style={{ color: "#4ad34a" }}>
                                        {t("LOTTO")}
                                      </label>

                                      {data.lottoEdit && (
                                        <select
                                          onChange={(e) =>
                                            setLottoHandler(
                                              e.target.value,
                                              data.id
                                            )
                                          }
                                          className="form-select"
                                        >
                                          <option value="return">
                                            {" "}
                                            {t("select the lot")}
                                          </option>
                                          {data.lottos &&
                                            data.lottos.map((lotto, idx) => (
                                              <option
                                                value={lotto?.batch_code}
                                                key={idx}
                                                selected={
                                                  data?.batch_father ===
                                                    lotto?.batch_code
                                                    ? true
                                                    : false
                                                }
                                              >
                                                {lotto?.batch_code}
                                              </option>
                                            ))}
                                        </select>
                                      )}

                                      <i
                                        className="ms-2 fas fa-pencil-alt text-white mt-1"
                                        style={{ fontSize: "12px" }}
                                        onClick={(e) => {
                                          // e.stopPropagation();
                                          batchEditHandler(data, index);
                                        }}
                                      ></i>
                                    </div>
                                    <div className="batchWorkList-ml px-2">
                                      <Scanner
                                        func={handleClickAdvanced}
                                        id={data.id}
                                      />
                                    </div>
                                  </li>
                                  <li>
                                    <div className="batchWorkList-ml">
                                      <label style={{ color: "#4ad34a" }}>
                                        {t("ID_ARTICLE")}
                                      </label>

                                      <span>{data.id_father}</span>
                                    </div>
                                  </li>

                                  <li>
                                    <div className="batchWorkList-ml ">
                                      {/* <label style={{ color: "#4ad34a" }}>
                                      {t("ARTICLE")}
                                    </label> */}

                                      <span
                                        className="wrapWordProduct"
                                        title={data?.description}
                                      >
                                        {data?.description}
                                      </span>
                                    </div>
                                  </li>
                                  <li>
                                    <div className="batchWorkList-ml">
                                      <label style={{ color: "#4ad34a" }}>
                                        {t("theoretical weight (KG)")}
                                      </label>
                                      <span>{data?.weight_father}</span>
                                      {/* <span>{data?.product_weighted}</span> */}
                                    </div>
                                  </li>
                                  {/* <li className="weightW">
                                  <div className="batchWorkList-ml">
                                    <label style={{ color: "#4ad34a" }}>
                                      {t("actual weight (KG)")}
                                    </label>
                                    {data?.actual_weight_father ? (
                                      <>
                                        <span>{data?.actual_weight_father}</span>
                                      </>
                                    ) : (
                                      <span>
                                        <i className="far fa-minus"></i>
                                      </span>
                                    )}
                                  </div>
                                </li> */}
                                  {/* <li className="actionW ms-auto">
                                  <div className="batchWorkList-ml">
                                    <button
                                      // onClick={(e) =>
                                      //   updateFatherActualWeight(e, data, "father")
                                      // }
                                    >
                                      <PLUSICON />
                                    </button>
                                  </div>
                                </li> */}
                                </ul>
                              </div>
                            </>
                          ))}

                        <hr className="customHr" />

                        {sonExecutionData &&
                          sonExecutionData.map((data, index) => (
                            <div
                              className={`prodDescList ${!data.show ? "boxdatahidden" : ""
                                } ${data?.isActive ? "ischecked" : ""}`}
                              key={index}
                              onClick={() =>
                                saveProductBalanceWeight(data, "son")
                              }
                            >
                              <ul className="list-unstyled batchlightColor">
                                <li>
                                  <label>
                                    {t("SON")} {index + 1}
                                  </label>
                                </li>
                                <li className="lotW">
                                  <div className="batchWorkList-ml">
                                    <label>{t("ID_ARTICLE")}</label>

                                    <span>{data.id_son}</span>
                                  </div>
                                  {data?.required ? (
                                    <div className="batchWorkList-ml">
                                      <div class="sm-statusbox">
                                        <div class="statusCircle bg-danger"></div>
                                      </div>
                                    </div>
                                  ) : (
                                    ""
                                  )}
                                </li>
                                <li>
                                  <div className="batchWorkList-ml">
                                    {/* <label>{t("ARTICLE")}</label> */}

                                    <span
                                      className="wrapWordProduct"
                                      title={data?.description}
                                    >
                                      {data?.description}
                                    </span>
                                  </div>
                                </li>
                                <li className="weightW">
                                  <div className="batchWorkList-ml">
                                    <label>{t("weight_count")}</label>
                                    <span>{data?.product_weighted}</span>
                                  </div>
                                </li>
                                <li className="weightW">
                                  <div className="batchWorkList-ml">
                                    <label>{t("total_weight")}</label>
                                    <span>{data?.total_weight ? data?.total_weight : 0}</span>
                                  </div>
                                </li>
                                {/* <li className="weightW">
                                <div className="batchWorkList-ml">
                                  <label>{t("actual weight (KG)")}</label>
                                  {data?.actual_weight_son ? (
                                    <span>{data?.actual_weight_son}</span>
                                  ) : (
                                    <span>
                                      <i className="far fa-minus"></i>
                                    </span>
                                  )}
                                </div>
                              </li> */}
                                {/* <li className="actionW ms-auto">
                                <div className="batchWorkList-ml">
                                  <button
                                    // onClick={(e) =>
                                    //   updateSonActualWeight(e, data, "son")
                                    // }
                                  >
                                    <PLUSICON />
                                  </button>
                                </div>
                              </li> */}
                              </ul>
                              {/* {data.show ? (
                              <button
                                className="hideprodDesc-list"
                                onClick={(e) => listHideHandler(e, index)}
                              >
                                <i className="fas fa-eye"></i>
                              </button>
                            ) : (
                              <button
                                className="hideprodDesc-list"
                                onClick={(e) => listUnHideHandler(e, index)}
                              >
                                <i className="fas fa-eye-slash"></i>
                              </button>
                            )} */}
                            </div>
                          ))}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          {/* <!--  Modal -->   */}
          <div
            className=" modal fade  "
            id="showExicutionSonModel"
            tabIndex="-1"
            aria-labelledby="editodernumLabel"
            aria-hidden="true"
          >
            <div className="modal-dialog modal-dialog-scrollable modal-xxl">
              <div className="modal-content">
                <div className="text-end pt-3 pe-3 mb-n3">
                  <button
                    type="button"
                    className="btn-close"
                    data-bs-dismiss="modal"
                    aria-label="Close"
                    onClick={closeExicutionDataModalHandler}
                  ></button>
                </div>

                <div className="modal-body p-5 mb-3 text-center">
                  <div className="batchWorkList">
                    {fatherExecutionModalData &&
                      fatherExecutionModalData.map((data, index) => (
                        <div
                          className={`prodDescList ${data?.isActive ? "ischecked" : ""
                            }`}
                          key={data.id}
                        // onClick={() => onClickFatherProductHandler(data)}
                        >
                          <ul className="list-unstyled">
                            <li>
                              <label>
                                {t("FATHER")} {index + 1}
                              </label>
                            </li>
                            <li className="lotW">
                              <div
                                className="batchWorkList-ml"
                                onClick={(e) => {
                                  e.stopPropagation();
                                }}
                              >
                                <label style={{ color: "#4ad34a" }}>
                                  {t("LOT")}
                                </label>

                                <span className="position-relative d-flex">
                                  {data?.batch_father}
                                </span>
                              </div>
                            </li>
                            <li>
                              <div className="batchWorkList-ml">
                                <label style={{ color: "#4ad34a" }}>
                                  {t("ARTICLE")}
                                </label>

                                <span
                                  className="limitdata"
                                  title={data?.description}
                                >
                                  {data?.description}
                                </span>
                              </div>
                            </li>
                            <li className="weightW">
                              <div className="batchWorkList-ml">
                                <label style={{ color: "#4ad34a" }}>
                                  {t("theoretical weight (KG)")}
                                </label>

                                <span>{data?.weight_father}</span>
                              </div>
                            </li>
                            <li className="weightW">
                              <div className="batchWorkList-ml">
                                <label style={{ color: "#4ad34a" }}>
                                  {t("actual weight (KG)")}
                                </label>
                                {data?.actual_weight_father ? (
                                  <>
                                    <span>{data?.actual_weight_father}</span>
                                  </>
                                ) : (
                                  <span>
                                    <i className="far fa-minus"></i>
                                  </span>
                                )}
                              </div>
                            </li>
                            {/* <li className="actionW ms-auto">
                            <i
                              className="far fa-print"
                              style={{ cursor: "pointer" }}
                              onClick={() => {
                                printLabelHandler(data);
                              }}
                            ></i>
                          </li> */}
                            <li className="actionW ms-auto">
                              <div className="batchWorkList-ml">
                                <button
                                  onClick={(e) => {
                                    jobDeleteHandler(e, data, "father");
                                  }}
                                >
                                  <i className="far fa-minus-circle"></i>
                                </button>
                              </div>
                            </li>
                          </ul>
                        </div>
                      ))}

                    {table === "both" &&
                      fatherExecutionModalData.length > 0 &&
                      sonExecutionModalData.length > 0 ? (
                      <hr className="customHr" />
                    ) : null}
                    {sonExecutionModalData &&
                      sonExecutionModalData.map((data, index) => (
                        <div
                          className={`prodDescList ${data?.isActive ? "ischecked" : ""
                            }`}
                          key={index}
                        // onClick={() => onClickSonProductHandler(data)}
                        >
                          <ul className="list-unstyled batchlightColor">
                            <li>
                              <label>
                                {t("SON")} {index + 1}
                              </label>
                            </li>
                            <li className="lotW">
                              {data?.required ? (
                                <div className="batchWorkList-ml">
                                  <div class="sm-statusbox">
                                    <div class="statusCircle bg-danger"></div>
                                  </div>
                                </div>
                              ) : (
                                ""
                              )}
                            </li>
                            <li>
                              <div className="batchWorkList-ml">
                                <label>{t("ARTICLE")}</label>

                                <span
                                  className="limitdata"
                                  title={data?.description}
                                >
                                  {data?.description}
                                </span>
                              </div>
                            </li>
                            <li className="weightW">
                              <div className="batchWorkList-ml">
                                <label>{t("theoretical weight (KG)")}</label>
                                {data?.weight_son ? (
                                  <span>{data?.weight_son}</span>
                                ) : (
                                  <span>
                                    <i className="far fa-minus"></i>
                                  </span>
                                )}
                              </div>
                            </li>
                            <li className="weightW">
                              <div className="batchWorkList-ml">
                                <label>{t("actual weight (KG)")}</label>
                                {data?.actual_weight_son ? (
                                  <span>{data?.actual_weight_son}</span>
                                ) : (
                                  <span>
                                    <i className="far fa-minus"></i>
                                  </span>
                                )}
                              </div>
                            </li>
                            <li className="actionW ms-auto">
                              <i
                                className="far fa-print"
                                style={{ cursor: "pointer" }}
                                onClick={() => {
                                  printLabelHandler(data);
                                }}
                              ></i>
                            </li>
                            <li className="actionW ms-auto">
                              <div className="batchWorkList-ml">
                                <button
                                  onClick={(e) => {
                                    jobDeleteHandler(e, data, "son");
                                  }}
                                >
                                  <i className="far fa-minus-circle"></i>
                                </button>
                              </div>
                            </li>
                          </ul>
                        </div>
                      ))}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      ) : (
        history.push("/")
      )}
    </>
  );
};

export default ProduzioneLista;
