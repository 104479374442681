import React, { useEffect, useState, useRef } from "react";
import { WEIGHTICON } from "../Utils/SVG";
import useRequest from "../../hooks/useRequest";
import { useSelector, useDispatch } from "react-redux";
import { updateLoading } from "../../store/auth/action";
import { useTranslation } from "react-i18next";

const FatherProductList = (props) => {
  const {
    stock,
    product,
    isEdit,
    addMoreFatherProduct,
    setAddMoreFatherProduct,
    setBarcode,
    index,
  } = props;
  console.info("stock F : - ", stock)
  let relationship = product.relationship;
  let id = product.id;
  let uniqueId = product.unique_id;

  let name = product.name;

  let quantity = product.quantity;

  const [t, i18n] = useTranslation("translation");
  const [weight, setWeight] = useState(product.weight ? product.weight : 0);
  const [searchQuery, setSearchQuery] = useState("");
  const [templateProductData, setTemplateProductData] = useState([]);
  const { request: productSearchRequest, response: productSearchResponse } =
    useRequest();
  const [toSearch, setToSearch] = useState(isEdit !== true);
  const [isFirstSearch, setIsFirstSearch] = useState(true);
  const [barcodes, setBarcodes] = useState([]);

  const searchQueryRef = useRef();
  const dispatch = useDispatch();
  const [open, setOpen] = useState(false);
  const { request: lottosRequest, response: lottosResponse } = useRequest();
  const [lottos, setLottos] = useState([]);
  const [lottoLength, setLottoLength] = useState(0);

  const {
    request: fatherBatchCodedRequest,
    response: fatherBatchCodedResponse,
  } = useRequest();

  useEffect(() => {
    setSearchQuery(name);
  }, [name]);

  useEffect(() => {
    if (productSearchResponse && productSearchResponse.status === "SUCCESS") {
      /*
        name: "Agnello da latte porzionato (coscio, spalla, carrè) da 1 kg. ca."
        id_article: 7485
        kg_per_pieces: 1
        um: "KG"
        */

      setTemplateProductData(productSearchResponse.data.products);
    }
  }, [productSearchResponse]);

  useEffect(() => {
    if (searchQuery.trim().length > 0 && toSearch && !isFirstSearch) {
      searchQueryRef.current = setTimeout(() => {
        if(stock){
          productSearchRequest("GET", `search-product?name=${searchQuery}`);
          dispatch(updateLoading({ loading: false }));
        }else{
          productSearchRequest("GET", `products-data?name=${searchQuery}`);
          dispatch(updateLoading({ loading: false }));
        }
      }, 1000);
    }
    return () => {
      clearTimeout(searchQueryRef.current);
      setIsFirstSearch(false);
    };
  }, [searchQuery]);

  const lottoHandler = (id_article) => {
    if (id_article) {
      lottosRequest("GET", `get-lotto/${id_article}`);
    }
  };

  const setLottoHandler = (lotto, id) => {
    if (lotto === "return") {
      return;
    }
    const rows = [...addMoreFatherProduct];
    const index = rows.findIndex((prod) => prod.unique_id === uniqueId);
    rows[index] = { ...rows[index], lotto };
    setAddMoreFatherProduct(rows);
    fatherBatchCodedRequest("POST", "production-barcodes", {
      batch_code: lotto,
      id_article: id
    });
  };

  useEffect(() => {
    if (lottosResponse) {
      if (lottosResponse.status === "SUCCESS") {
        setLottoLength(lottosResponse?.data?.lotto?.length);
        let newData = addMoreFatherProduct.map((a) => {
          if (a.unique_id === uniqueId) {
            a.lottos = lottosResponse?.data?.lotto;
          }
          return a;
        });
        setAddMoreFatherProduct(newData);
      }
    }
  }, [lottosResponse]);

  useEffect(() => {
    if (
      fatherBatchCodedResponse &&
      fatherBatchCodedResponse?.status === "SUCCESS"
    ) {
      // let oldBarcodes = { ...barcodes };
      // oldBarcodes[index] = fatherBatchCodedResponse?.data?.barcodes;
      setBarcodes(fatherBatchCodedResponse?.data?.barcodes);
    }
  }, [fatherBatchCodedResponse]);

  const updateWeightHandler = (value, type) => {
    let newData;
    if (type === "input") {
      newData = addMoreFatherProduct.map((a) => {
        if (a.unique_id === uniqueId) {
          a.weight = value;
          setWeight(value)
        }
        return a;
      });
    } else if (type === "barcode") {
      barcodes.forEach((data) => {
        if (value === data.barcode) {
          newData = addMoreFatherProduct.map((a) => {
            if (a.unique_id === uniqueId) {
              let weight = +data.weight / +data.pieces
              setWeight(weight)
              a.weight = weight;
              a.barcode = data.barcode;
            }
            return a;
          });
        }
      });
    }
    setAddMoreFatherProduct(newData);
  };

  const barcodeHandler = (value, id) => {
    // product.barcode = value
    if (relationship === "main_father") {
      setBarcode(value);
    }
    updateWeightHandler(value, "barcode");
  };

  const removeAddFatherProduct = (id) => {
    const rows = [...addMoreFatherProduct];
    const index = rows.findIndex((row) => row.unique_id === id);
    rows.splice(index, 1);
    setAddMoreFatherProduct(rows);
  };

  const productFilterHandler = (data, id) => {
    const values = [...addMoreFatherProduct];

    const index = values.findIndex((prod) => prod.unique_id === id);

    values[index] = {
      ...values[index],
      ...data,
      lottos: [],
      lotto: "",
    };
    setBarcodes([]);
    setLottoLength(0);
    setAddMoreFatherProduct(values);
  };

  return (
    <>
      <ul
        className={
          relationship === "main_father"
            ? "list-unstyled batchyellowColor newListingFather"
            : "list-unstyled newListingFather"
        }
      >
        <>
          <li>
            <label>
              {t("FATHER")} {index + 1}
            </label>
          </li>

          <li className="lotW">
            <div className="batchWorkList-ml">
              <label>{t("lot")}</label>
              {lottoLength === 0 ? (
                <input
                  className="form-control"
                  type="text"
                  value={product?.lotto}
                  onClick={() => lottoHandler(id)}
                />
              ) : (
                <select
                  onChange={(e) => setLottoHandler(e.target.value, id)}
                  className="form-control form-select"
                >
                  <option value="return" hidden>
                    {" "}
                    {t("select the lot")}
                  </option>
                  {addMoreFatherProduct &&
                    addMoreFatherProduct.map((prod, idx) => {
                      if (prod.unique_id === uniqueId) {
                        return prod.lottos.map((lotto, index) => (
                          <option value={lotto?.batch_code} key={index}
                            selected={lotto.batch_code === product?.lotto ? true : false}
                          >
                            {lotto?.batch_code}
                          </option>
                        ));
                      }
                    })}
                </select>
              )}
            </div>
          </li>
          <li className="lotW">
            <div className="batchWorkList-ml">
              <label>{t("barcode")}</label>
              {lottoLength === 0 ? (
                <input
                  className="form-control"
                  type="text"
                  value={product?.barcode}
                />
              ) : (
              <select
                onChange={(e) => barcodeHandler(e.target.value, id)}
                className="form-control form-select"
              >
                <option value="return" hidden>
                  {" "}
                  {t("select the barcode")}
                </option>
                {barcodes &&
                  barcodes.map((barcode, idx) => (
                    <option value={barcode?.barcode} key={idx} selected={barcode?.barcode === product?.barcode ? true : false}>
                      Weight: {barcode.weight} &nbsp; Barcode: {barcode.barcode}{" "}
                      &nbsp;
                    </option>
                  ))}
              </select>
              )}
            </div>
          </li>
          <li className="idArticle_col">
            <div className="batchWorkList-ml">
              <label>{t("ID_ARTICLE")}</label>

              <span
                style={
                  relationship === "main_father"
                    ? { color: "#182749" }
                    : { color: "#fff" }
                }
              >
                {id}
              </span>
            </div>
          </li>

          <li>
            <div className="batchWorkList-ml articleCol">
              <label>{t("ARTICLE")}</label>
              <div className="position-relative w-100">
                <input
                  className="form-control"
                  type="text"
                  value={searchQuery}
                  onChange={(e) => {
                    setSearchQuery(e.target.value);
                    setToSearch(true);
                  }}
                />

                <ul className="list-unstyled mb-0 filterCustom-drop">
                  {templateProductData &&
                    templateProductData.map((product, index) => (
                      <li
                        key={index}
                        onClick={() => {
                          
                          productFilterHandler(
                            {
                              name: product.description,
                              weight: product.kg_per_pieces,
                              macrocat: product.macrocat,
                              id: product.id_article,
                            },
                            uniqueId
                          );
                          setTemplateProductData([]);
                          setToSearch(false);
                        }}
                      >
                        {product.id_article} - {product.description}
                      </li>
                    ))}
                </ul>
              </div>
            </div>
          </li>

          <li className="theoreticalW">
            <div className="batchWorkList-ml">
              <div className="d-flex align-items-center w-100">
                <WEIGHTICON />
                <label>{t("theoretical weight (KG)")}</label>
              </div>
              <input
                className="form-control weightInput"
                type="text"
                // defaultValue={realWieght}
                value={weight}
                onChange={(e) => {
                  updateWeightHandler(e.target.value, "input");
                }}
              />
            </div>
          </li>

          <li className="actionW ms-auto">
            <div className="batchWorkList-ml">
              <button onClick={() => removeAddFatherProduct(uniqueId)}>
                <i className="far fa-minus-circle"></i>
              </button>
            </div>
          </li>
        </>
      </ul>
    </>
  );
};

export default FatherProductList;
