import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { useHistory } from "react-router-dom";
import Swal from "sweetalert2";
import withReactContent from "sweetalert2-react-content";
import { toast } from "react-toastify";
import { useTranslation } from "react-i18next";
import Header from "../../components/header/header";
import SideBar from "../../components/Sidebar/sideBar";
import useRequest from "../../hooks/useRequest";
import Pagination from "../../components/Pagination/Pagination";
import {
  ADD,
  CANCEL,
  SEARCH,
  VIEW,
  MODIFICATION,
  DELETE,
} from "../../components/Utils/SVG";

const MySwal = withReactContent(Swal);

const searchQueryHandler = (searchQuery = "", per_page, page) => {
  return `users?username=${searchQuery}&per_page=${per_page}&page=${page}`;
};

const StaffIndex = () => {
  const history = useHistory();
  const [t, i18n] = useTranslation("translation");
  const [page, setPage] = useState(1);
  const [totalDocuments, setTotalDocuments] = useState(10);
  const [perPage, setPerPage] = useState(10);

  const [users, setUsers] = useState([]);

  const [searchQuery, setSearchQuery] = useState("");

  const { request, response } = useRequest();

  const { request: requestDelete, response: responseDelete } = useRequest();

  useEffect(() => {
    request("GET", searchQueryHandler(searchQuery, perPage, 1));
  }, []);

  useEffect(() => {
    if (response) {
      // setData(response.data);
      if (response.status === "SUCCESS") {
        setUsers(response.data.results);
        setTotalDocuments((prev) =>
          response?.data?.total_records ? response?.data?.total_records : prev
        );
      }
    }
  }, [response]);

  const deleteHandler = (id) => {
    MySwal.fire({
      title: t("Are you sure"),
      text: t("Do you want to delete"),
      icon: "warning",
      showCancelButton: true,
      confirmButtonText: t("Yup"),
      cancelButtonText: "No",
      reverseButtons: true,
    }).then((result) => {
      if (result.isConfirmed) {
        requestDelete("GET", `delete-user/${id}`);
      } else if (result.isDismissed) {
      }
    });
  };

  useEffect(() => {
    if (responseDelete) {
      if (responseDelete.status === "SUCCESS") {
        toast.success(responseDelete?.message);
        request("GET", "users");
      }
    }
  }, [responseDelete]);

  const searchHandler = () => {
    request("GET", searchQueryHandler(searchQuery));
  };

  const fetchMoreData = ({ selected }) => {
    setUsers([]);

    setPage(selected + 1);

    request("GET", searchQueryHandler(searchQuery, perPage, selected + 1));
  };

  const perPageChangeHandler = (event) => {
    request("GET", searchQueryHandler(searchQuery, event.target.value, 1));

    setPage(1);

    setPerPage(event.target.value);
  };

  const resetHandler = () => {
    request("GET", "users");
    setSearchQuery("");
  };

  return (
    <div className="dashboard_parent">
      <SideBar />
      <div className="dashboard_right">
        <Header
          title={t("User")}
          links={[{ to: "/utente", name: t("User") }]}
        />

        <div className="dashboard_content">
          <div className="dashboard-container">
            {/* Staff Table */}
            <div className="p-4">
              <div className="themeTable bg-white rounded-3 mb-4">
                <div className="p-3 border-bottom">
                  <div className="row">
                    <div className="col-12 text-end">
                      <Link to="/aggiungi-utente" className="btn-pill">
                        <ADD />
                        {t("Add user")}
                      </Link>
                    </div>
                  </div>
                </div>
                <div className="tableSearch border-bottom p-3 p-md-4">
                  <div className="row align-items-end">
                    <div className="col-lg-3">
                      <div className="mb-3 mb-lg-0">
                        <label>{t("Username")}</label>
                        <input
                          type="text"
                          value={searchQuery}
                          onChange={(e) => setSearchQuery(e.target.value)}
                          className="form-control"
                        />
                      </div>
                    </div>
                    <div className="col-auto">
                      <button className="btn-pill me-3" onClick={searchHandler}>
                        <SEARCH />
                        {t("Research")}
                      </button>
                      <button
                        className="btn-pill btn-pill-secondary"
                        onClick={resetHandler}
                      >
                        <CANCEL />
                        {t("Reset")}
                      </button>
                    </div>
                  </div>
                </div>

                <div className="tablebox p-3 p-md-4">
                  <div className="table-responsive">
                    <table className="table table-bordered table-striped">
                      <thead>
                        <tr>
                          <th>{t("First name")}</th>
                          <th>{t("Last name")}</th>
                          <th>{t("Username")}</th>
                          <th>E-mail</th>
                          <th>{t("Phone")}</th>
                          <th>{t("Role")}</th>
                          <th>{t("Activation date")}</th>
                          {/* <th>Status</th> */}
                          <th>{t("Actions")}</th>
                        </tr>
                      </thead>
                      <tbody>
                        {users &&
                          users.map((user) => (
                            <tr key={user.id_user}>
                              <td>{user?.name}</td>
                              <td>{user?.surename}</td>
                              <td>{user?.username}</td>
                              <td>{user?.email}</td>
                              <td>{user?.phone}</td>
                              <td>{user?.role?.description}</td>
                              <td>{user?.activation_date}</td>
                              <td>
                                <div className="d-flex justify-content-end">
                                  <button
                                    className="btn-square btn-info ms-2"
                                    onClick={() =>
                                      history.push(`/view/${user?.id_user}`)
                                    }
                                  >
                                    <VIEW />
                                  </button>

                                  <button
                                    className="btn-square btn-primary ms-2"
                                    onClick={() =>
                                      history.push(
                                        `/modificare/${user.id_user}`
                                      )
                                    }
                                  >
                                    <MODIFICATION />
                                  </button>

                                  <button
                                    className="btn-square btn-danger ms-2"
                                    onClick={() => deleteHandler(user.id_user)}
                                  >
                                    <DELETE />
                                  </button>
                                </div>
                              </td>
                            </tr>
                          ))}
                      </tbody>
                    </table>
                  </div>
                </div>
              </div>

              <Pagination
                page={page}
                totalRecords={totalDocuments}
                getNewData={fetchMoreData}
                perPage={perPage}
                defaultPerPage={10}
                perPageChangeHandler={perPageChangeHandler}
                currentDocLength={users.length}
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default StaffIndex;
