import React, { useEffect, useState, useRef } from "react";
import { WEIGHTICON } from "../../Utils/SVG";
import useRequest from "../../../hooks/useRequest";
import { updateLoading } from "../../../store/auth/action";
import { useSelector, useDispatch } from "react-redux";
import { useTranslation } from "react-i18next";
import { toast } from "react-toastify";

const SonProductModificationList = (props) => {
  const {
    product,
    isEdit,
    index,
    setIdArticle,
    sonProducts,
    setSonProducts,
  } = props;
  const name = product.description;
  const relationship = product.type ? product?.type : product?.relationship;
  const id = product.id;
  const id_article = product.id_son;
  const weight = product.weight_son ? product.weight_son : product.weight;
  const required = product.required;
  const sonId = product.id_son ? product.id_son : product.id;

  const [t, i18n] = useTranslation("translation");
  const [searchQuery, setSearchQuery] = useState("");
  const [templateProductData, setTemplateProductData] = useState([]);
  const { request: productSearchRequest, response: productSearchResponse } =
    useRequest();
  const { request: deleteJobProductRequest, response: deleteJobProductResponse } =
    useRequest();
  const [toSearch, setToSearch] = useState(isEdit !== true);
  const [isFirstSearch, setIsFirstSearch] = useState(true);
  const searchQueryRef = useRef();
  const dispatch = useDispatch();

  useEffect(() => {
    if (productSearchResponse && productSearchResponse.status === "SUCCESS") {
      /*
        description: "Agnello da latte porzionato (coscio, spalla, carrè) da 1 kg. ca."
        id_article: 7485
        kg_per_pieces: 1
        um: "KG"
        */
      setTemplateProductData(productSearchResponse?.data?.products);
    }
  }, [productSearchResponse]);
  useEffect(() => {
    // if (description) {
    setSearchQuery(name);
    // }
  }, [name]);

  useEffect(() => {
    if (searchQuery) {
      if (searchQuery.trim().length > 0 && toSearch && !isFirstSearch) {
        searchQueryRef.current = setTimeout(() => {
          productSearchRequest("GET", `products-data?name=${searchQuery}`);

          dispatch(updateLoading({ loading: false }));
        }, 1000);
      }
    }

    return () => {
      clearTimeout(searchQueryRef.current);
      setIsFirstSearch(false);
    };
  }, [searchQuery]);

  const sonProductFilterHandler = (data, id) => {
    const values = [...sonProducts];
    const index = values.findIndex((prod) => prod.id === id);
    values[index] = { ...values[index], ...data, id: id, id_son: data.id };
    setIdArticle(values[index].id_son);
    console.log(values);
    setSonProducts(values);
  };

  const removeSonDataHandler = (id) => {
    const rows = [...sonProducts];
    const index = rows.findIndex((row) => row.id_son === id);
    const product = rows[index]
    rows.splice(index, 1);
    setSonProducts(rows);
    if(product?.id_job_exec_son){
      deleteJobProductRequest('POST', 'delete-job-product', {
        id_job_exec: product.id_job_exec_son,
        type: 'son'
      })
    }
  };

  // useEffect(() => {
  //   if(deleteJobProductResponse && deleteJobProductResponse?.status === "SUCCESS"){
  //     toast.success(deleteJobProductResponse?.message);
  //   }else{
  //     toast.error(deleteJobProductResponse?.message);
  //   }
  // }, [deleteJobProductResponse])

  const updateRequiredHandler = (id, required) => {
    const rows = [...sonProducts];

    const index = rows.findIndex(
      (prod) => prod.unique_id === id
    );

    rows[index] = { ...sonProducts[index], required };

    setSonProducts(rows);
  };

  return (
    <ul
      className={
        relationship === "main"
          ? "list-unstyled batchyellowColor"
          : "list-unstyled batchlightColor"
      }
    >
      <>
        <li>
          <label>
            {t("SON")} {index + 1}
          </label>
        </li>

        <li className="lotW">
          <div className="batchWorkList-ml">
            <label>{t("ID_ARTICLE")}</label>

            <span style={{ color: "#182749" }}>{id_article}</span>
          </div>
        </li>
        <li>
          <div className="batchWorkList-ml">
            <label>{t("ARTICLE")}</label>

            <div className="position-relative w-100">
              <input
                className="form-control"
                type="text"
                value={searchQuery}
                onChange={(e) => {
                  setSearchQuery(e.target.value);
                  setToSearch(true);
                }}
              />

              <ul className="list-unstyled mb-0 filterCustom-drop">
                {templateProductData &&
                  templateProductData.map((product, index) => (
                    <li
                      key={index}
                      onClick={() => {
                        sonProductFilterHandler(
                          {
                            shelflife: product.shelflife,
                            description: product.description,
                            weight_son: product.kg_per_pieces,
                            macrocat: product.macrocat,
                            id: product.id_article,
                          },
                          id
                        );
                        setTemplateProductData([]);
                        setToSearch(false);
                      }}
                    >
                      {product.id_article} - {product.description}
                    </li>
                  ))}
              </ul>
            </div>
          </div>
        </li>

        <li className="theoreticalWCheck">
          <div className="batchWorkList-ml">
            <div className="d-flex align-items-center w-100">
              <div className="form-check mb-0 mt-1">
                <input
                  className="form-check-input"
                  title="Obbligatorio"
                  type="checkbox"
                  checked={product?.required}
                  onChange={(e) => updateRequiredHandler(product?.unique_id, e.target.checked)}
                />
              </div>
              <WEIGHTICON />
              <label>{t("theoretical weight (KG)")}</label>
            </div>
            <input className="form-control" type="text" defaultValue={weight} />
          </div>
        </li>
        <li className="actionW ms-auto">
          <div className="batchWorkList-ml">
            <button onClick={() => removeSonDataHandler(sonId)}>
              <i className="far fa-minus-circle"></i>
            </button>
          </div>
        </li>
      </>
    </ul>
  );
};

export default SonProductModificationList;
