import React, { useState, useEffect, useRef } from "react";
import { toast } from "react-toastify";
import { useLocation, useHistory } from "react-router-dom";
import { useSelector } from "react-redux";
import { useTranslation } from "react-i18next";
import DatePicker from "react-multi-date-picker";
import moment from "moment";
import SideBar from "../../components/Sidebar/sideBar";
import Header from "../../components/header/header";
import useRequest from "../../hooks/useRequest";

// <----start----->
import FatherProductList from "../../components/layout/fatherProductBox-layout";
import SonProductList from "../../components/layout/sonProductBox-layout";
import { CALCULATEICON } from "../../components/Utils/SVG";
// <----end----->

const ProduzioneAggiunta = () => {
  const [t, i18n] = useTranslation("translation");
  const location = useLocation();
  const history = useHistory();
  const { idDepartment, idWarehouse } = useSelector((state) => state.auth);
  const [jobQuantityValidation, setJobQuantityValidation] = useState(false);
  const [jobDateValidation, setJobDateValidation] = useState(false);
  const [date, setDate] = useState(moment().format("YYYY-MM-DD"));
  const [jobQuantity, setJobQuantity] = useState(1);

  // <-----Grv start ------->
  const [addMoreFatherProduct, setAddMoreFatherProduct] = useState([]);
  const [addMoreSonProduct, setAddMoreSonProduct] = useState([
    {
      id: "",
      name: "",
      price_coeff: "",
      weight: "",
      macrocat: "",
      shelflife: "",
      relationship: "",
      quantity: "",
      required: false,
    },
  ]);

  const [fatherCurrentId, setFatherCurrentId] = useState(0);
  const [sonProductCurrentId, setSonProductCurrentId] = useState(0);
  const [departments, setDepartments] = useState([]);

  const [barcode, setBarcode] = useState("");
  // const [barcodes, setBarcodes] = useState([]);
  const [department, setDepartment] = useState(idDepartment);
  const [departmentValidation, setDepartmentValidation] = useState(false);
  // <------end-------->

  const { request: createJobRequest, response: createJobResponse } =
    useRequest();
  const { request: fatherSonProdRequest, response: fatherSonProdResponse } =
    useRequest();
  const { request: sonProdRequest, response: sonProdResponse } = useRequest();

  const {
    request: fatherSonCalculationRequest,
    response: fatherSonCalculationResponse,
  } = useRequest();

  const { userRoleId } = useSelector((state) => state.auth);
  const { request: departmentRequest, response: departmentResponse } =
    useRequest();

  const { request: scanBarcodeRequest, response: scanBarcodeResponse } = useRequest();
  const [scanBarcode, setScanBarcode] = useState(null);

  const jobData = location.state ? location.state.job : "";
  const [stock, setStock] = useState(jobData ? false : true);
  const [idArticle, setIdArticle] = useState(null);

  const batchSon = location.state ? location.state.batchSon : "";

  useEffect(() => {
    document.title = "HQF - Produzione";
    document.body.classList.add("production");
    console.info("stock ---- ", stock);
  }, []);

  useEffect(() => {
    if (department) {
      setDepartmentValidation(false);
    }
    if (jobQuantity) {
      setJobQuantityValidation(false);
    }
  }, [department, jobQuantity]);

  useEffect(() => {
    if (jobData?.id_product_son) {
      fatherSonProdRequest(
        "GET",
        `get-father-son-products/${jobData.id_product_son}`
      );
    }
  }, []);

  useEffect(() => {
    departmentRequest("GET", `departments?id_warehouse=${idWarehouse}`);
  }, []);

  useEffect(() => {
    if (idArticle) {
      fatherSonProdRequest("GET", `get-father-son-products/${idArticle}`);
    }
  }, [idArticle]);

  useEffect(() => {
    if (departmentResponse) {
      if (departmentResponse.status === "SUCCESS") {
        setDepartments(departmentResponse?.data);
      }
    }
  }, [departmentResponse]);

  useEffect(() => {
    if (fatherSonProdResponse) {
      if (fatherSonProdResponse?.status === "SUCCESS") {
        setStock(false);

        let fatherData = fatherSonProdResponse?.data?.father_products.map(
          (data) => {
            data.lotto = "";
            return data;
          }
        );
        setAddMoreFatherProduct(fatherData);

        let sonArray = fatherSonProdResponse?.data?.son_products.map(
          (data, index) => {
            data.required = false;
            data.sonId = index;
            return data;
          }
        );

        setSonProductCurrentId(sonArray.length);
        // sonArray.splice(1, 1);
        setAddMoreSonProduct(sonArray);
      } else {
        toast.error(fatherSonProdResponse?.message);
      }
    }
  }, [fatherSonProdResponse]);

  useEffect(()=>{
    if(scanBarcode && scanBarcode.length === 20){
      scanBarcodeRequest('POST', `scan-barcode-product`, {barcode: scanBarcode})
    }
  }, [scanBarcode])
  useEffect(()=>{
    if(scanBarcodeResponse?.status === "SUCCESS"){
      setScanBarcode('')
      setStock(false);
      // Set father product data in father list
      let product = scanBarcodeResponse?.data?.product
      let barcode = scanBarcodeResponse?.data?.barcode
      let fatherProduct = {
        unique_id: addMoreFatherProduct.length > 0 ? addMoreFatherProduct.length + 1 : 0,
        id: product?.id_article,
        name: product?.product_name,
        weight: product?.weight,
        macrocat: product?.macrocat,
        shelflife: product?.shelflife,
        lotto: product?.batch_code,
        lottos: [],
        barcode: barcode,
        relationship: "main_father",
        price_coeff: ''
      }
      console.info(addMoreFatherProduct)
      console.info(fatherProduct)
      let fatherProducts = [...addMoreFatherProduct, fatherProduct]
      console.info(fatherProducts)
      setAddMoreFatherProduct(fatherProducts);
    }else if(scanBarcodeResponse?.status === "FAIL"){
      toast.error(scanBarcodeResponse?.message);
    }
  }, [scanBarcodeResponse])

  const setExecutionDateByJobData = () => {
    let date =
      jobData && jobData?.execution_date ? jobData?.execution_date : null;
    if (date) {
      date = date.split("/");
      let day = date[0];
      let month = date[1];
      let year = date[2];
      setDate(year + "-" + month + "-" + day);
    }
  };

  const jobSaveHandler = () => {
    if (jobQuantity) {
      setJobQuantityValidation(false);
    } else {
      setJobQuantityValidation(true);
    }

    if (date) {
      setJobDateValidation(false);
    } else {
      setJobDateValidation(true);
    }

    if (department) {
      setDepartmentValidation(false);
    } else {
      setDepartmentValidation(true);
    }

    if (jobQuantity && date && department) {
      if(addMoreFatherProduct.length > 0 && addMoreSonProduct.length > 0){
        createJobRequest("POST", "job-creation", {
          quantity: +jobQuantity,
          creation_date: date,
          father_products: addMoreFatherProduct,
          son_products: addMoreSonProduct,
          is_stock: jobData ? false : true,
          id_department: +department,
          weight_son:
            addMoreSonProduct.length > 0 ? addMoreSonProduct[0].weight : null,
          id_warehouse:
            jobData && jobData.id_warehouse ? jobData.id_warehouse : null,
          order_id: jobData && jobData.order_id ? jobData.order_id : null,
          state: jobData && jobData.state ? jobData.state : null,
          batch_son: batchSon,
          id_selling_point:
            jobData && jobData.id_selling_point ? jobData.id_selling_point : null,
        });
      }else{
        if(addMoreFatherProduct.length === 0){
          toast.error(t('Please select atleast one father product'));
        }else if(addMoreSonProduct.length === 0){
          toast.error(t('Please select atleast one son product'));
        }
      }
    }
  };

  useEffect(() => {
    if (createJobResponse) {
      if (createJobResponse?.status === "SUCCESS") {
        history.push("/produzione");
        toast.success(createJobResponse.message);
      } else {
        toast.error(createJobResponse.message);
      }
    }
  }, [createJobResponse]);

  useEffect(() => {
    if (sonProdResponse) {
      if (sonProdResponse?.status === "SUCCESS") {
        let lastIndex = addMoreSonProduct.length;
        let sonArray = sonProdResponse?.data?.son_products.map(
          (data, index) => {
            data.required = false;
            data.sonId = index;
            // data.sonId = index+lastIndex;
            return data;
          }
        );
        // let sonProducts = [...addMoreSonProduct, ...sonArray]
        let sonProducts = sonArray;
        setSonProductCurrentId(sonProducts.length);
        setAddMoreSonProduct(sonProducts);
      }
    }
  }, [sonProdResponse]);

  const getSonProducts = () => {
    let main_father_articleId = null;
    let sec_father_ids = [];
    let main_son_id = null;
    addMoreFatherProduct.map((data) => {
      if (data?.relationship === "main_father") {
        if (data?.id !== "") {
          main_father_articleId = data?.id;
        }
      }
      if (data?.relationship !== "main_father") {
        if (data?.id !== "") {
          sec_father_ids.push(data?.id);
        }
      }
    });
    addMoreSonProduct.map((data) => {
      if (data?.relationship === "main_son") {
        if (data?.id !== "") {
          main_son_id = data?.id;
        }
      }
    });
    sonProdRequest("POST", "get-son-products", {
      id_article: jobData ? jobData?.id_product_son : main_son_id,
      main_father_id_article: main_father_articleId,
      sec_father_id_articles: sec_father_ids,
    });
  };

  const calculateFatherSonProduct = () => {
    let main_father_articleId;
    let sec_father_ids = [];
    addMoreFatherProduct.map((data) => {
      if (data?.relationship === "main_father") {
        if (data?.id !== "") {
          main_father_articleId = data?.id;
        }
      }
      if (data?.relationship !== "main_father") {
        if (data?.id !== "") {
          sec_father_ids.push(data?.id);
        }
      }
    });
    fatherSonCalculationRequest("POST", "father-son-products", {
      main_father_id_article: main_father_articleId,
      sec_father_id_articles: sec_father_ids,
    });
  };

  const departmentObj = {
    packages: "Confezionamento",
    warehouse: "Lab. Magazzino",
    receiving: "Lab. Ricezino",
    lab_boning: "Lab. Disosso",
    lab_fish: "Lab. Pesce",
    lab_packaging: "Lab. Confezionamento",
    lab_minced_meat: "Lab. Macinati",
    lab_cheese: "Lab. Formaggi",
    lab_portioned: "Lab. Porzionati",
    lab_dry: "Lab. Secco",
    lab_tartare: "Lab. Tartara",
    stock_sendings: "Stock e Preparazione",
  };

  return (
    <>
      <div className="container">
        {userRoleId === 5 || userRoleId === 2 ? (
          <>
            <div className="dashboard_parent">
              {/* <!-- Sidebar --> */}
              <SideBar />
              <div className="dashboard_right  bg-white">
                {/* <!-- dashboard header --> */}

                <Header
                  title={t("Production")}
                  links={[{ to: "/produzione", name: t("production list") }]}
                />

                {/* <!-- dashboard Content --> */}

                <div className="dashboard_content">
                  <div className="dashboard-container bg-white">
                    {/* New Processing */}
                    <div className="NewProcess">
                      <div className="NewProcessHead">
                        <h3>{t("CONFIGURATION PROCESSING")}</h3>
                      </div>

                      <div className="NewProcessInner">
                        <div className="row align-items-center">
                          <div className="col-md-8">
                            <div className="row align-items-center">
                              <div className="col-md-7">
                                <div className="row align-items-center">
                                  <div className="col-md-5 mb-1 mb-md-0">
                                    <label>{t("EXECUTION DATE")}</label>
                                  </div>
                                  <div className="col-md-7">
                                    <div className="themeInput themeIS date_box">
                                      <DatePicker
                                        placeholder={t("Select the date")}
                                        value={date}
                                        onChange={(date) => {
                                          setDate(date.format());
                                        }}
                                        minDate={new Date()}
                                        format="YYYY-MM-DD"
                                      />
                                      <button
                                        type="button"
                                        className="themeIS-btn"
                                      >
                                        <i className="far fa-calendar-alt"></i>
                                      </button>
                                      {jobDateValidation && (
                                        <div className="custom-invalid-feedback-date">
                                          {t("This field is mandatory.")}
                                        </div>
                                      )}
                                    </div>
                                  </div>
                                </div>
                              </div>
                              <div className="col-md-5">
                                <div className="row align-items-center">
                                  <div className="col-auto mb-1 mb-md-0">
                                    <label>{t("QUANTITY")}</label>
                                  </div>
                                  <div className="col">
                                    <div className="themeInput date_box">
                                      <input
                                        type="number"
                                        min="0"
                                        placeholder="INS. NUM. PZ."
                                        className="form-control"
                                        value={jobQuantity}
                                        onChange={(e) =>
                                          setJobQuantity(e.target.value)
                                        }
                                      />
                                    </div>
                                    {jobQuantityValidation && (
                                      <div className="custom-invalid-feedback-date">
                                        {t("This field is mandatory.")}
                                      </div>
                                    )}
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>

                          <div className="col-md-3 ms-auto">
                            <label className="d-block text-center">
                              {t("Department")}
                            </label>
                            <select
                              onChange={(e) => setDepartment(e.target.value)}
                              className="form-select theme-select"
                            >
                              {departments &&
                                departments.map((item, index) => (
                                  <option
                                    key={index}
                                    value={item.id_department}
                                    selected={item.id_department == department ? true : false}
                                  >
                                    {departmentObj[item.department]}
                                  </option>
                                ))}
                            </select>
                            {departmentValidation && (
                              <div className="custom-invalid-feedback-date">
                                {t("This field is mandatory.")}
                              </div>
                            )}
                          </div>
                          <div className="row mt-4">
                            <div className="col-md-4">
                              <div className="headerInput themeInput">
                                <label for="qrcode">{t("Qr Code")}</label>
                                <input type="text" value={scanBarcode} onChange={(e) => {setScanBarcode(e.target.value)}} id="qrcode" className="form-control" placeholder={t("Qr Code")} autoFocus/>
                              </div>
                            </div>
                            <div className="col-md-8">
                              <div className="orderList-dataction justify-content-end px-0 pt-2">
                                <button
                                  className="btn bgBtnTheme "
                                  title="calculate product"
                                  onClick={getSonProducts}
                                >
                                  <CALCULATEICON />
                                  {/* <i className="far fa-undo"></i> */}
                                </button>

                                <button
                                  className="btn bgBtnTheme"
                                  title={t("To close")}
                                  onClick={() => history.push("/produzione")}
                                >
                                  <i className="far fa-times"></i>
                                </button>
                                <button
                                  className="btn bgBtnTheme me-0"
                                  title={t("Save")}
                                  onClick={jobSaveHandler}
                                >
                                  <i className="far fa-check"></i>
                                </button>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="container mt-md-4 mt-2">
                        <div className="batchWorkListBlock">
                          <div className="batchWorkList">
                            {!stock ? (
                              <>
                                {addMoreFatherProduct.length > 0 &&
                                  addMoreFatherProduct.map((product, index) => {
                                    return (
                                      <FatherProductList
                                        stock={stock}
                                        product={product}
                                        key={index}
                                        setAddMoreFatherProduct={
                                          setAddMoreFatherProduct
                                        }
                                        addMoreFatherProduct={
                                          addMoreFatherProduct
                                        }
                                        setBarcode={setBarcode}
                                        index={index}
                                      />
                                    );
                                  })}
                                <button
                                  className="batchWorkListAdd"
                                  onClick={() => {
                                    setAddMoreFatherProduct((prev) => [
                                      ...prev,
                                      {
                                        id: "",
                                        name: "",
                                        price_coeff: "",
                                        weight: "",
                                        macrocat: "",
                                        shelflife: "",
                                        relationship: "",
                                        unique_id:
                                          addMoreFatherProduct.length > 0
                                            ? addMoreFatherProduct[
                                                addMoreFatherProduct.length - 1
                                              ]?.unique_id + 1
                                            : 0,
                                      },
                                    ]);

                                    setFatherCurrentId((prev) => prev + 1);
                                  }}
                                >
                                  {t("ADD FATHER")}
                                  <i className="far fa-plus-circle"></i>
                                </button>
                              </>
                            ) : (
                              ""
                            )}

                            <hr className="customHr" />
                            {addMoreSonProduct.length > 0 ? (
                              <>
                                {addMoreSonProduct.map((product, index) => {
                                  return (
                                    <SonProductList
                                      stock={stock}
                                      key={product.unique_id}
                                      index={index}
                                      product={product}
                                      quantity={product.quantity}
                                      required={product.required}
                                      addMoreSonProduct={addMoreSonProduct}
                                      setAddMoreSonProduct={
                                        setAddMoreSonProduct
                                      }
                                      setIdArticle={setIdArticle}
                                    />
                                  );
                                })}
                                <button
                                  className="batchWorkListAdd"
                                  onClick={() => {
                                    setAddMoreSonProduct((prev) => [
                                      ...prev,
                                      {
                                        id: "",
                                        name: "",
                                        price_coeff: "",
                                        weight: "",
                                        macrocat: "",
                                        shelflife: "",
                                        relationship: "",
                                        unique_id:
                                          addMoreSonProduct[
                                            addMoreSonProduct.length - 1
                                          ].unique_id + 1 ?? 0,
                                      },
                                    ]);

                                    setSonProductCurrentId((prev) => prev + 1);
                                  }}
                                >
                                  {t("ADD SON")}
                                  <i className="far fa-plus-circle"></i>
                                </button>
                              </>
                            ) : null}
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </>
        ) : (
          history.push("/produzione")
        )}
      </div>
    </>
  );
};

export default ProduzioneAggiunta;
