import ZebraBrowserPrintWrapper from "zebra-browser-print-wrapper";
import React from "react";
export const SendingPrintLabel = async (data) => {
  const pdvName = data[0].customer_description;
  let pdvAddressRowOne; // 54 characters max, then use next line
  let pdvAddressRowTwo; // 54 characters max, then truncate
  let productsList = [];

  data.forEach((item, index) => {
    productsList.push([
      item?.product_description,
      item?.pieces,
      item?.id_article,
      item?.weight,
    ]);
  });

  const addressSplit = (name) => {
    const arr = name.match(/.{1,54}/g);
    let [addressOne, addressSecond] = arr;
    pdvAddressRowOne = addressOne;
    pdvAddressRowTwo = addressSecond;
  };

  if (data[0].address) {
    addressSplit(data[0].address);
  }

  //   const productsList = [
  //     ["productName01", "2", "147852369", "7.523"],
  //     ["productName02", "5", "456789123", "4.125"],
  //     ["productName03", "6", "789456123", "6.587"],
  //     ["productName04", "4", "369852147", "4.012"],
  //     ["productName05", "8", "213654897", "5.899"],
  //     ["productName06", "1", "145236987", "6.258"],
  //     ["productName07", "7", "547896312", "1.785"],
  //     ["productName08", "4", "856321479", "2.654"],
  //     ["productName09", "5", "639852471", "4.257"],
  //     ["productName10", "6", "325698741", "8.102"],
  //     ["productName11", "8", "798645231", "4.253"],
  //     ["productName12", "9", "201478567", "3.291"],
  //     ["productName13", "2", "235689741", "2.498"],
  //     ["productName14", "1", "124578693", "1.587"],
  //   ]; // first value in each array: Product Name, 48 characters max, then truncate; second value: Product Quantity; third value: Product ID.

  const productsListSize = productsList.length; // in this case, 14

  let totalQuantity = 0; // this is the sum of the quantities of each product, which is the second value in each array (in the placeholder data above, 2+5+6+4+8+1+7+4+5+6+8+9+2+1)
  for (let i = 0; i < productsList.length; i++) {
    totalQuantity += parseInt(productsList[i][1]);
  }
  totalQuantity = totalQuantity.toString();
  let totalWeight = 0; // this is the sum of the weights of each product, which is the fourth value in each array (in the placeholder data above, 7.523+4.125+6.587...)
  for (let i = 0; i < productsList.length; i++) {
    totalWeight += parseFloat(productsList[i][3]);
  }
  totalWeight = totalWeight.toString();

  const courierInfo = data[0].zone;

  const productsOnEachLabel = 10; // this is a fixed value: we can print a maximum of 10 products on each label

  async function printLabels() {
    for (let i = 0; i < productsList.length; i += productsOnEachLabel) {
      const productsToPrint = productsList.slice(i, i + productsOnEachLabel);
      printLabel(productsToPrint);
    }
  }

  printLabels();

  async function printLabel(pTP) {
    try {
      // Create a new instance of the object
      const browserPrint = new ZebraBrowserPrintWrapper();
      // Select default printer
      const defaultPrinter = await browserPrint.getDefaultPrinter();
      // Set the printer
      browserPrint.setPrinter(defaultPrinter);
      // Check if the printer is ready
      const printerStatus = await browserPrint.checkPrinterStatus();

      let grande_nonmeat = `^XA
                    ~TA000
                    ~JSN
                    ^LT0
                    ^MNW
                    ^MTT
                    ^PON
                    ^PMN
                    ^LH0,0
                    ^JMA
                    ^PR6,6
                    ~SD15
                    ^JUS
                    ^LRN
                    ^CI27
                    ^PA0,1,1,0
                    ^XZ
                    ^XA
                    ^MMT
                    ^PW831
                    ^LL799
                    ^LS0
                    ^FT45,258^A0N,34,41^FH\^CI28^FD${pdvName}^FS^CI27
                    ^FPH,1^FT49,313^A0N,27,28^FH\^CI28^FD${pdvAddressRowOne}^FS^CI27
                    ^FPH,1^FT49,347^A0N,27,28^FH\^CI28^FD${pdvAddressRowTwo}^FS^CI27
                    ^FT51,438^AAN,18,10^FH\^FD${pTP[0] ? pTP[0][2] : ""}^FS
                    ^FT793,438^AAN,27,15^FH\^FD${pTP[0] ? pTP[0][1] : ""}^FS
                    ^FT193,438^AAN,18,10^FH\^FD${pTP[0] ? pTP[0][0] : ""}^FS
                    ^FT51,465^AAN,18,10^FH\^FD${pTP[1] ? pTP[1][2] : ""}^FS
                    ^FT793,465^AAN,27,15^FH\^FD${pTP[1] ? pTP[1][1] : ""}^FS
                    ^FT193,465^AAN,18,10^FH\^FD${pTP[1] ? pTP[1][0] : ""}^FS
                    ^FT51,411^AAN,18,10^FH\^FD${pTP[2] ? pTP[2][2] : ""}^FS
                    ^FT793,411^AAN,27,15^FH\^FD${pTP[2] ? pTP[2][1] : ""}^FS
                    ^FT193,411^AAN,18,10^FH\^FD${pTP[2] ? pTP[2][0] : ""}^FS
                    ^FT51,490^AAN,18,10^FH\^FD${pTP[3] ? pTP[3][2] : ""}^FS
                    ^FT793,490^AAN,27,15^FH\^FD${pTP[3] ? pTP[3][1] : ""}^FS
                    ^FT193,490^AAN,18,10^FH\^FD${pTP[3] ? pTP[3][0] : ""}^FS
                    ^FPH,1^FT45,707^A0N,23,30^FH\^CI28^FDTotale pezzi/Total pcs^FS^CI27
                    ^FPH,1^FT476,707^A0N,23,23^FH\^CI28^FDTotale lordo/Total gross^FS^CI27
                    ^FPH,1^FT362,708^A0N,27,28^FH\^CI28^FD${totalQuantity}^FS^CI27
                    ^FPH,1^FT741,708^A0N,27,28^FH\^CI28^FD${totalWeight}^FS^CI27
                    ^FPH,1^FT45,783^A0N,45,46^FH\^CI28^FD${courierInfo}^FS^CI27
                    ^FT51,544^AAN,18,10^FH\^FD${pTP[4] ? pTP[4][2] : ""}^FS
                    ^FT793,544^AAN,27,15^FH\^FD${pTP[4] ? pTP[4][1] : ""}^FS
                    ^FT193,544^AAN,18,10^FH\^FD${pTP[4] ? pTP[4][0] : ""}^FS
                    ^FT51,571^AAN,18,10^FH\^FD${pTP[5] ? pTP[5][2] : ""}^FS
                    ^FT793,571^AAN,27,15^FH\^FD${pTP[5] ? pTP[5][1] : ""}^FS
                    ^FT193,571^AAN,18,10^FH\^FD${pTP[5] ? pTP[5][0] : ""}^FS
                    ^FT51,517^AAN,18,10^FH\^FD${pTP[6] ? pTP[6][2] : ""}^FS
                    ^FT793,517^AAN,27,15^FH\^FD${pTP[6] ? pTP[6][1] : ""}^FS
                    ^FT193,517^AAN,18,10^FH\^FD${pTP[6] ? pTP[6][0] : ""}^FS
                    ^FT51,596^AAN,18,10^FH\^FD${pTP[7] ? pTP[7][2] : ""}^FS
                    ^FT793,596^AAN,27,15^FH\^FD${pTP[7] ? pTP[7][1] : ""}^FS
                    ^FT193,596^AAN,18,10^FH\^FD${pTP[7] ? pTP[7][0] : ""}^FS
                    ^FT51,650^AAN,18,10^FH\^FD${pTP[8] ? pTP[8][2] : ""}^FS
                    ^FT793,650^AAN,27,15^FH\^FD${pTP[8] ? pTP[8][1] : ""}^FS
                    ^FT193,650^AAN,18,10^FH\^FD${pTP[8] ? pTP[8][0] : ""}^FS
                    ^FT51,623^AAN,18,10^FH\^FD${pTP[9] ? pTP[9][2] : ""}^FS
                    ^FT793,623^AAN,27,15^FH\^FD${pTP[9] ? pTP[9][1] : ""}^FS
                    ^FT193,623^AAN,18,10^FH\^FD${pTP[9] ? pTP[9][0] : ""}^FS
                    ^PQ1,0,1,Y
                    ^XZ`;
      if (printerStatus.isReadyToPrint) {
        // ZPL script to print a qr code
        browserPrint.print(grande_nonmeat);
        console.log("Print success");
      } else {
        console.log("Error/s", printerStatus.errors);
        setTimeout(function (pTP) {
          browserPrint.print(grande_nonmeat);
        }, 640);
      }
    } catch (error) {
      throw new Error(error);
    }
  }

  //   React.useLayoutEffect(() => {
  //     //printLabel();
  //   }, []);

  //   return (
  //     <div className="App">
  //       <button type="button" onClick={printLabels}>
  //         <i className="far fa-print"></i>
  //       </button>
  //     </div>
  //   );
};

// export default SendingPrintLabel;
