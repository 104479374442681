import React, { useState, useEffect } from "react";
import { useHistory, useLocation } from "react-router-dom";
import { toast } from "react-toastify";
import withReactContent from "sweetalert2-react-content";
import { useSelector, useDispatch } from "react-redux";
import Swal from "sweetalert2";
import useRequest from "../../hooks/useRequest";
import SideBar from "../../components/Sidebar/sideBar";
// import Modal from "react-modal";
import Header from "../../components/header/header";
import { updateOrderRef } from "../../store/product/action";
import { MESSAGE } from "../../components/Utils/SVG";
import { addModelClass, removeModelClass } from "../../components/Utils/Utils";
import { useTranslation } from "react-i18next";
import { PrintLabelRicevimentoMeat } from "../../components/printlabel/receiving/ricevimentoMeatPrintLabel";
import { PrintLabelRicevimentoDry } from "../../components/printlabel/receiving/ricevimentoDryPrint";
import { generateBarCode } from "../../components/Utils/generateBarCode";
import Autosuggest from "react-autosuggest";
import { getFilteredProducts } from "../../components/Utils/Utils";

const MySwal = withReactContent(Swal);

const RicevimentoLista = (props) => {
  const { userRoleId } = useSelector((state) => state.auth);
  const history = useHistory();
  const location = useLocation();
  const [t, i18n] = useTranslation("translation");
  const [itemNote, setItemNote] = useState("");
  const [isOpenChat, setIsOpenChat] = useState(false);
  const [isCloseDisable, setIsCloseDisable] = useState(false);
  const [comment, setComment] = useState({});
  const [barcode, setBarcode] = useState("");

  const [order, setOrder] = useState(
    location.state ? location.state.order : ""
  );
  const [showDeleteButton, setShowDeleteButton] = useState(
    location.state?.showDeleteButton
  );
  const [orderRef, setOrderRef] = useState("");
  const { request, response } = useRequest();
  const { request: deleteRequest, response: deleteResponse } = useRequest();
  const { request: orderCloseRequest, response: orderCloseResponse } =
    useRequest();
  const { request: printDataRequest, response: printDataResponse } =
    useRequest();
  const { request: receivingProductSearchRequest, response: receivingProductSearchResponse } =
    useRequest();

  const dispatch = useDispatch();
  const [receivingData, setReceivingData] = useState();
  const [processedProducts, setProcessedProducts] = useState([]);
  const [notProcessedProducts, setNotProcessedProducts] = useState([]);
  const refState = useSelector((state) => state.product);
  const [articles, setArticles] = useState([]);
  const [orderStatus, setOrderStatus] = useState("");
  const [stateFlag, setStateFlag] = useState("");
  const [processProductFlag, setProcessProductFlag] = useState(false);
  const [isPlayButton, setIsPlayButton] = useState(false);
  const [products, setProducts] = useState([]);
  const [productSuggestion, setProductSuggestion] = useState([]);
  const [selectedProductName, setSelectedProductName] = useState("");
  const [productSearchQuery, setProductSearchQuery] = useState("");

  // const [edit]
  let order_id = `${order.id_oda}`;
  let delivery_date = order.delivery_date;

  useEffect(() => {
    if (!order || order == null) {
      handleBack();
    }
  }, [order]);

  const handleBack = () => {
    history.push({
      pathname: "/ricezione",

      state: {
        order: order || null,
      },
    });
    localStorage.setItem("orderRef", "");
  };

  useEffect(() => {
    request("POST", "receiving-products", { order_id, delivery_date });
    setBarcode(generateBarCode("R"));
    orderStatusUpdate();
  }, []);

  const getArticles = (products) => {
    {
      products &&
        products.map((product) => {
          articles.push(product.id_article);
        });
    }
  };

  const stateFlagManage = (notProcessedProducts, processedProducts) => {
    if (notProcessedProducts === 1 && processedProducts === 0) {
      setStateFlag("last");
    } else if (notProcessedProducts > 1 && processedProducts === 0) {
      setStateFlag("first");
    } else if (notProcessedProducts > 1 && processedProducts >= 1) {
      setStateFlag("");
    } else if (notProcessedProducts === 1 && processedProducts === 1) {
      setStateFlag("last");
    } else if (notProcessedProducts === 1 && processedProducts > 1) {
      setStateFlag("last");
    } else {
      setStateFlag("");
    }
  };

  useEffect(() => {
    if (response) {
      if (response.status === "SUCCESS") {
        setProducts(response?.data?.product_names)
        setReceivingData(response?.data);
        let docRefNum = response?.data.doc_ref_num ? response?.data?.doc_ref_num : ""
        setOrderRef(docRefNum);
        localStorage.setItem("orderRef", docRefNum);
        let orderRef = docRefNum
        dispatch(updateOrderRef({ orderRef }));

        const refId = localStorage.getItem("orderRef");
        const receivingDocRef = response?.data.doc_ref_num;
        const resStateRef = refState?.orderRef;
        if (!receivingDocRef && !orderRef && !resStateRef && !refId) {
          addModelClass("editodernum");
        } else if (receivingDocRef) {
          setOrderRef(receivingDocRef);
          removeModelClass("editodernum");
        } else if (refId) {
          setOrderRef(refId);
        } else {
          setOrderRef(resStateRef);
          localStorage.setItem("orderRef", resStateRef);
        }

        setProcessedProducts(response?.data?.processed_products);
        getArticles(response?.data?.processed_products);
        setNotProcessedProducts(response?.data?.not_processed_products);
        let processedProducts = response?.data?.processed_products.length;

        if (processedProducts > 0) {
          setProcessProductFlag(true);
        } else {
          setProcessProductFlag(false);
          setIsPlayButton(true);
        }
        let notProcessedProducts =
          response?.data?.not_processed_products.length;

        if (processedProducts > 0 && notProcessedProducts !== 0) {
          setOrderStatus("text-warning");
        } else if (notProcessedProducts === 0) {
          setOrderStatus("text-success");
        }

        stateFlagManage(notProcessedProducts, processedProducts);
      }
    }
  }, [response]);

  useEffect(() => {
    if (deleteResponse) {
      if (deleteResponse.status === "SUCCESS") {
        toast.success(deleteResponse?.message);
        request("POST", "receiving-products", { order_id, delivery_date });
      }
    }
  }, [deleteResponse]);

  const handleClick =
    (
      myLink,
      item,
      order,
      orderStatus,
      stateFlag,
      processProductFlag,
      barcode
    ) =>
      () => {
        dispatch(updateOrderRef({ orderRef }));
        history.push({
          pathname: myLink,
          state: {
            item,
            order,
            orderStatus,
            stateFlag,
            processProductFlag,
            barcode,
          },
        });
        // localStorage.setItem("orderRef", "");
      };

  const closeHandler = () => {

    if (orderRef !== "" && orderRef !== null) {
      if (orderRef.trim().length === 0) {
        setOrderRef("");
        return;
      }

      removeModelClass("editodernum");
    } else {
      history.push("/ricezione");
      localStorage.setItem("orderRef", "");
    }
  };

  const saveStateHandler = () => {
    removeModelClass("editodernum");
  };

  const editHandler = () => {
    addModelClass("editodernum");
  };

  const showCommentHandler = (index) => {
    let data = notProcessedProducts[index];
    addModelClass("showChatData");

    setItemNote(data?.note);
  };

  const closeChatModelHandler = () => {
    removeModelClass("showChatData");
  };

  const deleteProduct = (id) => {
    MySwal.fire({
      title: t("Are you sure"),
      text: t("Do you want to delete"),
      icon: "warning",
      showCancelButton: true,
      confirmButtonText: t("Yup"),
      confirmButtonColor: "#FF0000",
      cancelButtonText: "No",
      reverseButtons: true,
    }).then((result) => {
      if (result.isConfirmed) {
        deleteRequest("GET", `product-delete/${id}`);
        orderStatusUpdate();
      } else if (result.isDismissed) {
      }
    });
  };

  const orderCloseHandler = (isClose, isSafe) => {
    if (isClose) {
      if (order) {
        setIsPlayButton(true);
        let url = ''
        if (isSafe) {
          url = 'close-all-orders-safe'
        } else {
          url = 'close-all-orders'
        }
        orderCloseRequest("POST", url, {
          doc_ref_num: orderRef,
          order_id,
          delivery_date,
          processed_products: processedProducts.map((prod) => ({
            article_id: prod?.id_article,
            id_receiving: prod?.id_receiving,
          })),
          not_processed_products: notProcessedProducts,
        });
      }
    } else {
      history.push("/ricezione");
      localStorage.setItem("orderRef", "");
      // if(orderStatus === "text-success"){
      //   if (order) {
      //     orderCloseRequest("POST", "close-all-orders", {
      //       doc_ref_num: orderRef,
      //       order_id,
      //       delivery_date,
      //       processed_products: processedProducts.map((prod) => ({
      //         article_id: prod?.id_article,
      //         id_receiving: prod?.id_receiving,
      //       })),
      //       not_processed_products: notProcessedProducts,
      //     });
      //   }
      // }else{
      //   history.push("/ricezione");
      //   localStorage.setItem("orderRef", "");
      // }
    }
  };

  useEffect(() => {
    if (orderCloseResponse) {
      setIsPlayButton(false);
      if (orderCloseResponse.status === "SUCCESS") {
        toast.success(orderCloseResponse?.message);
        history.push("/ricezione");
        localStorage.setItem("orderRef", "");
      } else {
        toast.error(orderCloseResponse?.message);
      }
    }
  }, [orderCloseResponse]);

  const orderStatusUpdate = () => {
    const cls =
      order?.total_quantity === order?.total_missing_quantity_process
        ? "text-danger"
        : order?.total_missing_quantity_process === 0 &&
          order?.total_quantity > 0
          ? "text-success"
          : "text-warning";

    setOrderStatus(cls);
  };

  const productCommentHandler = (comment) => {
    if (comment) {
      setComment(comment);
    }
  };

  const detectEnterPress = (e) => {
    if (e.key === "Enter" || e.keyCode === 13) {
      if (orderRef.trim().length === 0) {
        setOrderRef("");
        return;
      }

      closeHandler();
    }
  };

  const remainingQty = (item) => {
    if (item?.quantity === item?.missing_quantity_process) {
      return item?.pieces;
    } else {
      return item?.missing_quantity_process;
    }
  };

  const weightCalc = (item) => {
    if (item?.pieces !== item?.missing_quantity_process) {
      return item?.kg_per_pieces * item?.missing_quantity_process;
    } else {
      return item?.weight;
    }
  };

  useEffect(() => {
    if (printDataResponse) {
      if (printDataResponse?.status === "SUCCESS") {
        if (printDataResponse?.data?.product_type === "Dry") {
          PrintLabelRicevimentoDry(
            printDataResponse?.data?.product,
            printDataResponse?.data?.pieces
          );
        } else {
          PrintLabelRicevimentoMeat(
            printDataResponse?.data?.product,
            printDataResponse?.data?.pieces
          );
        }
      }
    }
  }, [printDataResponse]);

  const printLabelHandler = (id) => {
    printDataRequest("GET", `get-rece-product/${id}`);
  };

  const renderSuggestion = (suggestion) => suggestion;

  const searchProduct = ({ value, reason }) => {
    const filteredProducts = getFilteredProducts(value, products);
    setProductSuggestion(filteredProducts);
  }

  const afterSearchClear = () => {
    setProductSuggestion(products);
  };

  const getSelectedValue = (suggestion) => {
    setProductSuggestion(products);
    setSelectedProductName(suggestion);
    receivingProductSearchRequest(
      "POST",
      "receiving-product-search",
      {
        product_name: suggestion,
        order_id: order_id,
        delivery_date: delivery_date
      }
    )
    return suggestion;
  };

  useEffect(() => {
    if (receivingProductSearchResponse && receivingProductSearchResponse?.status === "SUCCESS") {
      setNotProcessedProducts(receivingProductSearchResponse?.data?.not_processed_products);
      setProductSearchQuery(selectedProductName)
    }
  }, [receivingProductSearchResponse])

  const onChange = (e) => {
    e.preventDefault();
    setProductSearchQuery(e.target.value)
  };

  const inputProps = {
    placeholder: t("product name"),
    value: productSearchQuery ? productSearchQuery : "",
    onChange,
  };

  const handleSearchReset = () => {
    setProductSearchQuery('')
    setSelectedProductName('');
    receivingProductSearchRequest(
      "POST",
      "receiving-product-search",
      {
        product_name: '',
        order_id: order_id,
        delivery_date: delivery_date
      }
    )
  }

  return (
    <>
      {userRoleId === 5 || userRoleId === 1 ? (
        <div>
          <div className="dashboard_parent">
            {/* <!-- Sidebar --> */}
            <SideBar />
            <div className="dashboard_right">
              {/* <!-- dashboard header --> */}

              <Header
                title={t("receipt")}
                links={[{ to: "/ricezione", name: t("order list") }]}
              />

              {/* <!-- dashboard Content --> */}

              <div className="dashboard_content">
                <div className="dashboard-container">
                  <div className="p-3">
                    <div className="orderList-data">
                      {/* Order List Item */}

                      <div className="orderList-row">
                        {orderStatus !== "text-success" ? (
                          <div className="orderStatus">
                            <i className={`fa fa-caret-right ${orderStatus}`}></i>
                          </div>
                        ) : (
                          <div className="orderStatus orderStatusLast">
                            <i className="fa fa-caret-right text-secondary"></i>
                          </div>
                        )}
                        <div className="orderno">
                          <label>{t("order n")}</label>
                          <h6>{order?.id_oda}</h6>
                        </div>
                        <div className="suppliername">
                          <label>{t("supplier")}</label>
                          {order?.provider_name ? (
                            <h6>{order?.provider_name}</h6>
                          ) : (
                            <h6>{order?.batch?.provider_name}</h6>
                          )}
                        </div>
                        <div className="ownername">
                          <label>{t("qty")}</label>
                          {/* <h6>10pz / 30,00 kg</h6> */}
                          <h6>
                            {order?.total_pieces} pz / {order?.total_weight} kg
                          </h6>
                        </div>
                        <div className="orderdate">
                          <label>{t("order date")}</label>
                          <h6>{order?.delivery_date}</h6>
                        </div>
                        <div className="docorder">
                          <label>{t("Order document no")}</label>
                          <h6>
                            <>
                              {orderRef}
                              {orderStatus !== "text-success" ? (
                                <button
                                  className="btn btn-primary square-sm edit_btn"
                                  // data-bs-toggle="modal"
                                  // data-bs-target="#editodernum "text-success""
                                  // onClick={editHandler}
                                  onClick={
                                    orderStatus !== "text-success"
                                      ? editHandler
                                      : () => {
                                        return false;
                                      }
                                  }
                                  title={t("Document reference number")}
                                >
                                  <i className="fas fa-pencil"></i>
                                </button>
                              ) : (
                                ""
                              )}
                            </>
                          </h6>
                        </div>

                        {orderStatus === "text-success" ? (
                          <div className="orderStatus orderStatusLast">
                            <i
                              className={`fa fa-caret-right ${orderStatus} `}
                            ></i>
                          </div>
                        ) : (
                          <div className="orderStatus orderStatusLast">
                            <i className="fa fa-caret-right text-secondary"></i>
                          </div>
                        )}
                      </div>


                      {/* Table */}
                      <div className="orderList-tabledata">
                        <div className="row">
                          <div className="col-md-4 offset-8">
                            <div className="supplierSearch  themeIS mb-5">
                              <label>{t("Search for products")}</label>
                              <form
                                onSubmit={(e) => { e.preventDefault() }}
                                className="themeInput position-relative"
                              >
                                <Autosuggest
                                  suggestions={productSuggestion}
                                  onSuggestionsFetchRequested={
                                    searchProduct
                                  }
                                  onSuggestionsClearRequested={
                                    afterSearchClear
                                  }
                                  // alwaysRenderSuggestions={true} 
                                  getSuggestionValue={getSelectedValue}
                                  renderSuggestion={renderSuggestion}
                                  inputProps={inputProps}
                                />
                                <button className="themeIS-btn" type="submit" onClick={handleSearchReset}>
                                  <i className="fas fa-search"></i>
                                </button>
                              </form>
                            </div>
                          </div>
                        </div>
                        <div className="table-responsive">
                          <table className="table themeTable table-bordered table-striped">
                            <thead>
                              <tr className="">
                                <th>{t("Art")}</th>
                                <th className="bigcol">{t("Description")}</th>
                                <th>{t("Weight")}</th>

                                <th>{t("Pieces")}</th>
                                <th>{t("Minimum threshold")}</th>
                                <th>{t("Note")}</th>
                              </tr>
                            </thead>
                            <tbody>
                              {notProcessedProducts &&
                                notProcessedProducts.map((item, index) => (
                                  <tr
                                    onClick={handleClick(
                                      "/dettagli-ordine",
                                      item || null,
                                      order || null,
                                      orderStatus || "",
                                      stateFlag || "",
                                      processProductFlag,
                                      barcode
                                    )}
                                    className="cursor-pointer"
                                    key={index}
                                  >
                                    <td data-th={t("Article Id")}>
                                      {item?.id_article}
                                    </td>
                                    <td
                                      className="bigcol"
                                      data-th={t("Description")}
                                    >
                                      {item?.product_name}
                                    </td>
                                    {/* {item?.unit_mesure === "KG" ? (
                                      <td>{item?.kg_per_pieces.toFixed(2)}</td>
                                    ) : (
                                      <td>
                                        {(
                                          item?.pieces * item?.kg_per_pieces
                                        ).toFixed(2)}
                                      </td>
                                    )} */}
                                    <td data-th={t("Weight")}>
                                      {/* {weightCalc(item)} */}

                                      {(
                                        +item?.weight - +item?.received_weight
                                      ).toFixed(3)}
                                    </td>

                                    <td>
                                      {/* {remainingQty(item)}
                                      */}
                                      {item?.pieces - item?.received_pieces}
                                    </td>

                                    <td>{item?.soglia}</td>
                                    <td>
                                      <button
                                        className="btn btn-primary square-sm edit_btn"
                                        // data-bs-toggle="modal"
                                        // data-bs-target="#editodernum"
                                        onClick={(e) => {
                                          e.stopPropagation();
                                          showCommentHandler(index);
                                        }}
                                        title={t("Comment")}
                                      >
                                        <MESSAGE />
                                      </button>
                                    </td>
                                  </tr>
                                ))}
                            </tbody>
                          </table>
                        </div>

                        <div className="productaddedit">
                          <span className="productaddedit-head">
                            {t("PRODUCTS")}
                          </span>
                        </div>
                      </div>

                      {/* Table */}
                      <div className="orderList-esc">
                        <h4 className="text-white">{t("ESCAPED")}</h4>
                        <div className="table-responsive">
                          <table className="table themeTable table-bordered table-striped">
                            <thead>
                              <tr>
                                <th>{t("Art")}</th>
                                <th className="bigcol">{t("Description")}</th>
                                <th>{t("Weight")}</th>

                                <th>{t("Pieces")}</th>
                                <th>{t("Lot")}</th>
                                <th>{t("Action")}</th>
                              </tr>
                            </thead>
                            <tbody>
                              {processedProducts &&
                                processedProducts.map((item, index) => (
                                  <tr className="cursor-pointer" key={index}>
                                    <td>{item?.batch.id_article}</td>
                                    <td className="bigcol">
                                      {item?.batch.product_name}
                                    </td>
                                    {/* {item?.unit_meassure === "KG" ? (
                                      <td>{item?.kg_per_piece}</td>
                                    ) : (
                                      <td>{item?.weight}</td>
                                    )} */}
                                    <td>{(+item?.weight).toFixed(3)}</td>
                                    <td>{item?.pieces}</td>
                                    <td>{item?.batch.batch_code}</td>
                                    <td>
                                      {item?.is_comment ? (
                                        <button
                                          title={t("Comment")}
                                          className={`btn customToggle ${isOpenChat ? "show" : ""
                                            }`}
                                          onClick={() => {
                                            setIsOpenChat((prev) => !prev);
                                            productCommentHandler(item?.comment);
                                          }}
                                        >
                                          <i className="far fa-exclamation-triangle text-danger fs-5"></i>
                                        </button>
                                      ) : (
                                        ""
                                      )}
                                      <i
                                        className="far fa-print"
                                        style={{ cursor: "pointer" }}
                                        onClick={() => {
                                          printLabelHandler(item?.id_receiving);
                                        }}
                                      ></i>
                                      {!showDeleteButton ? (
                                        <button
                                          className="btn"
                                          onClick={() =>
                                            deleteProduct(item?.id_receiving)
                                          }
                                          title={t("Delete processed product")}
                                        >
                                          <i className="far fa-times"></i>
                                        </button>
                                      ) : (
                                        ""
                                      )}
                                    </td>
                                  </tr>
                                ))}
                            </tbody>
                          </table>
                        </div>
                      </div>
                    </div>

                    <div className="orderList-dataction">
                      <button
                        className="btn"
                        onClick={() => { orderCloseHandler(false, false) }}
                        title={t("Back")}
                      >
                        <i className="far fa-times"></i>
                      </button>

                      {!showDeleteButton ? (
                        <button
                          className="btn "
                          onClick={() => { orderCloseHandler(true, false) }}
                          title={t("Close product")}
                          disabled={isPlayButton}
                        >
                          <i className="fa fa-play"></i>
                        </button>
                      ) : (
                        ""
                      )}

                      {!showDeleteButton ? (
                        <button
                          className="btn "
                          onClick={() => { orderCloseHandler(true, true) }}
                          title={t("Close product partially")}
                          disabled={isPlayButton}
                        >
                          <i class="fa fa-arrow-up" aria-hidden="true"></i>

                         {/* <i className="fa fa-play-circle-o" aria-hidden="true"></i> */}

                        </button>
                      ) : (
                        ""
                      )}
                    </div>
                  </div>
                </div>
                {/* <!-- dashboard-container  END --> */}
              </div>
              {/* <!-- dashboard_content END --> */}
            </div>
          </div>

          {/* <!-- Modal -->   */}
          <div
            className="modal fade "
            id="editodernum"
            tabIndex="-1"
            aria-labelledby="editodernumLabel"
            aria-hidden="true"
          >
            <div className="modal-dialog modal-dialog-centered">
              <div className="modal-content">
                <div className="text-end pt-3 pe-3 mb-n3">
                  <button
                    type="button"
                    className="btn-close"
                    data-bs-dismiss="modal"
                    aria-label="Close"
                    onClick={closeHandler}
                  ></button>
                </div>

                <div className="modal-body p-5 mb-3 text-center">
                  <div className="form-group">
                    <label className="mb-2">
                      {t("enter transport document number")}
                    </label>
                    <h4>{orderRef}</h4>
                    <input
                      className="form-control"
                      value={orderRef}
                      type="text"
                      onChange={(e) => {
                        setOrderRef(e.target.value);

                        localStorage.setItem("orderRef", e.target.value);
                      }}
                      onKeyDown={detectEnterPress}
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>

          {/* <!-- chat Modal -->   */}
          <div
            className="modal fade "
            id="showChatData"
            tabIndex="-1"
            aria-labelledby="editodernumLabel"
            aria-hidden="true"
          >
            <div className="modal-dialog modal-dialog-centered">
              <div className="modal-content">
                <div className="text-end pt-3 pe-3 mb-n3">
                  <button
                    type="button"
                    className="btn-close"
                    data-bs-dismiss="modal"
                    aria-label="Close"
                    onClick={closeChatModelHandler}
                  ></button>
                </div>

                <div className="modal-body p-5 mb-3 text-center">
                  <div className="form-group">
                    <h1>{t("Note")}</h1>
                    <div className="chatBoxInner">
                      <textarea value={itemNote} name="description" disabled />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>

          {/* Chat Data */}
          <div
            className={`customChat-menu chatFixed ${isOpenChat ? "show" : ""}`}
          >
            <button className="closesetting" onClick={() => setIsOpenChat(false)}>
              <i className="far fa-times"></i>
            </button>

            <div className="chatBoxWarp">
              <div className="chatBox">
                <div className="chatBoxInner">
                  <label>{t("insert note")}</label>
                  <textarea
                    value={comment?.description}
                    name="description"
                  // onChange={(e) => {
                  //   setDescription(e.target.value);
                  // }}
                  />
                </div>

                <div className="damagedBox">
                  <div className="form-check">
                    <label className="form-check-label" htmlFor="danneggiato">
                      {t("damaged product")}
                    </label>
                    <input
                      className="form-check-input"
                      type="checkbox"
                      id="danneggiato"
                      name="danneggiato"
                      checked={comment?.damaged}
                    // onChange={(e) => setDanneggiato(e.target.checked)}
                    />
                  </div>

                  <div className="">
                    <div className="row align-items-center">
                      <div className="col-auto">
                        <label>{t("enter missing quantity")}</label>
                      </div>
                      <div className="col-5">
                        <div className="themeInput themeIS">
                          <input
                            type="text"
                            className="form-control"
                            name="chatMissingQuantity"
                            id="chatMissingQuantity"
                            value={comment?.missing_quantity}
                          />
                          <button type="button" className="themeIS-btn">
                            <i className="fas fa-pencil-alt"></i>
                          </button>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      ) : (
        history.push("/")
      )}
    </>
  );
};

export default RicevimentoLista;
