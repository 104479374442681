import DashboardLayout from "../../components/layout/dashboard-layout";

import { API } from "../../constant/api";
import { useDispatch, useSelector } from "react-redux";
import { useLocation, useHistory } from "react-router-dom";
const BACKEND_URL = API.Base_Url;

const Dashboard1 = () => {
  const history = useHistory();
  const dispatch = useDispatch();
  const { userRoleId } = useSelector((state) => state.auth);
  return (
    <>
      {userRoleId && userRoleId === 5 ? (
        <>
          <DashboardLayout title="Dashboard 1">
            <div className="dashboard-container mt-5 ms-5">
              <iframe
                src={`${BACKEND_URL}show_dash`}
                width="100%"
                height="1277px"
                title="Dashboard"
              ></iframe>
            </div>
          </DashboardLayout>
        </>
      ) : (
        history.push("/")
      )}
    </>
  );
};

export default Dashboard1;
