import React, { useEffect, useState, useRef } from "react";
import { WEIGHTICON } from "../../Utils/SVG";
import useRequest from "../../../hooks/useRequest";
import { useSelector, useDispatch } from "react-redux";
import { updateLoading } from "../../../store/auth/action";
import { useTranslation } from "react-i18next";
import { toast } from "react-toastify";

const FatherProductModificationList = (props) => {
  const { product, isEdit, index, fatherProducts, setFatherProducts, setBarcode } = props;
  
  let relationship = product.type ?? product.relationship;
  let name = product?.description;
  let id_article = product.id_father ?? product.id_article;
  // let fatherId = product.id_father ?? product.id;
  let quantity = product.quantity;
  let id = product.id;

  const [t, i18n] = useTranslation("translation");
  const [weight, setWeight] = useState(product.weight_father ? product.weight_father : 0);
  const [searchQuery, setSearchQuery] = useState("");
  const [templateProductData, setTemplateProductData] = useState([]);
  const { request: productSearchRequest, response: productSearchResponse } =
    useRequest();
  const [toSearch, setToSearch] = useState(isEdit !== true);
  const [isFirstSearch, setIsFirstSearch] = useState(true);

  const searchQueryRef = useRef();
  const dispatch = useDispatch();
  const [open, setOpen] = useState(false);
  const { request: lottosRequest, response: lottosResponse } = useRequest();
  const { request: barcodeRequest, response: barcodeResponse } = useRequest();
  
  const [lottos, setLottos] = useState(product?.lottos.length > 0 ? product?.lottos : []);
  const [barcodes, setBarcodes] = useState([]);
  const { request: deleteJobProductRequest, response: deleteJobProductResponse } =
    useRequest();

  useEffect(() => {
    setSearchQuery(name);
  }, [name]);

  useEffect(() => {
    barcodeRequest("POST", "production-barcodes", {
      batch_code: product.batch_father,
      id_article: id_article
    });
  }, [product.batch_father])

  useEffect(() => {
    if (productSearchResponse && productSearchResponse.status === "SUCCESS") {
      /*
        name: "Agnello da latte porzionato (coscio, spalla, carrè) da 1 kg. ca."
        id_article: 7485
        kg_per_pieces: 1
        um: "KG"
        */

      setTemplateProductData(productSearchResponse.data.products);
    }
  }, [productSearchResponse]);

  useEffect(() => {
    if (searchQuery) {
      if (searchQuery.trim().length > 0 && toSearch && !isFirstSearch) {
        searchQueryRef.current = setTimeout(() => {
          productSearchRequest("GET", `products-data?name=${searchQuery}`);
          dispatch(updateLoading({ loading: false }));
        }, 1000);
      }
    }
    return () => {
      clearTimeout(searchQueryRef.current);
      setIsFirstSearch(false);
    };
  }, [searchQuery]);
  
  const lottoHandler = (id_article) => {
    if (id_article) {
      lottosRequest("GET", `get-lotto/${id_article}`);
    }
  };

  const removeFatherDataHandler = (id) => {
    const rows = [...fatherProducts];
    const index = rows.findIndex((row) => row.id === id);
    const product = rows[index]
    rows.splice(index, 1);
    setFatherProducts(rows);
    if(product?.id_job_exec_father){
      deleteJobProductRequest('POST', 'delete-job-product', {
        id_job_exec: product.id_job_exec_father,
        type: 'father'
      })
    }
  };

  // useEffect(() => {
  //   if(deleteJobProductResponse && deleteJobProductResponse?.status === "SUCCESS"){
  //     toast.success(deleteJobProductResponse?.message);
  //   }else{
  //     toast.error(deleteJobProductResponse?.message);
  //   }
  // }, [deleteJobProductResponse])

  const productFilterHandler = (data, id) => {
    const values = [...fatherProducts];
    const index = values.findIndex((prod) => prod.id === id);

    values[index] = { ...values[index], ...data };
    setLottos([]);
    setFatherProducts(values);
  };

  const setLottoHandler = (lotto, id) => {
    if (lotto == "return") {
      return;
    }
    const rows = [...fatherProducts];
    const index = rows.findIndex((prod) => prod.id_father === id);
    rows[index] = { ...rows[index], lotto };
    let batch_father = lotto
    rows[index] = { ...rows[index], batch_father };
    setFatherProducts(rows);

    barcodeRequest("POST", "production-barcodes", {
      batch_code: lotto,
      id_article: id
    });
  };

  useEffect(() => {
    if (
      barcodeResponse &&
      barcodeResponse?.status === "SUCCESS"
    ) {
      setBarcodes(barcodeResponse?.data?.barcodes);
    }
  }, [barcodeResponse]);

  useEffect(() => {
    if (lottosResponse) {
      if (lottosResponse.status === "SUCCESS") {
        setLottos(lottosResponse?.data?.lotto);
      }
    }
  }, [lottosResponse]);

  const updateWeightHandler = (value, type) => {
    let newData;
    if(type == "input"){
      newData = fatherProducts.map((a) => {
        if (a.id === index) {
          a.weight_father = +value;
          setWeight(+value)
        }
        return a;
      });
    }else if(type == "barcode"){
      barcodes.forEach((data) => {
        if (value === data.barcode) {
          newData = fatherProducts.map((a) => {
            if (a.id === index) {
              let weight = +data.weight / +data.pieces
              setWeight(weight)
              a.weight_father = weight;
              a.barcode = data.barcode;
            }
            return a;
          });
        }
      });
    }
    setFatherProducts(newData);
  };


  const barcodeHandler = (value, id) => {
    // product.barcode = value
    if (relationship === "main") {
      setBarcode(value);
    }
    updateWeightHandler(value, "barcode");
  };

  return (
    <>
      <ul
        className={
          relationship === "main"
            ? "list-unstyled batchyellowColor"
            : "list-unstyled"
        }
      >
        <>
          <li>
            <label>
              {t("FATHER")} {index + 1}
            </label>
          </li>

          <li className="lotW">
            <div className="batchWorkList-ml">
              <label>{t("lot")}</label>
              {lottos.length === 0 ? (
                <input
                  className="form-control"
                  type="text"
                  value={product?.batch_father}
                  onClick={() => lottoHandler(id_article)}
                />
              ) : (
                <select
                  onChange={(e) => setLottoHandler(e.target.value, id_article)}
                  className="form-control form-select"
                >
                  <option value="return" hidden>
                    {" "}
                    {t("select the lot")}
                  </option>
                  {lottos &&
                    lottos.map((lotto, idx) => (
                      <option value={lotto?.batch_code} key={idx} selected={lotto?.batch_code === product?.batch_father ? true : false}>
                        {lotto?.batch_code}
                      </option>
                    ))}
                </select>
              )}
            </div>
          </li>
          <li className="lotW">
            <div className="batchWorkList-ml">
              <label>{t("barcode")}</label>
              {lottos.length === 0 ? (
                <input
                  className="form-control"
                  type="text"
                  value={product?.barcode}
                />
              ) : (
                <select
                  onChange={(e) => barcodeHandler(e.target.value, id)}
                  className="form-control form-select"
                >
                  <option value="return" hidden>
                    {" "}
                    {t("select the barcode")}
                  </option>
                  {barcodes &&
                    barcodes.map((barcode, idx) => (
                      <option value={barcode?.barcode} key={idx} 
                        selected={barcode.barcode === product.barcode ? true : false}
                      >
                        Weight: {barcode.weight} &nbsp; Barcode: {barcode.barcode}{" "}
                        &nbsp;
                      </option>
                    ))}
                </select>
              )}
            </div>
          </li>
          <li className="lotW">
            <div className="batchWorkList-ml">
              <label>{t("ID_ARTICLE")}</label>

              <span
                style={
                  relationship === "main_father"
                    ? { color: "#182749" }
                    : { color: "#fff" }
                }
              >
                {id_article}
              </span>
            </div>
          </li>

          <li>
            <div className="batchWorkList-ml">
              <label>{t("ARTICLE")}</label>
              <div className="position-relative w-100">
                <input
                  className="form-control"
                  type="text"
                  value={searchQuery}
                  onChange={(e) => {
                    setSearchQuery(e.target.value);
                    setToSearch(true);
                  }}
                />

                <ul className="list-unstyled mb-0 filterCustom-drop">
                  {templateProductData &&
                    templateProductData.map((product, index) => (
                      <li
                        key={index}
                        onClick={() => {
                          productFilterHandler(
                            {
                              description: product.description,
                              weight_father: product.kg_per_pieces,
                              macrocat: product.macrocat,
                              id_father: product.id_article,
                              // id_article: product.id_article,
                            },
                            id
                          );
                          setTemplateProductData([]);
                          setToSearch(false);
                        }}
                      >
                        {product.id_article} - {product.description}
                      </li>
                    ))}
                </ul>
              </div>
            </div>
          </li>

          <li className="theoreticalW">
            <div className="batchWorkList-ml">
              <div className="d-flex align-items-center w-100">
                <WEIGHTICON />
                <label>{t("theoretical weight (KG)")}</label>
              </div>
              <input
                className="form-control weightInput"
                type="text"
                value={weight}
                onChange={(e) => {
                  updateWeightHandler(e.target.value, "input");
                }}
              />
            </div>
          </li>

          <li className="actionW ms-auto">
            <div className="batchWorkList-ml">
              <button onClick={() => removeFatherDataHandler(id)}>
                <i className="far fa-minus-circle"></i>
              </button>
            </div>
          </li>
        </>
      </ul>
    </>
  );
};

export default FatherProductModificationList;
