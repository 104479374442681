import React from "react";
import { Link } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { logout } from "../../store/auth/action";
import { USERPROFILEICON, LOGOUTICON } from "../../components/Utils/SVG";
import Translator from "../../components/translation/translate";
import { useTranslation } from "react-i18next";
import QrScanner from "qr-scanner";

const Dashboard = () => {
  const dispatch = useDispatch();
  const { userId, userRoleId } = useSelector((state) => state.auth);
  const [t, i18n] = useTranslation("translation");
  const loginTime = new Date().toLocaleString();
  const style = { cursor: 'default' }

  return (
    <div className="bg-with-h100 dashbox-page">
      <div className="headerDashboard">
        <div className="headerDashboard-logo">
          <Link to="/">
            <img src="/img/logo.png" alt="logo" />
          </Link>
        </div>
        {userRoleId === 5 && (
          <div className="otherLinks">
            <Link to="/utente">
              <span>{t("ADD USER")}</span>
            </Link>
          </div>
        )}
        <Translator />

        <div className="headerDashboard-right ms-2 ms-md-3">
          <div className="headerDashboard-user">
            <USERPROFILEICON />
            <div className="headerDashboard-text">
              <span>ID : {userId}</span>
              <small>{loginTime}</small>
            </div>
          </div>

          <Link to="" onClick={() => dispatch(logout())} className="hd-signout">
            <LOGOUTICON />
          </Link>
        </div>
      </div>
      <div className="dashboardbox-wrap">
        <div className="pentagonBox-wrap">
          <div className="pentagonBoxlines">
            <span></span>
            <span></span>
            <span></span>
            <span></span>
            <span></span>
          </div>
          <div className="pentagonlinks">
            {userRoleId === 5 || userRoleId === 1 ? (
              <Link to="/ricezione">
                <img src="/img/RICEVIMENTO.svg" alt="Ricezione" />
                <span>{t("RECEIPT")}</span>
              </Link>
            ):(
              <Link to="/" style={style}>
                <img src="/img/RICEVIMENTO.svg" alt="Ricezione" />
                <span>{t("RECEIPT")}</span>
              </Link>
            )}

            {userRoleId === 5 || userRoleId === 4 ? (
              <Link to="/raccolta">
                <img src="/img/MAGAZZINO.svg" alt="MAGAZZINO" />
                <span>{t("Picking")}</span>
              </Link>
            ):(
              <Link to="/" style={style}>
                <img src="/img/MAGAZZINO.svg" alt="MAGAZZINO" />
                <span>{t("Picking")}</span>
              </Link>
            )}
            
            {userRoleId === 5 || userRoleId === 3 ? (
              <Link to="/spedizione">
                <img src="img/SPEDIZIONE.svg" alt="SPEDIZIONE" />
                <span>{t("SHIPMENT")}</span>
              </Link>
            ):(
              <Link to="/" style={style}>
                <img src="img/SPEDIZIONE.svg" alt="SPEDIZIONE" />
                <span>{t("SHIPMENT")}</span>
              </Link>
            )}
            
            <Link to="/movimentazioni">
              <img src="/img/MOVIMENTAZIONI.svg" alt="MOVIMENTAZIONI" />
              <span>{t("MOVEMENTS")}</span>
            </Link>

            {userRoleId === 5 || userRoleId === 2 ? (
              <Link to="/produzione">
                <img src="/img/PRODUZIONE.svg" alt="PRODUZIONE" />
                <span>{t("PRODUCTION")}</span>
              </Link>
            ):(
              <Link to="/" style={style}>
                <img src="/img/PRODUZIONE.svg" alt="PRODUZIONE" />
                <span>{t("PRODUCTION")}</span>
              </Link>
            )}
            
            {userRoleId === 5 ? (
              <Link
                to="/dashboard1"
                style={{
                  backgroundImage: 'url("/img/pentagonShape-white.svg")',
                }}
              >
                <img src="img/DASHBOARD.svg" alt="DASHBOARD" className="mt-3" />
                <span>{t("PANEL")}</span>
              </Link>
            ) : (
              <Link
                to="/"
                style={{
                  backgroundImage: 'url("/img/pentagonShape-blue.svg")',
                }}
              >
                <img
                  src="/img/logo-dashboard.png"
                  alt="logo"
                  className="mt-3"
                />
                {/* <span>PANNELLO</span> */}
              </Link>
            )}
          </div>
          <div
            className="pentagonBox"
            style={{ backgroundImage: 'url("img/pentagonShape.svg")' }}
          ></div>
        </div>
      </div>
    </div>
  );
};

export default Dashboard;
